import { CurrencyInfo } from "./ICurrencyInfo";

export const currencyDictionary: { [key: string]: CurrencyInfo } = {
        "AED": {"currency": "United Arab Emirates Dirham", "currencyCode": "AED", "image": "/assets/images/countires/UAE.png"},
        "AUD": {"currency": "Australian Dollar", "currencyCode": "AUD", "image": "/assets/images/countires/AUST.png"},
        "CAD": {"currency": "Canadian Dollar", "currencyCode": "CAD", "image": "/assets/images/countires/CAD.png"},
        "CHF": {"currency": "Swiss Franc", "currencyCode": "CHF", "image": "/assets/images/countires/CHF.png"},
        "EUR": {"currency": "Euro", "currencyCode": "EUR", "image": "/assets/images/countires/EUR.png"},
        "GBP": {"currency": "British Pound", "currencyCode": "GBP", "image": "/assets/images/countires/GBP.png"},
        "HUF": {"currency": "Hungarian Forint", "currencyCode": "HUF", "image": "/assets/images/countires/HUN.png"},
        "JPY": {"currency": "Japanese Yen", "currencyCode": "JPY", "image": "/assets/images/countires/JPY.png"},
        "USD": {"currency": "United States Dollar", "currencyCode": "USD", "image": "/assets/images/countires/USA.png"},
};