import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import { ChangeEvent, useState } from 'react';
import { useCreateKrakenWalletMutation } from '../../api/kraken.api';
import { useCreateCryptocomWalletMutation } from '../../api/cryptocom.api';
import { useCreateRevolutWalletMutation } from '../../api/revolut.api';
import { Divider } from '@mui/material';
import { Alert } from '@mui/material';
import { useCreateBinanceWalletMutation } from '../../api/binance.api';
const { REACT_APP_API_ENDPOINT } = process.env;

type Props = {};

interface AttachedFilePreviewProps {
    attachedFiles: File[];
}

enum AuthTypes {
    OAUTH,
    PUBLIC_PRIVATE,
    FILE
}

const exchangeItems = [
    { name: 'Coinbase', iconLink: "/assets/images/exchanges/coinbase.jpeg", apiUrl: `${REACT_APP_API_ENDPOINT}/api/coinbase/auth`, connectionType: AuthTypes.OAUTH, function: "coinbaseCall", disabled: false, guideLink: '/p/guides/coinbase' },
    { name: 'Kraken', iconLink: "/assets/images/exchanges/kraken.jpeg", apiUrl: `${REACT_APP_API_ENDPOINT}/api/kraken/wallet/create`, connectionType: AuthTypes.PUBLIC_PRIVATE, function: "krakenCall", disabled: false, guideLink: '/p/guides/kraken' },
    { name: 'Crypto.com', iconLink: "/assets/images/exchanges/cryptocom.jpeg", apiUrl: `${REACT_APP_API_ENDPOINT}/api/cryptocom/wallet/create`, connectionType: AuthTypes.PUBLIC_PRIVATE, function: "cryptocomCall", disabled: false, guideLink: '/p/guides/crypto-com' },
    { name: 'Revolut', iconLink: "/assets/images/exchanges/revolut.jpeg", apiUrl: `${REACT_APP_API_ENDPOINT}/api/revolut/wallet/create`, connectionType: AuthTypes.FILE, function: "revolutCall", disabled: false, guideLink: '/p/guides/revolut' },
    { name: 'ByBit', iconLink: "/assets/images/exchanges/bybit.jpeg", apiUrl: `${REACT_APP_API_ENDPOINT}/api/bybit/wallet/create`, connectionType: AuthTypes.PUBLIC_PRIVATE, function: "bybitCall", disabled: false, guideLink: '/p/guides/bybit' },
    { name: 'OKX', iconLink: "/assets/images/exchanges/okx.png", apiUrl: '', disabled: true },
    { name: 'Gate.io', iconLink: "/assets/images/exchanges/gateio.jpeg", apiUrl: '', disabled: true },
    { name: 'KuCoin', iconLink: "/assets/images/exchanges/kucoin.png", apiUrl: '', disabled: true },
    { name: 'Binance', iconLink: "/assets/images/exchanges/binance.jpeg", apiUrl: `${REACT_APP_API_ENDPOINT}/api/binance/wallet/create`, connectionType: AuthTypes.PUBLIC_PRIVATE, function: "binanceCall", disabled: false, guideLink: '/p/guides/binance' },
    { name: 'Bitfinex', iconLink: "/assets/images/exchanges/bitfinex.png", apiUrl: '', connectionType: AuthTypes.PUBLIC_PRIVATE, function: "binanceCall", disabled: false, guideLink: '/p/guides/bitfinex' },
];

const WalletSelector = (props: Props) => {
    const [selectedExchange, setSelectedExchange] = useState<any | null>(null);
    const [walletName, setWalletName] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [apiSecret, setApiSecret] = useState('');
    const [createKrakenWallet] = useCreateKrakenWalletMutation();
    const [createBinanceWallet] = useCreateBinanceWalletMutation();
    const [createRevolutWallet] = useCreateRevolutWalletMutation();
    const [createCryptocomWallet] = useCreateCryptocomWalletMutation();
    const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
    const [syncStarted, setSyncStarted] = useState(false);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const fileList = Array.from(files);
            setAttachedFiles(prevFiles => [...prevFiles, ...fileList]);
        }
    };

    const removeAttachedFile = (index: number) => {
        const updatedFiles = attachedFiles.filter((_, i) => i !== index);
        setAttachedFiles(updatedFiles);
    };

    const formatFileSize = (size: number): string => {
        if (size === 0) return '0 Bytes';

        const k = 1024;
        const decimals = 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
    };
  
    const AttachedFilePreview: React.FC<AttachedFilePreviewProps> = ({ attachedFiles }) => {
        return (
            <div>
                {attachedFiles.map((file, index) => (
                    <div className='flex' key={index}>
                        <div>
                            <FilePresentRoundedIcon style={{ marginRight: '10px', height: '40px' }} />
                        </div>
                        <div>
                            <div className='text-ds-primary' style={{ fontWeight: '500' }}>{file.name}</div>
                            <div style={{ fontWeight: '500' }}>{formatFileSize(file.size)}</div>
                            <button onClick={() => removeAttachedFile(index)}>Remove</button>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const handleWalletNameChange = (event: any) => {
        setWalletName(event.target.value);
    };

    const handleWalletApiKeyChange = (event: any) => {
        setApiKey(event.target.value);
    };

    const handleWalletApiSecretChange = (event: any) => {
        setApiSecret(event.target.value);
    };

    const handleRowClick = (exchange: any) => {
        setSelectedExchange(exchange);
    };

    const handleClosePopup = () => {
        setSelectedExchange(null);
    };

    const handleExchangeSubmit = (exchangeItems: any) => {
        if (exchangeItems.function === "coinbaseCall") {
            handleConnectCoinbase(exchangeItems.apiUrl);
            setSelectedExchange(null);
            setSyncStarted(true);
        }
        if (exchangeItems.function === "krakenCall") {
            handleConnectKraken(exchangeItems.apiUrl);
            setSelectedExchange(null);
            setSyncStarted(true);
        }
        if (exchangeItems.function === "binanceCall") {
            handleConnectBinance();
            setSelectedExchange(null);
            setSyncStarted(true);
        }
        if (exchangeItems.function === "cryptocomCall") {
            handleConnectCryptocom(exchangeItems.apiUrl);
            setSelectedExchange(null);
            setSyncStarted(true);
        }
        if (exchangeItems.function === "revolutCall") {
            handleConnectRevolut(attachedFiles[0]);
            setSelectedExchange(null);
            setSyncStarted(true);
        }
    };

    const originalLabelStyle = {
        fontSize: "1rem",
        letterSpacing: "0px",
        transform: "translate3d(0px, 1rem, 0px)"
    };

    const [labelStyle, setLabelStyle] = useState(originalLabelStyle);

    const handleInputFocus = () => {
        setLabelStyle({
            fontSize: "0.75rem", 
            letterSpacing: "0.3px",
            transform: "translate3d(0px, 0.375rem, 0px)"
        });
    };

    const handleInputBlur = () => {
        setLabelStyle(originalLabelStyle);
    };

    const handleConnectCoinbase = (apiUrl: string) => {
        if (apiUrl) {
            window.location.href = apiUrl;
        }
    }

    const handleConnectKraken = async (apiUrl: string) => {
        await createKrakenWallet({ name: walletName, apiKey: apiKey, apiSecret: apiSecret });
    }

    const handleConnectBinance = async () => {
        await createBinanceWallet({ name: walletName, apiKey: apiKey, apiSecret: apiSecret });
    }

    const handleConnectCryptocom = async (apiUrl: string) => {
        await createCryptocomWallet({ name: walletName, apiKey: apiKey, apiSecret: apiSecret });
    }

    const handleConnectRevolut = async (csvTransactions: File) => {
        console.log(csvTransactions);
        const formData = new FormData();
        formData.append('file', csvTransactions);
        formData.append('name', walletName);
        await createRevolutWallet({ name: 'Revolut', csvTransactions: csvTransactions });
    }

    return (
        <div className='content-container'>
            <main className='content-content-full-width'>
                <header className='content-header section header'>
                    <div className='exchanges-header'>
                        <h1 className='text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Tőzsdék</h1>
                        <div className='search-bordered search-container'>
                            <SearchRoundedIcon sx={{height: 24, width: 24}} />
                            <div>
                                <input id='exchange-search-input' type='text' placeholder='Tőzsde keresése' className='search-input' />
                                <div className='search-hidden-label' aria-hidden="true">Tőzsde keresése</div>
                            </div>
                        </div>
                    </div>
                    {syncStarted && (
                        <Alert severity="success" variant='filled' style={{ fontSize: '0.9rem', fontWeight: '600' }}>
                            A tranzakciók szinkronizálása sikeresen megkezdődött. Amint elkészül, e-mailben fogunk értesíteni!
                            <br/>
                            <span style={{ fontWeight: '500', fontSize: '.9rem' }}>NOTE! Bizonyos esetekben az adatok szinkronizálása akár 2 óráig is eltarthat (API limitáció)</span>
                        </Alert>
                    )}
                </header>
                <section className='primary-content'>
                    <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
                        <div className='flex items-center justify-between mb-ds-7'>
                            <div className='flex flex-col'>
                                <span className='text-ds-primary text-ds-celias-20-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Válassz tőzsdét</span>
                            </div>
                        </div>
                        <div className='select-exchange-display-mode'>
                            {exchangeItems.map((exchange, index) => (
                                <div className={`flex items-center${index % 4 === 0 ? ' flex-wrap' : ''}`} key={index}>
                                    <button onClick={() => handleRowClick(exchange)} disabled={exchange.disabled} className='flex px-ds-5 width-200 items-center select-wallet-border py-ds-4 ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 text-ds-button-primary-none hover:text-ds-button-primary-none-hover bg-ds-button-primary-none hover:bg-ds-button-primary-none-hover active:bg-ds-button-primary-none-active br-1r box-border relative focus-visible:outline focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-pointer [-webkit-appearance:button] focus:outline-ds-button'>
                                        <img className='br-1r' width={50} height={50} src={exchange.iconLink} />
                                        <span className='px-ds-5 text-ds-primary text-ds-celias-20-medium'>{exchange.name}</span>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    {selectedExchange && (
                    <div id="overlay" className="popup-container">
                        <div className="flex max-h-full w-full flex-col items-center">
                            <div role="dialog" style={{paddingTop: 0}} tabIndex={1} className="br-1r py-ds-dialog bg-ds-dialog backdrop-blur-ds-dialog focus-visible:outline-ds-dialog box-border flex max-h-full flex-col focus-visible:outline-2 animate-[ds-dialog-fadeIn_0.2s_forwards] max-w-full">
                                <div className="text-ds-celias-20-medium px-ds-dialog gap-ds-2 flex flex-row items-center justify-between exchange-modal-header">
                                    <div className="gap-ds-2 flex flex-row items-center">
                                        <div className="gap-ds-2 flex flex-col exchange-modal-header-title">
                                            <h2 className="text-ds-primary text-ds-celias-20-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">{selectedExchange.name} csatlakoztatása</h2>
                                        </div>
                                    </div>
                                    <div className="[&>*]:p-ds-2 [&>*]:m-ds-0 -me-1 flex flex-row">
                                        <button onClick={handleClosePopup} className="flex p-ds-3 ms-ds-1 me-ds-1 mt-ds-1 mb-ds-1 text-ds-button-neutral-none hover:text-ds-button-neutral-none-hover bg-ds-button-neutral-none hover:bg-ds-button-neutral-none-hover active:bg-ds-button-neutral-none-active rounded-ds-round box-border relative focus-visible:outline focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-pointer [-webkit-appearance:button] focus:outline-ds-button">
                                            <CloseRoundedIcon className='text-ds-primary' />
                                        </button>
                                    </div>
                                </div>
                                <div className="overflow-auto">
                                    <div className="px-ds-dialog overflow-visible">
                                        <div className="modal-content-container">
                                            <label className='hidden'>Enter wallet name</label>
                                            <div className='add-exchange-modal-name-container'>
                                                <label className='add-exchange-modal-name-placeholder' style={labelStyle}>Adjon egy nevet a tárcának</label>
                                                <input type='text' className='exchange-modal-wallet-name-input input-style' name='walletName' onChange={handleWalletNameChange} onFocus={handleInputFocus} onBlur={handleInputBlur} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {selectedExchange.connectionType === AuthTypes.PUBLIC_PRIVATE && (
                                    <div>
                                        <div className="overflow-auto">
                                            <div className="px-ds-dialog overflow-visible">
                                                <div className="modal-content-container">
                                                    <label className='hidden'>Enter API key</label>
                                                    <div className='add-exchange-modal-name-container'>
                                                        <label className='add-exchange-modal-name-placeholder' style={labelStyle}>API Key megadása</label>
                                                        <input type='text' className='exchange-modal-wallet-name-input input-style' name='apiKey' onChange={handleWalletApiKeyChange} onFocus={handleInputFocus} onBlur={handleInputBlur} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overflow-auto">
                                            <div className="px-ds-dialog overflow-visible">
                                                <div className="modal-content-container">
                                                    <label className='hidden'>Enter API secret</label>
                                                    <div className='add-exchange-modal-name-container'>
                                                        <label className='add-exchange-modal-name-placeholder' style={labelStyle}>API Secret megadása</label>
                                                        <input type='text' className='exchange-modal-wallet-name-input input-style' name='apiSecret' onChange={handleWalletApiSecretChange} onFocus={handleInputFocus} onBlur={handleInputBlur} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='add-exchange-modal-footer' style={{paddingBottom: 0}}>
                                            <button onClick={() => handleExchangeSubmit(selectedExchange)} className='inline-block no-underline whitespace-nowrap text-ds-button-md px-ds-7 py-ds-4 ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 text-ds-button-primary-high hover:text-ds-button-primary-high-hover bg-ds-button-primary-high hover:bg-ds-button-primary-high-hover active:bg-ds-button-primary-high-active w-full shadow-ds-button-primary-high hover:shadow-ds-button-primary-high-hover active:shadow-ds-button-primary-high-active rounded-ds-round box-border relative focus-visible:outline focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-pointer [-webkit-appearance:button] focus:outline-ds-button'>
                                                <span className='flex items-center justify-center'>
                                                    {selectedExchange.name} csatlakoztatása
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {selectedExchange.connectionType === AuthTypes.FILE && (
                                    <div>
                                        <div className="overflow-auto">
                                            <div className="px-ds-dialog overflow-visible">
                                                <div className="modal-content-container">
                                                    <label className='hidden'>Enter API key</label>
                                                    <div className='add-exchange-modal-name-container' style={{ alignItems: 'center' }}>
                                                        <div className='flex flex-col file-attach-style' style={{ backgroundColor: '#F7F7FB', borderWidth: '1px', borderRadius: '0.5rem', textAlign: 'center', justifyContent: 'center' }}>
                                                            <div style={{ marginTop: '20px' }}>
                                                                <input type='file' id="upload" multiple onChange={handleOnChange} className='file-attach-input button-border' placeholder='Choose file' hidden />
                                                                <label className="button-border" style={{ height: '100%' }} htmlFor='upload'>Fájl csatolása</label>
                                                            </div>
                                                            <div style={{ marginTop: '20px' }}>
                                                                Drag and drop
                                                            </div>
                                                        </div>
                                                        {attachedFiles.length > 0 && (
                                                            <div style={{ marginTop: '15px' }}>
                                                                {attachedFiles.map((file, index) => (
                                                                    <div key={index} className='border flex' style={{ borderRadius: '0.5rem', padding: '5px', justifyContent: 'space-between', marginTop: '5px' }}>
                                                                        <div style={{ marginLeft: '10px' }}>
                                                                            <div className='flex'>
                                                                                <div>
                                                                                    <FilePresentRoundedIcon style={{ marginRight: '10px', height: '40px' }} />
                                                                                </div>
                                                                                <div>
                                                                                    <div className='text-ds-primary' style={{ fontWeight: '500' }}>{file.name}</div>
                                                                                    <div style={{ fontWeight: '500' }}>{formatFileSize(file.size)}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <CloseRoundedIcon style={{ height: '40px', marginRight: '10px', cursor: 'pointer' }} onClick={() => removeAttachedFile(index)} />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='add-exchange-modal-footer' style={{paddingBottom: 0}}>
                                            <button onClick={() => handleExchangeSubmit(selectedExchange)} className='inline-block mt-ds-5 no-underline whitespace-nowrap text-ds-button-md px-ds-7 py-ds-4 ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 text-ds-button-primary-high hover:text-ds-button-primary-high-hover bg-ds-button-primary-high hover:bg-ds-button-primary-high-hover active:bg-ds-button-primary-high-active w-full shadow-ds-button-primary-high hover:shadow-ds-button-primary-high-hover active:shadow-ds-button-primary-high-active rounded-ds-round box-border relative focus-visible:outline focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-pointer [-webkit-appearance:button] focus:outline-ds-button'>
                                                <span className='flex items-center justify-center'>
                                                    Import
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {selectedExchange.connectionType === AuthTypes.OAUTH && (
                                    <div>
                                        <div className="overflow-auto">
                                        </div>
                                        <div className='add-exchange-modal-footer' style={{paddingBottom: 0}}>
                                            <button onClick={() => handleExchangeSubmit(selectedExchange)} className='inline-block mt-ds-5 no-underline whitespace-nowrap text-ds-button-md px-ds-7 py-ds-4 ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 text-ds-button-primary-high hover:text-ds-button-primary-high-hover bg-ds-button-primary-high hover:bg-ds-button-primary-high-hover active:bg-ds-button-primary-high-active w-full shadow-ds-button-primary-high hover:shadow-ds-button-primary-high-hover active:shadow-ds-button-primary-high-active rounded-ds-round box-border relative focus-visible:outline focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-pointer [-webkit-appearance:button] focus:outline-ds-button'>
                                                <span className='flex items-center justify-center'>
                                                    {selectedExchange.name} csatlakoztatása
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                                    <Divider style={{ width: '90%' }} />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1.5rem' }}>
                                    <a href={selectedExchange.guideLink} target='_blank'>Útmutató a kiválasztott tőzsde hozzáadásához</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </section>
            </main>
        </div>
    )
};

export default WalletSelector;
