import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
const { REACT_APP_API_ENDPOINT } = process.env;

export const coinbaseApi = createApi({
    reducerPath: "coinbaseApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${REACT_APP_API_ENDPOINT}/api/coinbase` }),
    endpoints: (build) => ({
        getPrimaryAccountTransactions: build.query<any, undefined>({
            query: () => ({ url: "/" }),
        }),
    }),
});

export const { useGetPrimaryAccountTransactionsQuery } = coinbaseApi;