import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';

export default function HelpMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <React.Fragment>
        <Box className='calculation-button-settings' sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Tooltip title="Help">
                <IconButton
                className='Menu_button'
                onClick={handleClick}
                size="small"
                sx={{ ms: 2 }}
                >
                    <HelpOutlineOutlinedIcon sx={{ width: 24, height: 24, color: "#686b82" }} />
                </IconButton>
            </Tooltip>
        </Box>
        <Menu className='calculation-button-settings'
            id="help-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
            }}
            style={{
            position: 'absolute',
            zIndex: 100000,
            borderRadius: "1rem",
            }}
        >
            <a href='/p/guides'>
                <MenuItem className='account-menu-menuitem'>
                    <div className='account-menu-option-div'>
                        <QuizRoundedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' />
                        <span className='text-ds-neutral text-ds-plex-16-regular'>Útmotatók</span>
                    </div>
                </MenuItem>
            </a>
            <a href='/p/support'>
                <MenuItem className='account-menu-menuitem'>
                    <div className='account-menu-option-div'>
                        <SupportAgentOutlinedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' />
                        <span className='text-ds-neutral text-ds-plex-16-regular'>Támogatás</span>
                    </div>
                </MenuItem>
            </a>
            <a href='/p/feedback'>
                <MenuItem className='account-menu-menuitem'>
                    <div className='account-menu-option-div'>
                        <TextsmsOutlinedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' />
                        <span className='text-ds-neutral text-ds-plex-16-regular'>Visszajelzés</span>
                    </div>
                </MenuItem>
            </a>
        </Menu>
    </React.Fragment>
  );
}
