import { Box, Button, TextField, Link as MuiLink , Typography } from '@mui/material';
import { useNavigate } from "react-router";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { User } from '../../../models/User';
import { useLoginMutation } from '../../../api/auth.api';
import { useAppDispatch } from '../../../app/hooks';
import { setAuthState } from '../../../slices/auth.slice';
import Alert from '@mui/material/Alert';

const LoginForm: React.FC = () => {
    const [email, setEmail] = useState("");
    const [emailErrored, setEmailErrored] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState("");
    const [password, setPassword] = useState("");
    const [passwordErrored, setPasswordErrored] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [login] = useLoginMutation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isEmailValid = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setErrorMessage("");

            if (!email) {
                setEmailErrored(true);
                setEmailErrorText("Kérlek add meg az e-mail címed");
            } else if (!isEmailValid(email)) {
                setEmailErrored(true);
                setEmailErrorText("Kérlek adj meg egy érvényes e-mail címet");
            } else {
                setEmailErrorText("");
            }

            setPasswordErrored(!password);

            if (!email || !password || !isEmailValid(email)) {
                return;
            }

            const response = (await login({ email, password })) as { data: User };
            
            if ('error' in response) {
                const error: any = response.error;
                const data = error?.data;
                const statusCode = data?.statusCode;
                const message = data?.message;
                const status = error?.status;

                if (statusCode === 401 && message === "Credentials are invalid") {
                    setErrorMessage("Hibás e-mail vagy jelszó. Kérlek ellenőrizd az adataidat, és próbáld újra.");
                    return;
                }

                if (status === 401 && message === "Invalid credentials") {
                    setErrorMessage("A kért fiókot még nem aktiválták.");
                    return;
                }

                if (statusCode === 404 && message?.includes("User does not exists")) {
                    setErrorMessage("A kért felhasználó nem létezik.");
                    return;
                }
            }
            dispatch(setAuthState({ user: response.data }));
            window.location.href = "/p";
        } catch (err) {
            setErrorMessage("Incorrect email or password. Please check your credentials and try again.");
            console.error("error", err);
        }
    }

    return (
        <div className='login-content-box'>
            <Typography
                textAlign="center"
                fontSize="3rem"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="125%"
                letterSpacing="-0.036rem"
                className="main-name"
            >
                Kontri.io
            </Typography>
            <div className='login-content-box-content'>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="0.5rem"
                >
                    <Typography
                        fontFamily="Inter"
                        fontSize="1.7rem"
                        fontStyle="normal"
                        fontWeight="600"
                        lineHeight="2.25rem"
                        letterSpacing="-0.01406rem"
                    >
                        Kezdj hozzá most ✨
                    </Typography>
                </Box>
                {errorMessage && (
                    <Alert severity="error" style={{ width: '100%', marginBottom: '0rem' }}>
                        {errorMessage}
                    </Alert>
                )}
                <form onSubmit={handleLogin} style={{ width: '100%' }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="1rem"
                    alignSelf="stretch"
                >
                    <TextField
                        label="Email"
                        fullWidth
                        type="email"
                        required
                        helperText={emailErrorText}
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value);
                            setEmailErrorText("");
                        }}
                        error={emailErrored}
                    />
                    <TextField
                        label="Jelszó"
                        fullWidth
                        type="password"
                        required
                        helperText={passwordErrored && "Nem adtál meg jelszót."}
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value);
                        }}
                        error={passwordErrored}
                    />
                    <Button className="login-button" variant="contained" fullWidth type='submit'>
                        <span style={{ color: '#FFF' }}>Bejelentkezés</span>
                    </Button>
                    <Typography>Nincs még fiókod?
                        <Link to="/signup" className="custom-color">     Regisztráció</Link>
                    </Typography>
                    <Typography>
                        <Link to="/reset-password" className="custom-color">     Elfelejtett a jelszó?</Link>
                    </Typography>
                </Box>
                </form>
            </div>
            <Typography>2024 Kontri, Minden jog fenntartva</Typography>
        </div>
    )
};

export { LoginForm };
