import React, { useState } from "react"
import { useGetUserTransactionsQuery } from "../../api/transaction.api";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentUser } from "../../slices/auth.slice";
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { format } from 'date-fns';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import NumberFormatComponent from "../general/NumberFormatComponent";
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import { isCryptoCurrency, isTraditionalCurrency } from "../../features/general-service";
import { currencyDictionary } from "../../general/constants/currencies";
import { cryptoCurrencyDictionary } from "../../general/constants/cryptocurrencies";
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import { Menu, MenuItem } from "@mui/material";
import { SingleWalletDetails } from "../../models/SingleWalletDetails";

type Props = {
    walletDetails: SingleWalletDetails;
};

enum ExchangeImages {
  COINBASE = "/assets/images/exchanges/coinbase.jpeg",
  KRAKEN = "/assets/images/exchanges/kraken.jpeg",
  CRYPTOCOM = "/assets/images/exchanges/cryptocom.jpeg",
  REVOLUT = "/assets/images/exchanges/revolut.jpeg",
  BYBIT = "/assets/images/exchanges/bybit.jpeg",
  OKX = "/assets/images/exchanges/okx.png",
  GATEIO = "/assets/images/exchanges/gateio.jpeg",
  KUKOIN = "/assets/images/exchanges/kucoin.png",
  BINANCE = "/assets/images/exchanges/binance.jpeg",
  BITFINEX = "/assets/images/exchanges/bitfinex.png"
}

enum TransactionType {
    FIAT_DEPOSIT = "fiat_deposit",
    FIAT_WITHDRAWAL = "fiat_withdrawal",
    DEPOSIT = "deposit",
    WITHDRAWAL = "withdrawal",
    BUY = "buy",
    SELL = "sell",
    EXCHANGE = "exchange",
    TRANSFER = "transfer",
    REWARD = "reward",
    SEND = "send",
    RECEIVE = "receive"
}

const WalletDetailsTransactionsGrid = ({ walletDetails }: Props) => {
  const user = useAppSelector((state) => selectCurrentUser(state));
  const data = walletDetails.walletTransactions;
  console.log(data)
  const location = useLocation();
  const page = parseInt(new URLSearchParams(location.search).get('page') || '1');
  const transactionsPerPage = 10;
  const startIndex = (page - 1) * transactionsPerPage;
  const endIndex = startIndex + transactionsPerPage;
  const [selectedTransaction, setSelectedTransaction] = useState<any | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleTxSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleTxSettingsClose = () => {
    setAnchorEl(null);
  };

  const handleRowClick = (transaction: any) => {
    setSelectedTransaction(transaction);
  };

  const handleClosePopup = () => {
    setSelectedTransaction(null);
  };

  const paginatedTransactions = data ? data.slice(startIndex, endIndex) : [];
  const totalTransactionsCount = data ? data.length : 0;
  const hasMoreTransactions = endIndex < totalTransactionsCount;

  var totalLength = 0;
  if (paginatedTransactions && paginatedTransactions.length) {
    totalLength = paginatedTransactions.length
  }

  const tbodyHeight = 20 + (totalLength * 80);

  const getIconForTransactionType = (transactionType: string) => {
    switch (transactionType) {
      case TransactionType.FIAT_DEPOSIT:
      case TransactionType.DEPOSIT:
      case TransactionType.REWARD:
      case TransactionType.RECEIVE:
        return <ArrowDownwardRoundedIcon style={{ color: 'green', fontSize: '20px' }} />;
      case TransactionType.FIAT_WITHDRAWAL:
      case TransactionType.WITHDRAWAL:
      case TransactionType.SEND:
        return <ArrowUpwardRoundedIcon style={{ color: 'red', fontSize: '20px' }}  />;
      case TransactionType.BUY:
      case TransactionType.SELL:
      case TransactionType.EXCHANGE:
      case TransactionType.TRANSFER:
        return <SyncAltRoundedIcon style={{ color: 'blue', fontSize: '20px' }}  />;
      default:
        return <SyncAltRoundedIcon style={{ color: 'blue', fontSize: '20px' }}  />;
    }
  };

  const navigate = useNavigate();


  // Function to update the page query parameter in the URL
  const setPage = (newPage: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', newPage);
    navigate({ search: searchParams.toString() });
  };

const nextPage = () => {
    const nextPageNumber = page + 1;
    if (hasMoreTransactions) {
        setPage(nextPageNumber.toString());
    }
};

  const prevPage = () => {
    const prevPageNumber = Math.max(page - 1, 1);
    setPage(prevPageNumber.toString());
  };

  return (
    <>
        <table className='wallet-details-transaction-table'>
            <thead className='general-table'>
                <tr className='assets-table-row'>
                    <th className='assets-table-th min-width-0 table-header-container explore-exchange-column-value' colSpan={1} role='columnheader'>
                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                            <div className='table-cell' aria-colspan={1} role='columnheader'>Kategória</div>
                        </span>
                    </th>
                    <th className='assets-table-th min-width-0 explore-wallet-details-column-change' colSpan={1} role='columnheader'>
                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                            <div className='table-cell wallet-table-align-right' aria-colspan={1} role='columnheader'>Kimenő</div>
                        </span>
                    </th>
                    <th className='assets-table-th min-width-0 transaction-table-header-column-arrow' role='columnheader'>
                    </th>
                    <th className='assets-table-th min-width-0 explore-wallet-details-column-change' colSpan={1} role='columnheader'>
                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                            <div className='table-cell' aria-colspan={1} role='columnheader'>Beérkező</div>
                        </span>
                    </th>
                    {/*<th className='assets-table-th min-width-0 explore-exchange-column-change' colSpan={1} role='columnheader'>
                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                            <div className='table-cell wallet-table-align-right' aria-colspan={1} role='columnheader'>Value</div>
                        </span>
                    </th>*/}
                    <th className='assets-table-th min-width-0 assets-table-right explore-exchange-column-delete' colSpan={1} role='columnheader'>
                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                            <div className='assets-table-right table-cell' aria-colspan={1} role='columnheader'></div>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody className='exchange-table-body table-table-row' style={{minHeight: `${tbodyHeight}px`, overflow: "hidden", display: "block", marginTop: '15px'}}>
                {paginatedTransactions && paginatedTransactions.map((transaction: any, index: number) => {
                    const translateValue = index * 80;
                    console.log(transaction.from_amount);

                    const createdDate = format(new Date(transaction.date), 'MMM dd, yyyy, HH:mm').toString();

                    const isTradCurrencyBase = isTraditionalCurrency(transaction.base);
                    const isTradCurrencyQuote = isTraditionalCurrency(transaction.quote);
                    const isCryptoCurrencyBase = isCryptoCurrency(transaction.base);
                    const isCryptoCurrencyQuote = isCryptoCurrency(transaction.quote);

                    let baseImageSrc = "";
                    let quoteImageSrc = "";

                    if (isTradCurrencyBase) {
                        baseImageSrc = currencyDictionary[transaction.base]?.image;
                    } else if (isCryptoCurrencyBase) {
                        baseImageSrc = cryptoCurrencyDictionary[transaction.base]?.image;
                    }

                    if (isTradCurrencyQuote) {
                        quoteImageSrc = currencyDictionary[transaction.quote]?.image;
                    } else if (isCryptoCurrencyQuote) {
                        quoteImageSrc = cryptoCurrencyDictionary[transaction.quote]?.image;
                    }

                    return (
                    <tr className='exchange-table-row' style={{ transform: `translateY(${translateValue}px)` }} key={index}>
                        <td className='table-tabledata transaction-table-tbody-column-category' role='cell' onClick={() => handleRowClick(transaction)}>
                            <div className='table-cell-div' role='cell'>
                                <div className="wallet-info-cell-name-right">
                                  <div>
                                    <span className='text-ds-primary text-ds-plex-12-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                      <span style={{ verticalAlign: 'middle' }}>{getIconForTransactionType(transaction.type)}</span>
                                      <span style={{ marginLeft: '5px' }}>{transaction.type.toUpperCase()}</span>
                                    </span>
                                  </div>
                                  <div>
                                    <span className='text-ds-neutral text-ds-plex-12-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{createdDate}</span>
                                  </div>
                                </div>
                            </div>
                        </td>
                        <td className='table-tabledata wallet-details-table-tbody-column-value' role='cell' onClick={() => handleRowClick(transaction)}>
                            <div className='table-cell-div' role='cell'>
                                <div className="position-end">
                                    <span className='text-ds-primary text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                      <span style={{ marginLeft: '8px', verticalAlign: 'middle', display: 'inline-block' }}>                                        
                                        { transaction.type === TransactionType.FIAT_DEPOSIT || transaction.type === TransactionType.REWARD || transaction.type === TransactionType.DEPOSIT ? (
                                          ''
                                        ) : (
                                          <div>
                                            <div>
                                              <span><NumberFormatComponent number={transaction.fromAmount} /></span>
                                            </div>
                                            <div>
                                              <span className='text-ds-neutral text-ds-plex-12-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>≈ <NumberFormatComponent number={transaction.fromAmountHuf * -1} /> HUF</span>
                                            </div>
                                          </div>
                                        )}
                                      </span>
                                      <span style={{ marginLeft: '8px', verticalAlign: 'middle' }}>
                                        { transaction.type === TransactionType.FIAT_DEPOSIT ? (
                                          <AccountBalanceRoundedIcon className="text-ds-neutral" style={{ fontSize: '25px' }} />
                                        ) : transaction.type === TransactionType.REWARD ? (
                                          <RedeemOutlinedIcon className="text-ds-neutral" style={{ fontSize: '25px' }} />
                                        ) : transaction.type === TransactionType.DEPOSIT ? (
                                          <LanguageRoundedIcon className="text-ds-neutral" style={{ fontSize: '25px' }} />
                                        ) : (
                                          <img src={baseImageSrc} alt={transaction.base} height={25} />
                                        )}
                                      </span>
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td className='table-tabledata transaction-table-tbody-column-arrow' role='cell' onClick={() => handleRowClick(transaction)}>
                            <div className='table-cell-div' role='cell'>
                                <div>
                                    <span className='text-ds-primary text-ds-plex-14-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'><EastRoundedIcon style={{ fontSize: '20px' }} /></span>
                                </div>
                            </div>
                        </td>
                        <td className='table-tabledata wallet-details-table-tbody-column-value' role='cell' onClick={() => handleRowClick(transaction)}>
                            <div className='table-cell-div' role='cell'>
                                <div>
                                    <span className='text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                      <span style={{ marginRight: '8px', verticalAlign: 'middle' }}>
                                        { transaction.type === TransactionType.FIAT_WITHDRAWAL ? (
                                          <AccountBalanceRoundedIcon className="text-ds-neutral" style={{ fontSize: '25px' }} />
                                        ) : transaction.type === TransactionType.SEND || transaction.type === TransactionType.WITHDRAWAL ? (
                                          <LanguageRoundedIcon className="text-ds-neutral" style={{ fontSize: '25px' }} />
                                        ) : (
                                          <img src={quoteImageSrc} alt={transaction.quote} height={25} />
                                        )}
                                      </span>
                                      <span style={{ marginRight: '8px', verticalAlign: 'middle', display: 'inline-block' }}>
                                        { transaction.type === TransactionType.FIAT_WITHDRAWAL || transaction.type === TransactionType.SEND || transaction.type === TransactionType.WITHDRAWAL ? (
                                          ''
                                        ) : (
                                          <div>
                                            <div>
                                              <span className='text-ds-primary text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>+<NumberFormatComponent number={transaction.toAmount} /></span>
                                            </div>
                                            <div>
                                              <span className='text-ds-neutral text-ds-plex-12-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>≈ <NumberFormatComponent number={transaction.toAmountHuf} /> HUF</span>
                                            </div>
                                          </div>
                                        )}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </td>
                        {/*<td className='table-tabledata transaction-table-tbody-column-value' role='cell'>
                            <div className='table-cell-div justify-content-right' role='cell'>
                                <div>
                                    <span className='text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>11</span>
                                </div>
                            </div>
                        </td>*/}
                        <td className='table-tabledata wallet-table-column-delete' role='cell'>
                            <div className='table-cell-div justify-center' role='cell'>
                                <div className=''>
                                    <span className='text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                        <div onClick={(event) => { event.stopPropagation(); handleTxSettingsClick(event); }}>
                                          <MoreVertRoundedIcon />
                                        </div>
                                        <Menu
                                          anchorEl={anchorEl}
                                          id="account-menu"
                                          open={open}
                                          onClose={handleTxSettingsClose}
                                          onClick={handleTxSettingsClose}
                                          PaperProps={{
                                            elevation: 0,
                                            sx: {
                                              overflow: 'visible',
                                              border: '1px solid grey',
                                              mt: 1.5,
                                              '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                              },
                                              '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                zIndex: 0,
                                              },
                                            },
                                          }}
                                          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                          <MenuItem onClick={handleTxSettingsClose}>
                                            Szerkesztés
                                          </MenuItem>
                                          <MenuItem onClick={handleTxSettingsClose}>
                                            Törlés
                                          </MenuItem>
                                        </Menu>
                                    </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    );
                })}
            </tbody>
            <div className='transaction-table-paging'>
              <div className='pagination-full-width'>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center'>
                    <button onClick={prevPage} className='p-ds-3 flex ms-ds-0 me-ds-4 mt-ds-0 mb-ds-0 text-ds-button-disabled bg-ds-button-disabled rounded-ds-round box-border relative focus-visible:outline focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-default [-webkit-appearance:button] focus:outline-ds-button' style={{ marginInlineEnd: '12px' }}>
                      <ArrowBackIosNewRoundedIcon style={{ fontSize: '24px' }} />
                    </button>
                    <span className='text-ds-disabled text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Previous Page</span>
                  </div>
                  <span className='text-ds-neutral text-ds-plex-14-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Page {page}</span>
                  <div className='flex items-center'>
                    <span className='text-ds-disabled text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Next Page</span>
                    <button onClick={nextPage} className='p-ds-3 flex ms-ds-0 me-ds-4 mt-ds-0 mb-ds-0 text-ds-button-disabled bg-ds-button-disabled rounded-ds-round box-border relative focus-visible:outline focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-default [-webkit-appearance:button] focus:outline-ds-button' style={{ marginInlineStart: '12px' }}>
                      <ArrowForwardIosRoundedIcon style={{ fontSize: '24px' }} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </table>

        {/* Popup for transaction details */}
      {selectedTransaction && (
        <div id="overlay" onClick={handleClosePopup} className="popup-container">
          <div className="flex max-h-full w-full flex-col items-center">
            <div role="dialog" tabIndex={1} className="rounded-ds-dialog py-ds-dialog bg-ds-dialog backdrop-blur-ds-dialog focus-visible:outline-ds-dialog box-border flex max-h-full flex-col focus-visible:outline-2 animate-[ds-dialog-fadeIn_0.2s_forwards] max-w-full">
              <div className="text-ds-celias-20-medium px-ds-dialog gap-ds-2 flex flex-row items-center justify-between">
                <div className="gap-ds-2 flex flex-row items-center">
                  <div className="gap-ds-2 flex flex-col">
                    <span className="text-ds-primary text-ds-celias-20-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">Transaction</span>
                  </div>
                </div>
                <div className="[&>*]:p-ds-2 [&>*]:m-ds-0 -me-1 flex flex-row">
                  <button className="flex p-ds-3 ms-ds-1 me-ds-1 mt-ds-1 mb-ds-1 text-ds-button-neutral-none hover:text-ds-button-neutral-none-hover bg-ds-button-neutral-none hover:bg-ds-button-neutral-none-hover active:bg-ds-button-neutral-none-active rounded-ds-round box-border relative focus-visible:outline focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-pointer [-webkit-appearance:button] focus:outline-ds-button">
                    <CloseRoundedIcon />
                  </button>
                </div>
              </div>
              <div className="overflow-auto">
                <div className="px-ds-dialog overflow-visible">
                  <div className="modal-content-container">
                    <div className="modal-transaction-asset-container">
                      <div>
                        <img width={50} height={50} src="https://assets.kraken.com/marketing/web/icons/sym-sol_colored.svg?e=www.kraken.com" />
                      </div>
                      <span className="text-ds-primary text-ds-celias-28-medium ms-ds-0 me-ds-0 mt-ds-2 mb-ds-0">0.02175 SOL</span>
                      <span className="text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-1 mb-ds-0">{selectedTransaction.id}</span>
                    </div>
                    <table className="transaction-modal-table-container">
                      <tbody>
                        <tr>
                          <th scope="row" className="transaction-modal-table-name">
                            <span className="text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">Exchange</span>
                          </th>
                          <td className="transaction-modal-table-value">
                            <span className="text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">Coinbase</span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="transaction-modal-table-name">
                            <span className="text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">Type</span>
                          </th>
                          <td className="transaction-modal-table-value">
                            <span className="text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">{selectedTransaction.type}</span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="transaction-modal-table-name">
                            <span className="text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">Exchange TX Type</span>
                          </th>
                          <td className="transaction-modal-table-value">
                            <span className="text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">{selectedTransaction.exchangeTxType}</span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="transaction-modal-table-name">
                            <span className="text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">Date</span>
                          </th>
                          <td className="transaction-modal-table-value">
                            <span className="text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">{format(new Date(selectedTransaction.date), 'MMM dd, yyyy, HH:mm').toString()}</span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="transaction-modal-table-name">
                            <span className="text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">From</span>
                          </th>
                          <td className="transaction-modal-table-value">
                            <span className="text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">{selectedTransaction.from}</span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="transaction-modal-table-name">
                            <span className="text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">To</span>
                          </th>
                          <td className="transaction-modal-table-value">
                            <span className="text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">{selectedTransaction.to}</span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="transaction-modal-table-name">
                            <span className="text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">From amount</span>
                          </th>
                          <td className="transaction-modal-table-value">
                            <span className="text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">{selectedTransaction.fromAmount} {selectedTransaction.base}</span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="transaction-modal-table-name">
                            <span className="text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">To amount</span>
                          </th>
                          <td className="transaction-modal-table-value">
                            <span className="text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">{selectedTransaction.toAmount} {selectedTransaction.quote}</span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="transaction-modal-table-name">
                            <span className="text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">Exchange TX ID</span>
                          </th>
                          <td className="transaction-modal-table-value">
                            <span className="text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">{selectedTransaction.exchangeTxId}</span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="transaction-modal-table-name">
                            <span className="text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">Description</span>
                          </th>
                          <td className="transaction-modal-table-value">
                            <span className="text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">{selectedTransaction.description}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      </>
  );
};

export { WalletDetailsTransactionsGrid };