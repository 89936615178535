import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ReportNumbers } from "../models/ReportNumbers";
const { REACT_APP_API_ENDPOINT } = process.env;

export const taxApi = createApi({
    reducerPath: "taxApi",
    baseQuery: fetchBaseQuery({ 
        baseUrl: `${REACT_APP_API_ENDPOINT}/api/tax`, 
        credentials: "include" 
    }),
    endpoints: (build) => ({
        getReportNumbers: build.query<ReportNumbers, string | undefined>({
            query: (userId) => ({ url: `${userId}/report-details` }),
        }),
    }),
});

export const { useGetReportNumbersQuery } = taxApi;