import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { WalletDetails } from "../models/WalletDetails";
import { SingleWalletDetails } from "../models/SingleWalletDetails";
const { REACT_APP_API_ENDPOINT } = process.env;

export const walletApi = createApi({
    reducerPath: "walletApi",
    baseQuery: fetchBaseQuery({ 
        baseUrl: `${REACT_APP_API_ENDPOINT}/api/wallet`, 
        credentials: "include" 
    }),
    endpoints: (build) => ({
        getUserWallets: build.query<WalletDetails, string | undefined>({
            query: (userId) => ({ url: `/user/${userId}` }),
        }),
        getWalletDetails: build.query<SingleWalletDetails, string | undefined>({
            query: (walletId) => ({ url: `/details/${walletId}` }),
        }),
        deleteUserWallet: build.mutation<void, string>({
            query: (walletId) => ({ url: `/delete/${walletId}`, method: 'DELETE' })
        }),
    }),
});

export const { useGetUserWalletsQuery, useGetWalletDetailsQuery, useDeleteUserWalletMutation } = walletApi;