import { useAppSelector } from '../../app/hooks';
import { Box, Typography } from '@mui/material';
import { selectCurrentUser } from '../../slices/auth.slice';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ValidateAccountForm } from '../../components/auth/login-form/validateAccount-form.component';

const ValidateAccount: React.FC = () => {
    const user = useAppSelector((state) => selectCurrentUser(state));
    const {userId, token}= useParams();
    const navigate = useNavigate();

    if (user) {
        navigate("/p");
    }
    return (
        <div className="login-page">
            <ValidateAccountForm userId={userId ?? ''} token={token ?? ''} /> 
            <div className='login-content-box'>
                <Typography
                    textAlign="center"
                    fontSize="3rem"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="125%"
                    letterSpacing="-0.036rem"
                    className="main-name"
                >
                    Kontri.io
                </Typography>
                <div className='activation-content-box-content' style={{ textAlign: 'center' }}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap="0.5rem"
                    >
                        <Typography
                            fontFamily="Inter"
                            fontSize="1.7rem"
                            fontStyle="normal"
                            fontWeight="500"
                            lineHeight="2.25rem"
                            letterSpacing="-0.01406rem"
                        >
                            A fiók aktiválása <strong style={{ color: 'black' }}>sikeresen</strong> megtörtént.
                        </Typography>
                        <Typography paddingTop="2rem">
                            <Link to="/login" className="custom-color" style={{ fontSize: '1.8rem', fontWeight: '600' }}>     Bejelentkezés</Link>
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        gap="1rem"
                        alignSelf="stretch"
                    >
                    </Box>
                </div>
                <Typography>2024 Kontri, Minden jog fenntartva</Typography>
            </div>
        </div>
    )
};

export default ValidateAccount;