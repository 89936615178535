import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentUser } from '../../slices/auth.slice';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import EditIc from '@mui/icons-material/Edit';
import SaveI from '@mui/icons-material/Save';
import { useGetUserDetailsQuery, useUpdateUserDetailsMutation } from '../../api/user-details.api';
import { TextField, } from '@mui/material';
import { UpdateUserDetails } from '../../dto/updateUserDetails.dto';
import { UpdateUserInfo } from '../../dto/updateUserInfor.dto';
import { useUpdateUserMutation } from '../../api/user.api';


type Props = {};

const AccountSettings: React.FC<Props> = (props: Props) => {

  const [updateUserDetails] = useUpdateUserDetailsMutation();
  const [updateUserInfoApi] = useUpdateUserMutation();

  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const user = useAppSelector((state) => selectCurrentUser(state));
  const userId = user?.id;

  const userDetails = useGetUserDetailsQuery(userId).data;

  const [isEditingUserInfo, setIsEditingUserInfo] = React.useState(false);
  const [isEditingUserDetailsInfo, setIsEditingUserDetailsInfo] = React.useState(false);


  const [firstName, setFirstName] = React.useState(user?.firstName);
  const [lastName, setLastName] = React.useState(user?.lastName);
  const [email, setEmail] = React.useState(user?.email);

  const [userDetailsName, setUserDetailsName] = React.useState(userDetails?.name);
  const [countryCode, setCountryCode] = React.useState(userDetails?.countryCode);
  const [postCode, setPostCode] = React.useState(userDetails?.postCode);
  const [city, setCity] = React.useState(userDetails?.city);
  const [address, setAddress] = React.useState(userDetails?.address);
  const [taxNumber, setTaxNumber] = React.useState(userDetails?.taxNumber);



  const handleEditButtonUserDetailsClick = () => {
    setIsEditingUserDetailsInfo(true);
    setUserDetailsName(userDetails?.name);
    setCountryCode(userDetails?.countryCode);
    setPostCode(userDetails?.postCode);
    setCity(userDetails?.city);
    setAddress(userDetails?.address);
    setTaxNumber(userDetails?.taxNumber);
  };
  const handleSaveButtonUserDetailsClick = async () => {
    setIsEditingUserDetailsInfo(false);

    // Send updated user information to the backend
    const updateUserDetailsRequest: UpdateUserDetails = {
      id: userDetails?.id ?? '',
      name: userDetailsName ?? '',
      countryCode: countryCode ?? '',
      city: city ?? '',
      address: address ?? '',
      postCode: postCode ?? '',
      taxNumber: taxNumber ?? '',
      userId: user?.id ?? ''
    };
    await updateUserDetails(updateUserDetailsRequest);
    handleReload();

  };

  const handleEditButtonUserClick = () => {
    setIsEditingUserInfo(true);
    setFirstName(user?.firstName);
    setLastName(user?.lastName);
    setEmail(user?.email);
  };

  const handleSaveButtonUserClick = async () => {
    // Perform save operation
    setIsEditingUserInfo(false);
    const updateUserInfo: UpdateUserInfo = {
      userId: user?.id ?? '',
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      email: email ?? ''
    };
    await updateUserInfoApi(updateUserInfo);
    handleReload();
  };

  const handleReload = () => {
    window.location.reload();
  }
  // Update active tab based on the current pathname
  React.useEffect(() => {
    switch (location.pathname) {
      case '/p/account/settings':
        setActiveTab(0);
        break;
      case '/p/account/subscription':
        setActiveTab(1);
        break;
      case '/p/account/documents':
        setActiveTab(2);
        break;
      default:
        setActiveTab(0); // Default to the first tab
        break;
    }
  }, [location.pathname]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    // Update the URL based on the selected tab
    switch (index) {
      case 0:
        navigate('/p/account/settings');
        break;
      case 1:
        navigate('/p/account/subscription');
        break;
      case 2:
        navigate('/p/account/documents');
        break;
      default:
        break;
    }
  };


  let divWidth = "";
  let divLeft = "";

  if (activeTab === 0) {
    divWidth = "115px";
    divLeft = "0px";
  }

  if (activeTab === 1) {
    divWidth = "105px";
    divLeft = "123px";
  }

  if (activeTab === 2) {
    divWidth = "151px";
    divLeft = "236px";
  }



  return (
    <div className='content-container'>
      <main className='content-content-full-width'>
        <header className='content-header section header'>
          <h1 className='text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Fiók</h1>
          <div className='overflow-x-auto'>
            <div className='flex ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
              <div className='relative grid grid-flow-col gap-x-2'>
                <div className={`bg-ds-tabs-active rounded-ds-round absolute`} style={{ height: "38px", width: divWidth, left: divLeft, top: "0px", transition: "all 0.2s ease 0s" }}></div>
                <button role='tab' aria-label='Beállítások' aria-selected={activeTab === 0} className={`py-ds-tabs-vert px-ds-tabs-horz transition-all text-ds-tabs hover:text-ds-tabs-active aria-selected:text-ds-tabs-active whitespace-nowrap box-border relative focus-visible:outline focus-visible:outline-2 outline-ds-tabs border-0 bg-ds-tabs hover:bg-ds-tabs-hover flex items-center rounded-ds-round text-ds-tabs-md cursor-pointer aria-selected:shadow-ds-tabs ${activeTab === 0 ? 'text-ds-tabs-active' : ''}`} onClick={() => handleTabClick(0)}>Beállítások</button>
                <button role='tab' aria-label='Előfizetés' aria-selected={activeTab === 1} className={`py-ds-tabs-vert px-ds-tabs-horz transition-all text-ds-tabs hover:text-ds-tabs-active aria-selected:text-ds-tabs-active whitespace-nowrap box-border relative focus-visible:outline focus-visible:outline-2 outline-ds-tabs border-0 bg-ds-tabs hover:bg-ds-tabs-hover flex items-center rounded-ds-round text-ds-tabs-md cursor-pointer aria-selected:shadow-ds-tabs ${activeTab === 1 ? 'text-ds-tabs-active' : ''}`} onClick={() => handleTabClick(1)}>Előfizetés</button>
                <button role='tab' aria-label='Dokumentumok' aria-selected={activeTab === 2} className={`py-ds-tabs-vert px-ds-tabs-horz transition-all text-ds-tabs hover:text-ds-tabs-active aria-selected:text-ds-tabs-active whitespace-nowrap box-border relative focus-visible:outline focus-visible:outline-2 outline-ds-tabs border-0 bg-ds-tabs hover:bg-ds-tabs-hover flex items-center rounded-ds-round text-ds-tabs-md cursor-pointer aria-selected:shadow-ds-tabs ${activeTab === 2 ? 'text-ds-tabs-active' : ''}`} onClick={() => handleTabClick(2)}>Dokumentumok</button>
              </div>
            </div>
          </div>
        </header>
        <section className='primary-content'>
          {activeTab === 0 && (
            <><div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
              <div className='mb-ds-3 flex w-full items-baseline relative'>
                <span className='text-ds-primary text-ds-celias-20-medium flex-1'>Személyes információk</span>
                <button className='ml-auto flex p-ds-4 ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 text-ds-button-primary-none hover:
  text-ds-button-primary-none-hover bg-ds-button-primary-none hover:bg-ds-button-primary-none-hover
   active:bg-ds-button-primary-none-active rounded-ds-round box-border relative focus-visible:outline
    focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-pointer [-webkit-appearance:button] focus:outline-ds-button'
                  onClick={isEditingUserInfo ? handleSaveButtonUserClick : handleEditButtonUserClick}>
                  {isEditingUserInfo ? (<SaveI />) : (<EditIc />)}
                </button>
              </div>
              <div className='personal-info-container'>
                <div className='personal-info-label-container'>
                  <div className='personal-info-label'>
                    <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Kontri ID</span>
                  </div>
                  <div className='personal-info-content'>
                    <div className='personal-info-label-value'>
                      <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{user?.id}</span>
                    </div>
                  </div>
                  <div className='personal-info-action'>
                    <div className='personal-info-copy-button'>
                      <button className='flex p-ds-4 ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 text-ds-button-primary-none hover:text-ds-button-primary-none-hover bg-ds-button-primary-none hover:bg-ds-button-primary-none-hover active:bg-ds-button-primary-none-active rounded-ds-round box-border relative focus-visible:outline focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-pointer [-webkit-appearance:button] focus:outline-ds-button'>
                        <ContentCopyRoundedIcon />
                      </button>
                    </div>
                  </div>
                </div>
                <div className='personal-info-label-container'>
                  <div className='personal-info-label'>
                    <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Név</span>
                  </div>


                  <div className='personal-info-content'>
                    <div className='personal-info-label-value'>
                      {!isEditingUserInfo ? (
                        <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                          {user?.firstName ?? ''} {user?.lastName ?? ''}
                        </span>
                      ) : (
                        <> <TextField
                          label="Name"
                          type="text"
                          required
                          style={{ width: "50%" }}
                          value={firstName}
                          onChange={(event) => {
                            setFirstName(event.target.value);
                          }}
                        />
                          <TextField
                            label="Last Name"
                            type="text"
                            required
                            style={{ width: "50%" }}
                            value={lastName}
                            onChange={(event) => {
                              setLastName(event.target.value);
                            }}


                          />
                        </>
                      )}

                    </div>
                  </div>

                  <div>
                  </div>
                </div>
                <div className='personal-info-label-container'>
                  <div className='personal-info-label'>
                    <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Email</span>
                  </div>
                  <div className='personal-info-content'>
                    <div className='personal-info-label-value'>
                      {!isEditingUserInfo ? (<span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{user?.email}</span>) : (

                        <>  <TextField
                          label="Email"
                          fullWidth
                          type="email"
                          style={{ width: "100%" }}

                          required
                          value={email}
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                        /> </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
                <div className='mb-ds-3 flex w-full items-baseline relative'>
                  <span className='text-ds-primary text-ds-celias-20-medium'>Számlázási információk</span>

                  <button className='ml-auto flex p-ds-4 ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 text-ds-button-primary-none hover:
  text-ds-button-primary-none-hover bg-ds-button-primary-none hover:bg-ds-button-primary-none-hover
   active:bg-ds-button-primary-none-active rounded-ds-round box-border relative focus-visible:outline
    focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-pointer [-webkit-appearance:button] focus:outline-ds-button'
                    onClick={isEditingUserDetailsInfo ? handleSaveButtonUserDetailsClick : handleEditButtonUserDetailsClick}>
                    {isEditingUserDetailsInfo ? (<SaveI />) : (<EditIc />)}
                  </button>



                </div>


                <div className='personal-info-container'>
                  <div className='personal-info-label-container'>
                    <div className='personal-info-label'>
                      <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Számlázási név</span>
                    </div>
                    <div className='personal-info-content'>
                      <div className='personal-info-label-value'>
                        {!isEditingUserDetailsInfo ? (
                          <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                            {userDetails?.name}
                          </span>) :
                          (
                            <>  <TextField
                              label="Name"
                              fullWidth
                              type="userDetailsName"
                              style={{ width: "100%" }}
                              required
                              value={userDetailsName}
                              onChange={(event) => {
                                setUserDetailsName(event.target.value);
                              }}
                            /> </>)
                        }

                      </div>
                    </div>

                  </div>
                  <div className='personal-info-label-container'>
                    <div className='personal-info-label'>
                      <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Ország</span>
                    </div>
                    <div className='personal-info-content'>
                      <div className='personal-info-label-value'>

                        {!isEditingUserDetailsInfo ? (
                          <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{userDetails?.countryCode}</span>


                        ) : (
                          <>  <TextField
                            label="Country Code"
                            fullWidth
                            type="userDetailsName"
                            style={{ width: "100%" }}
                            required
                            value={countryCode}
                            onChange={(event) => {
                              setCountryCode(event.target.value);
                            }}
                          /> </>

                        )}
                      </div>
                    </div>

                  </div>
                  <div className='personal-info-label-container'>
                    <div className='personal-info-label'>
                      <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Irányítószám</span>
                    </div>
                    <div className='personal-info-content'>
                      <div className='personal-info-label-value'>
                        {!isEditingUserDetailsInfo ? (

                          <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{userDetails?.postCode}</span>)
                          : (
                            <>  <TextField
                              label="Post Code"
                              fullWidth
                              type="userDetailsName"
                              style={{ width: "100%" }}
                              required
                              value={postCode}
                              onChange={(event) => {
                                setPostCode(event.target.value);
                              }}
                            /> </>
                          )
                        }

                      </div>
                    </div>
                  </div>
                  <div className='personal-info-label-container'>
                    <div className='personal-info-label'>
                      <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Város</span>
                    </div>
                    <div className='personal-info-content'>
                      <div className='personal-info-label-value'>
                        {!isEditingUserDetailsInfo ? (

                          <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{userDetails?.city}</span>

                        ) : (
                          <>  <TextField
                            label="City"
                            fullWidth
                            type="city"
                            style={{ width: "100%" }}
                            required
                            value={city}
                            onChange={(event) => {
                              setCity(event.target.value);
                            }}
                          /> </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='personal-info-label-container'>
                    <div className='personal-info-label'>
                      <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Számlázási cím</span>
                    </div>
                    <div className='personal-info-content'>
                      <div className='personal-info-label-value'>

                        {!isEditingUserDetailsInfo ? (

                          <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{userDetails?.address}</span>
                        ) : (
                          <>  <TextField
                            label="Address"
                            fullWidth
                            type="userDetailsAddress"
                            style={{ width: "100%" }}
                            required
                            value={address}
                            onChange={(event) => {
                              setAddress(event.target.value);
                            }}
                          /> </>)}

                      </div>
                    </div>
                  </div>
                  <div className='personal-info-label-container'>
                    <div className='personal-info-label'>
                      <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Adószám</span>
                    </div>
                    <div className='personal-info-content'>
                      <div className='personal-info-label-value'>


                        {!isEditingUserDetailsInfo ? (

                          <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{userDetails?.taxNumber}</span>
                        ) : (
                          <>  <TextField
                            label="Tax Number"
                            fullWidth
                            type="userDetailsTaxNumber"
                            style={{ width: "100%" }}
                            required
                            value={taxNumber}
                            onChange={(event) => {
                              setTaxNumber(event.target.value);
                            }}
                          /> </>)}
                      </div>
                    </div>
                  </div>
                </div>
              </div></>
          )}
          {activeTab === 1 && (
            <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
              <div className='mb-ds-3 flex w-full items-baseline'>
                <span className='text-ds-primary text-ds-celias-20-medium'>Megvásárolt szolgáltatások</span>
              </div>
              <div className='payment-method-content-container grid'>
                <div className='payment-method-empty-content'>
                  <div className='payment-method-content-content-empty'>
                    <img width={300} height={200} src='/assets/images/site_images/payment.jpg' alt='Payment' />
                    <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Még nincs megvásárolt szolgáltatásod.</span>
                  </div>
                </div>
                <div className='payment-methods-add-button'>
                  <a href='/p/product-purchase' className='inline-block no-underline whitespace-nowrap text-ds-button-md px-ds-7 py-ds-4 ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 text-ds-button-primary-none hover:text-ds-button-primary-none-hover bg-ds-button-primary-none hover:bg-ds-button-primary-none-hover active:bg-ds-button-primary-none-active rounded-ds-round box-border relative focus-visible:outline focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-pointer [-webkit-appearance:button] focus:outline-ds-button'>
                    <span className='flex items-center justify-center'>Vásárlás</span>
                  </a>
                </div>
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
              <div className='mb-ds-3 flex w-full items-baseline'>
                <span className='text-ds-primary text-ds-celias-20-medium'>Dokumentumok</span>
              </div>
              <div className='payment-method-content-container grid'>
                <table className='document-table'>
                  <thead>
                    <th className='document-table-name-th'>
                      <span className='text-ds-primary text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>DOKUMENTUM NÉV</span>
                    </th>
                    <th>
                      <span className='text-ds-primary text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>DOKUMENTUM TÍPUS</span>
                    </th>
                    <th className='document-table-action-th'>
                      <span className='text-ds-primary text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>MŰVELET</span>
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className='inline-flex  my-ds-2'>kontri_tax_report_2023.pdf</span>
                      </td>
                      <td>
                        <span className='inline-flex  my-ds-2'>TAX REPORT</span>
                      </td>
                      <td>
                        <div className='personal-info-copy-button'>
                          <button className='flex p-ds-4 ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 text-ds-button-primary-none hover:text-ds-button-primary-none-hover bg-ds-button-primary-none hover:bg-ds-button-primary-none-hover active:bg-ds-button-primary-none-active rounded-ds-round box-border relative focus-visible:outline focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-pointer [-webkit-appearance:button] focus:outline-ds-button'>
                            <span className='text-ds-button-md'>Download</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </section>
      </main>

    </div>
  );
};

export default AccountSettings;
