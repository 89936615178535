import { Alert, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentUser } from '../../slices/auth.slice';
import { useGetReportNumbersQuery } from '../../api/tax.api';
import NumberFormatComponent from '../../components/general/NumberFormatComponent';
import { useGetUserDetailsQuery } from '../../api/user-details.api';
import { useNavigate } from 'react-router-dom';
import { useGenerateDocumentMutation } from '../../api/document.api';

type Props = {};

const Taxation = (props: Props) => {
    const user = useAppSelector((state) => selectCurrentUser(state));
    const userId = user?.id;
    const [reportType, setReportType] = React.useState('');
    const { data: userDetails } = useGetUserDetailsQuery(userId);
    const { data: reportNumbers } = useGetReportNumbersQuery(userId);
    const [generateDocument, { isLoading }] = useGenerateDocumentMutation();
    const navigate = useNavigate();

    console.log(userDetails)

    const handleChange = (event: SelectChangeEvent) => {
        setReportType(event.target.value as string);
    };

    const handleProductPurchase = () => {
        navigate("/p/product-purchase");
    };

    const handleDocumentDownload = async () => {
        try {
            if (!userId) return;
            const response = await generateDocument(userId);
            
            if ('data' in response) {
                // Create a Blob object from the response data
                const blob = new Blob([response.data], { type: 'application/pdf' });
                
                // Create a URL for the Blob object
                const url = window.URL.createObjectURL(blob);
                
                // Create a link element to trigger the download
                const link = document.createElement('a');
                link.href = url;
                const dateTime = new Date();
                const year = dateTime.getFullYear();
                const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
                const day = dateTime.getDate().toString().padStart(2, '0');
                const formattedDate = `${year}_${month}_${day}`;
                link.download = 'jovedelemkalkulacio_' + formattedDate + '.pdf';
                
                // Append the link to the document body and trigger the download
                document.body.appendChild(link);
                link.click();
                
                // Cleanup: Remove the link and revoke the URL
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Error downloading document else:', response.error);
            }
        } catch (error) {
            console.error('Error downloading document:', error);
        }
    }


    return (
        <div className='content-container'>
            <main className='content-content content-content-main'>
                <header className='content-header section header'>
                    <div className='exchanges-header'>
                        <div className='flex flex-col'>
                            <h1 className='mobile-size-text text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Jövedelemkalkuláció a 2023-as évre</h1>
                            <span>2023 január 1-től 2023 december 31-ig</span>
                        </div>
                    </div>
                </header>
                <section className='primary-content'>
                    {userDetails && userDetails?.isSubscriptionActive ? (
                    <div className='overflow-hidden shadow-ds-container py-ds-7 px-ds-9 bg-ds-container rounded-ds-7 mt-ds-0 ms-ds-0 me-ds-0 mb-ds-0'>
                        <div className='flex items-center justify-between mb-ds-7'>
                            <div className='flex flex-col'>
                                <span className='text-ds-primary text-ds-celias-20-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Összegzés</span>
                            </div>
                        </div>
                        <div className='tax-report-summary-container'>
                            <div className='personal-info-label-container'>
                                <div className='tax-report-summary-label'>
                                    <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Tőkenyereség / P&L</span>
                                </div>
                                <div className='position-end'>
                                    <div className='personal-info-label-value'>
                                        <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{color: "green", fontWeight: "600"}}><NumberFormatComponent number={reportNumbers?.profitAndLoss} /> HUF</span>
                                    </div>
                                </div>
                            </div>
                            <div className='personal-info-label-container'>
                                <div className='tax-report-summary-label'>
                                    <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Egyéb nyereségek (futures, derivatives stb.)</span>
                                </div>
                                <div className='position-end'>
                                    <div className='personal-info-label-value'>
                                        <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>0.00 HUF</span>
                                    </div>
                                </div>
                            </div>
                            <div className='personal-info-label-container'>
                                <div className='tax-report-summary-label'>
                                    <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Bevételek</span>
                                </div>
                                <div className='position-end'>
                                    <div className='personal-info-label-value'>
                                        <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'><NumberFormatComponent number={reportNumbers?.totalIncome} /> HUF</span>
                                    </div>
                                </div>
                            </div>
                            <div className='personal-info-label-container'>
                                <div className='tax-report-summary-label'>
                                    <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Költségek és kiadások</span>
                                </div>
                                <div className='position-end'>
                                    <div className='personal-info-label-value'>
                                        <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'><NumberFormatComponent number={reportNumbers?.totalCosts} /> HUF</span>
                                    </div>
                                </div>
                            </div>
                            <div className='personal-info-label-container'>
                                <div className='tax-report-summary-label'>
                                    <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Ajándékok, adományok és elvesztett koinok</span>
                                </div>
                                <div className='position-end'>
                                    <div className='personal-info-label-value'>
                                        <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>0.00 HUF</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ) : (
                    null)}
                    <div className='overflow-hidden shadow-ds-container py-ds-7 px-ds-9 bg-ds-container rounded-ds-7 mt-ds-0 ms-ds-0 me-ds-0 mb-ds-0'>
                        <div className='flex items-center justify-between mb-ds-7'>
                            <div className='flex flex-col'>
                                <span className='text-ds-primary text-ds-celias-20-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Jövedelemkalkuláció</span>
                            </div>
                        </div>
                        {userDetails && userDetails?.isSubscriptionActive ? (
                        <div className='tax-report-summary-container'>
                            <div className='pointer border-radius relative mb2 w-full'>
                                <div className='connected-exchanges-wrapper'>
                                    {isLoading ? 
                                    <div style={{ display: 'flex', minWidth: '100%', justifyContent: 'center' }}>
                                        <div className="loader"></div>
                                    </div> 
                                    : 
                                    <button onClick={handleDocumentDownload} className='text-ds-button-sm mb-ds-0 me-ds-0 ms-ds-0 mt-ds-5 box-border inline-block cursor-pointer rounded-ds-round bg-ds-button-primary-medium px-ds-5 py-ds-3 text-ds-button-primary-medium hover:bg-ds-button-primary-medium-hover mt-7'>
                                        <span className='flex items-center justify-center'>
                                            <span className='my-px'>Jelentés letöltése</span>
                                        </span>
                                    </button>
                                    }
                                </div>
                            </div>
                        </div>
                        ) : (
                        <div className='alternative-content'>
                            <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="60" width="53" viewBox="0 0 448 512"><path fill="#ee2b2b" d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/></svg>
                                </span>
                            </div>
                            <Alert severity="warning">Mielőtt jelentéseket generálnál, meg kell vásárolnod az 2023-as jövedelemkalkulációt.</Alert>
                            <div className='connected-exchanges-wrapper'>
                                <button onClick={handleProductPurchase} className='text-ds-button-sm mb-ds-0 me-ds-0 ms-ds-0 mt-ds-5 box-border inline-block cursor-pointer rounded-ds-round bg-ds-button-primary-medium px-ds-5 py-ds-3 text-ds-button-primary-medium hover:bg-ds-button-primary-medium-hover mt-7'>
                                    <span className='flex items-center justify-center'>
                                        <span className='my-px'>Vásárlás</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                        )}
                    </div>
                    <div className='overflow-hidden shadow-ds-container py-ds-7 px-ds-9 bg-ds-container rounded-ds-7 mt-ds-0 ms-ds-0 me-ds-0 mb-ds-0'>
                        <div className='flex items-center justify-between mb-ds-7'>
                            <div className='flex flex-col'>
                                <span className='text-ds-primary text-ds-celias-20-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Segítség</span>
                            </div>
                        </div>
                        <div className='tax-report-summary-container'>
                            <a href='/p/guides/first-steps'>Hogyan használjam a Kontri.io-t?</a>
                        </div>
                    </div>
                </section>
                <section className='secondary-content'>
                    <div className='mt-10'>
                        <div className='card-card-dashboard carousel-card-dashboard'>
                            <div className='carousel-container'>
                                <div className='overflow-hidden shadow-ds-container py-ds-7 px-ds-9 bg-ds-container rounded-ds-7 mt-ds-0 ms-ds-0 me-ds-0 mb-ds-0'>
                                    <div className='flex items-center justify-center mb-ds-7'>
                                        <div className='flex flex-col'>
                                            <div>
                                                <span className='text-ds-primary text-ds-celias-20-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Adó beállítások</span>
                                            </div>
                                            <span className='text-ds-neutral text-ds-plex-11-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Ezeket a beállításokat használjuk a nyereségek számításához.</span>
                                        </div>
                                    </div>
                                    <div className='personal-info-container'>
                                        <div className='personal-info-label-container'>
                                            <div className='personal-info-label'>
                                                <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Adózási ország</span>
                                            </div>
                                            <div className='position-end'>
                                                <div className='personal-info-label-value'>
                                                    <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Magyar</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='personal-info-label-container'>
                                            <div className='personal-info-label'>
                                                <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Alap Deviza</span>
                                            </div>
                                            <div className='position-end'>
                                                <div className='personal-info-label-value'>
                                                    <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>HUF</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='personal-info-label-container'>
                                            <div className='personal-info-label'>
                                                <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Költség módszer</span>
                                            </div>
                                            <div className='position-end'>
                                                <div className='personal-info-label-value'>
                                                    <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>FIFO</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </section>
            </main>
        </div>
    )
};

export default Taxation;