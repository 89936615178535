import { useLocation } from 'react-router-dom';
import { usePaymentSuccessQuery } from '../../api/payment.api';

const PaymentInfo = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paymentId = searchParams.get('paymentId') ?? '';

    const { data } = usePaymentSuccessQuery(paymentId);

    return (
        <div className='content-container'>
            <main className='content-content-full-width'>
                <header className='content-header section header'>
                    <div className='exchanges-header'>
                        <h1 className='text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Fizetési információ</h1>
                    </div>
                </header>
                <section className='primary-content'>
                    <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
                        <div className='mb-ds-3 flex w-full items-baseline'>
                            <span className='text-ds-primary text-ds-celias-20-medium'>Fizetés állapota</span>
                        </div>
                        <div className='personal-info-container' style={{ marginTop: '30px' }}>
                            {data === true ? (
                                <div style={{ textAlign: 'center' }}>
                                    <div style={{ maxWidth: '100%', height: 'auto', marginBottom: '10px' }}>
                                        <img src='/assets/images/site_images/payment_success.png' alt='Exchanges' style={{ width: '30%', height: '100%', borderRadius: '1rem' }} />
                                    </div>
                                    <span style={{ fontSize: '25px' }} className='text-ds-primary text-ds-celias-20-medium'>Sikeres fizetés!</span>
                                    <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '15px' }}>
                                        <a href='/p/taxation' style={{ width: '250px' }} className='text-ds-button-sm mb-ds-0 me-ds-0 ms-ds-0 mt-ds-5 box-border inline-block cursor-pointer rounded-ds-round bg-ds-button-primary-medium px-ds-5 py-ds-3 text-ds-button-primary-medium hover:bg-ds-button-primary-medium-hover mt-7'>
                                            <span className='flex items-center justify-center'>
                                                <span className='my-px'>Kalkuláció megtekintése</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ textAlign: 'center' }}>
                                    <div style={{ maxWidth: '100%', height: 'auto', marginBottom: '30px' }}>
                                        <img src='/assets/images/site_images/payment_failed.png' alt='Exchanges' style={{ width: '30%', height: '100%', borderRadius: '1rem' }} />
                                    </div>
                                    <span style={{ fontSize: '25px' }} className='text-ds-primary text-ds-celias-20-medium'>Sikertelen fizetés :(</span>
                                    <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '15px' }}>
                                        <a href='/p' style={{ width: '250px' }} className='text-ds-button-sm mb-ds-0 me-ds-0 ms-ds-0 mt-ds-5 box-border inline-block cursor-pointer rounded-ds-round bg-ds-button-primary-medium px-ds-5 py-ds-3 text-ds-button-primary-medium hover:bg-ds-button-primary-medium-hover mt-7'>
                                            <span className='flex items-center justify-center'>
                                                <span className='my-px'>Vissza a Főoldalra</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default PaymentInfo;
