import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UserDetailsResponse } from "../dto/user-details-response.dto";
import { SaveUserDetailsRequest } from "../dto/save-user-details-request.dto";
import { UpdateUserDetails } from "../dto/updateUserDetails.dto";
const { REACT_APP_API_ENDPOINT } = process.env;

export const userDetailsApi = createApi({
    reducerPath: "userDetailsApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${REACT_APP_API_ENDPOINT}/api/user-details`, credentials: "include" }),
    endpoints: (build) => ({
        saveUserDetails: build.mutation<any, SaveUserDetailsRequest>({
            query: (saveUserDetailsRequest) => ({
                url: `/save/${saveUserDetailsRequest.userId}`,
                method: "POST",
                body: saveUserDetailsRequest,
            }),
        }),
        updateUserDetails: build.mutation<any, UpdateUserDetails>({
            query: (updateUserDetails) => ({
                url: `/update/${updateUserDetails.userId}`,
                method: "POST",
                body: updateUserDetails,
            }),
        }),
        getUserDetails: build.query<UserDetailsResponse, string | undefined>({
            query: (userId) => ({ url: `/${userId}` }),
        }),
    }),
});

export const { useSaveUserDetailsMutation, useGetUserDetailsQuery,useUpdateUserDetailsMutation } = userDetailsApi;
