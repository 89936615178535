import { useAppSelector } from '../../app/hooks';
import { TransactionsGrid } from '../../components/transactions/transactions-grid.component';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { selectCurrentUser } from '../../slices/auth.slice';
import { useGetUserTransactionsQuery, useSaveTransactionMutation } from '../../api/transaction.api';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ChangeEvent, useEffect, useState } from 'react';
import { useGetUserWalletsQuery } from '../../api/wallet.api';
import { ExchangeImages } from '../../components/wallet/UserWalletList';
import { mergeAndListInfo } from '../../features/general-service';
import { currencyDictionary } from '../../general/constants/currencies';
import { cryptoCurrencyDictionary } from '../../general/constants/cryptocurrencies';
import { WalletResponse } from '../../models/types/IWalletResponse';
import { Alert } from '@mui/material';

type Props = {};

interface Coin {
  code: string;
  currency: string;
  image: string;
}

const Transactions = (props: Props) => {
    const user = useAppSelector((state) => selectCurrentUser(state));
    const userId = user?.id;
    const { data, isLoading } = useGetUserTransactionsQuery(userId);
    const [addTransaction, setAddTransaction] = useState<any | null>(null);
    const [saveTransaction, { error, data: savedTx }] = useSaveTransactionMutation();

    const handleAddTransaction = () => {
      setAddTransaction(true);
    };

    const handleClosePopup = () => {
      setAddTransaction(null);
    };

    const [selectedOption, setSelectedOption] = useState('');
    const [txTypeError, setTxTypeError] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedReceiveWallet, setSelectedReceiveWallet] = useState<WalletResponse | null>(null);
    const [txReceivedWalletError, setTxReceivedWalletError] = useState('');
    const [isReceivedWalletDropdownOpen, setIsReceivedWalletDropdownOpen] = useState(false);
    const [selectedSentWallet, setSelectedSentWallet] = useState<WalletResponse | null>(null);
    const [txSentWalletError, setTxSentWalletError] = useState('');
    const [isSentWalletDropdownOpen, setIsSentWalletDropdownOpen] = useState(false);
    const [selectedFromCurrency, setSelectedFromCurrency] = useState('');
    const [txFromCurrencyError, setTxFromCurrencyError] = useState('');
    const [isFromCurrencyDropdownOpen, setIsFromCurrencyDropdownOpen] = useState(false);
    const [selectedToCurrency, setSelectedToCurrency] = useState('');
    const [txToCurrencyError, setTxToCurrencyError] = useState('');
    const [isToCurrencyDropdownOpen, setIsToCurrencyDropdownOpen] = useState(false);
    const [transactionDate, setTransactionDate] = useState('');
    const [txDateError, setTxDateError] = useState('');
    const [fromAmount, setFromAmount] = useState('');
    const [txFromAmountError, setTxFromAmountError] = useState('');
    const [toAmount, setToAmount] = useState('');
    const [txToAmountError, setTxToAmountError] = useState('');
    const [fromAddress, setFromAddress] = useState('');
    const [toAddress, setToAddress] = useState('');

    const handleFromAddressChange = (event: any) => {
      setFromAddress(event.target.value);
    };

    const handleToAddressChange = (event: any) => {
      setToAddress(event.target.value);
    };

    const handleFromAmountChange = (event: any) => {
      setFromAmount(event.target.value);
    };

    const handleToAmountChange = (event: any) => {
      setToAmount(event.target.value);
    };

    const handleDateChange = (event: any) => {
      setTransactionDate(event.target.value);
    };

    const handleOptionSelect = (option: any) => {
      setSelectedOption(option);
      setIsDropdownOpen(false);
    };

    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };

    const handleOptionSelectReceivedWallet = (option: WalletResponse) => {
      setSelectedReceiveWallet(option);
      setIsReceivedWalletDropdownOpen(false);
    }

    const toggleReceivedWalletDropdown = () => {
      setIsReceivedWalletDropdownOpen(!isReceivedWalletDropdownOpen);
    };

    const handleOptionSelectSentWallet = (option: WalletResponse) => {
      setSelectedSentWallet(option);
      setIsSentWalletDropdownOpen(false);
    }

    const toggleSentWalletDropdown = () => {
      setIsSentWalletDropdownOpen(!isSentWalletDropdownOpen);
    };

    const handleOptionSelectFromCurrency = (option: any) => {
      setSelectedFromCurrency(option);
      setIsFromCurrencyDropdownOpen(false);
    }

    const toggleFromCurrencyDropdown = () => {
      setIsFromCurrencyDropdownOpen(!isFromCurrencyDropdownOpen);
    };

    const handleOptionSelectToCurrency = (option: any) => {
      setSelectedToCurrency(option);
      setIsToCurrencyDropdownOpen(false);
    }

    const toggleToCurrencyDropdown = () => {
      setIsToCurrencyDropdownOpen(!isToCurrencyDropdownOpen);
    };

    const transactionTypes = ['Deposit', 'Withdrawal', 'Exchange'];

    const { data: walletDetails } = useGetUserWalletsQuery(userId);

    const coins = mergeAndListInfo(currencyDictionary, cryptoCurrencyDictionary);

    const [searchTermFrom, setSearchTermFrom] = useState<string>('');
    const [searchTermTo, setSearchTermTo] = useState<string>('');
    const [filteredCoinsFrom, setFilteredCoinsFrom] = useState<Coin[]>([]);
    const [filteredCoinsTo, setFilteredCoinsTo] = useState<Coin[]>([]);

    // Handler for input change in the search bar
    const handleSearchInputChangeFrom = (event: ChangeEvent<HTMLInputElement>) => {
      const term = event.target.value.toUpperCase();
      setSearchTermFrom(term);
      const filtered = Object.values(coins).filter((coin) =>
        coin.code.toUpperCase().includes(term)
      );
      setFilteredCoinsFrom(filtered);
    };

    // Set filtered coins initially to all coins
    useEffect(() => {
      setFilteredCoinsFrom(Object.values(coins));
    }, []);

    const handleSearchInputChangeTo = (event: ChangeEvent<HTMLInputElement>) => {
      const term = event.target.value.toUpperCase();
      setSearchTermTo(term);
      const filtered = Object.values(coins).filter((coin) =>
        coin.code.toUpperCase().includes(term)
      );
      setFilteredCoinsTo(filtered);
    };

    // Set filtered coins initially to all coins
    useEffect(() => {
      setFilteredCoinsTo(Object.values(coins));
    }, []);

    const [showNotification, setShowNotification] = useState(false);

    const handleSaveTransaction = async () => {
      let hasError = false;

      try {
        if (!selectedOption) {
          setTxTypeError('You have to select a type');
          hasError = true;
        }
        if (!transactionDate || transactionDate === '') {
          setTxDateError('You have to provide date');
          hasError = true;
        }
        if (selectedOption === 'Deposit' || selectedOption === 'Exchange') {
          if (!selectedReceiveWallet) {
            setTxReceivedWalletError('You have to select a wallet');
            hasError = true;
          }
        }
        if (selectedOption === 'Withdrawal' || selectedOption === 'Exchange') {
          if (!selectedSentWallet) {
            setTxSentWalletError('You have to select a wallet');
            hasError = true;
          }
        }
        if (selectedOption !== 'Deposit') {
          if (!selectedFromCurrency) {
            setTxFromCurrencyError('You have to select a currency');
            hasError = true;
          }
        }
        if (selectedOption !== 'Withdrawal') {
          if (!selectedToCurrency) {
            setTxToCurrencyError('You have to select a currency');
            hasError = true;
          }
        }
        if (selectedOption !== 'Deposit') {
          if (!fromAmount || fromAmount === '') {
            setTxFromAmountError('You have to provide the amount');
            hasError = true;
          }
        }
        if (selectedOption !== 'Withdrawal') {
          if (!toAmount || toAmount === '') {
            setTxToAmountError('You have to provide the amount');
            hasError = true;
          }
        }

        if (hasError) {
          return;
        }

        const response = await saveTransaction({
          type: selectedOption, 
          date: new Date(transactionDate).toLocaleString("hu", { timeZone: "Europe/Budapest" }),
          from: selectedReceiveWallet?.id ?? '',
          fromCurrency: selectedFromCurrency,
          fromAmount: Number(fromAmount),
          to: selectedSentWallet?.id ?? '',
          toCurrency: selectedToCurrency,
          toAmount: Number(toAmount),
          fromAddress: fromAddress,
          toAddress: toAddress 
        });

        setAddTransaction(null);

        console.log(savedTx.statusCode);
      } catch(error) {
        console.error('Error saving transaction: ', error);
      }
    }

    useEffect(() => {
        if (savedTx) {
            setShowNotification(true);
            setTimeout(() => {
                setShowNotification(false);
            }, 10000); // Hide notification after 10 seconds
        }
    }, [savedTx]);

    return (
      <div className='content-container'>
        <main className='content-content-full-width'>
          <header className='content-header section header'>
            {showNotification && <Alert variant='filled' severity="success">This is a success Alert.</Alert>}
              <div className='exchanges-header'>
                  <h1 className='text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Tranzakciók</h1>
                  <div className='search-bordered search-container'>
                      <SearchRoundedIcon sx={{height: 24, width: 24}} />
                      <div>
                          <input id='transaction-search-input' type='text' placeholder='Tranzakció keresése' className='search-input' />
                          <div className='search-hidden-label' aria-hidden="true">Tranzakció keresése</div>
                      </div>
                  </div>
              </div>
          </header>
          <section className='primary-content'>
            <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
              <div className='flex items-center justify-between mb-ds-7'>
                <div className='flex flex-col'>
                  <span className='text-ds-primary text-ds-celias-20-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Saját tranzakciók</span>
                  <span className='text-ds-neutral text-ds-plex-14-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{data && data.length} hozzáadva</span>
                </div>
                <div className='flex justify-center items-center undefined'>
                  <a className='text-ds-button-sm mb-ds-0 me-ds-0 ms-ds-0 box-border inline-block cursor-pointer rounded-ds-round bg-ds-button-primary-medium px-ds-5 py-ds-3 text-ds-button-primary-medium hover:bg-ds-button-primary-medium-hover' onClick={handleAddTransaction}>
                    <span className='flex items-center justify-center'>
                      <span className='my-px'>Tranzakció hozzáadása</span>
                    </span>
                  </a>
                </div>
              </div>
              <div className='responsive-assets-container'>
                <TransactionsGrid />
              </div>
              {/* Popup for transaction details */}
              {addTransaction && (
                <div id="overlay" className="popup-container">
                  <div className="flex max-h-full w-full flex-col items-center">
                    <div role="dialog" tabIndex={1} className="rounded-ds-dialog py-ds-dialog bg-ds-dialog backdrop-blur-ds-dialog focus-visible:outline-ds-dialog box-border flex max-h-full flex-col focus-visible:outline-2 animate-[ds-dialog-fadeIn_0.2s_forwards] max-w-full">
                      <div className="text-ds-celias-20-medium px-ds-dialog gap-ds-2 flex flex-row items-center justify-between">
                        <div className="gap-ds-2 flex flex-row items-center">
                          <div className="gap-ds-2 flex flex-col">
                            <span className="text-ds-primary text-ds-celias-20-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0">Tranzakció hozzáadása</span>
                          </div>
                        </div>
                        <div className="[&>*]:p-ds-2 [&>*]:m-ds-0 -me-1 flex flex-row">
                          <button onClick={handleClosePopup} className="flex p-ds-3 ms-ds-1 me-ds-1 mt-ds-1 mb-ds-1 text-ds-button-neutral-none hover:text-ds-button-neutral-none-hover bg-ds-button-neutral-none hover:bg-ds-button-neutral-none-hover active:bg-ds-button-neutral-none-active rounded-ds-round box-border relative focus-visible:outline focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-pointer [-webkit-appearance:button] focus:outline-ds-button">
                            <CloseRoundedIcon />
                          </button>
                        </div>
                      </div>
                      <div className="overflow-auto">
                        <div className="px-ds-dialog overflow-visible">
                          <div className="modal-content-container">
                            <table className="transaction-modal-table-container">
                              <tbody>
                                {txTypeError && <span style={{ color: 'red' }}>{txTypeError}</span>}
                                <tr>
                                  <td colSpan={2}>
                                    <div className="styled-select">
                                      <div className="select" onClick={toggleDropdown}>
                                        <span>{selectedOption || 'Select Type of Transaction'}</span>
                                        <i className={`arrow ${isDropdownOpen ? 'open' : ''}`} />
                                      </div>
                                      {isDropdownOpen && (
                                        <ul className="dropdown-menu">
                                          {transactionTypes.map((option, index) => (
                                            <li
                                              key={index}
                                              className="option"
                                              onClick={() => handleOptionSelect(option)}
                                            >
                                              {option}
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                                {txDateError && <span style={{ color: 'red' }}>{txDateError}</span>}
                                <tr>
                                  <div className='add-transaction-input-div w-full'>
                                    <div className='add-transaction-input-inner-div'>
                                      <input placeholder='Date' className='add-transaction-input' type='datetime-local' value={transactionDate} onChange={handleDateChange} />
                                    </div>
                                  </div>
                                </tr>
                                {selectedOption === 'Exchange' || selectedOption === 'Withdrawal' ? (
                                <div className='tx-box-separator' style={{ borderColor: 'grey' }}>
                                  <h3 style={{ color: 'red' }}>Sent</h3>
                                  <div>
                                    {txSentWalletError && <span style={{ color: 'red' }}>{txSentWalletError}</span>}
                                  </div>
                                  <div>
                                    {txFromCurrencyError && <span style={{ color: 'red' }}>{txFromCurrencyError}</span>}
                                  </div>
                                  <div>
                                    {txFromAmountError && <span style={{ color: 'red' }}>{txFromAmountError}</span>}
                                  </div>
                                  <tr style={{ marginBottom: '5px' }}>
                                    <td colSpan={2}>
                                      <div className="styled-select">
                                        <div className="select" onClick={toggleSentWalletDropdown}>
                                          <span className='text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{selectedSentWallet?.name || 'Select Wallet'}</span>
                                          <i className={`arrow ${isSentWalletDropdownOpen ? 'open' : ''}`} />
                                        </div>
                                        {isSentWalletDropdownOpen && (
                                          <ul className="dropdown-menu">
                                            {walletDetails && walletDetails.wallets.map((wallet, index) => (
                                              <li
                                                key={index}
                                                className="option"
                                                onClick={() => handleOptionSelectSentWallet(wallet)}
                                              >
                                                <div className='flex' style={{ alignItems: 'center' }}>
                                                  <div>
                                                    <img width={35} height={35} src={ExchangeImages[wallet.type as keyof typeof ExchangeImages]} alt='img' style={{
                                                      borderRadius: "1rem"
                                                    }} />
                                                  </div>
                                                  <div style={{ marginLeft: '10px' }}>
                                                    <span className='text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{wallet.name}</span>
                                                  </div>
                                                </div>
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                  <div className='flex justify-between'>
                                    <tr style={{ width: '-webkit-fill-available', marginRight: '5px' }}>
                                      <td colSpan={2}>
                                        <div className="styled-select">
                                          <div className="select" onClick={toggleFromCurrencyDropdown}>
                                            <span className='text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{selectedFromCurrency || 'Select Currency'}</span>
                                            <i className={`arrow ${isFromCurrencyDropdownOpen ? 'open' : ''}`} />
                                          </div>
                                          {isFromCurrencyDropdownOpen && (
                                            <div className="dropdown-menu-container">
                                              <input
                                                type="text"
                                                placeholder="Search currency"
                                                className="search-input-currency-transaction"
                                                value={searchTermFrom}
                                                onChange={handleSearchInputChangeFrom}
                                              />
                                              <ul className="dropdown-menu" style={{ maxHeight: '300px', overflow: 'scroll' }}>
                                                {filteredCoinsFrom.map((coin, index) => (
                                                  <li
                                                    key={index}
                                                    className="option"
                                                    onClick={() => handleOptionSelectFromCurrency(coin.code)}
                                                  >
                                                    <div className='flex' style={{ alignItems: 'center' }}>
                                                      <div>
                                                        <img width={25} height={25} src={coin.image} alt={coin.currency} />
                                                      </div>
                                                      <div style={{ marginLeft: '10px' }}>
                                                        <span className='text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{coin.code}</span>
                                                      </div>
                                                    </div>
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr style={{ width: '-webkit-fill-available', marginLeft: '5px' }}>
                                      <td colSpan={2}>
                                        <div className='add-transaction-input-div'>
                                          <div className='add-transaction-input-inner-div'>
                                            <input placeholder='Amount' className='add-transaction-input w-full' type='text' value={fromAmount} onChange={handleFromAmountChange} />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </div>
                                </div>
                                ) : null}
                                {selectedOption === 'Exchange' || selectedOption === 'Deposit' ? (
                                <div className='tx-box-separator' style={{ marginTop: '10px', borderColor: 'grey' }}>
                                  <h3 style={{ color: 'green' }}>Received</h3>
                                  <div>
                                    {txReceivedWalletError && <span style={{ color: 'red' }}>{txReceivedWalletError}</span>}
                                  </div>
                                  <div>
                                    {txToCurrencyError && <span style={{ color: 'red' }}>{txToCurrencyError}</span>}
                                  </div>
                                  <div>
                                    {txToAmountError && <span style={{ color: 'red' }}>{txToAmountError}</span>}
                                  </div>
                                  <tr style={{ marginBottom: '5px' }}>
                                    <td colSpan={2}>
                                      <div className="styled-select">
                                        <div className="select" onClick={toggleReceivedWalletDropdown}>
                                          <span className='text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{selectedReceiveWallet?.name || 'Select Wallet'}</span>
                                          <i className={`arrow ${isReceivedWalletDropdownOpen ? 'open' : ''}`} />
                                        </div>
                                        {isReceivedWalletDropdownOpen && (
                                          <ul className="dropdown-menu">
                                            {walletDetails && walletDetails.wallets.map((wallet, index) => (
                                              <li
                                                key={index}
                                                className="option"
                                                onClick={() => handleOptionSelectReceivedWallet(wallet)}
                                              >
                                                <div className='flex' style={{ alignItems: 'center' }}>
                                                  <div>
                                                    <img width={35} height={35} src={ExchangeImages[wallet.type as keyof typeof ExchangeImages]} alt='img' style={{
                                                      borderRadius: "1rem"
                                                    }} />
                                                  </div>
                                                  <div style={{ marginLeft: '10px' }}>
                                                    <span className='text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{wallet.name}</span>
                                                  </div>
                                                </div>
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                  <div className='flex justify-between'>
                                    <tr style={{ width: '-webkit-fill-available', marginRight: '5px' }}>
                                      <td colSpan={2}>
                                        <div className="styled-select">
                                          <div className="select" onClick={toggleToCurrencyDropdown}>
                                            <span className='text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{selectedToCurrency || 'Select Currency'}</span>
                                            <i className={`arrow ${isToCurrencyDropdownOpen ? 'open' : ''}`} />
                                          </div>
                                          {isToCurrencyDropdownOpen && (
                                            <div className="dropdown-menu-container">
                                              <input
                                                type="text"
                                                placeholder="Search currency"
                                                className="search-input-currency-transaction"
                                                value={searchTermTo}
                                                onChange={handleSearchInputChangeTo}
                                              />
                                              <ul className="dropdown-menu" style={{ maxHeight: '300px', overflow: 'scroll' }}>
                                                {filteredCoinsTo.map((coin, index) => (
                                                  <li
                                                    key={index}
                                                    className="option"
                                                    onClick={() => handleOptionSelectToCurrency(coin.code)}
                                                  >
                                                    <div className='flex' style={{ alignItems: 'center' }}>
                                                      <div>
                                                        <img width={25} height={25} src={coin.image} alt={coin.currency} />
                                                      </div>
                                                      <div style={{ marginLeft: '10px' }}>
                                                        <span className='text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{coin.code}</span>
                                                      </div>
                                                    </div>
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr style={{ width: '-webkit-fill-available', marginLeft: '5px' }}>
                                      <td colSpan={2}>
                                        <div className='add-transaction-input-div'>
                                          <div className='add-transaction-input-inner-div'>
                                            <input placeholder='Amount' className='add-transaction-input w-full' type='text' value={toAmount} onChange={handleToAmountChange} />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </div>
                                </div>
                                ) : null}
                                {selectedOption === 'Deposit' ? (
                                <tr>
                                  <div className='add-transaction-input-div w-full'>
                                    <div className='add-transaction-input-inner-div'>
                                      <input placeholder='From address (Optional)'  className='add-transaction-input' type='text' value={fromAddress} onChange={handleFromAddressChange} />
                                    </div>
                                  </div>
                                </tr>
                                ) : null}
                                {selectedOption === 'Withdrawal' ? (
                                <tr>
                                  <div className='add-transaction-input-div w-full'>
                                    <div className='add-transaction-input-inner-div'>
                                      <input placeholder='To address (Optional)' className='add-transaction-input' type='text' value={toAddress} onChange={handleToAddressChange} />
                                    </div>
                                  </div>
                                </tr>
                                ) : null}
                                <button style={{ marginTop: '10px' }} onClick={handleSaveTransaction} className='w-full shadow-ds-button-primary-high shadow-ds-button-primary-high-active text-ds-button-primary-high rounded-ds-round margin-start-0 margind-end-4 text-ds-button-sm bg-ds-button-primary-high py-ds-3 px-ds-5 text-transform-none'>Save transaction</button>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </main>
      </div>
    )
};

export default Transactions;