import { 
    Box, 
    Button, 
    Typography, 
    useTheme 
} from '@mui/material';
import { useMemo, useState } from 'react';
import { kpis } from '../dashboard/data';
import { 
    CartesianGrid, 
    Label, 
    Legend, 
    Line, 
    LineChart, 
    ResponsiveContainer, 
    Tooltip, 
    XAxis, 
    YAxis 
} from 'recharts';
import regression, { DataPoint } from "regression";
import { GetKpisResponse } from '../../state/types';
import DashboardBox from '../../components/dashboard/DashboardBox';
import FlexBetween from '../../components/general/FlexBetween';
import PageHeader from '../../components/dashboard/PageHeader';

function convertToGetKpisResponse(data: any[]): Array<GetKpisResponse> {
  return data.map((item) => {
    // Extracting totalProfit, totalRevenue, and totalExpenses from strings to numbers
    const totalProfit = parseFloat(item.totalProfit.replace('$', '').replace(/,/g, ''));
    const totalRevenue = parseFloat(item.totalRevenue.replace('$', '').replace(/,/g, ''));
    const totalExpenses = parseFloat(item.totalExpenses.replace('$', '').replace(/,/g, ''));

    // Extracting monthly data
    const monthlyData = item.monthlyData.map((month: any) => ({
      id: month.month.toLowerCase(),
      month: month.month,
      revenue: parseFloat(month.revenue.replace('$', '').replace(/,/g, '')),
      expenses: parseFloat(month.expenses.replace('$', '').replace(/,/g, '')),
      operationalExpenses: parseFloat(month.operationalExpenses.replace('$', '').replace(/,/g, '')),
      nonOperationalExpenses: parseFloat(month.nonOperationalExpenses.replace('$', '').replace(/,/g, '')),
    }));

    // Extracting daily data
    const dailyData = item.dailyData.map((day: any) => ({
      id: day.date,
      date: day.date,
      revenue: parseFloat(day.revenue.replace('$', '').replace(/,/g, '')),
      expenses: parseFloat(day.expenses.replace('$', '').replace(/,/g, '')),
    }));

    // Creating the GetKpisResponse object
    return {
      id: item._id,
      _id: item._id,
      __v: 0, // Assuming __v is always 0
      totalProfit,
      totalRevenue,
      totalExpenses,
      expensesByCategory: {
        salaries: 38000.00, // You can set appropriate values if available
        supplies: 13000.00,
        services: 10000.00,
      },
      monthlyData,
      dailyData,
      createdAt: '', // You can set the appropriate createdAt value if available
      updatedAt: '', // You can set the appropriate updatedAt value if available
    };
  });
}

const getKpisResponseArray = convertToGetKpisResponse(kpis);

const Predictions = () => {
    const [isPredictions, setIsPredictions] = useState(false);
    const data = getKpisResponseArray;

    const formattedData = useMemo(() => {
        if (!data) return [];
        const monthData = data[0].monthlyData;

        const formatted: Array<DataPoint> = monthData.map(
            ({ revenue }, i: number) => {
                return[i, revenue];
            }
        );
        const regressionLine = regression.linear(formatted);

        return monthData.map(({ month, revenue }, i: number) => {
            return {
                name: month,
                "Actual Revenue": revenue,
                "Regression Line": regressionLine.points[i][1],
                "Predicted Revenue": regressionLine.predict(i + 12)[1]
            };
        });
    }, [data]);

    return (
        <>
        <div className='content-container'>
            <Box margin="auto" width="95%" mb="2rem" padding="1rem 2rem 0rem 2rem">
                <PageHeader 
                    name="Predictions"
                    description="See predictions based on Machine Learning"
                    isDateRange={false}
                    buttonText="Connect Wallet"
                />
            </Box>
            <Box margin="auto" width="95%" height="100%" display="grid" gap="1.5rem"
                padding="0rem 2rem 2rem 2rem">
                <DashboardBox
                    margin="auto"
                    width="100%" 
                    height="100%" 
                    p="1rem" 
                    overflow="hidden"
                >
                    <FlexBetween m="1rem 2.5rem" gap="0.3rem">
                        <Box>
                            <Typography variant="h3">Revenue and Predictions</Typography>
                            <Typography variant="h6">
                                charted revenue and predicted revenue based on a simple linear
                                regression model
                            </Typography>
                        </Box>
                        <Button 
                            onClick={() => setIsPredictions(!isPredictions)}
                            sx={{
                                color: "#fff" ,
                                backgroundColor: "#fff" ,
                                boxShadow: "0.1rem 0.1rem 0.1rem 0.1rem rgba(0,0,0,.4)"
                            }}
                        >
                            Show Predicted Revenue for Next Year
                        </Button>
                    </FlexBetween>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                        data={formattedData}
                        margin={{
                            top: 20,
                            right: 75,
                            left: 20,
                            bottom: 80,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" stroke={"#fff" } />
                        <XAxis 
                            dataKey="name" 
                            tickLine={false} 
                            style={{ fontSize: "10px" }} 
                        >
                            <Label value="Month" offset={-5} position="insideBottom" />
                        </XAxis>
                        <YAxis
                            domain={[12000, 26000]}
                            axisLine={{ strokeWidth: "0" }}
                            style={{ fontSize: "10px" }}
                            tickFormatter={(v) => `$${v}`}
                        >
                            <Label 
                                value="Revenue in USD" 
                                angle={-90} 
                                offset={-5} 
                                position="insideLeft" 
                            />
                        </YAxis>
                        <Tooltip />
                        <Legend verticalAlign="top" />
                        <Line 
                            type="monotone"
                            dataKey="Actual Revenue"
                            stroke={"#fff" }
                            strokeWidth={0}
                            dot={{ strokeWidth: 5 }}
                        />
                        <Line 
                            type="monotone"
                            dataKey="Regression Line"
                            stroke="#8884d8"
                            dot={false}
                        />
                        {isPredictions && (
                            <Line 
                            strokeDasharray="5 5"
                            dataKey="Predicted Revenue"
                            stroke={"#fff" }
                        />
                        )}
                        </LineChart>
                    </ResponsiveContainer>
                </DashboardBox>
            </Box>
        </div>
        </>
    );
};

export default Predictions;