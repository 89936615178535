import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

const ActivateAccount: React.FC = () => {
    const location = useLocation();
    const { email } = location.state;

    return (
        <div className="login-page">
            <div className='login-content-box'>
                <Typography
                    textAlign="center"
                    fontSize="3rem"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="125%"
                    letterSpacing="-0.036rem"
                    className="main-name"
                >
                    Kontri.io
                </Typography>
                <div className='activation-content-box-content' style={{ textAlign: 'center' }}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap="0.5rem"
                    >
                        <Typography
                            fontFamily="Inter"
                            fontSize="1.7rem"
                            fontStyle="normal"
                            fontWeight="500"
                            lineHeight="2.25rem"
                            letterSpacing="-0.01406rem"
                        >
                            Emailt küldtünk a <strong style={{ color: 'black' }}>{email}</strong> címre. Az fiókod aktiválásához kérjük, nyisd meg az email címedre küldött linket.
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        gap="1rem"
                        alignSelf="stretch"
                    >
                    </Box>
                </div>
                <Typography>2024 Kontri, Minden jog fenntartva</Typography>
            </div>
        </div>
    );
};

export default ActivateAccount;