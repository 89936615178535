import { useMemo } from 'react';
import { kpis, products } from '../dashboard/data';
import { 
    CartesianGrid, 
    Cell,
    Line, 
    LineChart, 
    Pie, 
    PieChart, 
    ResponsiveContainer, 
    Scatter, 
    ScatterChart, 
    Tooltip, 
    XAxis, 
    YAxis,
    ZAxis
} from 'recharts';
import { Box, Typography, useTheme } from '@mui/material';
import { GetKpisResponse, GetProductsResponse } from '../../state/types';
import DashboardBox from '../../components/dashboard/DashboardBox';
import BoxHeader from '../../components/dashboard/BoxHeader';
import FlexBetween from '../../components/general/FlexBetween';

function convertToGetKpisResponse(data: any[]): Array<GetKpisResponse> {
  return data.map((item) => {
    // Extracting totalProfit, totalRevenue, and totalExpenses from strings to numbers
    const totalProfit = parseFloat(item.totalProfit.replace('$', '').replace(/,/g, ''));
    const totalRevenue = parseFloat(item.totalRevenue.replace('$', '').replace(/,/g, ''));
    const totalExpenses = parseFloat(item.totalExpenses.replace('$', '').replace(/,/g, ''));

    // Extracting monthly data
    const monthlyData = item.monthlyData.map((month: any) => ({
      id: month.month.toLowerCase(),
      month: month.month,
      revenue: parseFloat(month.revenue.replace('$', '').replace(/,/g, '')),
      expenses: parseFloat(month.expenses.replace('$', '').replace(/,/g, '')),
      operationalExpenses: parseFloat(month.operationalExpenses.replace('$', '').replace(/,/g, '')),
      nonOperationalExpenses: parseFloat(month.nonOperationalExpenses.replace('$', '').replace(/,/g, '')),
    }));

    // Extracting daily data
    const dailyData = item.dailyData.map((day: any) => ({
      id: day.date,
      date: day.date,
      revenue: parseFloat(day.revenue.replace('$', '').replace(/,/g, '')),
      expenses: parseFloat(day.expenses.replace('$', '').replace(/,/g, '')),
    }));

    // Creating the GetKpisResponse object
    return {
      id: item._id,
      _id: item._id,
      __v: 0, // Assuming __v is always 0
      totalProfit,
      totalRevenue,
      totalExpenses,
      expensesByCategory: {
        salaries: 38000.00, // You can set appropriate values if available
        supplies: 13000.00,
        services: 10000.00,
      },
      monthlyData,
      dailyData,
      createdAt: '', // You can set the appropriate createdAt value if available
      updatedAt: '', // You can set the appropriate updatedAt value if available
    };
  });
}

const getKpisResponseArray = convertToGetKpisResponse(kpis);

function convertToGetProductsResponse(data: any[]): Array<GetProductsResponse> {
  return data.map((item) => {
    // Parse price and expense strings to numbers
    const price = parseFloat(item.price.replace('$', ''));
    const expense = parseFloat(item.expense.replace('$', ''));

    return {
      id: item._id,
      _id: item._id,
      __v: item.__v,
      price: price,
      expense: expense,
      transactions: item.transactions,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    };
  });
}

const pieData = [
    { name: "Group A", value: 600 },
    { name: "Group B", value: 400 }
]

const getProductsResponseArray = convertToGetProductsResponse(products);

const Row2 = () => {
    const pieColors = ["#fff" , "#fff" ];
    const operationalData = getKpisResponseArray;
    const productData = getProductsResponseArray;

    const operationalExpenses = useMemo(() => {
        return (
            operationalData &&
            operationalData[0].monthlyData.map(({ month, operationalExpenses, nonOperationalExpenses }) => {
                return {
                    name: month.substring(0, 3),
                    "Operational Expenses": operationalExpenses,
                    "Non Operational Expenses": nonOperationalExpenses,
                }
            })
        );
    }, [operationalData]);

    const productExpenseData = useMemo(() => {
        return (
            productData &&
            productData.map(({ _id, price, expense }) => {
                return {
                    id: _id,
                    price: price,
                    expense: expense,
                }
            })
        );
    }, [productData]);

    return (
        <>
            <DashboardBox gridArea="d">
                <BoxHeader
                    title="Operational vs Non-Operational Expanses"
                    sideText="+4%"
                />
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                    data={operationalExpenses}
                    margin={{
                        top: 20,
                        right: 0,
                        left: -10,
                        bottom: 55,
                    }}
                    >
                    <CartesianGrid vertical={false} stroke={"#fff" } />
                    <XAxis 
                        dataKey="name" 
                        tickLine={false} 
                        style={{ fontSize: "10px" }} 
                    />
                    <YAxis
                        yAxisId="left"
                        orientation="left"
                        tickLine={false}
                        axisLine={false}
                        style={{ fontSize: "10px" }}
                    />
                    <YAxis
                        yAxisId="right"
                        orientation="right"
                        tickLine={false} 
                        axisLine={false}
                        style={{ fontSize: "10px" }}
                    />
                    <Tooltip />
                    <Line 
                        yAxisId="left"
                        type="monotone"
                        dataKey="Non Operational Expenses"
                        stroke={"#fff" }
                    />
                    <Line 
                        yAxisId="right"
                        type="monotone"
                        dataKey="Operational Expenses"
                        stroke={"#fff" }
                    />
                    </LineChart>
                </ResponsiveContainer>
            </DashboardBox>
            <DashboardBox gridArea="e">
                <BoxHeader
                    title="Campaigns and Targets"
                    sideText="+4%"
                />
                <FlexBetween mt="0.25rem" gap="1.5rem" pr="1rem">
                    <PieChart 
                        width={110} 
                        height={100}
                        margin={{
                            top: 0,
                            right: -10,
                            left: 10,
                            bottom: 0,
                        }}
                    >
                        <Pie
                            stroke="none"
                            data={pieData}
                            innerRadius={18}
                            outerRadius={38}
                            paddingAngle={2}
                            dataKey="value"
                        >
                            {pieData.map((entry, index) => (
                                <Cell 
                                    key={`cell-${index}`} 
                                    fill={pieColors[index]} 
                                />
                            ))}
                        </Pie>
                    </PieChart>
                    <Box ml="-0.7rem" flexBasis="40%" textAlign="center">
                        <Typography variant="h5">Target Sales</Typography>
                        <Typography m="0.3rem 0" variant="h3" color={"#fff" }>
                            83
                        </Typography>
                        <Typography variant="h6">
                            Finance goals of the campaign that is desired
                        </Typography>
                    </Box>
                    <Box flexBasis="40%">
                        <Typography variant="h5">Losses in Revenue</Typography>
                        <Typography variant="h6">
                            Losses are down 25%
                        </Typography>
                        <Typography mt="0.4rem" variant="h5">
                            Profit Margins
                        </Typography>
                        <Typography variant="h6">
                            Margins are up by 30% from last month.
                        </Typography>
                    </Box>
                </FlexBetween>
            </DashboardBox>
            <DashboardBox gridArea="f">
                <BoxHeader title="Product Prices vs Expenses" sideText="+4%" />
                <ResponsiveContainer width="100%" height="100%">
                    <ScatterChart
                        margin={{
                            top: 20,
                            right: 25,
                            bottom: 40,
                            left: -10,
                        }}
                    >
                        <CartesianGrid  stroke={"#fff" }/>
                        <XAxis 
                            type="number" 
                            dataKey="price" 
                            name="price" 
                            axisLine={false}
                            tickLine={false}
                            style={{ fontSize: "10%" }}
                            tickFormatter={(v) => `$${v}`}
                        />
                        <YAxis 
                            type="number" 
                            dataKey="expense" 
                            name="expense" 
                            axisLine={false}
                            tickLine={false}
                            style={{ fontSize: "10%" }}
                            tickFormatter={(v) => `$${v}`}
                        />
                        <ZAxis type="number" range={[20]} />
                        <Tooltip formatter={(v) => `$${v}`} />
                        <Scatter 
                            name="Product Expense Ratio" 
                            data={productExpenseData} 
                            fill={"#fff" } 
                        />
                    </ScatterChart>
                </ResponsiveContainer>
            </DashboardBox>
        </>
    )
};

export default Row2;