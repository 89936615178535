import { cryptoCurrencyDictionary } from "../general/constants/cryptocurrencies";
import { currencyDictionary } from "../general/constants/currencies";

interface CurrencyInfo {
    currency: string;
    currencyCode: string;
    image: string;
}

interface CryptoCurrencyInfo {
    cryptoCurrency: string;
    cryptoCurrencyCode: string;
    image: string;
}

interface MergedInfo {
    currency: string;
    code: string;
    image: string;
}

/**
 * This service returns the list of all currencies
 */
export function currencies(): void {
    for (const code in currencyDictionary) {
        if (Object.prototype.hasOwnProperty.call(currencyDictionary, code)) {
            const register = currencyDictionary[code];
            console.log(`Code: ${code}, Name: ${register.currency}, Code: ${register.currencyCode}`);
        }
    }
}

/**
 * This service returns the list of all cryptocurrencies
 */
export function cryptoCurrencies(): void {
    for (const code in cryptoCurrencyDictionary) {
        if (Object.prototype.hasOwnProperty.call(cryptoCurrencyDictionary, code)) {
            const register = cryptoCurrencyDictionary[code];
            console.log(`Code: ${code}, Name: ${register.cryptoCurrency}, Code: ${register.cryptoCurrencyCode}`);
        }
    }
}

export function getCryptoCodeByName(name: string): string | undefined {
    for (const code in cryptoCurrencyDictionary) {
        if (Object.prototype.hasOwnProperty.call(cryptoCurrencyDictionary, code)) {
            const info = cryptoCurrencyDictionary[code];
            if (info.cryptoCurrency === name) {
                return code;
            }
        }
    }
    return undefined;
}

export function getCurrencyCodeByName(name: string): string | undefined {
    for (const code in currencyDictionary) {
        if (Object.prototype.hasOwnProperty.call(currencyDictionary, code)) {
            const info = currencyDictionary[code];
            if (info.currency === name) {
                return code;
            }
        }
    }
    return undefined;
}

export function isTraditionalCurrency(currency: string): boolean {
    for (const code in currencyDictionary) {
        if (Object.prototype.hasOwnProperty.call(currencyDictionary, code)) {
            const info = currencyDictionary[code];
            if (info.currencyCode === currency) {
                return true;
            }
        }
    }
    return false;
}

export function isCryptoCurrency(currency: string): boolean {
    for (const code in cryptoCurrencyDictionary) {
        if (Object.prototype.hasOwnProperty.call(cryptoCurrencyDictionary, code)) {
            const info = cryptoCurrencyDictionary[code];
            if (info.cryptoCurrencyCode === currency) {
                return true;
            }
        }
    }
    return false;
}

export function mergeAndListInfo(currencyDict: Record<string, CurrencyInfo>, cryptoDict: Record<string, CryptoCurrencyInfo>): Record<string, MergedInfo> {
    const mergedDict: Record<string, MergedInfo> = {};

    // Merge currencies
    for (const currencyCode in currencyDict) {
        const currencyInfo = currencyDict[currencyCode];
        mergedDict[currencyCode] = { currency: currencyInfo.currency, code: currencyInfo.currencyCode, image: currencyInfo.image };
    }

    // Merge cryptocurrencies
    for (const cryptoCode in cryptoDict) {
        const cryptoInfo = cryptoDict[cryptoCode];
        mergedDict[cryptoCode] = { currency: cryptoInfo.cryptoCurrency, code: cryptoInfo.cryptoCurrencyCode, image: cryptoInfo.image };
    }

    return mergedDict;
}
