import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

type Props = {};

const AddTransaction = (props: Props) => {
    return (
      <div className='content-container'>
        <main className='content-content-full-width'>
          <header className='content-header section header'>
              <div className='exchanges-header'>
                  <h1 className='text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>NFTs</h1>
                  <div className='search-bordered search-container'>
                      <SearchRoundedIcon sx={{height: 24, width: 24}} />
                      <div>
                          <input id='exchange-search-input' type='text' placeholder='Search NFTs' className='search-input' />
                          <div className='search-hidden-label' aria-hidden="true">Search NFTs</div>
                      </div>
                  </div>
              </div>
          </header>
        </main>
      </div>
    )
};

export default AddTransaction;