import React from 'react';
import { Link } from 'react-router-dom';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DataThresholdingOutlinedIcon from '@mui/icons-material/DataThresholdingOutlined';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';

const Sidebar = () => {
  const navigationItems = [
    { text: 'Főoldal', icon: <HomeIcon />, link: '/p' },
    { text: 'Tárcák', icon: <AccountBalanceWalletIcon />, link: '/p/wallets' },
    { text: 'Tranzakciók', icon: <AccessTimeOutlinedIcon />, link: '/p/transactions' },
    { text: 'NFTk', icon: <DashboardRoundedIcon />, link: '/p/nfts' },
  ];

  return (
    <nav className="sidebar">
        <menu className='sidebar-menu'>
            {navigationItems.map((item) => (
                <li className='sidebar-menuitem text-ds-neutral text-ds-plex-18-medium ms-ds-0 mt-ds-0 me-ds-0 mb-ds-0'>
                    <span>
                        <a href={item.link}>
                          {item.icon}
                          {item.text}
                        </a>
                    </span>
                </li>
            ))}
        </menu>
    </nav>
  );
};

export default Sidebar;