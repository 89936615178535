import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { CryptocomWallet } from "../models/CryptocomWallet";
import { CreateCryptocomWalletRequest } from "../dto/create-crypto-com-wallet-request.dto";
const { REACT_APP_API_ENDPOINT } = process.env;

export const cryptocomApi = createApi({
    reducerPath: "cryptocomApi",
    baseQuery: fetchBaseQuery({ 
        baseUrl: `${REACT_APP_API_ENDPOINT}/api/cryptocom`, 
        credentials: "include" 
    }),
    endpoints: (build) => ({
        createCryptocomWallet: build.mutation<CryptocomWallet, CreateCryptocomWalletRequest>({
            query: (createCryptocomWalletRequest) => ({
                url: "/wallet/create",
                method: "POST",
                body: createCryptocomWalletRequest,
            }),
        }),
    }),
});

export const { useCreateCryptocomWalletMutation } = cryptocomApi;