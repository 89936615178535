import { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useCreateUserMutation } from '../../../api/user.api';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

const SignupForm: React.FC = () => {
    const [email, setEmail] = useState("");
    const [emailErrored, setEmailErrored] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState("");
    const [password, setPassword] = useState("");
    const [passwordErrored, setPasswordErrored] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [firstNameErrored, setFirstNameErrored] = useState(false);
    const [lastName, setLastName] = useState("");
    const [lastNameErrored, setLastNameErrored] = useState(false);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [createUser] = useCreateUserMutation();
    const navigate = useNavigate();

    const isEmailValid = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSignup = async () => {
        if (!email) {
            setEmailErrored(true);
            setEmailErrorText("Kérlek add meg az e-mail címed");
        } else if (!isEmailValid(email)) {
            setEmailErrored(true);
            setEmailErrorText("Kérlek adj meg egy érvényes e-mail címet");
        } else {
            setEmailErrorText("");
        }
        if (!password) {
            setPasswordErrored(true);
        } else {
            setPasswordErrored(false);
        }
        if (!firstName) {
            setFirstNameErrored(true);
        } else {
            setFirstNameErrored(false);
        }
        if (!lastName) {
            setLastNameErrored(true);
        } else {
            setLastNameErrored(false);
        } 
        try {
            if (!email || !password || !isEmailValid(email)) {
                return;
            }

             const response = await createUser({ firstName, lastName, email, password });
             if ('error' in response) {
                const error: any = response.error;
                const data = error?.data;
                const statusCode = data?.statusCode;
                const message = data?.message;

                if (statusCode === 400 && message?.includes("This email already exists")) {
                    setErrorMessage("A megadott e-mail címhez már tartozik regisztrált fiók.");
                    return;
                }
            }
            setRegistrationSuccess(true);
            navigate("/activate-account", { state: { email } });
        } catch (err) {
            console.log(err);
        }
    }
 
    return (
        <div className='login-content-box'>
            <Typography
                textAlign="center"
                fontSize="3rem"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="125%"
                letterSpacing="-0.036rem"
                className="main-name"
            >
                Kontri.io
            </Typography>
            <div className='login-content-box-content'>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="0.5rem"
                >
                    <Typography
                        fontFamily="Inter"
                        fontSize="1.7rem"
                        fontStyle="normal"
                        fontWeight="600"
                        lineHeight="2.25rem"
                        letterSpacing="-0.01406rem"
                    >
                        Kezdj hozzá most ✨
                    </Typography>
                </Box>
                {registrationSuccess ? (
                <Alert sx={{ width: '100%' }} icon={<CheckIcon fontSize="inherit" />} severity="success">
                    Hamarosan megkapod az e-mail megerősítő linket.
                </Alert>
                ) : (
                    null
                )}
                {errorMessage && (
                    <Alert severity="warning" style={{ width: '100%', marginBottom: '0rem' }}>
                        {errorMessage}
                    </Alert>
                )}
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="1rem"
                    alignSelf="stretch"
                >
                    <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
                        <TextField
                            label="Keresztnév"
                            fullWidth
                            type="text"
                            required
                            helperText={firstNameErrored && "Kérlek adj meg egy érvényes keresztnevet"}
                            value={firstName}
                            onChange={(event) => setFirstName(event.target.value)}
                            error={firstNameErrored}
                        />
                        <TextField
                            label="Vezetéknév"
                            fullWidth
                            type="text"
                            required
                            helperText={lastNameErrored && "Kérlek adj meg egy érvényes vezetéknevet"}
                            value={lastName}
                            onChange={(event) => setLastName(event.target.value)}
                            error={lastNameErrored}
                        />
                    </div>
                    <TextField
                        label="Email"
                        fullWidth
                        type="email"
                        required
                        helperText={emailErrorText}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        error={emailErrored}
                    />
                    <TextField
                        label="Jelszó"
                        fullWidth
                        type="password"
                        required
                        helperText={passwordErrored && "A jelszó nem lehet üres"}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        error={passwordErrored}
                    />
                    <PasswordStrengthBar password={password} style={{ width: '100%' }} minLength={8} />
                    <Button className="login-button" variant="contained" fullWidth onClick={handleSignup}>
                        <span style={{ color: '#FFF' }}>Regisztráció</span>
                    </Button>
                    <Typography>Már van fiókod?
                        <Link to="/login" className="custom-color">     Bejelentkezés</Link>
                    </Typography>
                </Box>
            </div>
            <Typography>2024 Kontri, Minden jog fenntartva</Typography>
        </div>
    )
};

export { SignupForm };