import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import { useState } from 'react';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';

type Props = {};

const KrakenGuide = (props: Props) => {
    return (
    <div className='content-container'>
        <main className='content-content-full-width'>
            <header className='content-header section header'>
                <div className='exchanges-header'>
                    <h1 className='text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Útmutatók / Kraken</h1>
                </div>
            </header>
            <section className='primary-content'>
                <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
                    <div className='flex items-center justify-between mb-ds-7'>
                        <div className='flex flex-col'>
                            <span className='text-ds-primary text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Adatok szinkronizálása a Kraken-ről</span>
                            <span className='text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>A következő leírás abban segít, hogy a legtöbbet hozhasd ki a <strong>Kontri.io</strong>-ból</span>
                        </div>
                    </div>
                    <div className='flex items-center justify-between mb-ds-7'>
                        <div className='flex flex-col'>
                            <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '15px' }}>A <strong>Kraken</strong> tárcák hozzáadásakor javasoljuk az API szinkronizálási lehetőség használatát.</span>
                        </div>
                    </div>
                    <div className='flex items-center justify-between mb-ds-7'>
                        <div className='flex flex-col'>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '10px', marginBottom: '30px' }}>Csatlakoztatás API-n keresztül</span>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.1rem', marginBottom: '20px' }}>Kraken fiók esetében:</span>
                            <ol style={{ marginLeft: '35px', marginBottom: '25px' }}>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Jelentkezz be a <strong>Kraken</strong> fiókodba.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Menj a profilodra a jobb felső sarokban, majd válaszd a <strong>Security</strong> opciót.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Görgess le a lap aljára és kattints a <strong>Create API key</strong> gombra az <strong>API Keys</strong> szekcióban.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Ez át fog irányítani téged a Kraken Pro felületére.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Ezen az oldalon kattints a <strong>Create API key</strong> gombra.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>A következő lépés az API kulcs létrehozása a lenti képen látható konfiguráció alapján.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Az API kulcsok sikeres létrehozását követően másold ki a kulcsokat és illetszd be a <strong>Kontri</strong> felületén.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Menj a Kontri <strong>Tárcák</strong> oldalára, majd kattints a <strong>Tárca hozzáadása</strong> gombra.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Válaszd ki a <strong>Kraken</strong> opciót.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Add meg az API-kulcsaidat, majd kattints a <strong>Kraken csatlakoztatása</strong> gombra.</span></li>
                            </ol>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.1rem', marginBottom: '20px' }}>Kraken Pro fiók esetében:</span>
                            <ol style={{ marginLeft: '35px', marginBottom: '25px' }}>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Jelentkezz be a <strong>Kraken</strong> fiókodba.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Menj a profilodra a jobb felső sarokban, majd válaszd a <strong>Settings</strong> opciót.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Kattints az API gombra a felső sorban.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Ezen az oldalon kattints a <strong>Create API key</strong> gombra.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>A következő lépés az API kulcs létrehozása a lenti képen látható konfiguráció alapján.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Az API kulcsok sikeres létrehozását követően másold ki a kulcsokat és illetszd be a <strong>Kontri</strong> felületén.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Menj a Kontri <strong>Tárcák</strong> oldalára, majd kattints a <strong>Tárca hozzáadása</strong> gombra.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Válaszd ki a <strong>Kraken</strong> opciót.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Add meg az API-kulcsaidat, majd kattints a <strong>Kraken csatlakoztatása</strong> gombra.</span></li>
                            </ol>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '10px', marginBottom: '40px' }}>Kraken API konfiguráció</span>
                            <div style={{ maxWidth: '100%', height: 'auto' }}>
                                <img src='/assets/images/site_images/kraken_konfig.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '30px', marginBottom: '40px' }}>Kraken API kulcsok</span>
                            <div style={{ maxWidth: '100%', height: 'auto' }}>
                                <img src='/assets/images/site_images/kraken_api.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '30px', marginBottom: '40px' }}>Kraken Tárca létrehozása</span>
                            <div style={{ maxWidth: '100%', height: 'auto' }}>
                                <img src='/assets/images/site_images/kraken_wallet.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '30px', marginBottom: '0px' }}>Ha egyéb kérdésed merül fel, lépj velünk kapcsolatba!</span>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
    )
};

export default KrakenGuide;