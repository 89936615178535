import { useNavigate } from 'react-router-dom';
import { Box, Button, Drawer, List, ListItem, ListItemText, Typography } from '@mui/material';
import FlexBetween from '../../components/general/FlexBetween';
import AccountMenu from '../../components/navbar/AccountMenu';
import HelpMenu from '../../components/navbar/HelpMenu';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useLogoutMutation } from '../../api/auth.api';
import { useAppDispatch } from '../../app/hooks';
import { setAuthState } from '../../slices/auth.slice';

const Navbar = () => {
    const [logout] = useLogoutMutation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const navigateHome = () => {
        navigate("/p");
    };

    const navigateHomeMobile = () => {
        navigate("/p");
        setDrawerOpen(!drawerOpen);
    };

    const navigateWallets = () => {
        navigate("/p/wallets");
        setDrawerOpen(!drawerOpen);
    };

    const navigateTransactions = () => {
        navigate("/p/transactions");
        setDrawerOpen(!drawerOpen);
    };

    const handleTaxCalculationClick = () => {
        navigate("/p/taxation");
    };

    const handleTaxCalculationClickMobile = () => {
        navigate("/p/taxation");
        setDrawerOpen(!drawerOpen);
    };

    const navigateDocs = () => {
        navigate("/p/account/documents");
        setDrawerOpen(!drawerOpen);
    };

    const navigateSubscription = () => {
        navigate("/p/account/subscription");
        setDrawerOpen(!drawerOpen);
    };

    const navigateSettings = () => {
        navigate("/p/account/settings");
        setDrawerOpen(!drawerOpen);
    };

    const navigateFeedback = () => {
        navigate("/p/feedback");
        setDrawerOpen(!drawerOpen);
    };

    const navigateSupport = () => {
        navigate("/p/support");
        setDrawerOpen(!drawerOpen);
    };

    const navigateGuides = () => {
        navigate("/p/guides");
        setDrawerOpen(!drawerOpen);
    };

    const navigateNfts = () => {
        navigate("/p/nfts");
        setDrawerOpen(!drawerOpen);
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleLogout = async () => {
        try {
        await logout();
        dispatch(setAuthState({ user: undefined }));
        window.location.href = "/login";
        } catch (err) {
        console.error(err);
        }
    }


    return (
        <>
            <Box className="top_nav">
                <Box className="Header_topnav">
                    <Box className="Header_content header-header-top header-transition">
                        <FlexBetween gap={1} className="Header_left brand-effect" onClick={navigateHome}>
                            <img src={window.location.origin + '/assets/icons/logo.png'} alt="logo" height={30} />
                            <Typography variant="h4" fontSize="25px">
                                <a href='/p' style={{ color: '#595b60' }}>Kontri.io</a>
                            </Typography>
                        </FlexBetween>
                        <Box className="Header_right">
                            <Button onClick={handleTaxCalculationClick} className='calculation-button-settings shadow-ds-button-primary-high shadow-ds-button-primary-high-active text-ds-button-primary-high rounded-ds-round margin-start-0 margind-end-4 text-ds-button-sm bg-ds-button-primary-high py-ds-3 px-ds-5 text-transform-none'>Kalkuláció</Button>
                            <HelpMenu />
                            <AccountMenu />
                            <div className='mobile-menu-kontri' >
                                <MenuIcon onClick={toggleDrawer} style={{ fontSize: '2rem' }}/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer} sx={{ 
                width: '70%',
                '& .MuiDrawer-paper': {
                    width: '70%'
                }
            }}>
                <div style={{ width: '100%' }}>
                    <List>
                        <div className="Header_left brand-effect" style={{ marginTop: '15px', marginBottom: '10px' }} onClick={navigateHomeMobile}>
                            <img src={window.location.origin + '/assets/icons/logo.png'} alt="logo" height={30} style={{ marginRight: '5px' }} />
                            <Typography variant="h4" fontSize="25px">
                                <a href='/p' style={{ color: '#595b60' }}>Kontri.io</a>
                            </Typography>
                        </div>
                        <div>
                            <ListItem className='sidebar-menuitem text-ds-primary text-ds-plex-18-medium ms-ds-0 mt-ds-0 me-ds-0 mb-ds-0' onClick={navigateHomeMobile}>
                                <HomeIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md' style={{ color: '#5841d8' }} />
                                <ListItemText primary="Főoldal" />
                            </ListItem>
                        </div>
                        <div>
                            <ListItem className='sidebar-menuitem text-ds-primary text-ds-plex-18-medium ms-ds-0 mt-ds-0 me-ds-0 mb-ds-0' onClick={navigateWallets}>
                                <AccountBalanceWalletIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md' style={{ color: '#5841d8' }} />
                                <ListItemText primary="Tárcák" />
                            </ListItem>
                        </div>
                        <div>
                            <ListItem className='sidebar-menuitem text-ds-primary text-ds-plex-18-medium ms-ds-0 mt-ds-0 me-ds-0 mb-ds-0' onClick={navigateTransactions}>
                                <AccessTimeOutlinedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' style={{ color: '#5841d8' }} />
                                <ListItemText primary="Tranzakciók" />
                            </ListItem>
                        </div>
                        <div>
                            <ListItem className='sidebar-menuitem text-ds-primary text-ds-plex-18-medium ms-ds-0 mt-ds-0 me-ds-0 mb-ds-0' onClick={navigateNfts}>
                                <DashboardRoundedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' style={{ color: '#5841d8' }} />
                                <ListItemText primary="NFTk" />
                            </ListItem>
                        </div>
                        <div>
                            <ListItem className='sidebar-menuitem text-ds-primary text-ds-plex-18-medium ms-ds-0 mt-ds-0 me-ds-0 mb-ds-0' onClick={handleTaxCalculationClickMobile}>
                                <Button onClick={handleTaxCalculationClick} className='shadow-ds-button-primary-high shadow-ds-button-primary-high-active text-ds-button-primary-high rounded-ds-round margin-start-0 margind-end-4 text-ds-button-sm bg-ds-button-primary-high py-ds-3 px-ds-5 text-transform-none'>Kalkuláció</Button>
                            </ListItem>
                        </div>
                        <div className='h-px block bg-ds-divider-high ms-ds-0 me-ds-0 mt-ds-5 mb-ds-5 px-ds-5' style={{ marginLeft: '10px', marginRight: '10px' }}></div>
                        <h2 className="text-ds-primary text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-3" style={{ marginLeft: '10px', marginRight: '10px' }}>Támogatás</h2>
                        <div>
                            <ListItem className='sidebar-menuitem text-ds-primary text-ds-plex-18-medium ms-ds-0 mt-ds-0 me-ds-0 mb-ds-0' onClick={navigateGuides}>
                                <QuizRoundedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' style={{ color: '#5841d8' }} />
                                <ListItemText primary="Útmutatók" />
                            </ListItem>
                        </div>
                        <div>
                            <ListItem className='sidebar-menuitem text-ds-primary text-ds-plex-18-medium ms-ds-0 mt-ds-0 me-ds-0 mb-ds-0' onClick={navigateSupport}>
                                <SupportAgentOutlinedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' style={{ color: '#5841d8' }} />
                                <ListItemText primary="Támogatás" />
                            </ListItem>
                        </div>
                        <div>
                            <ListItem className='sidebar-menuitem text-ds-primary text-ds-plex-18-medium ms-ds-0 mt-ds-0 me-ds-0 mb-ds-0' onClick={navigateFeedback}>
                                <TextsmsOutlinedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' style={{ color: '#5841d8' }} />
                                <ListItemText primary="Visszajelzés" />
                            </ListItem>
                        </div>
                        <div className='h-px block bg-ds-divider-high ms-ds-0 me-ds-0 mt-ds-5 mb-ds-5 px-ds-5' style={{ marginLeft: '10px', marginRight: '10px' }}></div>
                        <h2 className="text-ds-primary text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-3" style={{ marginLeft: '10px', marginRight: '10px' }}>Fiók</h2>
                        <div>
                            <ListItem className='sidebar-menuitem text-ds-primary text-ds-plex-18-medium ms-ds-0 mt-ds-0 me-ds-0 mb-ds-0' onClick={navigateSettings}>
                                <SettingsOutlinedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' style={{ color: '#5841d8' }} />
                                <ListItemText primary="Beállítások" />
                            </ListItem>
                        </div>
                        <div>
                            <ListItem className='sidebar-menuitem text-ds-primary text-ds-plex-18-medium ms-ds-0 mt-ds-0 me-ds-0 mb-ds-0' onClick={navigateSubscription}>
                                <PaymentOutlinedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' style={{ color: '#5841d8' }} />
                                <ListItemText primary="Előfizetés" />
                            </ListItem>
                        </div>
                        <div>
                            <ListItem className='sidebar-menuitem text-ds-primary text-ds-plex-18-medium ms-ds-0 mt-ds-0 me-ds-0 mb-ds-0' onClick={navigateDocs}>
                                <ArticleOutlinedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' style={{ color: '#5841d8' }} />
                                <ListItemText primary="Dokumentumok" />
                            </ListItem>
                        </div>
                        <div className='h-px block bg-ds-divider-high ms-ds-0 me-ds-0 mt-ds-5 mb-ds-5 px-ds-5' style={{ marginLeft: '10px', marginRight: '10px' }}></div>
                        <div>
                            <ListItem className='sidebar-menuitem text-ds-primary text-ds-plex-18-medium ms-ds-0 mt-ds-0 me-ds-0 mb-ds-0' onClick={handleLogout}>
                                <LogoutOutlinedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' style={{ color: '#5841d8' }} />
                                <ListItemText primary="Kijelentkezés" />
                            </ListItem>
                        </div>
                    </List>
                </div>
            </Drawer>
        </>
    );
};

export default Navbar;