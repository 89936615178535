type Props = {
    number: number | undefined
};

const NumberFormatComponent = ({ number }: Props) => {
    let formattedNumber = "0";
    if (number !== undefined) {
        const num = parseFloat(String(number));
        if (num !== 0) {
            // Check if the absolute value of the number is greater than or equal to 1
            // If so, limit to 2 decimal places
            const decimalPlaces = Math.abs(num) >= 1 ? 2 : 8;
            formattedNumber = num.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: decimalPlaces });
        }
    }

    return <>{formattedNumber}</>;
};

export default NumberFormatComponent;
