import React from 'react';
import { useNavigate } from 'react-router';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { WalletResponse } from '../../models/types/IWalletResponse';
import NumberFormatComponent from '../general/NumberFormatComponent';
import { WalletDetails } from '../../models/WalletDetails';
import { Menu, MenuItem } from '@mui/material';
import { useDeleteUserWalletMutation } from '../../api/wallet.api';

type Props = {
    walletDetails: WalletDetails;
};

export enum ExchangeImages {
  COINBASE = "/assets/images/exchanges/coinbase.jpeg",
  KRAKEN = "/assets/images/exchanges/kraken.jpeg",
  CRYPTOCOM = "/assets/images/exchanges/cryptocom.jpeg",
  REVOLUT = "/assets/images/exchanges/revolut.jpeg",
  BYBIT = "/assets/images/exchanges/bybit.jpeg",
  OKX = "/assets/images/exchanges/okx.png",
  GATEIO = "/assets/images/exchanges/gateio.jpeg",
  KUKOIN = "/assets/images/exchanges/kucoin.png",
  BINANCE = "/assets/images/exchanges/binance.jpeg",
  BITFINEX = "/assets/images/exchanges/bitfinex.png"
}

const UserWalletList = ({ walletDetails }: Props) => {
    const navigate = useNavigate();
    const [deleteUserWallet] = useDeleteUserWalletMutation();

    var totalLength = 0;
    if (walletDetails && walletDetails.wallets.length) {
        totalLength = walletDetails.wallets.length
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleWalletSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleWalletSettingsClose = () => {
        setAnchorEl(null);
    };

    const tbodyHeight = 40 + (totalLength * 80);

    const handleTrClick = (walletId: string) => {
        navigate("/p/wallets/" + walletId)
    }

    const handleDeleteWallet = async (walletId: string) => {
        const confirmDelete = window.confirm("Biztosan törölni akarja a tárcát?");
        if (confirmDelete) {
            try {
                await deleteUserWallet(walletId);
                window.location.reload();
            } catch (error) {
                console.error('Error deleting wallet:', error);
            }
        }
    }

    return (
        <>
        <table className='wallet-table'>
            <thead className='general-table'>
                <tr className='assets-table-row'>
                    <th className='assets-table-th min-width-0 table-header-container explore-exchange-column-name' colSpan={1} role='columnheader'>
                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                            <div className='table-cell' aria-colspan={1} role='columnheader'>Tőzsde / név</div>
                        </span>
                    </th>
                    <th className='assets-table-th min-width-0 table-header-container wallet-table-align-right explore-exchange-column-value' colSpan={1} role='columnheader'>
                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                            <div className='table-cell wallet-table-align-right' aria-colspan={1} role='columnheader'>Teljes érték</div>
                        </span>
                    </th>
                    <th className='assets-table-th min-width-0 table-header-container wallet-table-align-right explore-exchange-column-change' colSpan={1} role='columnheader'>
                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                            <div className='table-cell wallet-table-align-right' aria-colspan={1} role='columnheader'>Változás (24 óra)</div>
                        </span>
                    </th>
                    <th className='assets-table-th min-width-0 wallet-table-align-right explore-exchange-column-change' colSpan={1} role='columnheader'>
                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                            <div className='table-cell wallet-table-align-right' aria-colspan={1} role='columnheader'>Tranzakciók</div>
                        </span>
                    </th>
                    <th className='assets-table-th min-width-0 assets-table-right explore-exchange-column-delete' colSpan={1} role='columnheader'>
                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                            <div className='assets-table-right table-cell' aria-colspan={1} role='columnheader'></div>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody className='exchange-table-body table-table-row' style={{minHeight: `${tbodyHeight}px`, overflow: "hidden", display: "block"}}>
                {walletDetails?.wallets && walletDetails.wallets.map((wallet: WalletResponse, index: number) => {
                    const translateValue = index * 80;

                    return (
                    <tr className='exchange-table-row' style={{ transform: `translateY(${translateValue}px)` }} key={index}>
                        <td className='table-tabledata wallet-table-column-name' role='cell' onClick={() => handleTrClick(wallet.id)}>
                            <div className='table-cell-div' role='cell'>
                                <div className='wallet-info-cell'>
                                    <img width={50} height={50} src={ExchangeImages[wallet.type as keyof typeof ExchangeImages]} alt='img' style={{
                                    borderRadius: "1rem"
                                    }} />
                                    <div className='wallet-info-cell-name-right'>
                                        <span className='text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{wallet.name}</span>
                                        <span className='text-ds-neutral text-ds-plex-14-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{wallet.type}</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className='table-tabledata wallet-table-column-value' role='cell' onClick={() => handleTrClick(wallet.id)}>
                            <div className='table-cell-div justify-content-right' role='cell'>
                                <div className='position-end'>
                                    <span className='text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'><NumberFormatComponent number={wallet.totalHufValue} /> HUF</span>
                                </div>
                            </div>
                        </td>
                        <td className='table-tabledata wallet-table-column-change' role='cell' onClick={() => handleTrClick(wallet.id)}>
                            <div className='table-cell-div justify-content-right' role='cell'>
                                <div className='position-end'>
                                    <span className='text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{color: "green"}}><NumberFormatComponent number={wallet.change} />%</span>
                                </div>
                            </div>
                        </td>
                        <td className='table-tabledata wallet-table-column-transaction' role='cell' onClick={() => handleTrClick(wallet.id)}>
                            <div className='table-cell-div justify-content-right' role='cell'>
                                <div className='position-end'>
                                    <span className='text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{wallet.transactionCount}</span>
                                </div>
                            </div>
                        </td>
                        <td className='table-tabledata wallet-table-column-delete' role='cell'>
                            <div className='table-cell-div justify-center' role='cell'>
                                <span className='text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                    <div onClick={(event) => { event.stopPropagation(); handleWalletSettingsClick(event); }}>
                                        <MoreVertRoundedIcon />
                                    </div>
                                    <Menu
                                        anchorEl={anchorEl}
                                        id="account-menu"
                                        open={open}
                                        onClose={handleWalletSettingsClose}
                                        onClick={handleWalletSettingsClose}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                border: '1px solid grey',
                                                mt: 1.5,
                                                '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                '&::before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: 'background.paper',
                                                    zIndex: 0,
                                                },
                                            },
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        <MenuItem onClick={handleWalletSettingsClose}>
                                            Szerkesztés
                                        </MenuItem>
                                        <MenuItem onClick={() => handleDeleteWallet(wallet.id)}>
                                            Törlés
                                        </MenuItem>
                                    </Menu>
                                </span>
                            </div>
                        </td>
                    </tr>
                    );
                })}
            </tbody>
        </table>
        </>
    )
}

export { UserWalletList };