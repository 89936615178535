import { useAppSelector } from '../../app/hooks';
import { SignupForm } from '../../components/auth/signup-form/signup-form.component';
import { Box, Typography } from '@mui/material';
import { selectCurrentUser } from '../../slices/auth.slice';
import { useNavigate } from 'react-router-dom';
import { ResetPasswordForm } from '../../components/auth/resetPassword-form/resetPassword.component';

const ResetPasswordPage = () => {
    const user = useAppSelector((state) => selectCurrentUser(state));
    const navigate = useNavigate();

    if (user) {
        navigate("/p");
    }

    return (
        <div className="login-page">
            <ResetPasswordForm />
            <div className="login-content">
                <div className="login-content-inner">
                    <Typography
                            color="white"
                            fontSize="2rem"
                            fontStyle="normal"
                            fontWeight="700"
                            lineHeight="125%"
                            letterSpacing="-0.036rem"
                        >
                            Mi mindent kiszámítunk neked, hogy ne kelljen aggódnod
                        </Typography>
                        <Typography
                            color="#D4D4D8"
                            fontFamily="Inter"
                            fontSize="0.85rem"
                            fontStyle="normal"
                            fontWeight="600"
                            lineHeight="1.75rem"
                            letterSpacing="-0.00625rem"
                        >
                            Kövesd, elemezd és kezeld kriptovaluta eszközeidet a pénzügyi siker érdekében.
                        </Typography>
                    <img className="login-image" alt="The house from the offer." src={window.location.origin + '/test_image.png'} />
                </div>
            </div>
        </div>
    )
};

export default ResetPasswordPage;