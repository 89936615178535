import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { setAuthState } from '../../slices/auth.slice';
import { useNavigate } from "react-router";
import { useAppDispatch } from '../../app/hooks';
import { useLogoutMutation } from '../../api/auth.api';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [logout] = useLogoutMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      dispatch(setAuthState({ user: undefined }));
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  }
  
  return (
    <React.Fragment>
      <Box className='calculation-button-settings' sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            className='Menu_button'
            onClick={handleClick}
            size="small"
            sx={{ ms: 2 }}
          >
            <AccountCircleOutlinedIcon sx={{ width: 24, height: 24 }}/>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu className='calculation-button-settings'
        id="account-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{
          position: 'absolute',
          zIndex: 100000,
          borderRadius: "1rem",
        }}
      >
          <a href='/p/account/settings'>
            <MenuItem className='account-menu-menuitem'>
                <div className='account-menu-option-div'>
                  <SettingsOutlinedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' />
                  <span className='text-ds-neutral text-ds-plex-16-regular'>Beállítások</span>
                </div>
            </MenuItem>
          </a>
          <a href='/p/account/subscription'>
            <MenuItem className='account-menu-menuitem'>
                <div className='account-menu-option-div'>
                  <PaymentOutlinedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' />
                  <span className='text-ds-neutral text-ds-plex-16-regular'>Előfizetés</span>
                </div>
            </MenuItem>
          </a>
          <a href='/p/account/documents'>
            <MenuItem className='account-menu-menuitem'>
                <div className='account-menu-option-div'>
                  <ArticleOutlinedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' />
                  <span className='text-ds-neutral text-ds-plex-16-regular'>Dokumentumok</span>
                </div>
            </MenuItem>
          </a>
          <Divider />
          <MenuItem className='account-menu-menuitem' onClick={handleLogout}>
            <div className='account-menu-option-div'>
              <LogoutOutlinedIcon className='me-ds-3 stroke-ds-icon w-ds-icon-md h-ds-icon-md text-ds-neutral' />
              <span className='text-ds-neutral text-ds-plex-16-regular'>Kijelenetkezés</span>
            </div>
          </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
