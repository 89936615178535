import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { CreateRevolutWalletRequest } from "../dto/create-revolut-wallet-request.dto";
import { RevolutWallet } from "../models/RevolutWallet";
const { REACT_APP_API_ENDPOINT } = process.env;

const createFormDataAndSetInBody = (data: CreateRevolutWalletRequest): FormData => {
  const formData = new FormData();
  formData.append('csvTransactions', data.csvTransactions);
  formData.append('name', data.name);
  return formData;
};


export const revolutApi = createApi({
    reducerPath: "krakenApi",
    baseQuery: fetchBaseQuery({ 
        baseUrl: `${REACT_APP_API_ENDPOINT}/api/revolut`, 
        credentials: "include" 
    }),
    endpoints: (build) => ({
        createRevolutWallet: build.mutation<RevolutWallet, CreateRevolutWalletRequest>({
            query: (createRevolutWalletRequest) => ({
                url: "/wallet/create",
                method: "POST",
                body: createFormDataAndSetInBody(createRevolutWalletRequest),
                headers: {
                }
            }),
        }),
    }),
});

export const { useCreateRevolutWalletMutation } = revolutApi;