import './App.css';
import { useEffect, useState } from 'react';
import { Routes } from './routes/routes';
import Navbar from "./pages/navbar";
import Sidebar from './pages/vertical_navbar';
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { checkTokenExpiration, selectCurrentUser, setAuthState } from "./slices/auth.slice";
import { useGetUserQuery } from "./api/user.api";
import Footer from "./pages/footer";


function App() {
  const { data: user } = useGetUserQuery(undefined);
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector((state) => selectCurrentUser(state));

  useEffect(() => {
    dispatch(checkTokenExpiration());
    
    if (user) {
      dispatch(setAuthState({ user }));
    }

    const intervalId = setInterval(() => {
      dispatch(checkTokenExpiration());
    }, 60000);

    return () => clearInterval(intervalId);
  }, [user, dispatch]);
  
  return (
    <Box>
      <Box component="div" className="container">
        {isAuth && <Navbar />}
        <div className="container-body">
          {isAuth && <Sidebar />}
          <div>
            <Routes />
          </div>
          {isAuth && <Footer />}
        </div>
      </Box>
    </Box>
  );
}

export default App;
