import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { kpis } from '../dashboard/data';
import { 
    ResponsiveContainer, 
    AreaChart, 
    XAxis,
    YAxis,
    Tooltip,
    Area,
    Line,
    CartesianGrid,
    Legend,
    LineChart,
    BarChart,
    Bar
} from 'recharts';
import { GetKpisResponse } from '../../state/types';
import BoxHeader from '../../components/dashboard/BoxHeader';
import DashboardBox from '../../components/dashboard/DashboardBox';

function convertToGetKpisResponse(data: any[]): Array<GetKpisResponse> {
  return data.map((item) => {
    // Extracting totalProfit, totalRevenue, and totalExpenses from strings to numbers
    const totalProfit = parseFloat(item.totalProfit.replace('$', '').replace(/,/g, ''));
    const totalRevenue = parseFloat(item.totalRevenue.replace('$', '').replace(/,/g, ''));
    const totalExpenses = parseFloat(item.totalExpenses.replace('$', '').replace(/,/g, ''));

    // Extracting monthly data
    const monthlyData = item.monthlyData.map((month: any) => ({
      id: month.month.toLowerCase(),
      month: month.month,
      revenue: parseFloat(month.revenue.replace('$', '').replace(/,/g, '')),
      expenses: parseFloat(month.expenses.replace('$', '').replace(/,/g, '')),
      operationalExpenses: parseFloat(month.operationalExpenses.replace('$', '').replace(/,/g, '')),
      nonOperationalExpenses: parseFloat(month.nonOperationalExpenses.replace('$', '').replace(/,/g, '')),
    }));

    // Extracting daily data
    const dailyData = item.dailyData.map((day: any) => ({
      id: day.date,
      date: day.date,
      revenue: parseFloat(day.revenue.replace('$', '').replace(/,/g, '')),
      expenses: parseFloat(day.expenses.replace('$', '').replace(/,/g, '')),
    }));

    // Creating the GetKpisResponse object
    return {
      id: item._id,
      _id: item._id,
      __v: 0, // Assuming __v is always 0
      totalProfit,
      totalRevenue,
      totalExpenses,
      expensesByCategory: {
        salaries: 38000.00, // You can set appropriate values if available
        supplies: 13000.00,
        services: 10000.00,
      },
      monthlyData,
      dailyData,
      createdAt: '', // You can set the appropriate createdAt value if available
      updatedAt: '', // You can set the appropriate updatedAt value if available
    };
  });
}

const getKpisResponseArray = convertToGetKpisResponse(kpis);

const Row1 = () => {
    const data = getKpisResponseArray;

    const revenueExpenses = useMemo(() => {
        return (
            data &&
            data[0].monthlyData.map(({ month, revenue, expenses }) => {
                return {
                    name: month.substring(0, 3),
                    revenue: revenue,
                    expenses: expenses,
                }
            })
        );
    }, [data]);

    const revenueProfit = useMemo(() => {
        return (
            data &&
            data[0].monthlyData.map(({ month, revenue, expenses }) => {
                return {
                    name: month.substring(0, 3),
                    revenue: revenue,
                    profit: (revenue - expenses).toFixed(2),
                }
            })
        );
    }, [data]);

    const revenue = useMemo(() => {
        return (
            data &&
            data[0].monthlyData.map(({ month, revenue }) => {
                return {
                    name: month.substring(0, 3),
                    revenue: revenue,
                }
            })
        );
    }, [data]);

    return (
        <>
            <DashboardBox gridArea="a">
                <BoxHeader
                    title="Total Profit and Loss"
                    subtitle="top line represents revenue, bottom line represents expenses"
                    sideText="+12%"
                />
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                    width={500}
                    height={400}
                    data={revenueExpenses}
                    margin={{
                        top: 15,
                        right: 25,
                        left: -10,
                        bottom: 60,
                    }}
                    >
                    <defs>
                        <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
                            <stop 
                                offset="5%" 
                                stopColor={"#fff" } 
                                stopOpacity={0.5} 
                            />
                            <stop 
                                offset="95%"
                                stopColor={"#fff" } 
                                stopOpacity={0} 
                            />
                        </linearGradient>
                        <linearGradient id="colorExpenses" x1="0" y1="0" x2="0" y2="1">
                            <stop 
                                offset="5%" 
                                stopColor={"#fff" } 
                                stopOpacity={0.5} 
                            />
                            <stop 
                                offset="95%"
                                stopColor={"#fff" } 
                                stopOpacity={0} 
                            />
                        </linearGradient>
                    </defs>
                    <XAxis 
                        dataKey="name" 
                        tickLine={false} 
                        style={{ fontSize: "10px" }} 
                    />
                    <YAxis
                        tickLine={false} 
                        axisLine={{ strokeWidth: "0" }}
                        style={{ fontSize: "10px" }}
                        domain={[8000, 23000]}
                    />
                    <Tooltip />
                    <Area 
                        type="monotone" 
                        dataKey="revenue"
                        dot={true}
                        stroke={"#fff" }
                        fillOpacity={1}
                        fill="url(#colorRevenue)"
                    />
                    <Area 
                        type="monotone" 
                        dataKey="expenses"
                        dot={true}
                        stroke={"#fff" }
                        fillOpacity={1}
                        fill="url(#colorExpenses)"
                    />
                    </AreaChart>
                </ResponsiveContainer>
            </DashboardBox>
            <DashboardBox gridArea="b">
                <BoxHeader
                    title="Investment Performance (ROI)"
                    subtitle="top line represents revenue, bottom line represents expenses"
                    sideText="-5%"
                />
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                    data={revenueProfit}
                    margin={{
                        top: 20,
                        right: 0,
                        left: -10,
                        bottom: 55,
                    }}
                    >
                    <CartesianGrid vertical={false} stroke={"#fff" } />
                    <XAxis 
                        dataKey="name" 
                        tickLine={false} 
                        style={{ fontSize: "10px" }} 
                    />
                    <YAxis
                        yAxisId="left"
                        tickLine={false}
                        axisLine={false}
                        style={{ fontSize: "10px" }}
                    />
                    <YAxis
                        yAxisId="right"
                        orientation="right"
                        tickLine={false} 
                        axisLine={false}
                        style={{ fontSize: "10px" }}
                    />
                    <Tooltip />
                    <Legend height={20} wrapperStyle={{
                        margin: '0 0 10px 0'
                    }} />
                    <Line 
                        yAxisId="left"
                        type="monotone"
                        dataKey="profit"
                        stroke={"#fff" }
                    />
                    <Line 
                        yAxisId="right"
                        type="monotone"
                        dataKey="revenue"
                        stroke={"#fff" }
                    />
                    </LineChart>
                </ResponsiveContainer>
            </DashboardBox>
            <DashboardBox gridArea="c">
                <BoxHeader
                    title="Portfolio Value"
                    subtitle="graph representing the revenue month by month"
                    sideText="+16%"
                />
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={revenue}
                        margin={{
                            top: 17,
                            right: 15,
                            left: -5,
                            bottom: 58,
                        }}
                    >
                    <defs>
                        <linearGradient id="colorExpenses" x1="0" y1="0" x2="0" y2="1">
                            <stop 
                                offset="5%" 
                                stopColor={"#fff" } 
                                stopOpacity={0.5} 
                            />
                            <stop 
                                offset="95%"
                                stopColor={"#fff" } 
                                stopOpacity={0} 
                            />
                        </linearGradient>
                    </defs>
                    <CartesianGrid vertical={false} stroke={"#fff" } />
                    <XAxis 
                        dataKey="name" 
                        axisLine={false} 
                        tickLine={false} 
                        style={{ fontSize: "10px" }} 
                    />
                    <YAxis
                        axisLine={false} 
                        tickLine={false} 
                        style={{ fontSize: "10px" }}  
                    />
                    <Tooltip />
                    <Bar dataKey="revenue" fill="url(#colorRevenue)" />
                    </BarChart>
                </ResponsiveContainer>
            </DashboardBox>
        </>
    )
};

export default Row1;