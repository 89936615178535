import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import { useState } from 'react';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';

type Props = {};

const CoinbaseGuide = (props: Props) => {
    return (
    <div className='content-container'>
        <main className='content-content-full-width'>
            <header className='content-header section header'>
                <div className='exchanges-header'>
                    <h1 className='text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Útmutatók / Coinbase</h1>
                </div>
            </header>
            <section className='primary-content'>
                <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
                    <div className='flex items-center justify-between mb-ds-7'>
                        <div className='flex flex-col'>
                            <span className='text-ds-primary text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Adatok szinkronizálása a Coinbase-ről</span>
                            <span className='text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>A következő leírás abban segít, hogy a legtöbbet hozhasd ki a <strong>Kontri.io</strong>-ból</span>
                        </div>
                    </div>
                    <div className='flex items-center justify-between mb-ds-7'>
                        <div className='flex flex-col'>
                            <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '15px' }}>A <strong>Coinbase</strong> tárcák hozzáadásakor javasoljuk az API szinkronizálási lehetőség használatát.</span>
                        </div>
                    </div>
                    <div className='flex items-center justify-between mb-ds-7'>
                        <div className='flex flex-col'>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '10px', marginBottom: '40px' }}>Csatlakoztatás API-n keresztül</span>
                            <ol style={{ marginLeft: '35px', marginBottom: '25px' }}>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Menj a Kontri <strong>Tárcák</strong> oldalára, majd kattints a <strong>Tárca hozzáadása</strong> gombra.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Válaszd ki a <strong>Coinbase</strong> opciót.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Ez átirányít téged a Coinbase weboldalára, ahol jóváhagyhatod a Kontri hozzáférését a tranzakcióidhoz. Fontos, hogy a Kontri csak olvasási hozzáférést kér a fiókod tranzakcióihoz.</span></li>
                            </ol>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '10px', marginBottom: '40px' }}>1. Lépés</span>
                            <div style={{ maxWidth: '100%', height: 'auto' }}>
                                <img src='/assets/images/site_images/exchanges.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '30px', marginBottom: '40px' }}>2. Lépés</span>
                            <div style={{ maxWidth: '100%', height: 'auto' }}>
                                <img src='/assets/images/site_images/coinbase_exchange.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '30px', marginBottom: '40px' }}>3. Lépés</span>
                            <div style={{ maxWidth: '100%', height: 'auto' }}>
                                <img src='/assets/images/site_images/coinbase_select.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '30px', marginBottom: '40px' }}>4. Lépés</span>
                            <div style={{ maxWidth: '100%', height: 'auto' }}>
                                <img src='/assets/images/site_images/coinbase_option.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '30px', marginBottom: '0px' }}>Ha egyéb kérdésed merül fel, lépj velünk kapcsolatba!</span>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
    )
};

export default CoinbaseGuide;