import React from 'react';
import { useGetNewsQuery } from '../../api/news.api';
import { Avatar, Divider, Link, List, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from '@mui/material';

type Props = {};

const NewsFeed = (props: Props) => {
    const { data, isLoading } = useGetNewsQuery(undefined);
    const { palette } = useTheme();

    const replaceImage = (error: any) => {
        error.target.src = window.location.origin + '/default_news_image.jpeg';
    }
    
    return (
        <List sx={{ width: '100%', overflowY: 'scroll', overflowX: 'hidden', height: '100%' }}>
            {data && data.map((news: any) => (
                <><ListItem alignItems="flex-start">
                    <Link target="_blank" href={news.url} sx={{ textDecoration: 'none', display: 'flex' }}>
                    <ListItemAvatar sx={{ paddingRight: "1.5rem" }}>
                        <img width={100} height={70} src={news.image ? news.image : window.location.origin + '/default_news_image.jpeg'} onError={replaceImage} alt='img' style={{
                                objectFit: "cover",
                                borderRadius: "0.5rem"
                            }} />
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            color: palette.grey[100],
                        }}
                        primary={<React.Fragment>
                            <Typography>
                                {news.title}
                            </Typography>
                        </React.Fragment>}
                        secondary={<React.Fragment>
                            <Typography color={palette.grey[600]}>
                                {news.body.length <= 110 ? news.body : news.body.substring(0, 110) + "..."}
                            </Typography>
                        </React.Fragment>} />
                    </Link>
                </ListItem>
                <Divider variant="inset" component="li" /></>
            ))}
        </List>
    );
}

export default NewsFeed;