import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import { userApi } from '../api/user.api';
import { authApi } from '../api/auth.api';
import auth from "../slices/auth.slice";
import { coinbaseApi } from '../api/coinbase.api';
import { walletApi } from '../api/wallet.api';
import { newsApi } from '../api/news.api';
import { transactionApi } from '../api/transaction.api';
import { krakenApi } from '../api/kraken.api';
import { cryptocomApi } from '../api/cryptocom.api';
import { balanceApi } from '../api/balance.api';
import { portfolioApi } from '../api/portfolio.api';
import tokenMiddleware from '../slices/token-middleware';
import { taxApi } from '../api/tax.api';
import { userDetailsApi } from '../api/user-details.api';
import { paymentApi } from '../api/payment.api';
import { documentApi } from '../api/document.api';
import { supportApi } from '../api/support.api';
import { binanceApi } from '../api/binance.api';

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [coinbaseApi.reducerPath]: coinbaseApi.reducer,
    [walletApi.reducerPath]: walletApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
    [transactionApi.reducerPath]: transactionApi.reducer,
    [krakenApi.reducerPath]: krakenApi.reducer,
    [cryptocomApi.reducerPath]: cryptocomApi.reducer,
    [balanceApi.reducerPath]: balanceApi.reducer,
    [portfolioApi.reducerPath]: portfolioApi.reducer,
    [taxApi.reducerPath]: taxApi.reducer,
    [userDetailsApi.reducerPath]: userDetailsApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [binanceApi.reducerPath]: binanceApi.reducer,
    counter: counterReducer,
    auth
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(authApi.middleware)
      .concat(coinbaseApi.middleware)
      .concat(walletApi.middleware)
      .concat(newsApi.middleware)
      .concat(transactionApi.middleware)
      .concat(krakenApi.middleware)
      .concat(cryptocomApi.middleware)
      .concat(balanceApi.middleware)
      .concat(portfolioApi.middleware)
      .concat(userDetailsApi.middleware)
      .concat(paymentApi.middleware)
      .concat(documentApi.middleware)
      .concat(taxApi.middleware)
      .concat(supportApi.middleware)
      .concat(binanceApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
