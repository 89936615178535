import { Navigate, Routes as ReactRouterRoutes, Route } from 'react-router-dom';
import { ProtectedRoute } from './protected-route.component';
import LoginPage from '../pages/login';
import SignupPage from '../pages/signup';
import Dashboard from '../pages/dashboard';
import Predictions from '../pages/predictions';
import WalletSelector from '../pages/wallet-selector';
import Wallets from '../pages/wallets';
import Portfolio from '../pages/portfolio';
import Transactions from '../pages/transactions';
import WalletDetails from '../pages/wallet-details';
import NFTs from '../pages/nfts';
import AccountSettings from '../pages/account-settings';
import Taxation from '../pages/tax-report';
import AddTransaction from '../pages/add-transaction';
import Guides from '../pages/guides';
import FirstStepsGuide from '../pages/guides/first-steps';
import CoinbaseGuide from '../pages/guides/coinbase';
import KrakenGuide from '../pages/guides/kraken';
import CryptoComGuide from '../pages/guides/cryptocom';
import RevolutGuide from '../pages/guides/revolut';
import BinanceGuide from '../pages/guides/binance';
import BitfinexGuide from '../pages/guides/bitfinex';
import Feedback from '../pages/feedback';
import ProductPurchase from '../pages/product-purchase';
import PaymentInfo from '../pages/payment-info';
import ResetPasswordPage from '../pages/resetPassword';
import SetPasswordPage from '../pages/resetPassword/setPassword';
import ValidateAccountPage from '../pages/login/validateAccount';
import Support from '../pages/support';
import ActivateAccount from '../pages/activate-account';

const Routes: React.FC = () => {
    return (
        <ReactRouterRoutes>
            <Route
                path="/"
                element={<Navigate to="/login" />}
            />
            <Route 
                path="/p" 
                element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/predictions" 
                element={
                    <ProtectedRoute>
                        <Predictions />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/wallets" 
                element={
                    <ProtectedRoute>
                        <Wallets />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/wallets/:walletId"
                element={
                    <ProtectedRoute>
                        <WalletDetails />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/wallets/select" 
                element={
                    <ProtectedRoute>
                        <WalletSelector />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/portfolio" 
                element={
                    <ProtectedRoute>
                        <Portfolio />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/transactions" 
                element={
                    <ProtectedRoute>
                        <Transactions />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/nfts" 
                element={
                    <ProtectedRoute>
                        <NFTs />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/account/settings"
                element={
                    <ProtectedRoute>
                        <AccountSettings />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/account/subscription"
                element={
                    <ProtectedRoute>
                        <AccountSettings />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/account/documents"
                element={
                    <ProtectedRoute>
                        <AccountSettings />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/taxation"
                element={
                    <ProtectedRoute>
                        <Taxation />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/transaction/add"
                element={
                    <ProtectedRoute>
                        <AddTransaction />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/guides"
                element={
                    <ProtectedRoute>
                        <Guides />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/guides/first-steps"
                element={
                    <ProtectedRoute>
                        <FirstStepsGuide />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/guides/coinbase"
                element={
                    <ProtectedRoute>
                        <CoinbaseGuide />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/guides/kraken"
                element={
                    <ProtectedRoute>
                        <KrakenGuide />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/guides/crypto-com"
                element={
                    <ProtectedRoute>
                        <CryptoComGuide />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/guides/revolut"
                element={
                    <ProtectedRoute>
                        <RevolutGuide />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/guides/binance"
                element={
                    <ProtectedRoute>
                        <BinanceGuide />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/guides/bitfinex"
                element={
                    <ProtectedRoute>
                        <BitfinexGuide />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/feedback"
                element={
                    <ProtectedRoute>
                        <Feedback />
                    </ProtectedRoute>
                }
            />
               <Route 
                path="/p/support"
                element={
                    <ProtectedRoute>
                        <Support />
                    </ProtectedRoute>
                }
            />
            
            <Route 
                path="/p/product-purchase"
                element={
                    <ProtectedRoute>
                        <ProductPurchase />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/payment-info"
                element={
                    <ProtectedRoute>
                        <PaymentInfo />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/p/support"
                element={
                    <ProtectedRoute>
                        <Support />
                    </ProtectedRoute>
                }
            />
            <Route path="login" element={<LoginPage />} />
            <Route path="signup" element={<SignupPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route path="reset-password/:userId/:token" element={<SetPasswordPage />} />
            <Route path="validate-account/:userId/:token" element={<ValidateAccountPage />} />
            <Route path="activate-account" element={<ActivateAccount />} />

        </ReactRouterRoutes>
    );
};

export { Routes };