import { CryptoCurrencyInfo } from "./ICryptoCurrencyInfo";

export const cryptoCurrencyDictionary: { [key: string]: CryptoCurrencyInfo } = {

    "RPL": {"cryptoCurrency": "Rocket Pool", "cryptoCurrencyCode": "RPL", "image": "/assets/images/coins/rocket_pool.png"},
    "BTC": {"cryptoCurrency": "Bitcoin", "cryptoCurrencyCode": "BTC", "image": "/assets/images/coins/bitcoin.png"},
    "ETH": {"cryptoCurrency": "Ethereum", "cryptoCurrencyCode": "ETH", "image": "/assets/images/coins/ethereum.png"},
    "LTC": {"cryptoCurrency": "Litecoin", "cryptoCurrencyCode": "LTC", "image": "/assets/images/coins/litecoin.png"},
    "BCH": {"cryptoCurrency": "Bitcoin Cash", "cryptoCurrencyCode": "BCH", "image": "/assets/images/coins/bitcoin-cash.png"},
    "ETC": {"cryptoCurrency": "Ethereum Classic", "cryptoCurrencyCode": "ETC", "image": "/assets/images/coins/ethereum-classic.png"},
    "ZRX": {"cryptoCurrency": "0x Protocol", "cryptoCurrencyCode": "ZRX", "image": "/assets/images/coins/0x.png"},
    "USDC": {"cryptoCurrency": "USDC", "cryptoCurrencyCode": "USDC", "image": "/assets/images/coins/usdc.png"},
    "BAT": {"cryptoCurrency": "Basic Attention Token", "cryptoCurrencyCode": "BAT", "image": "/assets/images/coins/basic-attention-token.png"},
    "ZEC": {"cryptoCurrency": "Zcash", "cryptoCurrencyCode": "ZEC", "image": "/assets/images/coins/zcash.png"},
    "XRP": {"cryptoCurrency": "XRP", "cryptoCurrencyCode": "XRP", "image": "/assets/images/coins/xrp.png"},
    "XLM": {"cryptoCurrency": "Stellar Lumens", "cryptoCurrencyCode": "XLM", "image": "/assets/images/coins/stellar.png"},
    "REP": {"cryptoCurrency": "Augur", "cryptoCurrencyCode": "REP", "image": "/assets/images/coins/augur.png"},
    "DAI": {"cryptoCurrency": "Dai", "cryptoCurrencyCode": "DAI", "image": "/assets/images/coins/dai.png"},
    "EOS": {"cryptoCurrency": "EOS", "cryptoCurrencyCode": "EOS", "image": "/assets/images/coins/eos.png"},
    "LINK": {"cryptoCurrency": "Chainlink", "cryptoCurrencyCode": "LINK", "image": "/assets/images/coins/chainlink.png"},
    "XTZ": {"cryptoCurrency": "Tezos", "cryptoCurrencyCode": "XTZ", "image": "/assets/images/coins/tezos.png"},
    "DASH": {"cryptoCurrency": "Dash", "cryptoCurrencyCode": "DASH", "image": "/assets/images/coins/dash.png"},
    "OXT": {"cryptoCurrency": "Orchid", "cryptoCurrencyCode": "OXT", "image": "/assets/images/coins/orchid.png"},
    "ATOM": {"cryptoCurrency": "Cosmos", "cryptoCurrencyCode": "ATOM", "image": "/assets/images/coins/cosmos.png"},
    "KNC": {"cryptoCurrency": "Kyber Network", "cryptoCurrencyCode": "KNC", "image": "/assets/images/coins/kyber-network.png"},
    "MKR": {"cryptoCurrency": "Maker", "cryptoCurrencyCode": "MKR", "image": "/assets/images/coins/maker.png"},
    "COMP": {"cryptoCurrency": "Compound", "cryptoCurrencyCode": "COMP", "image": "/assets/images/coins/COMP.png"},
    "ALGO": {"cryptoCurrency": "Algorand", "cryptoCurrencyCode": "ALGO", "image": "/assets/images/coins/algo.png"},
    "BAND": {"cryptoCurrency": "Band Protocol", "cryptoCurrencyCode": "BAND", "image": "/assets/images/coins/band-protocol.png"},
    "NMR": {"cryptoCurrency": "Numeraire", "cryptoCurrencyCode": "NMR", "image": "/assets/images/coins/numeraire.png"},
    "CGLD": {"cryptoCurrency": "Celo", "cryptoCurrencyCode": "CGLD", "image": "/assets/images/coins/celo.png"},
    "UMA": {"cryptoCurrency": "UMA", "cryptoCurrencyCode": "UMA", "image": "/assets/images/coins/UMA.png"},
    "LRC": {"cryptoCurrency": "Loopring", "cryptoCurrencyCode": "LRC", "image": "/assets/images/coins/loopring.png"},
    "YFI": {"cryptoCurrency": "yearn.finance", "cryptoCurrencyCode": "YFI", "image": "/assets/images/coins/yearn-finance.png"},
    "UNI": {"cryptoCurrency": "Uniswap", "cryptoCurrencyCode": "UNI", "image": "/assets/images/coins/uni.png"},
    "BAL": {"cryptoCurrency": "Balancer", "cryptoCurrencyCode": "BAL", "image": "/assets/images/coins/balancer.png"},
    "WBTC": {"cryptoCurrency": "Wrapped Bitcoin", "cryptoCurrencyCode": "WBTC", "image": "/assets/images/coins/wbtc.png"},
    "CVC": {"cryptoCurrency": "Civic", "cryptoCurrencyCode": "CVC", "image": "/assets/images/coins/civic.png"},
    "MANA": {"cryptoCurrency": "Decentraland", "cryptoCurrencyCode": "MANA", "image": "/assets/images/coins/decentraland-mana.png"},
    "DNT": {"cryptoCurrency": "district0x", "cryptoCurrencyCode": "DNT", "image": "/assets/images/coins/district0x.png"},
    "FIL": {"cryptoCurrency": "Filecoin", "cryptoCurrencyCode": "FIL", "image": "/assets/images/coins/filecoin.png"},
    "AAVE": {"cryptoCurrency": "Aave", "cryptoCurrencyCode": "AAVE", "image": "/assets/images/coins/AAVE.png"},
    "BNT": {"cryptoCurrency": "Bancor Network Token", "cryptoCurrencyCode": "BNT", "image": "/assets/images/coins/bancor-bnt.png"},
    "SNX": {"cryptoCurrency": "Synthetix Network Token", "cryptoCurrencyCode": "SNX", "image": "/assets/images/coins/SNX.png"},
    "GRT": {"cryptoCurrency": "The Graph", "cryptoCurrencyCode": "GRT", "image": "/assets/images/coins/graph-token.png"},
    "SUSHI": {"cryptoCurrency": "SushiSwap", "cryptoCurrencyCode": "SUSHI", "image": "/assets/images/coins/sushi.png"},
    "MATIC": {"cryptoCurrency": "Polygon", "cryptoCurrencyCode": "MATIC", "image": "/assets/images/coins/matic.png"},
    "SKL": {"cryptoCurrency": "SKALE", "cryptoCurrencyCode": "SKL", "image": "/assets/images/coins/skale.png"},
    "ADA": {"cryptoCurrency": "Cardano", "cryptoCurrencyCode": "ADA", "image": "/assets/images/coins/cardano.png"},
    "STORJ": {"cryptoCurrency": "Storj", "cryptoCurrencyCode": "STORJ", "image": "/assets/images/coins/storj.png"},
    "CRV": {"cryptoCurrency": "Curve DAO Token", "cryptoCurrencyCode": "CRV", "image": "/assets/images/coins/curve.png"},
    "ANKR": {"cryptoCurrency": "Ankr", "cryptoCurrencyCode": "ANKR", "image": "/assets/images/coins/ankr.png"},
    "NKN": {"cryptoCurrency": "NKN", "cryptoCurrencyCode": "NKN", "image": "/assets/images/coins/nkn.png"},
    "OGN": {"cryptoCurrency": "Origin Token", "cryptoCurrencyCode": "OGN", "image": "/assets/images/coins/ogn.png"},
    "1INCH": {"cryptoCurrency": "1inch", "cryptoCurrencyCode": "1INCH", "image": "/assets/images/coins/1inch-token.png"},
    "ENJ": {"cryptoCurrency": "Enjin Coin", "cryptoCurrencyCode": "ENJ", "image": "/assets/images/coins/ENJ.png"},
    "FORTH": {"cryptoCurrency": "Ampleforth Governance Token", "cryptoCurrencyCode": "FORTH", "image": "/assets/images/coins/forth.png"},
    "USDT": {"cryptoCurrency": "Tether", "cryptoCurrencyCode": "USDT", "image": "/assets/images/coins/tether.png"},
    "CTSI": {"cryptoCurrency": "Cartesi", "cryptoCurrencyCode": "CTSI", "image": "/assets/images/coins/cartesi.png"},
    "TRB": {"cryptoCurrency": "Tellor", "cryptoCurrencyCode": "TRB", "image": "/assets/images/coins/tellor.png"},
    "RLC": {"cryptoCurrency": "iExec RLC", "cryptoCurrencyCode": "RLC", "image": "/assets/images/coins/RLC.png"},
    "ICP": {"cryptoCurrency": "Internet Computer", "cryptoCurrencyCode": "ICP", "image": "/assets/images/coins/ICP.png"},
    "DOGE": {"cryptoCurrency": "Dogecoin", "cryptoCurrencyCode": "DOGE", "image": "/assets/images/coins/dogecoin.png"},
    "MLN": {"cryptoCurrency": "Enzyme", "cryptoCurrencyCode": "MLN", "image": "/assets/images/coins/melon.png"},
    "GTC": {"cryptoCurrency": "Gitcoin", "cryptoCurrencyCode": "GTC", "image": "/assets/images/coins/gitcoin.png"},
    "AMP": {"cryptoCurrency": "Amp", "cryptoCurrencyCode": "AMP", "image": "/assets/images/coins/amp.png"},
    "DOT": {"cryptoCurrency": "Polkadot", "cryptoCurrencyCode": "DOT", "image": "/assets/images/coins/polkadot.png"},
    "SOL": {"cryptoCurrency": "Solana", "cryptoCurrencyCode": "SOL", "image": "/assets/images/coins/solana.png"},
    "CHZ": {"cryptoCurrency": "Chiliz", "cryptoCurrencyCode": "CHZ", "image": "/assets/images/coins/CHZ.png"},
    "LPT": {"cryptoCurrency": "Livepeer", "cryptoCurrencyCode": "LPT", "image": "/assets/images/coins/LPT.png"},
    "QNT": {"cryptoCurrency": "Quant", "cryptoCurrencyCode": "QNT", "image": "/assets/images/coins/QNT.png"},
    "MASK": {"cryptoCurrency": "Mask Network", "cryptoCurrencyCode": "MASK", "image": "/assets/images/coins/mask.png"},
    "CLV": {"cryptoCurrency": "Clover Finance", "cryptoCurrencyCode": "CLV", "image": "/assets/images/coins/clover-finance.png"},
    "PAX": {"cryptoCurrency": "Paxos Standard", "cryptoCurrencyCode": "PAX", "image": "/assets/images/coins/pax-stan.png"},
    "FET": {"cryptoCurrency": "Fetch.ai", "cryptoCurrencyCode": "FET", "image": "/assets/images/coins/fetch-ai.png"},
    "FARM": {"cryptoCurrency": "Harvest Finance", "cryptoCurrencyCode": "FARM", "image": "/assets/images/coins/harvest-finance.png"},
    "RAI": {"cryptoCurrency": "Rai Reflex Index", "cryptoCurrencyCode": "RAI", "image": "/assets/images/coins/RAI.png"},
    "ACH": {"cryptoCurrency": "Alchemy Pay", "cryptoCurrencyCode": "ACH", "image": "/assets/images/coins/alchemy-pay.png"},
    "IOTX": {"cryptoCurrency": "IoTeX", "cryptoCurrencyCode": "IOTX", "image": "/assets/images/coins/iotex.png"},
    "ORN": {"cryptoCurrency": "Orion Protocol", "cryptoCurrencyCode": "ORN", "image": "/assets/images/coins/orn-prot.png"},
    "REQ": {"cryptoCurrency": "Request", "cryptoCurrencyCode": "REQ", "image": "/assets/images/coins/request-net.png"},
    "AXS": {"cryptoCurrency": "Axie Infinity", "cryptoCurrencyCode": "AXS", "image": "/assets/images/coins/axie-infinity.png"},
    "TRU": {"cryptoCurrency": "TrueFi", "cryptoCurrencyCode": "TRU", "image": "/assets/images/coins/truefi.png"},
    "COTI": {"cryptoCurrency": "COTI", "cryptoCurrencyCode": "COTI", "image": "/assets/images/coins/coti.png"},
    "RAD": {"cryptoCurrency": "Radworks", "cryptoCurrencyCode": "RAD", "image": "/assets/images/coins/radicle.png"},
    "XYO": {"cryptoCurrency": "XYO", "cryptoCurrencyCode": "XYO", "image": "/assets/images/coins/XYO-net.png"},
    "BTRST": {"cryptoCurrency": "Braintrust", "cryptoCurrencyCode": "BTRST", "image": "/assets/images/coins/braintrust.png"},
    "ZEN": {"cryptoCurrency": "Horizen", "cryptoCurrencyCode": "ZEN", "image": "/assets/images/coins/horizen.png"},
    "SHIB": {"cryptoCurrency": "SHIBA INU", "cryptoCurrencyCode": "SHIB", "image": "/assets/images/coins/shiba.png"},
    "AGLD": {"cryptoCurrency": "Adventure Gold", "cryptoCurrencyCode": "AGLD", "image": "/assets/images/coins/adventure-gold.png"},
    "AVAX": {"cryptoCurrency": "Avalanche", "cryptoCurrencyCode": "AVAX", "image": "/assets/images/coins/avalanche.png"},
    "FX": {"cryptoCurrency": "Function X", "cryptoCurrencyCode": "FX", "image": "/assets/images/coins/fx-coin.png"},
    "WCFG": {"cryptoCurrency": "Wrapped Centrifuge", "cryptoCurrencyCode": "WCFG", "image": "/assets/images/coins/WCFG.png"},
    "RARI": {"cryptoCurrency": "Rarible", "cryptoCurrencyCode": "RARI", "image": "/assets/images/coins/rari.png"},
    "BADGER": {"cryptoCurrency": "Badger DAO", "cryptoCurrencyCode": "BADGER", "image": "/assets/images/coins/badger-dao.png"},
    "JASMY": {"cryptoCurrency": "Jasmy", "cryptoCurrencyCode": "JASMY", "image": "/assets/images/coins/JASMY.png"},
    "PERP": {"cryptoCurrency": "Perpetual Protocol", "cryptoCurrencyCode": "PERP", "image": "/assets/images/coins/PERP.png"},
    "ARPA": {"cryptoCurrency": "ARPA Chain", "cryptoCurrencyCode": "ARPA", "image": "/assets/images/coins/arpa.png"},
    "AUCTION": {"cryptoCurrency": "Bounce Token", "cryptoCurrencyCode": "AUCTION", "image": "/assets/images/coins/auction.png"},
    "ASM": {"cryptoCurrency": "Assemble Protocol", "cryptoCurrencyCode": "ASM", "image": "/assets/images/coins/assemble-prot.png"},
    "KRL": {"cryptoCurrency": "Kryll", "cryptoCurrencyCode": "KRL", "image": "/assets/images/coins/krl.png"},
    "LCX": {"cryptoCurrency": "LCX", "cryptoCurrencyCode": "LCX", "image": "/assets/images/coins/LCX.png"},
    "TRAC": {"cryptoCurrency": "OriginTrail", "cryptoCurrencyCode": "TRAC", "image": "/assets/images/coins/TRAC.png"},
    "CRO": {"cryptoCurrency": "Crypto.com Coin", "cryptoCurrencyCode": "CRO", "image": "/assets/images/coins/cro_token.png"},
    "SUKU": {"cryptoCurrency": "SUKU", "cryptoCurrencyCode": "SUKU", "image": "/assets/images/coins/suku.png"},
    "ENS": {"cryptoCurrency": "Ethereum Name Service", "cryptoCurrencyCode": "ENS", "image": "/assets/images/coins/ENS.png"},
    "GYEN": {"cryptoCurrency": "GYEN", "cryptoCurrencyCode": "GYEN", "image": "/assets/images/coins/gyen.png"},
    "ALCX": {"cryptoCurrency": "Alchemix", "cryptoCurrencyCode": "ALCX", "image": "/assets/images/coins/Alchemix.png"},
    "POWR": {"cryptoCurrency": "Powerledger", "cryptoCurrencyCode": "POWR", "image": "/assets/images/coins/powerledger.png"},
    "BICO": {"cryptoCurrency": "BICONOMY (BICO)", "cryptoCurrencyCode": "BICO", "image": "/assets/images/coins/biconomy.png"},
    "IDEX": {"cryptoCurrency": "IDEX", "cryptoCurrencyCode": "IDEX", "image": "/assets/images/coins/idex.png"},
    "POLS": {"cryptoCurrency": "Polkastarter", "cryptoCurrencyCode": "POLS", "image": "/assets/images/coins/polkastarter.png"},
    "FOX": {"cryptoCurrency": "Shapeshift FOX Token", "cryptoCurrencyCode": "FOX", "image": "/assets/images/coins/FOX.png"},
    "SUPER": {"cryptoCurrency": "SuperVerse", "cryptoCurrencyCode": "SUPER", "image": "/assets/images/coins/SV.png"},
    "SPELL": {"cryptoCurrency": "Spell Token", "cryptoCurrencyCode": "SPELL", "image": "/assets/images/coins/SPELL.png"},
    "BLZ": {"cryptoCurrency": "Bluzelle", "cryptoCurrencyCode": "BLZ", "image": "/assets/images/coins/BLZ.png"},
    "MDT": {"cryptoCurrency": "Measurable Data Token", "cryptoCurrencyCode": "MDT", "image": "/assets/images/coins/mdt.png"},
    "COVAL": {"cryptoCurrency": "Circuits of Value", "cryptoCurrencyCode": "COVAL", "image": "/assets/images/coins/coval.png"},
    "API3": {"cryptoCurrency": "API3", "cryptoCurrencyCode": "API3", "image": "/assets/images/coins/api3.png"},
    "RBN": {"cryptoCurrency": "Ribbon Finance", "cryptoCurrencyCode": "RBN", "image": "/assets/images/coins/ribbon.png"},
    "GODS": {"cryptoCurrency": "Gods Unchained", "cryptoCurrencyCode": "GODS", "image": "/assets/images/coins/gods-un.png"},
    "IMX": {"cryptoCurrency": "Immutable", "cryptoCurrencyCode": "IMX", "image": "/assets/images/coins/immutableX.png"},
    "DESO": {"cryptoCurrency": "Decentralized Social", "cryptoCurrencyCode": "DESO", "image": "/assets/images/coins/deso.png"},
    "GFI": {"cryptoCurrency": "Goldfinch Protocol", "cryptoCurrencyCode": "GFI", "image": "/assets/images/coins/GOLDFINCH.png"},
    "LQTY": {"cryptoCurrency": "Liquity", "cryptoCurrencyCode": "LQTY", "image": "/assets/images/coins/lqty.png"},
    "PRO": {"cryptoCurrency": "Propy", "cryptoCurrencyCode": "PRO", "image": "/assets/images/coins/propy.png"},
    "INV": {"cryptoCurrency": "Inverse Finance", "cryptoCurrencyCode": "INV", "image": "/assets/images/coins/inverse_finance.png"},
    "NCT": {"cryptoCurrency": "PolySwarm", "cryptoCurrencyCode": "NCT", "image": "/assets/images/coins/NCT.png"},
    "STX": {"cryptoCurrency": "Stacks", "cryptoCurrencyCode": "STX", "image": "/assets/images/coins/Stacks.png"},
    "SHPING": {"cryptoCurrency": "SHPING", "cryptoCurrencyCode": "SHPING", "image": "/assets/images/coins/shping.png"},
    "CTX": {"cryptoCurrency": "Cryptex Finance", "cryptoCurrencyCode": "CTX", "image": "/assets/images/coins/CTX.png"},
    "PLU": {"cryptoCurrency": "Pluton", "cryptoCurrencyCode": "PLU", "image": "/assets/images/coins/pluton.png"},
    "MPL": {"cryptoCurrency": "Maple", "cryptoCurrencyCode": "MPL", "image": "/assets/images/coins/maple.png"},
    "DIA": {"cryptoCurrency": "DIA", "cryptoCurrencyCode": "DIA", "image": "/assets/images/coins/DIA.png"},
    "UNFI": {"cryptoCurrency": "Unifi Protocol DAO", "cryptoCurrencyCode": "UNFI", "image": "/assets/images/coins/unfi.png"},
    "ORCA": {"cryptoCurrency": "Orca", "cryptoCurrencyCode": "ORCA", "image": "/assets/images/coins/orca.png"},
    "FIDA": {"cryptoCurrency": "Bonfida", "cryptoCurrencyCode": "FIDA", "image": "/assets/images/coins/bonfida.png"},
    "RNDR": {"cryptoCurrency": "Render (ERC-20)", "cryptoCurrencyCode": "RNDR", "image": "/assets/images/coins/rndr.png"},
    "AVT": {"cryptoCurrency": "Aventus", "cryptoCurrencyCode": "AVT", "image": "/assets/images/coins/aventus.png"},
    "ALICE": {"cryptoCurrency": "MyNeighborAlice", "cryptoCurrencyCode": "ALICE", "image": "/assets/images/coins/alice.png"},
    "SNT": {"cryptoCurrency": "Status", "cryptoCurrencyCode": "SNT", "image": "/assets/images/coins/status.png"},
    "AERGO": {"cryptoCurrency": "Aergo", "cryptoCurrencyCode": "AERGO", "image": "/assets/images/coins/aergo.png"},
    "ERN": {"cryptoCurrency": "Ethernity Chain", "cryptoCurrencyCode": "ERN", "image": "/assets/images/coins/ern.png"},
    "AIOZ": {"cryptoCurrency": "AIOZ Network", "cryptoCurrencyCode": "AIOZ", "image": "/assets/images/coins/aioz-net.png"},
    "HIGH": {"cryptoCurrency": "Highstreet", "cryptoCurrencyCode": "HIGH", "image": "/assets/images/coins/hihgstreet.png"},
    "SYN": {"cryptoCurrency": "Synapse", "cryptoCurrencyCode": "SYN", "image": "/assets/images/coins/synapse.png"},
    "GLM": {"cryptoCurrency": "Golem", "cryptoCurrencyCode": "GLM", "image": "/assets/images/coins/golem.png"},
    "APE": {"cryptoCurrency": "ApeCoin", "cryptoCurrencyCode": "APE", "image": "/assets/images/coins/apecoin.png"},
    "MINA": {"cryptoCurrency": "Mina", "cryptoCurrencyCode": "MINA", "image": "/assets/images/coins/mina-prot.png"},
    "ROSE": {"cryptoCurrency": "Oasis Network", "cryptoCurrencyCode": "ROSE", "image": "/assets/images/coins/oasis-net.png"},
    "GMT": {"cryptoCurrency": "STEPN", "cryptoCurrencyCode": "GMT", "image": "/assets/images/coins/token-gmt.png"},
    "GST": {"cryptoCurrency": "Green Satoshi Token", "cryptoCurrencyCode": "GST", "image": "/assets/images/coins/gst.png"},
    "GAL": {"cryptoCurrency": "Galxe", "cryptoCurrencyCode": "GAL", "image": "/assets/images/coins/galaxy.png"},
    "FLOW": {"cryptoCurrency": "Flow", "cryptoCurrencyCode": "FLOW", "image": "/assets/images/coins/flow.png"},
    "SAND": {"cryptoCurrency": "The Sandbox", "cryptoCurrencyCode": "SAND", "image": "/assets/images/coins/sandbox_sand.png"},
    "OP": {"cryptoCurrency": "Optimism", "cryptoCurrencyCode": "OP", "image": "/assets/images/coins/optimism.png"},
    "KSM": {"cryptoCurrency": "Kusama", "cryptoCurrencyCode": "KSM", "image": "/assets/images/coins/kusama.png"},
    "BIT": {"cryptoCurrency": "BitDAO", "cryptoCurrencyCode": "BIT", "image": "/assets/images/coins/BIT.png"},
    "C98": {"cryptoCurrency": "Coin98", "cryptoCurrencyCode": "C98", "image": "/assets/images/coins/c98.png"},
    "WAMPL": {"cryptoCurrency": "Wrapped Ampleforth", "cryptoCurrencyCode": "WAMPL", "image": "/assets/images/coins/WAMPL.png"},
    "INDEX": {"cryptoCurrency": "Index Cooperative", "cryptoCurrencyCode": "INDEX", "image": "/assets/images/coins/index.png"},
    "FORT": {"cryptoCurrency": "Forta", "cryptoCurrencyCode": "FORT", "image": "/assets/images/coins/Forta.png"},
    "POND": {"cryptoCurrency": "Marlin", "cryptoCurrencyCode": "POND", "image": "/assets/images/coins/POND.png"},
    "FIS": {"cryptoCurrency": "StaFi", "cryptoCurrencyCode": "FIS", "image": "/assets/images/coins/FIS.png"},
    "DAR": {"cryptoCurrency": "Mines of Dalarnia", "cryptoCurrencyCode": "DAR", "image": "/assets/images/coins/dar.png"},
    "DYP": {"cryptoCurrency": "Dypius", "cryptoCurrencyCode": "DYP", "image": "/assets/images/coins/dypius.png"},
    "ALEPH": {"cryptoCurrency": "Aleph.im", "cryptoCurrencyCode": "ALEPH", "image": "/assets/images/coins/Aleph.png"},
    "PRQ": {"cryptoCurrency": "PARSIQ", "cryptoCurrencyCode": "PRQ", "image": "/assets/images/coins/PRQ.png"},
    "MATH": {"cryptoCurrency": "MATH", "cryptoCurrencyCode": "MATH", "image": "/assets/images/coins/MATH.png"},
    "HOPR": {"cryptoCurrency": "HOPR", "cryptoCurrencyCode": "HOPR", "image": "/assets/images/coins/HOPR.png"},
    "ELA": {"cryptoCurrency": "Elastos", "cryptoCurrencyCode": "ELA", "image": "/assets/images/coins/Elastos.png"},
    "MUSE": {"cryptoCurrency": "Muse", "cryptoCurrencyCode": "MUSE", "image": "/assets/images/coins/MUSE.png"},
    "TIME": {"cryptoCurrency": "Chrono.tech", "cryptoCurrencyCode": "TIME", "image": "/assets/images/coins/TIME.png"},
    "DEXT": {"cryptoCurrency": "DEXTools", "cryptoCurrencyCode": "DEXT", "image": "/assets/images/coins/dext.png"},
    "MEDIA": {"cryptoCurrency": "Media Network", "cryptoCurrencyCode": "MEDIA", "image": "/assets/images/coins/MEDIA.png"},
    "METIS": {"cryptoCurrency": "Metis", "cryptoCurrencyCode": "METIS", "image": "/assets/images/coins/metis-token.png"},
    "XCN": {"cryptoCurrency": "Onyxcoin", "cryptoCurrencyCode": "XCN", "image": "/assets/images/coins/onyxlogo.png"},
    "BOBA": {"cryptoCurrency": "Boba Network", "cryptoCurrencyCode": "BOBA", "image": "/assets/images/coins/BOBA.png"},
    "AST": {"cryptoCurrency": "AirSwap", "cryptoCurrencyCode": "AST", "image": "/assets/images/coins/Airswap.png"},
    "SWFTC": {"cryptoCurrency": "SwftCoin", "cryptoCurrencyCode": "SWFTC", "image": "/assets/images/coins/SWFTCoin.png"},
    "GNO": {"cryptoCurrency": "Gnosis", "cryptoCurrencyCode": "GNO", "image": "/assets/images/coins/gnosis.png"},
    "ABT": {"cryptoCurrency": "Arcblock", "cryptoCurrencyCode": "ABT", "image": "/assets/images/coins/arcblock.png"},
    "MTL": {"cryptoCurrency": "Metal DAO", "cryptoCurrencyCode": "MTL", "image": "/assets/images/coins/Metal.png"},
    "RARE": {"cryptoCurrency": "SuperRare", "cryptoCurrencyCode": "RARE", "image": "/assets/images/coins/RARE.png"},
    "LOKA": {"cryptoCurrency": "League of Kingdoms Arena", "cryptoCurrencyCode": "LOKA", "image": "/assets/images/coins/loka.png"},
    "GUSD": {"cryptoCurrency": "Gemini Dollar", "cryptoCurrencyCode": "GUSD", "image": "/assets/images/coins/gemini-dollar-gusd.png"},
    "CELR": {"cryptoCurrency": "Celer Network", "cryptoCurrencyCode": "CELR", "image": "/assets/images/coins/Celr.png"},
    "CBETH": {"cryptoCurrency": "Coinbase Wrapped Staked ETH", "cryptoCurrencyCode": "CBETH", "image": "/assets/images/coins/cbeth.png"},
    "NEAR": {"cryptoCurrency": "NEAR Protocol", "cryptoCurrencyCode": "NEAR", "image": "/assets/images/coins/near.png"},
    "AURORA": {"cryptoCurrency": "Aurora", "cryptoCurrencyCode": "AURORA", "image": "/assets/images/coins/aurora_token.png"},
    "CVX": {"cryptoCurrency": "Convex Finance", "cryptoCurrencyCode": "CVX", "image": "/assets/images/coins/convex.png"},
    "OCEAN": {"cryptoCurrency": "Ocean Protocol", "cryptoCurrencyCode": "OCEAN", "image": "/assets/images/coins/ocean-protocol.png"},
    "PUNDIX": {"cryptoCurrency": "Pundi X (New)", "cryptoCurrencyCode": "PUNDIX", "image": "/assets/images/coins/PUNDIX.png"},
    "INJ": {"cryptoCurrency": "Injective", "cryptoCurrencyCode": "INJ", "image": "/assets/images/coins/Injectie.png"},
    "PNG": {"cryptoCurrency": "Pangolin", "cryptoCurrencyCode": "PNG", "image": "/assets/images/coins/PNG_token.png"},
    "00": {"cryptoCurrency": "00 Token", "cryptoCurrencyCode": "00", "image": "/assets/images/coins/zer0zer0.png"},
    "ILV": {"cryptoCurrency": "Illuvium", "cryptoCurrencyCode": "ILV", "image": "/assets/images/coins/ILV.png"},
    "HBAR": {"cryptoCurrency": "Hedera", "cryptoCurrencyCode": "HBAR", "image": "/assets/images/coins/HBAR.png"},
    "APT": {"cryptoCurrency": "Aptos", "cryptoCurrencyCode": "APT", "image": "/assets/images/coins/APTOS.png"},
    "WAXL": {"cryptoCurrency": "Wrapped Axelar", "cryptoCurrencyCode": "WAXL", "image": "/assets/images/coins/AxelarW.png"},
    "MSOL": {"cryptoCurrency": "Marinade Staked SOL", "cryptoCurrencyCode": "MSOL", "image": "/assets/images/coins/mSOL.png"},
    "MNDE": {"cryptoCurrency": "Marinade", "cryptoCurrencyCode": "MNDE", "image": "/assets/images/coins/MNDE.png"},
    "HFT": {"cryptoCurrency": "Hashflow", "cryptoCurrencyCode": "HFT", "image": "/assets/images/coins/Hashflow.png"},
    "LDO": {"cryptoCurrency": "Lido DAO Token", "cryptoCurrencyCode": "LDO", "image": "/assets/images/coins/Lido_DAO.png"},
    "QI": {"cryptoCurrency": "BENQI", "cryptoCurrencyCode": "QI", "image": "/assets/images/coins/QI.png"},
    "PYR": {"cryptoCurrency": "Vulcan Forged PYR", "cryptoCurrencyCode": "PYR", "image": "/assets/images/coins/vulcan-forged.png"},
    "GHST": {"cryptoCurrency": "Aavegotchi", "cryptoCurrencyCode": "GHST", "image": "/assets/images/coins/GHST.png"},
    "LIT": {"cryptoCurrency": "Litentry", "cryptoCurrencyCode": "LIT", "image": "/assets/images/coins/LIT.png"},
    "SFI": {"cryptoCurrency": "Safron", "cryptoCurrencyCode": "SFI", "image": "/assets/images/coins/sfi.png"},
    "ERG": {"cryptoCurrency": "Ergo", "cryptoCurrencyCode": "ERG", "image": "/assets/images/coins/Ergo.png"},
    "HOLY": {"cryptoCurrency": "Holyheld", "cryptoCurrencyCode": "HOLY", "image": "/assets/images/coins/dog.png"},
    "BAS": {"cryptoCurrency": "Basis Gold Share", "cryptoCurrencyCode": "BAS", "image": "/assets/images/coins/BAS.png"},
    "BTMX": {"cryptoCurrency": "BitMax Token", "cryptoCurrencyCode": "BTMX", "image": "/assets/images/coins/dog.png"},
    "FLM": {"cryptoCurrency": "Flamingo", "cryptoCurrencyCode": "FLM", "image": "/assets/images/coins/flamingo_finance.png"},
    "SAFEMOON": {"cryptoCurrency": "SafeMoon", "cryptoCurrencyCode": "SAFEMOON", "image": "/assets/images/coins/dog.png"},
    "STETH": {"cryptoCurrency": "Staked Ether", "cryptoCurrencyCode": "STETH", "image": "/assets/images/coins/steth.png"},
    "SNM": {"cryptoCurrency": "SONM", "cryptoCurrencyCode": "SNM", "image": "/assets/images/coins/sonm.png"},
    "VISR": {"cryptoCurrency": "Visor", "cryptoCurrencyCode": "VISR", "image": "/assets/images/coins/dog.png"},
    "WOO": {"cryptoCurrency": "Wootrade Network", "cryptoCurrencyCode": "WOO", "image": "/assets/images/coins/WOO.png"},
    "CREAM": {"cryptoCurrency": "Cream Finance", "cryptoCurrencyCode": "CREAM", "image": "/assets/images/coins/Cream.png"},
    "IOTEX": {"cryptoCurrency": "IOTEX", "cryptoCurrencyCode": "IOTEX", "image": "/assets/images/coins/iotex.png"},
    "LGCY": {"cryptoCurrency": "LGCY Network", "cryptoCurrencyCode": "LGCY", "image": "/assets/images/coins/LGCY_network.png"},
    "BOND": {"cryptoCurrency": "BarnBridge", "cryptoCurrencyCode": "BOND", "image": "/assets/images/coins/barnbridge.png"},
    "HEGIC": {"cryptoCurrency": "Hegic", "cryptoCurrencyCode": "HEGIC", "image": "/assets/images/coins/HEGIC.png"},
    "PRT": {"cryptoCurrency": "PolkaFoundry", "cryptoCurrencyCode": "PRT", "image": "/assets/images/coins/dog.png"},
    "TIDAL": {"cryptoCurrency": "Tidal Finance", "cryptoCurrencyCode": "TIDAL", "image": "/assets/images/coins/Tidal.png"},
    "RAZOR": {"cryptoCurrency": "Razor Network", "cryptoCurrencyCode": "RAZOR", "image": "/assets/images/coins/RAZOR.png"},
    "RBNB": {"cryptoCurrency": "Rubic", "cryptoCurrencyCode": "RBNB", "image": "/assets/images/coins/dog.png"},
    "DHV": {"cryptoCurrency": "Data Highway", "cryptoCurrencyCode": "DHV", "image": "/assets/images/coins/dog.png"},
    "DDIM": {"cryptoCurrency": "DuckDaoDime", "cryptoCurrencyCode": "DDIM", "image": "/assets/images/coins/token_DDIM.png"},
    "DVG": {"cryptoCurrency": "Dvision Network", "cryptoCurrencyCode": "DVG", "image": "/assets/images/coins/DVG.png"},
    "XRT": {"cryptoCurrency": "Robonomics", "cryptoCurrencyCode": "XRT", "image": "/assets/images/coins/Robonomics-Network.png"},
    "ZRX2": {"cryptoCurrency": "ZRX", "cryptoCurrencyCode": "ZRX2", "image": "/assets/images/coins/0x.png"},
    "XCP": {"cryptoCurrency": "Counterparty", "cryptoCurrencyCode": "XCP", "image": "/assets/images/coins/counterparty.png"},
    "WAN": {"cryptoCurrency": "Wanchain", "cryptoCurrencyCode": "WAN", "image": "/assets/images/coins/WAN.png"},
    "NOIA": {"cryptoCurrency": "Syntropy", "cryptoCurrencyCode": "NOIA", "image": "/assets/images/coins/NOIA.png"},
    "VLX": {"cryptoCurrency": "Velas", "cryptoCurrencyCode": "VLX", "image": "/assets/images/coins/VLX.png"},
    "RAI2": {"cryptoCurrency": "Rai Reflex Index", "cryptoCurrencyCode": "RAI2", "image": "/assets/images/coins/RAI.png"},
    "ESD": {"cryptoCurrency": "Empty Set Dollar", "cryptoCurrencyCode": "ESD", "image": "Empty Set Dollar"},
    "1": {"cryptoCurrency": "1irstcoin", "cryptoCurrencyCode": "1", "image": "/assets/images/coins/dog.png"},
    "RARE2": {"cryptoCurrency": "SuperRare", "cryptoCurrencyCode": "RARE2", "image": "/assets/images/coins/RARE.png"},
    "FOA": {"cryptoCurrency": "FirmaChain", "cryptoCurrencyCode": "FOA", "image": "/assets/images/coins/dog.png"},
    "VERI": {"cryptoCurrency": "Veritaseum", "cryptoCurrencyCode": "VERI", "image": "/assets/images/coins/veritaseum.png"},
    "HOLO": {"cryptoCurrency": "Holo", "cryptoCurrencyCode": "HOLO", "image": "/assets/images/coins/dog.png"},
    "COS": {"cryptoCurrency": "Contentos", "cryptoCurrencyCode": "COS", "image": "/assets/images/coins/contenos.png"},
    "CVP": {"cryptoCurrency": "PowerPool", "cryptoCurrencyCode": "CVP", "image": "/assets/images/coins/Powerpool.png"},
    "LQTY2": {"cryptoCurrency": "Liquity", "cryptoCurrencyCode": "LQTY2", "image": "/assets/images/coins/lqty.png"},
    "CORE": {"cryptoCurrency": "Cvault.finance", "cryptoCurrencyCode": "CORE", "image": "/assets/images/coins/cvault.png"},
    "TRB2": {"cryptoCurrency": "Tellor", "cryptoCurrencyCode": "TRB2", "image": "/assets/images/coins/tellor.png"},
    "TRU2": {"cryptoCurrency": "TrueFi", "cryptoCurrencyCode": "TRU2", "image": "/assets/images/coins/truefi.png"},
    "NWC": {"cryptoCurrency": "Newscrypto Coin", "cryptoCurrencyCode": "NWC", "image": "/assets/images/coins/NWC.png"},
    "PAID": {"cryptoCurrency": "PAID Network", "cryptoCurrencyCode": "PAID", "image": "/assets/images/coins/PAID.png"},
    "PIXL": {"cryptoCurrency": "Pixl Finance", "cryptoCurrencyCode": "PIXL", "image": "/assets/images/coins/dog.png"},
    "XPRT": {"cryptoCurrency": "Persistence", "cryptoCurrencyCode": "XPRT", "image": "/assets/images/coins/dog.png"},
    "DRC": {"cryptoCurrency": "Digital Reserve Currency", "cryptoCurrencyCode": "DRC", "image": "/assets/images/coins/dog.png"},
    "AVAX2": {"cryptoCurrency": "Avalanche", "cryptoCurrencyCode": "AVAX2", "image": "/assets/images/coins/avalanche.png"},
    "OXY": {"cryptoCurrency": "Oxygen", "cryptoCurrencyCode": "OXY", "image": "/assets/images/coins/OXY.png"},
    "ORAI": {"cryptoCurrency": "Oraichain Token", "cryptoCurrencyCode": "ORAI", "image": "/assets/images/coins/orai.png"},
    "OCT": {"cryptoCurrency": "OctoFi", "cryptoCurrencyCode": "OCT", "image": "/assets/images/coins/octofi.png"},
    "NCT2": {"cryptoCurrency": "PolySwarm", "cryptoCurrencyCode": "NCT2", "image": "/assets/images/coins/NCT.png"},
    "MIR": {"cryptoCurrency": "Mirror Protocol", "cryptoCurrencyCode": "MIRROR", "image": "/assets/images/coins/mirror-prot.png"},
    "LSS": {"cryptoCurrency": "Lossless", "cryptoCurrencyCode": "LSS", "image": "/assets/images/coins/lossless.png"},
    "KICK": {"cryptoCurrency": "KickToken", "cryptoCurrencyCode": "KICK", "image": "/assets/images/coins/kick.png"},
    "KAI": {"cryptoCurrency": "Kardiachain", "cryptoCurrencyCode": "KAI", "image": "/assets/images/coins/KAI.png"},
    "IOP": {"cryptoCurrency": "Internet of People", "cryptoCurrencyCode": "IOP", "image": "/assets/images/coins/dog.png"},
    "HNS": {"cryptoCurrency": "Handshake", "cryptoCurrencyCode": "HNS", "image": "/assets/images/coins/handshake.png"},
    "BPRO": {"cryptoCurrency": "BitDAO", "cryptoCurrencyCode": "BPRO", "image": "/assets/images/coins/BIT.png"},
    "SDS": {"cryptoCurrency": "Alchemix", "cryptoCurrencyCode": "SDS", "image": "/assets/images/coins/Alchemix.png"},
    "L2": {"cryptoCurrency": "Layer 2", "cryptoCurrencyCode": "L2", "image": "/assets/images/coins/L2.png"},
    "SAFE": {"cryptoCurrency": "Safe", "cryptoCurrencyCode": "SAFE", "image": "/assets/images/coins/safe-token.png"},
    "1SG": {"cryptoCurrency": "1SG", "cryptoCurrencyCode": "1SG", "image": "/assets/images/coins/dog.png"},
    "FET2": {"cryptoCurrency": "Fetch.ai", "cryptoCurrencyCode": "FET2", "image": "/assets/images/coins/fetch-ai.png"},
    "FLO": {"cryptoCurrency": "Floki Inu", "cryptoCurrencyCode": "FLO", "image": "/assets/images/coins/dog.png"},
    "PERX": {"cryptoCurrency": "PeerEx Network", "cryptoCurrencyCode": "PERX", "image": "/assets/images/coins/dog.png"},
    "PERL": {"cryptoCurrency": "Perlin", "cryptoCurrencyCode": "PERL", "image": "/assets/images/coins/PERL.png"},
    "PSG": {"cryptoCurrency": "Paris Saint-Germain Fan Token", "cryptoCurrencyCode": "PSG", "image": "/assets/images/coins/psg.png"},
    "DPET": {"cryptoCurrency": "My DeFi Pet", "cryptoCurrencyCode": "DPET", "image": "/assets/images/coins/mydefipet.png"},
    "NEXO": {"cryptoCurrency": "Nexo", "cryptoCurrencyCode": "NEXO", "image": "/assets/images/coins/nexo.png"},
    "TRX": {"cryptoCurrency": "TRON", "cryptoCurrencyCode": "TRX", "image": "/assets/images/coins/tron.png"},
    "QSP": {"cryptoCurrency": "Quantstamp", "cryptoCurrencyCode": "QSP", "image": "/assets/images/coins/dog.png"},
    "ETH2x-FLI": {"cryptoCurrency": "ETH 2x Flexible Leverage Index", "cryptoCurrencyCode": "ETH2x-FLI", "image": "/assets/images/coins/ETH2x-FLI.png"},
    "USTC": {"cryptoCurrency": "TerraUSD", "cryptoCurrencyCode": "USTC", "image": "/assets/images/coins/USTC.png"},
    "FOR": {"cryptoCurrency": "ForTube", "cryptoCurrencyCode": "FOR", "image": "/assets/images/coins/FOR.png"},
    "EPK": {"cryptoCurrency": "EpiK Protocol", "cryptoCurrencyCode": "EPK", "image": "/assets/images/coins/AIEPK.png"},
    "CYC": {"cryptoCurrency": "Cyclone Protocol", "cryptoCurrencyCode": "CYC", "image": "/assets/images/coins/CYC.png"},
    "ONE2": {"cryptoCurrency": "Harmony2", "cryptoCurrencyCode": "ONE2", "image": "/assets/images/coins/ONE.png"},
    "ONE": {"cryptoCurrency": "Harmony", "cryptoCurrencyCode": "ONE2", "image": "/assets/images/coins/ONE.png"},
    "GVT": {"cryptoCurrency": "Genesis Vision", "cryptoCurrencyCode": "GVT", "image": "/assets/images/coins/dog.png"},
    "BONDLY": {"cryptoCurrency": "Bondly", "cryptoCurrencyCode": "BOND2", "image": "/assets/images/coins/BONDLY.png"},
    "XHV": {"cryptoCurrency": "Haven Protocol", "cryptoCurrencyCode": "XHV", "image": "/assets/images/coins/XHV.png"},
    "DVPN": {"cryptoCurrency": "Sentinel", "cryptoCurrencyCode": "DVPN", "image": "/assets/images/coins/sentinel.png"},
    "SWAP": {"cryptoCurrency": "Trustswap", "cryptoCurrencyCode": "SWAP", "image": "/assets/images/coins/SWAP.png"},
    "DIGG": {"cryptoCurrency": "DIGG", "cryptoCurrencyCode": "DIGG", "image": "/assets/images/coins/digg.png"},
    "XAUT": {"cryptoCurrency": "Tether Gold", "cryptoCurrencyCode": "XAUT", "image": "/assets/images/coins/Tether_Gold.png"},
    "FOX2": {"cryptoCurrency": "Shapeshift FOX Token", "cryptoCurrencyCode": "FOX2", "image": "/assets/images/coins/FOX.png"},
    "DDOG": {"cryptoCurrency": "DODO", "cryptoCurrencyCode": "DDOG", "image": "/assets/images/coins/DODO.png"},
    "KYL": {"cryptoCurrency": "KRYLL", "cryptoCurrencyCode": "KYL", "image": "/assets/images/coins/krl.png"},
    "CRBRUS": {"cryptoCurrency": "Cerberus", "cryptoCurrencyCode": "CERBERUS", "image": "/assets/images/coins/CRBRUS.png"},
    "PCX": {"cryptoCurrency": "ChainX", "cryptoCurrencyCode": "PCX", "image": "/assets/images/coins/chainx.png"},
    "STETH2": {"cryptoCurrency": "Lido stETH", "cryptoCurrencyCode": "STETH2", "image": "/assets/images/coins/steth.png"},
    "PIVX": {"cryptoCurrency": "PIVX", "cryptoCurrencyCode": "PIVX", "image": "/assets/images/coins/pivx.png"},
    "GMEE": {"cryptoCurrency": "GAMEE", "cryptoCurrencyCode": "GMEE", "image": "/assets/images/coins/gmee.png"},
    "SFP": {"cryptoCurrency": "SafePal", "cryptoCurrencyCode": "SFP", "image": "/assets/images/coins/sfp.png"},
    "FEI": {"cryptoCurrency": "Fei Protocol", "cryptoCurrencyCode": "FEI", "image": "/assets/images/coins/dog.png"},
    "VXV": {"cryptoCurrency": "Voyager Token", "cryptoCurrencyCode": "VXV", "image": "/assets/images/coins/dog.png"},
    "RAMP": {"cryptoCurrency": "RAMP", "cryptoCurrencyCode": "RAMP", "image": "/assets/images/coins/ramp.png"},
    "GRT2": {"cryptoCurrency": "The Graph", "cryptoCurrencyCode": "GRT2", "image": "/assets/images/coins/graph-token.png"},
    "OUSD": {"cryptoCurrency": "Origin Dollar", "cryptoCurrencyCode": "OUSD", "image": "/assets/images/coins/ousd.png"},
    "MUST": {"cryptoCurrency": "Must", "cryptoCurrencyCode": "MUST", "image": "/assets/images/coins/must.png"},
    "BDP": {"cryptoCurrency": "Big Data Protocol", "cryptoCurrencyCode": "BDP", "image": "/assets/images/coins/bdp.png"},
    "ENJ2": {"cryptoCurrency": "Enjin Coin", "cryptoCurrencyCode": "ENJ2", "image": "/assets/images/coins/enjin.png"},
    "IQ": {"cryptoCurrency": "Everipedia", "cryptoCurrencyCode": "IQ", "image": "/assets/images/coins/everipedia.png"},
    "STAKE": {"cryptoCurrency": "xDai", "cryptoCurrencyCode": "STAKE", "image": "/assets/images/coins/xdai.png"},
    "DOG": {"cryptoCurrency": "BSCDOG", "cryptoCurrencyCode": "DOG", "image": "/assets/images/coins/dog.png"},
    "WETH": {"cryptoCurrency": "WETH", "cryptoCurrencyCode": "WETH", "image": "/assets/images/coins/weth.png"},
    "OMG": {"cryptoCurrency": "OMG Network", "cryptoCurrencyCode": "OMG", "image": "/assets/images/coins/OMG_Network.png"},
    "TWT": {"cryptoCurrency": "Trust Wallet", "cryptoCurrencyCode": "TWT", "image": "/assets/images/coins/trust.png"},
    "XVS": {"cryptoCurrency": "Venus", "cryptoCurrencyCode": "XVS", "image": "/assets/images/coins/xvs.png"},
    "NFTX": {"cryptoCurrency": "NFTX", "cryptoCurrencyCode": "NFTX", "image": "/assets/images/coins/nftx.png"},
    "BNB": {"cryptoCurrency": "BNB", "cryptoCurrencyCode": "BNB", "image": "/assets/images/coins/bnb.png"},
    "CAKE": {"cryptoCurrency": "PancakeSwap", "cryptoCurrencyCode": "CAKE", "image": "/assets/images/coins/cake.png"},
};