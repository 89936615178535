import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { User } from "../models/User";
import {  SupportMessage } from "../dto/support-message.dto";
import { SaveRate } from "../dto/save-rate-request.dto";
const { REACT_APP_API_ENDPOINT } = process.env;

export const supportApi = createApi({
    reducerPath: "supportApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${REACT_APP_API_ENDPOINT}/api/support`, credentials: "include" }),
    endpoints: (build) => ({
        sendSupportMessage: build.mutation<User, SupportMessage>({
            query: (supportMessage) => ({
                url: "",
                method: "POST",
                body: supportMessage,
            }),
        }),
        saveRate: build.mutation<User, SaveRate>({
            query: (saveRate) => ({
                url: "/rate",
                method: "POST",
                body: saveRate,
            }),
        }),
       
    }),
});

export const { useSendSupportMessageMutation, useSaveRateMutation } = supportApi;
