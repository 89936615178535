export const kpis = [
  {
    _id: "63bf8239f03239e002001612",
    totalProfit: "$212000.00",
    totalRevenue: "$283000.00",
    totalExpenses: "$71000.00",
    monthlyData: [
      {
        month: "january",
        revenue: "$15989.64",
        expenses: "$14231.73",
        operationalExpenses: "$10340.03",
        nonOperationalExpenses: "$4891.70",
      },
      {
        month: "february",
        revenue: "$15832.77",
        expenses: "$11677.84",
        operationalExpenses: "$7006.69",
        nonOperationalExpenses: "$8661.15",
      },
      {
        month: "march",
        revenue: "$16481.27",
        expenses: "$14664.03",
        operationalExpenses: "$8797.42",
        nonOperationalExpenses: "$7866.61",
      },
      {
        month: "april",
        revenue: "$18229.38",
        expenses: "$12336.52",
        operationalExpenses: "$7401.91",
        nonOperationalExpenses: "$2934.61",
      },
      {
        month: "may",
        revenue: "$17401.79",
        expenses: "$11160.61",
        operationalExpenses: "$4296.37",
        nonOperationalExpenses: "$4864.24",
      },
      {
        month: "june",
        revenue: "$18274.03",
        expenses: "$12311.61",
        operationalExpenses: "$7386.96",
        nonOperationalExpenses: "$9924.65",
      },
      {
        month: "july",
        revenue: "$19349.98",
        expenses: "$15431.81",
        operationalExpenses: "$9258.09",
        nonOperationalExpenses: "$7173.72",
      },
      {
        month: "august",
        revenue: "$16647.29",
        expenses: "$13213.71",
        operationalExpenses: "$3127.82",
        nonOperationalExpenses: "$5085.89",
      },
      {
        month: "september",
        revenue: "$19344.07",
        expenses: "$17405.92",
        operationalExpenses: "$10443.55",
        nonOperationalExpenses: "$4962.37",
      },
      {
        month: "october",
        revenue: "$21160.22",
        expenses: "$12990.58",
        operationalExpenses: "$3594.35",
        nonOperationalExpenses: "$9396.23",
      },
      {
        month: "november",
        revenue: "$22655.03",
        expenses: "$17140.80",
        operationalExpenses: "$10284.48",
        nonOperationalExpenses: "$3856.32",
      },
      {
        month: "december",
        revenue: "$17757.75",
        expenses: "$15266.97",
        operationalExpenses: "$9160.18",
        nonOperationalExpenses: "$4106.79",
      },
    ],
    dailyData: [
      { date: "2022-01-02", revenue: "$1498.94", expenses: "$430.64" },
      { date: "2022-01-03", revenue: "$935.08", expenses: "$329.32" },
      { date: "2022-01-04", revenue: "$1350.96", expenses: "$562.49" },
      { date: "2022-01-05", revenue: "$1244.33", expenses: "$679.32" },
      { date: "2022-01-06", revenue: "$1132.99", expenses: "$439.80" },
      { date: "2022-01-07", revenue: "$1158.31", expenses: "$336.12" },
      { date: "2022-01-08", revenue: "$1184.66", expenses: "$516.10" },
      { date: "2022-01-09", revenue: "$637.32", expenses: "$635.06" },
      { date: "2022-01-10", revenue: "$1315.21", expenses: "$560.12" },
      { date: "2022-01-11", revenue: "$1093.06", expenses: "$540.05" },
      { date: "2022-01-12", revenue: "$1477.77", expenses: "$487.16" },
      { date: "2022-01-13", revenue: "$1076.09", expenses: "$616.29" },
      { date: "2022-01-14", revenue: "$1130.96", expenses: "$392.17" },
      { date: "2022-01-15", revenue: "$846.42", expenses: "$548.38" },
      { date: "2022-01-16", revenue: "$1182.35", expenses: "$366.72" },
      { date: "2022-01-17", revenue: "$1046.61", expenses: "$481.56" },
      { date: "2022-01-18", revenue: "$601.65", expenses: "$452.75" },
      { date: "2022-01-19", revenue: "$798.33", expenses: "$351.23" },
      { date: "2022-01-20", revenue: "$1499.37", expenses: "$340.85" },
      { date: "2022-01-21", revenue: "$1462.26", expenses: "$630.31" },
      { date: "2022-01-22", revenue: "$1447.38", expenses: "$476.59" },
      { date: "2022-01-23", revenue: "$1194.96", expenses: "$482.85" },
      { date: "2022-01-24", revenue: "$713.11", expenses: "$754.33" },
      { date: "2022-01-25", revenue: "$1084.48", expenses: "$547.37" },
      { date: "2022-01-26", revenue: "$819.53", expenses: "$653.59" },
      { date: "2022-01-27", revenue: "$1306.61", expenses: "$636.87" },
      { date: "2022-01-28", revenue: "$1029.78", expenses: "$719.46" },
      { date: "2022-01-29", revenue: "$1415.18", expenses: "$387.49" },
      { date: "2022-01-30", revenue: "$845.38", expenses: "$533.93" },
      { date: "2022-01-31", revenue: "$764.69", expenses: "$762.35" },
      { date: "2022-02-01", revenue: "$616.49", expenses: "$658.92" },
      { date: "2022-02-02", revenue: "$677.71", expenses: "$754.69" },
      { date: "2022-02-03", revenue: "$576.99", expenses: "$395.43" },
      { date: "2022-02-04", revenue: "$713.58", expenses: "$733.27" },
      { date: "2022-02-05", revenue: "$714.18", expenses: "$336.24" },
      { date: "2022-02-06", revenue: "$807.64", expenses: "$534.49" },
      { date: "2022-02-07", revenue: "$824.40", expenses: "$743.82" },
      { date: "2022-02-08", revenue: "$975.30", expenses: "$307.51" },
      { date: "2022-02-09", revenue: "$1098.58", expenses: "$615.31" },
      { date: "2022-02-10", revenue: "$1119.34", expenses: "$677.04" },
      { date: "2022-02-11", revenue: "$1232.39", expenses: "$664.69" },
      { date: "2022-02-12", revenue: "$1405.21", expenses: "$422.57" },
      { date: "2022-02-13", revenue: "$1343.47", expenses: "$350.77" },
      { date: "2022-02-14", revenue: "$1495.81", expenses: "$301.39" },
      { date: "2022-02-15", revenue: "$1131.97", expenses: "$597.06" },
      { date: "2022-02-16", revenue: "$1174.17", expenses: "$685.08" },
      { date: "2022-02-17", revenue: "$702.38", expenses: "$671.23" },
      { date: "2022-02-18", revenue: "$1440.95", expenses: "$539.55" },
      { date: "2022-02-19", revenue: "$1272.87", expenses: "$703.02" },
      { date: "2022-02-20", revenue: "$530.16", expenses: "$305.75" },
      { date: "2022-02-21", revenue: "$552.26", expenses: "$556.19" },
      { date: "2022-02-22", revenue: "$1456.57", expenses: "$675.05" },
      { date: "2022-02-23", revenue: "$512.04", expenses: "$576.37" },
      { date: "2022-02-24", revenue: "$886.31", expenses: "$410.14" },
      { date: "2022-02-25", revenue: "$1254.55", expenses: "$321.41" },
      { date: "2022-02-26", revenue: "$1123.03", expenses: "$480.50" },
      { date: "2022-02-27", revenue: "$671.45", expenses: "$494.61" },
      { date: "2022-02-28", revenue: "$1373.22", expenses: "$312.67" },
      { date: "2022-03-01", revenue: "$896.75", expenses: "$696.92" },
      { date: "2022-03-02", revenue: "$721.53", expenses: "$505.34" },
      { date: "2022-03-03", revenue: "$560.48", expenses: "$541.09" },
      { date: "2022-03-04", revenue: "$1065.40", expenses: "$733.90" },
      { date: "2022-03-05", revenue: "$692.26", expenses: "$462.62" },
      { date: "2022-03-06", revenue: "$832.86", expenses: "$642.28" },
      { date: "2022-03-07", revenue: "$709.00", expenses: "$742.25" },
      { date: "2022-03-08", revenue: "$1442.66", expenses: "$596.24" },
      { date: "2022-03-09", revenue: "$542.74", expenses: "$655.54" },
      { date: "2022-03-10", revenue: "$526.11", expenses: "$530.28" },
      { date: "2022-03-11", revenue: "$658.47", expenses: "$352.21" },
      { date: "2022-03-12", revenue: "$996.34", expenses: "$749.20" },
      { date: "2022-03-13", revenue: "$770.93", expenses: "$516.91" },
      { date: "2022-03-14", revenue: "$617.37", expenses: "$758.07" },
      { date: "2022-03-15", revenue: "$786.48", expenses: "$451.41" },
      { date: "2022-03-16", revenue: "$540.91", expenses: "$756.89" },
      { date: "2022-03-17", revenue: "$919.24", expenses: "$701.41" },
      { date: "2022-03-18", revenue: "$909.23", expenses: "$638.37" },
      { date: "2022-03-19", revenue: "$546.92", expenses: "$465.94" },
      { date: "2022-03-20", revenue: "$1233.13", expenses: "$441.79" },
      { date: "2022-03-21", revenue: "$1494.34", expenses: "$333.61" },
      { date: "2022-03-22", revenue: "$610.14", expenses: "$565.13" },
      { date: "2022-03-23", revenue: "$1044.45", expenses: "$704.07" },
      { date: "2022-03-24", revenue: "$790.03", expenses: "$393.17" },
      { date: "2022-03-25", revenue: "$915.05", expenses: "$709.24" },
      { date: "2022-03-26", revenue: "$620.99", expenses: "$309.02" },
      { date: "2022-03-27", revenue: "$1404.61", expenses: "$649.14" },
      { date: "2022-03-28", revenue: "$727.52", expenses: "$580.27" },
      { date: "2022-03-29", revenue: "$618.69", expenses: "$503.99" },
      { date: "2022-03-30", revenue: "$806.00", expenses: "$545.37" },
      { date: "2022-03-31", revenue: "$629.04", expenses: "$449.41" },
      { date: "2022-04-01", revenue: "$1197.71", expenses: "$524.89" },
      { date: "2022-04-02", revenue: "$1347.49", expenses: "$779.95" },
      { date: "2022-04-03", revenue: "$845.73", expenses: "$373.66" },
      { date: "2022-04-04", revenue: "$701.97", expenses: "$424.72" },
      { date: "2022-04-05", revenue: "$699.12", expenses: "$652.28" },
      { date: "2022-04-06", revenue: "$1487.15", expenses: "$527.12" },
      { date: "2022-04-07", revenue: "$770.93", expenses: "$455.04" },
      { date: "2022-04-08", revenue: "$823.81", expenses: "$686.44" },
      { date: "2022-04-09", revenue: "$1190.89", expenses: "$501.52" },
      { date: "2022-04-10", revenue: "$667.10", expenses: "$792.51" },
      { date: "2022-04-11", revenue: "$712.50", expenses: "$325.40" },
      { date: "2022-04-12", revenue: "$1113.94", expenses: "$610.37" },
      { date: "2022-04-13", revenue: "$755.04", expenses: "$413.31" },
      { date: "2022-04-14", revenue: "$903.46", expenses: "$592.64" },
      { date: "2022-04-15", revenue: "$668.47", expenses: "$439.13" },
      { date: "2022-04-16", revenue: "$1053.55", expenses: "$723.14" },
      { date: "2022-04-17", revenue: "$574.82", expenses: "$627.62" },
      { date: "2022-04-18", revenue: "$732.19", expenses: "$545.84" },
      { date: "2022-04-19", revenue: "$1214.28", expenses: "$585.94" },
      { date: "2022-04-20", revenue: "$844.63", expenses: "$668.86" },
      { date: "2022-04-21", revenue: "$545.95", expenses: "$450.46" },
      { date: "2022-04-22", revenue: "$925.01", expenses: "$588.16" },
      { date: "2022-04-23", revenue: "$840.44", expenses: "$324.07" },
      { date: "2022-04-24", revenue: "$799.81", expenses: "$320.28" },
      { date: "2022-04-25", revenue: "$1108.47", expenses: "$330.84" },
      { date: "2022-04-26", revenue: "$572.72", expenses: "$605.23" },
      { date: "2022-04-27", revenue: "$1416.82", expenses: "$746.86" },
      { date: "2022-04-28", revenue: "$1344.54", expenses: "$448.10" },
      { date: "2022-04-29", revenue: "$957.31", expenses: "$653.00" },
      { date: "2022-04-30", revenue: "$1042.75", expenses: "$686.48" },
      { date: "2022-05-01", revenue: "$614.52", expenses: "$399.34" },
      { date: "2022-05-02", revenue: "$968.08", expenses: "$490.92" },
      { date: "2022-05-03", revenue: "$818.17", expenses: "$666.67" },
      { date: "2022-05-04", revenue: "$787.90", expenses: "$641.50" },
      { date: "2022-05-05", revenue: "$887.20", expenses: "$456.85" },
      { date: "2022-05-06", revenue: "$926.64", expenses: "$595.10" },
      { date: "2022-05-07", revenue: "$667.62", expenses: "$652.33" },
      { date: "2022-05-08", revenue: "$1450.41", expenses: "$660.60" },
      { date: "2022-05-09", revenue: "$1009.90", expenses: "$479.36" },
      { date: "2022-05-10", revenue: "$547.53", expenses: "$554.87" },
      { date: "2022-05-11", revenue: "$579.74", expenses: "$432.36" },
      { date: "2022-05-12", revenue: "$597.11", expenses: "$411.56" },
      { date: "2022-05-13", revenue: "$1300.97", expenses: "$654.18" },
      { date: "2022-05-14", revenue: "$1042.83", expenses: "$715.01" },
      { date: "2022-05-15", revenue: "$1345.61", expenses: "$334.37" },
      { date: "2022-05-16", revenue: "$768.61", expenses: "$425.22" },
      { date: "2022-05-17", revenue: "$1175.15", expenses: "$729.16" },
      { date: "2022-05-18", revenue: "$767.39", expenses: "$779.83" },
      { date: "2022-05-19", revenue: "$969.34", expenses: "$341.02" },
      { date: "2022-05-20", revenue: "$993.34", expenses: "$530.27" },
      { date: "2022-05-21", revenue: "$968.98", expenses: "$597.76" },
      { date: "2022-05-22", revenue: "$628.01", expenses: "$574.00" },
      { date: "2022-05-23", revenue: "$842.68", expenses: "$402.90" },
      { date: "2022-05-24", revenue: "$986.72", expenses: "$365.50" },
      { date: "2022-05-25", revenue: "$1321.66", expenses: "$536.73" },
      { date: "2022-05-26", revenue: "$806.21", expenses: "$499.02" },
      { date: "2022-05-27", revenue: "$886.34", expenses: "$776.51" },
      { date: "2022-05-28", revenue: "$663.62", expenses: "$718.90" },
      { date: "2022-05-29", revenue: "$546.73", expenses: "$729.32" },
      { date: "2022-05-30", revenue: "$1284.65", expenses: "$355.50" },
      { date: "2022-05-31", revenue: "$1422.81", expenses: "$776.55" },
      { date: "2022-06-01", revenue: "$678.50", expenses: "$767.03" },
      { date: "2022-06-02", revenue: "$867.65", expenses: "$581.76" },
      { date: "2022-06-03", revenue: "$674.84", expenses: "$666.78" },
      { date: "2022-06-04", revenue: "$595.25", expenses: "$354.79" },
      { date: "2022-06-05", revenue: "$1262.15", expenses: "$588.65" },
      { date: "2022-06-06", revenue: "$1286.75", expenses: "$403.69" },
      { date: "2022-06-07", revenue: "$731.00", expenses: "$532.55" },
      { date: "2022-06-08", revenue: "$1096.82", expenses: "$328.76" },
      { date: "2022-06-09", revenue: "$1113.24", expenses: "$749.16" },
      { date: "2022-06-10", revenue: "$1479.08", expenses: "$417.01" },
      { date: "2022-06-11", revenue: "$1023.70", expenses: "$326.53" },
      { date: "2022-06-12", revenue: "$953.94", expenses: "$426.52" },
      { date: "2022-06-13", revenue: "$1154.32", expenses: "$565.70" },
      { date: "2022-06-14", revenue: "$1431.49", expenses: "$398.88" },
      { date: "2022-06-15", revenue: "$1206.06", expenses: "$442.20" },
      { date: "2022-06-16", revenue: "$1441.07", expenses: "$730.36" },
      { date: "2022-06-17", revenue: "$664.42", expenses: "$583.27" },
      { date: "2022-06-18", revenue: "$569.78", expenses: "$556.95" },
      { date: "2022-06-19", revenue: "$619.93", expenses: "$519.31" },
      { date: "2022-06-20", revenue: "$1232.45", expenses: "$410.94" },
      { date: "2022-06-21", revenue: "$1429.32", expenses: "$348.96" },
      { date: "2022-06-22", revenue: "$1304.03", expenses: "$597.34" },
      { date: "2022-06-23", revenue: "$1089.02", expenses: "$576.66" },
      { date: "2022-06-24", revenue: "$740.89", expenses: "$616.74" },
      { date: "2022-06-25", revenue: "$1066.17", expenses: "$656.03" },
      { date: "2022-06-26", revenue: "$1385.65", expenses: "$719.00" },
      { date: "2022-06-27", revenue: "$1498.16", expenses: "$571.97" },
      { date: "2022-06-28", revenue: "$579.61", expenses: "$410.93" },
      { date: "2022-06-29", revenue: "$624.32", expenses: "$526.26" },
      { date: "2022-06-30", revenue: "$798.71", expenses: "$736.02" },
      { date: "2022-07-01", revenue: "$977.14", expenses: "$660.07" },
      { date: "2022-07-02", revenue: "$1257.50", expenses: "$682.12" },
      { date: "2022-07-03", revenue: "$598.53", expenses: "$421.27" },
      { date: "2022-07-04", revenue: "$1178.54", expenses: "$750.49" },
      { date: "2022-07-05", revenue: "$652.35", expenses: "$613.77" },
      { date: "2022-07-06", revenue: "$926.22", expenses: "$693.88" },
      { date: "2022-07-07", revenue: "$1303.57", expenses: "$683.27" },
      { date: "2022-07-08", revenue: "$538.83", expenses: "$703.28" },
      { date: "2022-07-09", revenue: "$805.46", expenses: "$511.35" },
      { date: "2022-07-10", revenue: "$614.23", expenses: "$607.68" },
      { date: "2022-07-11", revenue: "$986.25", expenses: "$590.38" },
      { date: "2022-07-12", revenue: "$1035.61", expenses: "$496.20" },
      { date: "2022-07-13", revenue: "$510.87", expenses: "$310.95" },
      { date: "2022-07-14", revenue: "$1375.38", expenses: "$766.17" },
      { date: "2022-07-15", revenue: "$1298.54", expenses: "$726.07" },
      { date: "2022-07-16", revenue: "$858.92", expenses: "$302.26" },
      { date: "2022-07-17", revenue: "$520.66", expenses: "$611.26" },
      { date: "2022-07-18", revenue: "$794.83", expenses: "$307.78" },
      { date: "2022-07-19", revenue: "$1328.66", expenses: "$439.09" },
      { date: "2022-07-20", revenue: "$1314.81", expenses: "$350.62" },
      { date: "2022-07-21", revenue: "$1281.19", expenses: "$343.02" },
      { date: "2022-07-22", revenue: "$569.59", expenses: "$689.64" },
      { date: "2022-07-23", revenue: "$951.97", expenses: "$638.80" },
      { date: "2022-07-24", revenue: "$1323.68", expenses: "$454.90" },
      { date: "2022-07-25", revenue: "$852.04", expenses: "$645.14" },
      { date: "2022-07-26", revenue: "$1323.45", expenses: "$763.45" },
      { date: "2022-07-27", revenue: "$1091.68", expenses: "$576.46" },
      { date: "2022-07-28", revenue: "$805.31", expenses: "$534.42" },
      { date: "2022-07-29", revenue: "$858.09", expenses: "$547.69" },
      { date: "2022-07-30", revenue: "$720.94", expenses: "$308.57" },
      { date: "2022-07-31", revenue: "$854.13", expenses: "$340.54" },
      { date: "2022-08-01", revenue: "$775.98", expenses: "$493.31" },
      { date: "2022-08-02", revenue: "$1194.06", expenses: "$327.15" },
      { date: "2022-08-03", revenue: "$1463.58", expenses: "$560.15" },
      { date: "2022-08-04", revenue: "$846.65", expenses: "$586.21" },
      { date: "2022-08-05", revenue: "$1347.22", expenses: "$664.95" },
      { date: "2022-08-06", revenue: "$643.63", expenses: "$718.49" },
      { date: "2022-08-07", revenue: "$582.72", expenses: "$339.96" },
      { date: "2022-08-08", revenue: "$1360.65", expenses: "$683.15" },
      { date: "2022-08-09", revenue: "$1284.11", expenses: "$343.74" },
      { date: "2022-08-10", revenue: "$1103.50", expenses: "$614.88" },
      { date: "2022-08-11", revenue: "$528.48", expenses: "$762.16" },
      { date: "2022-08-12", revenue: "$979.69", expenses: "$468.36" },
      { date: "2022-08-13", revenue: "$1457.87", expenses: "$390.97" },
      { date: "2022-08-14", revenue: "$1397.29", expenses: "$761.24" },
      { date: "2022-08-15", revenue: "$802.38", expenses: "$680.88" },
      { date: "2022-08-16", revenue: "$916.44", expenses: "$563.35" },
      { date: "2022-08-17", revenue: "$525.83", expenses: "$622.37" },
      { date: "2022-08-18", revenue: "$1043.48", expenses: "$757.84" },
      { date: "2022-08-19", revenue: "$689.54", expenses: "$593.78" },
      { date: "2022-08-20", revenue: "$1306.55", expenses: "$397.12" },
      { date: "2022-08-21", revenue: "$1414.61", expenses: "$380.05" },
      { date: "2022-08-22", revenue: "$636.09", expenses: "$426.99" },
      { date: "2022-08-23", revenue: "$1128.52", expenses: "$341.34" },
      { date: "2022-08-24", revenue: "$794.05", expenses: "$432.00" },
      { date: "2022-08-25", revenue: "$936.38", expenses: "$416.62" },
      { date: "2022-08-26", revenue: "$1159.46", expenses: "$709.27" },
      { date: "2022-08-27", revenue: "$1457.79", expenses: "$498.26" },
      { date: "2022-08-28", revenue: "$1376.86", expenses: "$581.87" },
      { date: "2022-08-29", revenue: "$830.11", expenses: "$423.40" },
      { date: "2022-08-30", revenue: "$1431.68", expenses: "$471.83" },
      { date: "2022-08-31", revenue: "$720.17", expenses: "$719.55" },
      { date: "2022-09-01", revenue: "$1158.03", expenses: "$570.54" },
      { date: "2022-09-02", revenue: "$1117.39", expenses: "$606.89" },
      { date: "2022-09-03", revenue: "$1367.28", expenses: "$767.69" },
      { date: "2022-09-04", revenue: "$1152.67", expenses: "$435.39" },
      { date: "2022-09-05", revenue: "$530.15", expenses: "$449.32" },
      { date: "2022-09-06", revenue: "$615.71", expenses: "$749.52" },
      { date: "2022-09-07", revenue: "$1057.53", expenses: "$336.59" },
      { date: "2022-09-08", revenue: "$1130.75", expenses: "$485.13" },
      { date: "2022-09-09", revenue: "$1374.56", expenses: "$606.54" },
      { date: "2022-09-10", revenue: "$1086.33", expenses: "$474.42" },
      { date: "2022-09-11", revenue: "$938.13", expenses: "$431.59" },
      { date: "2022-09-12", revenue: "$815.20", expenses: "$765.07" },
      { date: "2022-09-13", revenue: "$1009.19", expenses: "$589.63" },
      { date: "2022-09-14", revenue: "$1247.00", expenses: "$476.95" },
      { date: "2022-09-15", revenue: "$615.12", expenses: "$730.85" },
      { date: "2022-09-16", revenue: "$1025.87", expenses: "$517.46" },
      { date: "2022-09-17", revenue: "$1012.51", expenses: "$316.31" },
      { date: "2022-09-18", revenue: "$1381.92", expenses: "$626.52" },
      { date: "2022-09-19", revenue: "$817.26", expenses: "$360.33" },
      { date: "2022-09-20", revenue: "$642.00", expenses: "$728.13" },
      { date: "2022-09-21", revenue: "$554.87", expenses: "$770.02" },
      { date: "2022-09-22", revenue: "$1494.84", expenses: "$569.33" },
      { date: "2022-09-23", revenue: "$1254.25", expenses: "$390.00" },
      { date: "2022-09-24", revenue: "$749.93", expenses: "$473.59" },
      { date: "2022-09-25", revenue: "$1329.51", expenses: "$361.60" },
      { date: "2022-09-26", revenue: "$1094.98", expenses: "$660.94" },
      { date: "2022-09-27", revenue: "$1170.18", expenses: "$420.90" },
      { date: "2022-09-28", revenue: "$872.36", expenses: "$627.82" },
      { date: "2022-09-29", revenue: "$990.59", expenses: "$405.93" },
      { date: "2022-09-30", revenue: "$831.62", expenses: "$382.83" },
      { date: "2022-10-01", revenue: "$1284.41", expenses: "$736.88" },
      { date: "2022-10-02", revenue: "$1272.95", expenses: "$645.85" },
      { date: "2022-10-03", revenue: "$1492.35", expenses: "$583.82" },
      { date: "2022-10-04", revenue: "$848.46", expenses: "$574.83" },
      { date: "2022-10-05", revenue: "$599.00", expenses: "$781.89" },
      { date: "2022-10-06", revenue: "$976.82", expenses: "$713.21" },
      { date: "2022-10-07", revenue: "$1252.20", expenses: "$438.58" },
      { date: "2022-10-08", revenue: "$577.48", expenses: "$658.74" },
      { date: "2022-10-09", revenue: "$998.48", expenses: "$756.13" },
      { date: "2022-10-10", revenue: "$822.26", expenses: "$525.18" },
      { date: "2022-10-11", revenue: "$566.85", expenses: "$497.54" },
      { date: "2022-10-12", revenue: "$614.44", expenses: "$568.29" },
      { date: "2022-10-13", revenue: "$912.66", expenses: "$646.20" },
      { date: "2022-10-14", revenue: "$1181.90", expenses: "$499.01" },
      { date: "2022-10-15", revenue: "$687.80", expenses: "$509.42" },
      { date: "2022-10-16", revenue: "$1193.87", expenses: "$784.02" },
      { date: "2022-10-17", revenue: "$673.46", expenses: "$713.26" },
      { date: "2022-10-18", revenue: "$1220.92", expenses: "$549.16" },
      { date: "2022-10-19", revenue: "$748.21", expenses: "$745.88" },
      { date: "2022-10-20", revenue: "$1138.51", expenses: "$794.99" },
      { date: "2022-10-21", revenue: "$785.03", expenses: "$713.34" },
      { date: "2022-10-22", revenue: "$1245.45", expenses: "$525.21" },
      { date: "2022-10-23", revenue: "$1451.62", expenses: "$542.40" },
      { date: "2022-10-24", revenue: "$935.08", expenses: "$640.45" },
      { date: "2022-10-25", revenue: "$812.39", expenses: "$349.79" },
      { date: "2022-10-26", revenue: "$1273.79", expenses: "$727.45" },
      { date: "2022-10-27", revenue: "$1231.44", expenses: "$633.77" },
      { date: "2022-10-28", revenue: "$1203.35", expenses: "$497.31" },
      { date: "2022-10-29", revenue: "$702.78", expenses: "$332.65" },
      { date: "2022-10-30", revenue: "$1053.93", expenses: "$420.18" },
      { date: "2022-10-31", revenue: "$1056.37", expenses: "$367.50" },
      { date: "2022-11-01", revenue: "$1038.36", expenses: "$347.48" },
      { date: "2022-11-02", revenue: "$614.72", expenses: "$420.23" },
      { date: "2022-11-03", revenue: "$664.16", expenses: "$651.04" },
      { date: "2022-11-04", revenue: "$1417.08", expenses: "$631.56" },
      { date: "2022-11-05", revenue: "$557.25", expenses: "$667.31" },
      { date: "2022-11-06", revenue: "$634.14", expenses: "$459.52" },
      { date: "2022-11-07", revenue: "$895.09", expenses: "$683.38" },
      { date: "2022-11-08", revenue: "$1365.47", expenses: "$320.44" },
      { date: "2022-11-09", revenue: "$587.71", expenses: "$435.66" },
      { date: "2022-11-10", revenue: "$1439.46", expenses: "$511.32" },
      { date: "2022-11-11", revenue: "$1318.18", expenses: "$689.70" },
      { date: "2022-11-12", revenue: "$774.94", expenses: "$602.80" },
      { date: "2022-11-13", revenue: "$1345.93", expenses: "$418.38" },
      { date: "2022-11-14", revenue: "$934.12", expenses: "$694.30" },
      { date: "2022-11-15", revenue: "$856.11", expenses: "$719.66" },
      { date: "2022-11-16", revenue: "$834.06", expenses: "$472.46" },
      { date: "2022-11-17", revenue: "$862.35", expenses: "$576.89" },
      { date: "2022-11-18", revenue: "$1098.70", expenses: "$378.77" },
      { date: "2022-11-19", revenue: "$1257.86", expenses: "$594.74" },
      { date: "2022-11-20", revenue: "$1020.98", expenses: "$696.09" },
      { date: "2022-11-21", revenue: "$725.09", expenses: "$648.75" },
      { date: "2022-11-22", revenue: "$738.74", expenses: "$481.11" },
      { date: "2022-11-23", revenue: "$661.41", expenses: "$726.44" },
      { date: "2022-11-24", revenue: "$689.55", expenses: "$542.89" },
      { date: "2022-11-25", revenue: "$1451.71", expenses: "$754.48" },
      { date: "2022-11-26", revenue: "$916.67", expenses: "$554.41" },
      { date: "2022-11-27", revenue: "$1474.95", expenses: "$583.52" },
      { date: "2022-11-28", revenue: "$1438.52", expenses: "$533.21" },
      { date: "2022-11-29", revenue: "$531.00", expenses: "$452.18" },
      { date: "2022-11-30", revenue: "$812.71", expenses: "$302.62" },
      { date: "2022-12-01", revenue: "$772.43", expenses: "$641.09" },
      { date: "2022-12-02", revenue: "$1382.87", expenses: "$482.25" },
      { date: "2022-12-03", revenue: "$573.27", expenses: "$572.70" },
      { date: "2022-12-04", revenue: "$644.09", expenses: "$476.26" },
      { date: "2022-12-05", revenue: "$1338.13", expenses: "$513.24" },
      { date: "2022-12-06", revenue: "$841.11", expenses: "$390.83" },
      { date: "2022-12-07", revenue: "$697.51", expenses: "$759.51" },
      { date: "2022-12-08", revenue: "$627.41", expenses: "$685.80" },
      { date: "2022-12-09", revenue: "$1194.34", expenses: "$447.89" },
      { date: "2022-12-10", revenue: "$891.38", expenses: "$384.43" },
      { date: "2022-12-11", revenue: "$642.22", expenses: "$346.45" },
      { date: "2022-12-12", revenue: "$846.17", expenses: "$655.70" },
      { date: "2022-12-13", revenue: "$1162.43", expenses: "$788.19" },
      { date: "2022-12-14", revenue: "$1419.39", expenses: "$422.59" },
      { date: "2022-12-15", revenue: "$1350.41", expenses: "$722.04" },
      { date: "2022-12-16", revenue: "$568.48", expenses: "$651.17" },
      { date: "2022-12-17", revenue: "$586.94", expenses: "$364.81" },
      { date: "2022-12-18", revenue: "$787.93", expenses: "$752.41" },
      { date: "2022-12-19", revenue: "$751.40", expenses: "$303.15" },
      { date: "2022-12-20", revenue: "$511.29", expenses: "$319.61" },
      { date: "2022-12-21", revenue: "$789.03", expenses: "$601.99" },
      { date: "2022-12-22", revenue: "$578.12", expenses: "$379.53" },
      { date: "2022-12-23", revenue: "$1298.60", expenses: "$625.81" },
      { date: "2022-12-24", revenue: "$1308.95", expenses: "$708.39" },
      { date: "2022-12-25", revenue: "$788.83", expenses: "$612.17" },
      { date: "2022-12-26", revenue: "$1393.26", expenses: "$354.50" },
      { date: "2022-12-27", revenue: "$907.86", expenses: "$513.74" },
      { date: "2022-12-28", revenue: "$1049.91", expenses: "$583.10" },
      { date: "2022-12-29", revenue: "$1110.05", expenses: "$387.96" },
      { date: "2022-12-30", revenue: "$1403.44", expenses: "$527.35" },
      { date: "2022-12-31", revenue: "$549.52", expenses: "$577.92" },
      { date: "2023-01-01", revenue: "$832.70", expenses: "$664.41" },
    ],
    expensesByCategory: {
      salaries: "$38000.00",
      supplies: "$13000.00",
      services: "$10000.00",
    },
  },
];

export const products = [
  {
    _id: "63bf7ac9f03239e002001600",
    price: "$43.41",
    expense: "$5.97",
    transactions: [
      "63bf7ccef03239554c002449",
      "63bf7ccef03239855d00155c",
      "63bf7ccef032397b4f001603",
      "63bf7ccef03239eb9d0016d4",
    ],
  },
  {
    _id: "63bf7ac9f03239e002001601",
    price: "$60.13",
    expense: "$9.12",
    transactions: [
      "63bf7ccef032396a8e001696",
      "63bf7ccef0323922d2001694",
      "63bf7ccef03239855d001557",
      "63bf7ccef03239554c00244d",
    ],
  },
  {
    _id: "63bf7ac9f03239e002001602",
    price: "$195.05",
    expense: "$6.72",
    transactions: ["63bf7ccef03239e002001606", "63bf7ccef0323922d200169a"],
  },
  {
    _id: "63bf7ac9f03239e002001603",
    price: "$46.25",
    expense: "$9.95",
    transactions: [
      "63bf7ccef03239aec900155b",
      "63bf7ccef03239eb9d0016d3",
      "63bf7ccef03239aec9001559",
    ],
  },
  {
    _id: "63bf7ac9f03239e002001604",
    price: "$87.41",
    expense: "$7.97",
    transactions: [
      "63bf7ccef03239855d00155d",
      "63bf7ccef03239855d001561",
      "63bf7ccef03239e00200160e",
      "63bf7ccef0323922d2001697",
      "63bf7ccef032394f5f002442",
    ],
  },
  {
    _id: "63bf7ac9f03239e002001605",
    price: "$73.28",
    expense: "$7.85",
    transactions: [
      "63bf7ccef03239966c0016a0",
      "63bf7ccef03239966c00169f",
      "63bf7ccef032394cbd0016a0",
      "63bf7ccef03239554c002453",
      "63bf7ccef0323901e6001557",
    ],
  },
  {
    _id: "63bf7ac9f03239343e00169b",
    price: "$130.65",
    expense: "$7.95",
    transactions: ["63bf7ccef0323901e6001560", "63bf7ccef032396d89000013"],
  },
  {
    _id: "63bf7ac9f03239343e00169c",
    price: "$198.54",
    expense: "$9.50",
    transactions: [
      "63bf7ccef03239eb9d0016d9",
      "63bf7ccef032396a8e001696",
      "63bf7ccef03239cf1b0016a7",
      "63bf7ccef0323901e6001562",
    ],
  },
  {
    _id: "63bf7ac9f03239343e00169d",
    price: "$147.32",
    expense: "$5.86",
    transactions: ["63bf7ccef03239343e0016a9", "63bf7ccef03239f920001690"],
  },
  {
    _id: "63bf7ac9f03239343e00169e",
    price: "$187.22",
    expense: "$5.82",
    transactions: [
      "63bf7ccef03239aec9001560",
      "63bf7ccef032396d8900000f",
      "63bf7ccef0323922d2001697",
    ],
  },
  {
    _id: "63bf7ac9f03239343e00169f",
    price: "$162.43",
    expense: "$3.28",
    transactions: [
      "63bf7ccef032395ccf001697",
      "63bf7ccef0323901e600155e",
      "63bf7ccef03239aec9001559",
    ],
  },
  {
    _id: "63bf7ac9f03239343e0016a0",
    price: "$30.09",
    expense: "$8.55",
    transactions: ["63bf7ccef03239343e0016a3"],
  },
  {
    _id: "63bf7ac9f0323901e6001551",
    price: "$31.07",
    expense: "$4.98",
    transactions: [
      "63bf7ccef032395ccf001695",
      "63bf7ccef03239eb9d0016dd",
      "63bf7ccef032397b4f001606",
      "63bf7ccef03239855d00155b",
      "63bf7ccef032397b4f001604",
    ],
  },
  {
    _id: "63bf7ac9f0323901e6001552",
    price: "$12.21",
    expense: "$6.82",
    transactions: [
      "63bf7ccef03239855d00155c",
      "63bf7ccef032395ccf001696",
      "63bf7ccef03239cf1b0016a2",
      "63bf7ccef032396a8e00169a",
      "63bf7ccef03239966c00169a",
    ],
  },
  {
    _id: "63bf7ac9f0323901e6001553",
    price: "$76.31",
    expense: "$3.84",
    transactions: [
      "63bf7ccef03239e002001608",
      "63bf7ccef0323922d200169b",
      "63bf7ccef03239eb9d0016e6",
    ],
  },
  {
    _id: "63bf7ac9f0323901e6001554",
    price: "$169.87",
    expense: "$8.35",
    transactions: [
      "63bf7ccef03239eb9d0016d5",
      "63bf7ccef03239966c00169e",
      "63bf7ccef0323974200016a7",
    ],
  },
  {
    _id: "63bf7ac9f0323901e6001555",
    price: "$187.36",
    expense: "$8.12",
    transactions: [
      "63bf7ccef032394f5f00243a",
      "63bf7ccef03239eb9d0016de",
      "63bf7ccef03239f920001696",
    ],
  },
  {
    _id: "63bf7ac9f0323901e6001556",
    price: "$67.29",
    expense: "$6.32",
    transactions: [
      "63bf7ccef032396d89000015",
      "63bf7ccef03239f920001690",
      "63bf7ccef03239eb9d0016d2",
    ],
  },
  {
    _id: "63bf7ac9f032394cbd001699",
    price: "$85.35",
    expense: "$9.33",
    transactions: [
      "63bf7ccef03239aec9001558",
      "63bf7ccef03239f92000169a",
      "63bf7ccef03239742000169d",
      "63bf7ccef03239554c00244a",
    ],
  },
  {
    _id: "63bf7ac9f032394cbd00169a",
    price: "$35.75",
    expense: "$9.93",
    transactions: [
      "63bf7ccef03239554c002453",
      "63bf7ccef03239e002001608",
      "63bf7ccef0323937a500168d",
    ],
  },
  {
    _id: "63bf7ac9f032394cbd00169b",
    price: "$59.80",
    expense: "$1.51",
    transactions: [
      "63bf7ccef0323937a5001690",
      "63bf7ccef03239e00200160b",
      "63bf7ccef032394f5f002441",
      "63bf7ccef0323975d60016a3",
      "63bf7ccef032395ccf00169f",
    ],
  },
  {
    _id: "63bf7ac9f032394cbd00169c",
    price: "$86.45",
    expense: "$3.55",
    transactions: ["63bf7ccef032396d8900000f"],
  },
  {
    _id: "63bf7ac9f032394cbd00169d",
    price: "$142.21",
    expense: "$1.85",
    transactions: ["63bf7ccef032396a8e001698", "63bf7ccef03239eb9d0016d0"],
  },
  {
    _id: "63bf7ac9f032394cbd00169e",
    price: "$10.01",
    expense: "$5.61",
    transactions: [
      "63bf7ccef03239e002001610",
      "63bf7ccef03239f920001699",
      "63bf7ccef03239966c00169a",
      "63bf7ccef03239e002001611",
      "63bf7ccef032394cbd00169f",
    ],
  },
  {
    _id: "63bf7ac9f03239cf1b001698",
    price: "$112.78",
    expense: "$6.74",
    transactions: ["63bf7ccef032396a8e00169d", "63bf7ccef032396a8e001695"],
  },
  {
    _id: "63bf7ac9f03239cf1b001699",
    price: "$156.34",
    expense: "$6.26",
    transactions: ["63bf7ccef03239e002001606"],
  },
  {
    _id: "63bf7ac9f03239cf1b00169a",
    price: "$119.34",
    expense: "$8.62",
    transactions: ["63bf7ccef0323975d600169e", "63bf7ccef03239554c00244b"],
  },
  {
    _id: "63bf7ac9f03239cf1b00169b",
    price: "$87.49",
    expense: "$8.74",
    transactions: ["63bf7ccef03239343e0016ac", "63bf7ccef0323937a5001698"],
  },
  {
    _id: "63bf7ac9f03239cf1b00169c",
    price: "$34.52",
    expense: "$2.31",
    transactions: ["63bf7ccef03239aec9001562", "63bf7ccef0323937a500168d"],
  },
  {
    _id: "63bf7ac9f03239cf1b00169d",
    price: "$90.83",
    expense: "$7.83",
    transactions: [
      "63bf7ccef0323975d6001698",
      "63bf7ccef032394f5f002439",
      "63bf7ccef03239e00200160f",
      "63bf7ccef0323975d600169b",
      "63bf7ccef03239aec9001557",
    ],
  },
  {
    _id: "63bf7ac9f03239aec9001551",
    price: "$181.98",
    expense: "$3.02",
    transactions: [
      "63bf7ccef032395ccf001698",
      "63bf7ccef03239343e0016ab",
      "63bf7ccef0323975d60016a2",
      "63bf7ccef03239cf1b0016a7",
    ],
  },
  {
    _id: "63bf7ac9f03239aec9001552",
    price: "$62.17",
    expense: "$9.52",
    transactions: ["63bf7ccef03239855d00155e", "63bf7ccef03239966c00169e"],
  },
  {
    _id: "63bf7ac9f03239aec9001553",
    price: "$83.76",
    expense: "$1.09",
    transactions: [
      "63bf7ccef0323975d60016a3",
      "63bf7ccef032394f5f002444",
      "63bf7ccef03239554c002450",
      "63bf7ccef032395ccf00169b",
    ],
  },
  {
    _id: "63bf7ac9f03239aec9001554",
    price: "$62.47",
    expense: "$2.38",
    transactions: [
      "63bf7ccef03239eb9d0016e4",
      "63bf7ccef03239eb9d0016d0",
      "63bf7ccef03239966c00169b",
      "63bf7ccef03239eb9d0016d5",
      "63bf7ccef0323975d6001698",
    ],
  },
  {
    _id: "63bf7ac9f03239aec9001555",
    price: "$54.68",
    expense: "$5.12",
    transactions: [
      "63bf7ccef032396d89000013",
      "63bf7ccef0323974200016a7",
      "63bf7ccef0323922d2001699",
      "63bf7ccef03239e002001608",
    ],
  },
  {
    _id: "63bf7ac9f03239aec9001556",
    price: "$91.72",
    expense: "$4.73",
    transactions: [
      "63bf7ccef03239cf1b0016a3",
      "63bf7ccef032394f5f00243e",
      "63bf7ccef0323974200016a2",
    ],
  },
  {
    _id: "63bf7ac9f03239554c002443",
    price: "$159.65",
    expense: "$3.79",
    transactions: [
      "63bf7ccef03239554c00244e",
      "63bf7ccef03239855d001561",
      "63bf7ccef032395ccf00169b",
      "63bf7ccef03239343e0016aa",
      "63bf7ccef032394f5f002439",
    ],
  },
  {
    _id: "63bf7ac9f03239554c002444",
    price: "$190.89",
    expense: "$5.24",
    transactions: [
      "63bf7ccef03239343e0016a2",
      "63bf7ccef0323974200016a3",
      "63bf7ccef0323922d200169c",
      "63bf7ccef03239966c00169e",
      "63bf7ccef03239cf1b0016a7",
    ],
  },
  {
    _id: "63bf7ac9f03239554c002445",
    price: "$155.13",
    expense: "$1.68",
    transactions: [
      "63bf7ccef03239cf1b00169f",
      "63bf7ccef03239966c00169f",
      "63bf7ccef0323937a5001697",
      "63bf7ccef0323975d60016a2",
    ],
  },
  {
    _id: "63bf7ac9f03239554c002446",
    price: "$43.82",
    expense: "$7.85",
    transactions: ["63bf7ccef03239eb9d0016dd", "63bf7ccef0323901e6001557"],
  },
  {
    _id: "63bf7ac9f03239554c002447",
    price: "$58.31",
    expense: "$9.94",
    transactions: [
      "63bf7ccef03239cf1b0016a6",
      "63bf7ccef03239966c00169a",
      "63bf7ccef0323974200016a1",
      "63bf7ccef0323975d600169c",
    ],
  },
  {
    _id: "63bf7ac9f03239554c002448",
    price: "$138.47",
    expense: "$6.40",
    transactions: [
      "63bf7ccef032397b4f001606",
      "63bf7ccef03239f920001691",
      "63bf7ccef03239966c001698",
    ],
  },
  {
    _id: "63bf7ac9f032397420001696",
    price: "$10.40",
    expense: "$3.20",
    transactions: [
      "63bf7ccef03239966c00169a",
      "63bf7ccef032396d89000013",
      "63bf7ccef03239eb9d0016d6",
      "63bf7ccef032396a8e00169e",
    ],
  },
  {
    _id: "63bf7ac9f032397420001697",
    price: "$22.48",
    expense: "$5.36",
    transactions: ["63bf7ccef03239cf1b0016a6", "63bf7ccef03239eb9d0016df"],
  },
  {
    _id: "63bf7ac9f032397420001698",
    price: "$92.16",
    expense: "$9.12",
    transactions: [
      "63bf7ccef03239cf1b0016a9",
      "63bf7ccef032396d89000015",
      "63bf7ccef03239f920001691",
      "63bf7ccef03239f920001699",
    ],
  },
  {
    _id: "63bf7ac9f032397420001699",
    price: "$140.77",
    expense: "$9.02",
    transactions: [
      "63bf7ccef0323974200016a2",
      "63bf7ccef032395ccf00169c",
      "63bf7ccef0323937a500168d",
    ],
  },
  {
    _id: "63bf7ac9f03239742000169a",
    price: "$100.62",
    expense: "$6.55",
    transactions: [
      "63bf7ccef032394f5f002442",
      "63bf7ccef0323922d2001695",
      "63bf7ccef032395ccf001696",
      "63bf7ccef0323974200016a7",
    ],
  },
  {
    _id: "63bf7ac9f03239742000169b",
    price: "$69.13",
    expense: "$6.26",
    transactions: [
      "63bf7ccef03239eb9d0016d0",
      "63bf7ccef0323975d600169c",
      "63bf7ccef03239cf1b0016a8",
    ],
  },
  {
    _id: "63bf7ac9f03239966c001692",
    price: "$124.82",
    expense: "$8.49",
    transactions: [
      "63bf7ccef03239eb9d0016de",
      "63bf7ccef03239eb9d0016d3",
      "63bf7ccef0323974200016a7",
      "63bf7ccef032396d8900000e",
      "63bf7ccef032397b4f001607",
    ],
  },
  {
    _id: "63bf7ac9f03239966c001693",
    price: "$196.68",
    expense: "$3.32",
    transactions: [
      "63bf7ccef03239554c002452",
      "63bf7ccef0323922d2001698",
      "63bf7ccef03239aec9001562",
      "63bf7ccef03239855d00155f",
      "63bf7ccef0323901e600155f",
    ],
  },
  {
    _id: "63bf7ac9f03239966c001694",
    price: "$172.48",
    expense: "$2.38",
    transactions: [
      "63bf7ccef032394cbd0016a0",
      "63bf7ccef03239343e0016aa",
      "63bf7ccef032395ccf00169a",
      "63bf7ccef03239554c00244d",
      "63bf7ccef032396a8e001699",
    ],
  },
  {
    _id: "63bf7ac9f03239966c001695",
    price: "$112.84",
    expense: "$4.34",
    transactions: ["63bf7ccef032396d89000013", "63bf7ccef03239554c00244c"],
  },
  {
    _id: "63bf7ac9f03239966c001696",
    price: "$37.97",
    expense: "$5.76",
    transactions: [
      "63bf7ccef032394f5f002440",
      "63bf7ccef03239eb9d0016df",
      "63bf7ccef0323937a5001694",
      "63bf7ccef032396a8e001698",
      "63bf7ccef032396a8e001696",
    ],
  },
  {
    _id: "63bf7ac9f03239966c001697",
    price: "$123.72",
    expense: "$2.85",
    transactions: [
      "63bf7ccef03239eb9d0016e3",
      "63bf7ccef0323974200016a6",
      "63bf7ccef0323937a5001691",
    ],
  },
  {
    _id: "63bf7ac9f0323975d6001692",
    price: "$15.02",
    expense: "$2.00",
    transactions: ["63bf7ccef032396a8e001697", "63bf7ccef0323975d6001698"],
  },
  {
    _id: "63bf7ac9f0323975d6001693",
    price: "$19.69",
    expense: "$2.65",
    transactions: ["63bf7ccef0323937a500168e"],
  },
  {
    _id: "63bf7ac9f0323975d6001694",
    price: "$30.80",
    expense: "$5.02",
    transactions: [
      "63bf7ccef0323975d600169f",
      "63bf7ccef032394f5f002440",
      "63bf7ccef032396a8e001695",
      "63bf7ccef03239742000169c",
    ],
  },
  {
    _id: "63bf7ac9f0323975d6001695",
    price: "$90.56",
    expense: "$2.93",
    transactions: ["63bf7ccef0323975d600169d"],
  },
  {
    _id: "63bf7ac9f0323975d6001696",
    price: "$30.37",
    expense: "$8.63",
    transactions: [
      "63bf7ccef0323922d200169c",
      "63bf7ccef0323974200016a5",
      "63bf7ccef032394f5f002443",
    ],
  },
  {
    _id: "63bf7ac9f0323975d6001697",
    price: "$149.26",
    expense: "$1.33",
    transactions: [
      "63bf7ccef03239554c00244d",
      "63bf7ccef03239e002001606",
      "63bf7ccef03239eb9d0016d6",
      "63bf7ccef03239966c00169e",
    ],
  },
  {
    _id: "63bf7ac9f032396a8e00168e",
    price: "$167.45",
    expense: "$9.30",
    transactions: [
      "63bf7ccef0323937a5001693",
      "63bf7ccef03239343e0016a9",
      "63bf7ccef032397b4f001608",
    ],
  },
  {
    _id: "63bf7ac9f032396a8e00168f",
    price: "$50.36",
    expense: "$4.38",
    transactions: ["63bf7ccef032394f5f00243f"],
  },
  {
    _id: "63bf7ac9f032396a8e001690",
    price: "$151.61",
    expense: "$2.27",
    transactions: ["63bf7ccef03239966c001699", "63bf7ccef03239742000169f"],
  },
  {
    _id: "63bf7ac9f032396a8e001691",
    price: "$191.46",
    expense: "$5.60",
    transactions: ["63bf7ccef0323901e600155f"],
  },
  {
    _id: "63bf7ac9f032396a8e001692",
    price: "$131.96",
    expense: "$8.80",
    transactions: [
      "63bf7ccef032397b4f001605",
      "63bf7ccef03239855d001561",
      "63bf7ccef03239eb9d0016d9",
      "63bf7ccef0323901e6001558",
      "63bf7ccef032394f5f002444",
    ],
  },
  {
    _id: "63bf7ac9f032396a8e001693",
    price: "$164.96",
    expense: "$4.16",
    transactions: ["63bf7ccef03239eb9d0016d8"],
  },
  {
    _id: "63bf7ac9f032395ccf00168e",
    price: "$13.57",
    expense: "$5.18",
    transactions: ["63bf7ccef032395ccf001695"],
  },
  {
    _id: "63bf7ac9f032395ccf00168f",
    price: "$177.57",
    expense: "$7.04",
    transactions: ["63bf7ccef0323922d2001698", "63bf7ccef03239855d00155c"],
  },
  {
    _id: "63bf7ac9f032395ccf001690",
    price: "$172.71",
    expense: "$5.73",
    transactions: ["63bf7ccef03239966c00169d"],
  },
  {
    _id: "63bf7ac9f032395ccf001691",
    price: "$17.48",
    expense: "$4.81",
    transactions: ["63bf7ccef0323974200016a0", "63bf7ccef0323937a5001692"],
  },
  {
    _id: "63bf7ac9f032395ccf001692",
    price: "$54.13",
    expense: "$7.44",
    transactions: ["63bf7ccef0323922d2001694", "63bf7ccef03239eb9d0016df"],
  },
  {
    _id: "63bf7ac9f032395ccf001693",
    price: "$155.93",
    expense: "$9.10",
    transactions: [
      "63bf7ccef03239343e0016a7",
      "63bf7ccef032394f5f002440",
      "63bf7ccef03239cf1b0016a9",
      "63bf7ccef032395ccf00169a",
    ],
  },
  {
    _id: "63bf7ac9f032397b4f0015fb",
    price: "$13.34",
    expense: "$6.17",
    transactions: ["63bf7ccef03239cf1b0016a4"],
  },
  {
    _id: "63bf7ac9f032397b4f0015fc",
    price: "$75.89",
    expense: "$1.11",
    transactions: [
      "63bf7ccef03239aec900155f",
      "63bf7ccef03239966c0016a0",
      "63bf7ccef03239cf1b00169f",
      "63bf7ccef032394f5f002440",
    ],
  },
  {
    _id: "63bf7ac9f032397b4f0015fd",
    price: "$153.85",
    expense: "$6.99",
    transactions: [
      "63bf7ccef03239343e0016a3",
      "63bf7ccef0323974200016a3",
      "63bf7ccef0323975d600169c",
      "63bf7ccef03239742000169c",
      "63bf7ccef03239cf1b0016a3",
    ],
  },
  {
    _id: "63bf7ac9f032397b4f0015fe",
    price: "$187.83",
    expense: "$6.66",
    transactions: [
      "63bf7ccef0323937a5001692",
      "63bf7ccef03239aec9001559",
      "63bf7ccef0323922d200169b",
      "63bf7ccef0323922d200169c",
    ],
  },
  {
    _id: "63bf7ac9f032397b4f0015ff",
    price: "$119.33",
    expense: "$1.40",
    transactions: [
      "63bf7ccef032397b4f00160b",
      "63bf7ccef03239eb9d0016ce",
      "63bf7ccef03239eb9d0016d3",
      "63bf7ccef032397b4f001602",
      "63bf7ccef03239cf1b0016a7",
    ],
  },
  {
    _id: "63bf7ac9f032397b4f001600",
    price: "$54.00",
    expense: "$5.61",
    transactions: ["63bf7ccef03239554c002454", "63bf7ccef032394cbd0016a9"],
  },
  {
    _id: "63bf7ac9f0323922d200168e",
    price: "$144.81",
    expense: "$7.45",
    transactions: [
      "63bf7ccef032395ccf00169b",
      "63bf7ccef03239eb9d0016dd",
      "63bf7ccef03239eb9d0016d4",
    ],
  },
  {
    _id: "63bf7ac9f0323922d200168f",
    price: "$37.04",
    expense: "$5.86",
    transactions: ["63bf7ccef0323974200016a5"],
  },
  {
    _id: "63bf7ac9f0323922d2001690",
    price: "$161.03",
    expense: "$4.48",
    transactions: ["63bf7ccef03239966c00169a"],
  },
  {
    _id: "63bf7ac9f0323922d2001691",
    price: "$73.46",
    expense: "$7.95",
    transactions: [
      "63bf7ccef032395ccf001696",
      "63bf7ccef03239eb9d0016de",
      "63bf7ccef03239f92000168f",
      "63bf7ccef032394f5f00243e",
      "63bf7ccef032394cbd0016a5",
    ],
  },
  {
    _id: "63bf7ac9f0323922d2001692",
    price: "$120.96",
    expense: "$8.90",
    transactions: ["63bf7ccef032396a8e00169e", "63bf7ccef032397b4f001603"],
  },
  {
    _id: "63bf7ac9f0323922d2001693",
    price: "$34.46",
    expense: "$9.25",
    transactions: [
      "63bf7ccef032396d89000012",
      "63bf7ccef03239f920001697",
      "63bf7ccef0323937a5001694",
      "63bf7ccef03239855d00155b",
      "63bf7ccef032395ccf001697",
    ],
  },
  {
    _id: "63bf7ac9f032396d89000007",
    price: "$172.27",
    expense: "$3.92",
    transactions: ["63bf7ccef03239554c00244b"],
  },
  {
    _id: "63bf7ac9f032396d89000008",
    price: "$20.54",
    expense: "$8.12",
    transactions: ["63bf7ccef03239855d001558"],
  },
  {
    _id: "63bf7ac9f032396d89000009",
    price: "$80.31",
    expense: "$7.04",
    transactions: [
      "63bf7ccef0323901e6001558",
      "63bf7ccef0323937a5001693",
      "63bf7ccef03239eb9d0016d9",
    ],
  },
  {
    _id: "63bf7ac9f032396d8900000a",
    price: "$77.67",
    expense: "$4.14",
    transactions: ["63bf7ccef03239343e0016ab"],
  },
  {
    _id: "63bf7ac9f032396d8900000b",
    price: "$47.25",
    expense: "$5.53",
    transactions: ["63bf7ccef03239343e0016a8", "63bf7ccef032394f5f00243e"],
  },
  {
    _id: "63bf7ac9f032396d8900000c",
    price: "$181.02",
    expense: "$7.57",
    transactions: [
      "63bf7ccef0323922d2001698",
      "63bf7ccef03239eb9d0016e3",
      "63bf7ccef032396a8e00169c",
      "63bf7ccef032397b4f001608",
      "63bf7ccef032396d89000013",
    ],
  },
  {
    _id: "63bf7ac9f03239f920001689",
    price: "$67.20",
    expense: "$8.37",
    transactions: [
      "63bf7ccef032396a8e00169b",
      "63bf7ccef03239343e0016a2",
      "63bf7ccef03239966c00169d",
      "63bf7ccef0323922d2001698",
      "63bf7ccef03239855d001561",
    ],
  },
  {
    _id: "63bf7ac9f03239f92000168a",
    price: "$58.15",
    expense: "$3.19",
    transactions: ["63bf7ccef03239f920001690"],
  },
  {
    _id: "63bf7ac9f03239f92000168b",
    price: "$145.65",
    expense: "$3.83",
    transactions: ["63bf7ccef032394cbd0016aa"],
  },
  {
    _id: "63bf7ac9f03239f92000168c",
    price: "$134.08",
    expense: "$7.03",
    transactions: [
      "63bf7ccef032396a8e00169a",
      "63bf7ccef032397b4f001604",
      "63bf7ccef03239eb9d0016d1",
      "63bf7ccef03239cf1b0016a7",
      "63bf7ccef03239eb9d0016ea",
    ],
  },
  {
    _id: "63bf7ac9f03239f92000168d",
    price: "$33.79",
    expense: "$4.54",
    transactions: [
      "63bf7ccef032394cbd0016a1",
      "63bf7ccef0323922d200169d",
      "63bf7ccef03239cf1b0016a1",
      "63bf7ccef0323975d60016a3",
      "63bf7ccef03239aec9001557",
    ],
  },
  {
    _id: "63bf7ac9f03239f92000168e",
    price: "$182.10",
    expense: "$9.54",
    transactions: [
      "63bf7ccef032396a8e001698",
      "63bf7ccef0323922d2001699",
      "63bf7ccef03239742000169c",
      "63bf7ccef03239aec900155d",
    ],
  },
  {
    _id: "63bf7ac9f032394f5f002433",
    price: "$129.93",
    expense: "$1.15",
    transactions: [
      "63bf7ccef0323975d600169a",
      "63bf7ccef032397b4f001604",
      "63bf7ccef03239966c001699",
      "63bf7ccef032397b4f001603",
    ],
  },
  {
    _id: "63bf7ac9f032394f5f002434",
    price: "$148.47",
    expense: "$1.63",
    transactions: [
      "63bf7ccef03239554c00244e",
      "63bf7ccef032395ccf00169f",
      "63bf7ccef0323937a500168d",
      "63bf7ccef0323975d60016a1",
    ],
  },
  {
    _id: "63bf7ac9f032394f5f002435",
    price: "$13.52",
    expense: "$9.76",
    transactions: [
      "63bf7ccef03239343e0016a3",
      "63bf7ccef03239966c00169f",
      "63bf7ccef03239cf1b0016a8",
      "63bf7ccef032395ccf00169d",
    ],
  },
  {
    _id: "63bf7ac9f032394f5f002436",
    price: "$120.88",
    expense: "$5.94",
    transactions: [
      "63bf7ccef03239e00200160d",
      "63bf7ccef03239cf1b0016a1",
      "63bf7ccef032396d8900000f",
    ],
  },
  {
    _id: "63bf7ac9f032394f5f002437",
    price: "$153.54",
    expense: "$9.63",
    transactions: ["63bf7ccef03239eb9d0016d0", "63bf7ccef032396d89000010"],
  },
  {
    _id: "63bf7ac9f032394f5f002438",
    price: "$35.74",
    expense: "$8.71",
    transactions: [
      "63bf7ccef0323975d6001698",
      "63bf7ccef03239554c00244c",
      "63bf7ccef03239cf1b0016a0",
      "63bf7ccef032394f5f002442",
      "63bf7ccef03239f92000168f",
    ],
  },
  {
    _id: "63bf7ac9f0323937a5001687",
    price: "$120.21",
    expense: "$5.00",
    transactions: [
      "63bf7ccef03239554c00244a",
      "63bf7ccef032394cbd0016a1",
      "63bf7ccef03239966c00169e",
      "63bf7ccef03239742000169f",
      "63bf7ccef03239eb9d0016e2",
    ],
  },
  {
    _id: "63bf7ac9f0323937a5001688",
    price: "$180.06",
    expense: "$6.39",
    transactions: ["63bf7ccef03239554c002453"],
  },
  {
    _id: "63bf7ac9f0323937a5001689",
    price: "$92.70",
    expense: "$5.53",
    transactions: [
      "63bf7ccef03239343e0016a9",
      "63bf7ccef03239cf1b0016a1",
      "63bf7ccef032397b4f001609",
    ],
  },
  {
    _id: "63bf7ac9f0323937a500168a",
    price: "$35.39",
    expense: "$2.89",
    transactions: [
      "63bf7ccef0323937a5001694",
      "63bf7ccef03239eb9d0016da",
      "63bf7ccef0323937a5001698",
      "63bf7ccef03239eb9d0016e6",
    ],
  },
  {
    _id: "63bf7ac9f0323937a500168b",
    price: "$159.59",
    expense: "$3.54",
    transactions: [
      "63bf7ccef03239eb9d0016d9",
      "63bf7ccef032396a8e001697",
      "63bf7ccef032394cbd0016aa",
      "63bf7ccef03239f920001693",
    ],
  },
  {
    _id: "63bf7ac9f0323937a500168c",
    price: "$116.66",
    expense: "$5.60",
    transactions: [
      "63bf7ccef03239aec900155a",
      "63bf7ccef032394cbd0016a2",
      "63bf7ccef032396a8e001694",
      "63bf7ccef0323975d60016a0",
    ],
  },
  {
    _id: "63bf7ac9f03239855d001551",
    price: "$19.66",
    expense: "$6.43",
    transactions: [
      "63bf7ccef03239eb9d0016d0",
      "63bf7ccef03239554c00244e",
      "63bf7ccef0323922d2001697",
    ],
  },
  {
    _id: "63bf7ac9f03239855d001552",
    price: "$99.78",
    expense: "$7.03",
    transactions: [
      "63bf7ccef03239eb9d0016cd",
      "63bf7ccef032395ccf001697",
      "63bf7ccef03239855d001558",
      "63bf7ccef03239966c00169c",
      "63bf7ccef0323922d2001696",
    ],
  },
  {
    _id: "63bf7ac9f03239855d001553",
    price: "$145.56",
    expense: "$9.40",
    transactions: ["63bf7ccef0323922d2001694"],
  },
  {
    _id: "63bf7ac9f03239855d001554",
    price: "$133.65",
    expense: "$1.03",
    transactions: [
      "63bf7ccef0323937a5001690",
      "63bf7ccef03239f920001696",
      "63bf7ccef0323901e600155e",
      "63bf7ccef032396d89000018",
      "63bf7ccef0323901e6001560",
    ],
  },
  {
    _id: "63bf7ac9f03239855d001555",
    price: "$174.42",
    expense: "$5.30",
    transactions: ["63bf7ccef03239aec9001558"],
  },
  {
    _id: "63bf7ac9f03239855d001556",
    price: "$77.55",
    expense: "$1.01",
    transactions: ["63bf7ccef03239e002001607"],
  },
  {
    _id: "63bf7ac9f03239eb9d0016c3",
    price: "$103.01",
    expense: "$6.81",
    transactions: [
      "63bf7ccef03239966c001699",
      "63bf7ccef03239554c00244e",
      "63bf7ccef03239eb9d0016cd",
      "63bf7ccef03239cf1b0016a8",
      "63bf7ccef032394f5f00243b",
    ],
  },
  {
    _id: "63bf7ac9f03239eb9d0016c4",
    price: "$88.02",
    expense: "$8.00",
    transactions: ["63bf7ccef0323901e600155f", "63bf7ccef03239eb9d0016e6"],
  },
  {
    _id: "63bf7ac9f03239eb9d0016c5",
    price: "$119.84",
    expense: "$8.95",
    transactions: ["63bf7ccef032395ccf001695", "63bf7ccef03239f920001695"],
  },
  {
    _id: "63bf7ac9f03239eb9d0016c6",
    price: "$18.02",
    expense: "$5.48",
    transactions: [
      "63bf7ccef03239e00200160b",
      "63bf7ccef032395ccf00169a",
      "63bf7ccef03239aec9001560",
      "63bf7ccef0323901e600155e",
      "63bf7ccef03239343e0016a9",
    ],
  },
  {
    _id: "63bf7ac9f03239eb9d0016c7",
    price: "$121.59",
    expense: "$7.27",
    transactions: [
      "63bf7ccef0323975d600169b",
      "63bf7ccef032396a8e00169d",
      "63bf7ccef03239eb9d0016d5",
      "63bf7ccef03239e00200160e",
    ],
  },
  {
    _id: "63bf7ac9f03239eb9d0016c8",
    price: "$42.71",
    expense: "$7.26",
    transactions: ["63bf7ccef032396a8e001696", "63bf7ccef032397b4f001608"],
  },
  {
    _id: "63bf7ac9f03239eb9d0016c9",
    price: "$106.89",
    expense: "$2.49",
    transactions: ["63bf7ccef03239966c00169d"],
  },
  {
    _id: "63bf7ac9f03239eb9d0016ca",
    price: "$162.96",
    expense: "$2.18",
    transactions: [
      "63bf7ccef03239f920001691",
      "63bf7ccef0323937a5001693",
      "63bf7ccef03239f920001694",
      "63bf7ccef0323901e600155b",
    ],
  },
  {
    _id: "63bf7ac9f03239eb9d0016cb",
    price: "$102.90",
    expense: "$1.40",
    transactions: [
      "63bf7ccef03239966c00169f",
      "63bf7ccef032394cbd0016a6",
      "63bf7ccef032395ccf00169d",
      "63bf7ccef032395ccf00169e",
      "63bf7ccef032396a8e001697",
    ],
  },
  {
    _id: "63bf7ac9f03239eb9d0016cc",
    price: "$21.98",
    expense: "$7.86",
    transactions: [
      "63bf7ccef03239343e0016a3",
      "63bf7ccef032397b4f001606",
      "63bf7ccef0323901e6001559",
    ],
  },
];

export const transactions = [
  {
    _id: "63bf7ccef03239e002001606",
    amount: "$154.37",
    buyer: "Jorrie Tidswell",
    productIds: ["63bf7ac9f03239e002001604"],
  },
  {
    _id: "63bf7ccef03239e002001607",
    amount: "$207.45",
    buyer: "Alfonse Surridge",
    productIds: [
      "63bf7ac9f032396a8e00168e",
      "63bf7ac9f032396a8e001693",
      "63bf7ac9f03239855d001555",
      "63bf7ac9f0323922d2001692",
    ],
  },
  {
    _id: "63bf7ccef03239e002001608",
    amount: "$23.74",
    buyer: "Flem Domenc",
    productIds: [
      "63bf7ac9f0323937a5001689",
      "63bf7ac9f032396a8e001692",
      "63bf7ac9f03239343e00169d",
      "63bf7ac9f03239855d001553",
      "63bf7ac9f03239cf1b001698",
    ],
  },
  {
    _id: "63bf7ccef03239e002001609",
    amount: "$156.67",
    buyer: "Jordain Gilberthorpe",
    productIds: [
      "63bf7ac9f0323975d6001692",
      "63bf7ac9f0323937a500168a",
      "63bf7ac9f0323922d2001691",
      "63bf7ac9f03239f92000168b",
    ],
  },
  {
    _id: "63bf7ccef03239e00200160a",
    amount: "$174.95",
    buyer: "Thelma Christoforou",
    productIds: [
      "63bf7ac9f032397b4f0015fd",
      "63bf7ac9f032396a8e001690",
      "63bf7ac9f03239554c002445",
      "63bf7ac9f032395ccf001692",
    ],
  },
  {
    _id: "63bf7ccef03239e00200160b",
    amount: "$245.32",
    buyer: "Mattie Iuorio",
    productIds: [
      "63bf7ac9f03239f92000168e",
      "63bf7ac9f0323975d6001693",
      "63bf7ac9f03239f920001689",
      "63bf7ac9f03239966c001696",
    ],
  },
  {
    _id: "63bf7ccef03239e00200160c",
    amount: "$133.57",
    buyer: "Ancell Valerio",
    productIds: [
      "63bf7ac9f032396a8e00168f",
      "63bf7ac9f03239343e0016a0",
      "63bf7ac9f032394cbd00169c",
    ],
  },
  {
    _id: "63bf7ccef03239e00200160d",
    amount: "$122.49",
    buyer: "Ollie Bolderstone",
    productIds: ["63bf7ac9f03239343e00169f"],
  },
  {
    _id: "63bf7ccef03239e00200160e",
    amount: "$270.23",
    buyer: "Audrey Zimmermeister",
    productIds: ["63bf7ac9f0323901e6001552"],
  },
  {
    _id: "63bf7ccef03239e00200160f",
    amount: "$173.38",
    buyer: "Sibby Iacovolo",
    productIds: [
      "63bf7ac9f032397b4f0015fe",
      "63bf7ac9f03239554c002447",
      "63bf7ac9f0323937a500168c",
      "63bf7ac9f03239e002001605",
      "63bf7ac9f032395ccf00168f",
    ],
  },
  {
    _id: "63bf7ccef03239e002001610",
    amount: "$283.65",
    buyer: "Perry Scarrisbrick",
    productIds: ["63bf7ac9f032395ccf001690", "63bf7ac9f03239343e00169e"],
  },
  {
    _id: "63bf7ccef03239e002001611",
    amount: "$187.80",
    buyer: "Danika Dearth",
    productIds: [
      "63bf7ac9f03239e002001600",
      "63bf7ac9f0323901e6001553",
      "63bf7ac9f03239aec9001554",
      "63bf7ac9f032394f5f002438",
    ],
  },
  {
    _id: "63bf7ccef03239343e0016a1",
    amount: "$233.90",
    buyer: "Jeddy Juschka",
    productIds: ["63bf7ac9f03239aec9001553", "63bf7ac9f03239aec9001554"],
  },
  {
    _id: "63bf7ccef03239343e0016a2",
    amount: "$217.32",
    buyer: "Shelley Ganiford",
    productIds: ["63bf7ac9f032396a8e001691", "63bf7ac9f0323937a500168c"],
  },
  {
    _id: "63bf7ccef03239343e0016a3",
    amount: "$272.71",
    buyer: "Inigo Paddeley",
    productIds: ["63bf7ac9f03239cf1b00169a", "63bf7ac9f03239855d001552"],
  },
  {
    _id: "63bf7ccef03239343e0016a4",
    amount: "$297.98",
    buyer: "Mallorie Handrek",
    productIds: [
      "63bf7ac9f03239f92000168b",
      "63bf7ac9f03239aec9001551",
      "63bf7ac9f03239855d001554",
      "63bf7ac9f03239554c002448",
    ],
  },
  {
    _id: "63bf7ccef03239343e0016a5",
    amount: "$145.37",
    buyer: "Isadora Hollingsby",
    productIds: [
      "63bf7ac9f03239f92000168c",
      "63bf7ac9f0323937a500168a",
      "63bf7ac9f0323901e6001554",
    ],
  },
  {
    _id: "63bf7ccef03239343e0016a6",
    amount: "$297.67",
    buyer: "Claudetta McNally",
    productIds: [
      "63bf7ac9f0323975d6001695",
      "63bf7ac9f032396a8e001690",
      "63bf7ac9f03239e002001604",
    ],
  },
  {
    _id: "63bf7ccef03239343e0016a7",
    amount: "$221.50",
    buyer: "Garek Van Halen",
    productIds: ["63bf7ac9f03239554c002447"],
  },
  {
    _id: "63bf7ccef03239343e0016a8",
    amount: "$296.28",
    buyer: "Rozanna Lochead",
    productIds: [
      "63bf7ac9f03239e002001605",
      "63bf7ac9f032396a8e00168e",
      "63bf7ac9f032394cbd001699",
    ],
  },
  {
    _id: "63bf7ccef03239343e0016a9",
    amount: "$270.66",
    buyer: "Valerie Dooly",
    productIds: [
      "63bf7ac9f032394cbd00169d",
      "63bf7ac9f032395ccf001691",
      "63bf7ac9f03239e002001601",
      "63bf7ac9f032396d89000008",
      "63bf7ac9f0323975d6001693",
    ],
  },
  {
    _id: "63bf7ccef03239343e0016aa",
    amount: "$70.39",
    buyer: "Ronny Dishman",
    productIds: ["63bf7ac9f03239cf1b00169b", "63bf7ac9f0323975d6001692"],
  },
  {
    _id: "63bf7ccef03239343e0016ab",
    amount: "$193.31",
    buyer: "Carita Tampion",
    productIds: [
      "63bf7ac9f03239eb9d0016c5",
      "63bf7ac9f032394cbd00169e",
      "63bf7ac9f032395ccf001693",
    ],
  },
  {
    _id: "63bf7ccef03239343e0016ac",
    amount: "$123.95",
    buyer: "Guido Valentine",
    productIds: ["63bf7ac9f03239cf1b00169c"],
  },
  {
    _id: "63bf7ccef0323901e6001557",
    amount: "$49.37",
    buyer: "Kimberley Glassopp",
    productIds: ["63bf7ac9f032396a8e001692"],
  },
  {
    _id: "63bf7ccef0323901e6001558",
    amount: "$89.87",
    buyer: "Cloris Vasyushkhin",
    productIds: [
      "63bf7ac9f03239343e00169d",
      "63bf7ac9f0323922d2001691",
      "63bf7ac9f032396a8e001693",
      "63bf7ac9f03239343e00169f",
      "63bf7ac9f03239855d001551",
    ],
  },
  {
    _id: "63bf7ccef0323901e6001559",
    amount: "$72.12",
    buyer: "Chevy Richardet",
    productIds: ["63bf7ac9f0323937a5001687"],
  },
  {
    _id: "63bf7ccef0323901e600155a",
    amount: "$271.82",
    buyer: "Ailis Bum",
    productIds: [
      "63bf7ac9f03239554c002448",
      "63bf7ac9f032394cbd001699",
      "63bf7ac9f03239343e00169c",
    ],
  },
  {
    _id: "63bf7ccef0323901e600155b",
    amount: "$282.33",
    buyer: "Florance Flook",
    productIds: [
      "63bf7ac9f03239aec9001554",
      "63bf7ac9f03239f920001689",
      "63bf7ac9f03239f92000168c",
      "63bf7ac9f03239e002001601",
    ],
  },
  {
    _id: "63bf7ccef0323901e600155c",
    amount: "$177.35",
    buyer: "Agna Tresvina",
    productIds: [
      "63bf7ac9f03239742000169b",
      "63bf7ac9f03239966c001695",
      "63bf7ac9f03239966c001694",
      "63bf7ac9f032397420001698",
    ],
  },
  {
    _id: "63bf7ccef0323901e600155d",
    amount: "$20.59",
    buyer: "Lulita Johanssen",
    productIds: [
      "63bf7ac9f03239cf1b001698",
      "63bf7ac9f0323922d2001690",
      "63bf7ac9f032395ccf001693",
      "63bf7ac9f0323937a500168a",
    ],
  },
  {
    _id: "63bf7ccef0323901e600155e",
    amount: "$221.00",
    buyer: "Kally Sapshed",
    productIds: [
      "63bf7ac9f032394f5f002436",
      "63bf7ac9f03239554c002446",
      "63bf7ac9f0323975d6001694",
      "63bf7ac9f03239343e00169b",
      "63bf7ac9f032395ccf001692",
    ],
  },
  {
    _id: "63bf7ccef0323901e600155f",
    amount: "$158.49",
    buyer: "Curcio Vaines",
    productIds: [
      "63bf7ac9f03239eb9d0016c8",
      "63bf7ac9f03239742000169a",
      "63bf7ac9f0323937a5001689",
      "63bf7ac9f03239eb9d0016cc",
      "63bf7ac9f032394f5f002433",
    ],
  },
  {
    _id: "63bf7ccef0323901e6001560",
    amount: "$293.27",
    buyer: "Morgen Benettolo",
    productIds: ["63bf7ac9f03239eb9d0016c7"],
  },
  {
    _id: "63bf7ccef0323901e6001561",
    amount: "$182.51",
    buyer: "Leanor Game",
    productIds: [
      "63bf7ac9f03239aec9001553",
      "63bf7ac9f032396d8900000a",
      "63bf7ac9f032396d89000007",
      "63bf7ac9f03239eb9d0016c4",
    ],
  },
  {
    _id: "63bf7ccef0323901e6001562",
    amount: "$64.71",
    buyer: "Del Coveny",
    productIds: [
      "63bf7ac9f032397b4f0015ff",
      "63bf7ac9f03239cf1b001699",
      "63bf7ac9f03239554c002447",
      "63bf7ac9f03239e002001602",
    ],
  },
  {
    _id: "63bf7ccef032394cbd00169f",
    amount: "$195.92",
    buyer: "Juliette Whiteside",
    productIds: ["63bf7ac9f03239855d001552", "63bf7ac9f032394cbd00169d"],
  },
  {
    _id: "63bf7ccef032394cbd0016a0",
    amount: "$10.22",
    buyer: "Licha Mewis",
    productIds: [
      "63bf7ac9f032394f5f002438",
      "63bf7ac9f03239eb9d0016c3",
      "63bf7ac9f03239e002001604",
    ],
  },
  {
    _id: "63bf7ccef032394cbd0016a1",
    amount: "$176.47",
    buyer: "Freedman Basnett",
    productIds: [
      "63bf7ac9f03239f92000168a",
      "63bf7ac9f0323937a500168a",
      "63bf7ac9f03239742000169a",
      "63bf7ac9f032394cbd00169a",
    ],
  },
  {
    _id: "63bf7ccef032394cbd0016a2",
    amount: "$283.94",
    buyer: "Dex Martinat",
    productIds: [
      "63bf7ac9f03239343e0016a0",
      "63bf7ac9f0323922d200168e",
      "63bf7ac9f03239343e00169d",
    ],
  },
  {
    _id: "63bf7ccef032394cbd0016a3",
    amount: "$135.62",
    buyer: "Mal Hessing",
    productIds: [
      "63bf7ac9f03239f92000168e",
      "63bf7ac9f03239aec9001552",
      "63bf7ac9f03239855d001554",
      "63bf7ac9f03239e002001603",
      "63bf7ac9f0323901e6001556",
    ],
  },
  {
    _id: "63bf7ccef032394cbd0016a4",
    amount: "$87.83",
    buyer: "Kenyon Lake",
    productIds: [
      "63bf7ac9f0323975d6001697",
      "63bf7ac9f03239966c001696",
      "63bf7ac9f03239966c001693",
    ],
  },
  {
    _id: "63bf7ccef032394cbd0016a5",
    amount: "$114.41",
    buyer: "Winifield Ahmed",
    productIds: [
      "63bf7ac9f032397b4f0015fb",
      "63bf7ac9f03239855d001555",
      "63bf7ac9f03239f92000168c",
      "63bf7ac9f03239966c001692",
      "63bf7ac9f03239e002001601",
    ],
  },
  {
    _id: "63bf7ccef032394cbd0016a6",
    amount: "$263.24",
    buyer: "Stace Igglesden",
    productIds: [
      "63bf7ac9f032396d8900000b",
      "63bf7ac9f03239f92000168b",
      "63bf7ac9f032396d89000008",
      "63bf7ac9f03239eb9d0016c4",
    ],
  },
  {
    _id: "63bf7ccef032394cbd0016a7",
    amount: "$117.52",
    buyer: "Jordan Skillern",
    productIds: ["63bf7ac9f0323901e6001555"],
  },
  {
    _id: "63bf7ccef032394cbd0016a8",
    amount: "$64.63",
    buyer: "Yorker Sharphurst",
    productIds: [
      "63bf7ac9f03239343e00169c",
      "63bf7ac9f0323975d6001694",
      "63bf7ac9f03239554c002444",
      "63bf7ac9f032396a8e001690",
      "63bf7ac9f03239cf1b00169d",
    ],
  },
  {
    _id: "63bf7ccef032394cbd0016a9",
    amount: "$180.19",
    buyer: "Stormy Timoney",
    productIds: [
      "63bf7ac9f032394cbd00169c",
      "63bf7ac9f032396a8e001693",
      "63bf7ac9f032395ccf001690",
      "63bf7ac9f0323901e6001552",
    ],
  },
  {
    _id: "63bf7ccef032394cbd0016aa",
    amount: "$70.70",
    buyer: "Reginauld Brinicombe",
    productIds: [
      "63bf7ac9f03239eb9d0016c5",
      "63bf7ac9f03239855d001553",
      "63bf7ac9f03239343e00169b",
    ],
  },
  {
    _id: "63bf7ccef03239cf1b00169e",
    amount: "$49.60",
    buyer: "Benedikt Gibbe",
    productIds: [
      "63bf7ac9f03239eb9d0016c5",
      "63bf7ac9f03239aec9001554",
      "63bf7ac9f0323901e6001556",
    ],
  },
  {
    _id: "63bf7ccef03239cf1b00169f",
    amount: "$64.19",
    buyer: "Elia Kenningham",
    productIds: [
      "63bf7ac9f032397b4f0015fb",
      "63bf7ac9f03239aec9001556",
      "63bf7ac9f03239855d001554",
    ],
  },
  {
    _id: "63bf7ccef03239cf1b0016a0",
    amount: "$275.70",
    buyer: "Kenyon Harbour",
    productIds: [
      "63bf7ac9f03239855d001553",
      "63bf7ac9f03239e002001603",
      "63bf7ac9f03239966c001694",
    ],
  },
  {
    _id: "63bf7ccef03239cf1b0016a1",
    amount: "$92.02",
    buyer: "Willabella Bilbrey",
    productIds: [
      "63bf7ac9f0323901e6001552",
      "63bf7ac9f032396a8e001693",
      "63bf7ac9f03239966c001695",
      "63bf7ac9f032396d8900000b",
      "63bf7ac9f03239e002001605",
    ],
  },
  {
    _id: "63bf7ccef03239cf1b0016a2",
    amount: "$164.62",
    buyer: "Sax Caselli",
    productIds: ["63bf7ac9f032396d8900000a", "63bf7ac9f03239cf1b00169c"],
  },
  {
    _id: "63bf7ccef03239cf1b0016a3",
    amount: "$245.43",
    buyer: "Burr D'Aulby",
    productIds: ["63bf7ac9f03239aec9001552", "63bf7ac9f0323975d6001695"],
  },
  {
    _id: "63bf7ccef03239cf1b0016a4",
    amount: "$266.98",
    buyer: "Maribeth Quiddinton",
    productIds: [
      "63bf7ac9f03239966c001692",
      "63bf7ac9f0323937a5001689",
      "63bf7ac9f032394cbd00169c",
      "63bf7ac9f0323922d2001691",
      "63bf7ac9f03239554c002443",
    ],
  },
  {
    _id: "63bf7ccef03239cf1b0016a5",
    amount: "$121.31",
    buyer: "Doralia Fogg",
    productIds: ["63bf7ac9f03239343e00169d", "63bf7ac9f032394f5f002434"],
  },
  {
    _id: "63bf7ccef03239cf1b0016a6",
    amount: "$282.87",
    buyer: "Gianna Malkinson",
    productIds: [
      "63bf7ac9f03239aec9001551",
      "63bf7ac9f0323975d6001693",
      "63bf7ac9f032396a8e001691",
    ],
  },
  {
    _id: "63bf7ccef03239cf1b0016a7",
    amount: "$86.12",
    buyer: "Daria Ramble",
    productIds: [
      "63bf7ac9f0323937a500168a",
      "63bf7ac9f03239e002001604",
      "63bf7ac9f03239855d001551",
      "63bf7ac9f032395ccf001693",
      "63bf7ac9f032394cbd00169a",
    ],
  },
  {
    _id: "63bf7ccef03239cf1b0016a8",
    amount: "$59.70",
    buyer: "Sile Pettinger",
    productIds: [
      "63bf7ac9f03239e002001601",
      "63bf7ac9f03239855d001552",
      "63bf7ac9f032397420001698",
    ],
  },
  {
    _id: "63bf7ccef03239cf1b0016a9",
    amount: "$87.28",
    buyer: "Barny Aldrick",
    productIds: [
      "63bf7ac9f032397b4f001600",
      "63bf7ac9f03239eb9d0016ca",
      "63bf7ac9f03239554c002447",
      "63bf7ac9f032397b4f0015fc",
    ],
  },
  {
    _id: "63bf7ccef03239aec9001557",
    amount: "$10.68",
    buyer: "Marylee Emanueli",
    productIds: [
      "63bf7ac9f032396d8900000a",
      "63bf7ac9f03239343e00169c",
      "63bf7ac9f03239554c002448",
    ],
  },
  {
    _id: "63bf7ccef03239aec9001558",
    amount: "$116.97",
    buyer: "Skell Rumbold",
    productIds: [
      "63bf7ac9f03239aec9001553",
      "63bf7ac9f0323937a5001688",
      "63bf7ac9f03239cf1b00169d",
      "63bf7ac9f03239eb9d0016c3",
      "63bf7ac9f032394cbd00169c",
    ],
  },
  {
    _id: "63bf7ccef03239aec9001559",
    amount: "$97.54",
    buyer: "Peg Flattman",
    productIds: ["63bf7ac9f03239f92000168a", "63bf7ac9f03239cf1b00169a"],
  },
  {
    _id: "63bf7ccef03239aec900155a",
    amount: "$237.00",
    buyer: "Guenna Allonby",
    productIds: ["63bf7ac9f032397420001698", "63bf7ac9f03239f920001689"],
  },
  {
    _id: "63bf7ccef03239aec900155b",
    amount: "$19.63",
    buyer: "Jaquenette Tunnacliffe",
    productIds: ["63bf7ac9f032394cbd001699", "63bf7ac9f032394f5f002438"],
  },
  {
    _id: "63bf7ccef03239aec900155c",
    amount: "$81.94",
    buyer: "Adelind Bloan",
    productIds: [
      "63bf7ac9f0323975d6001697",
      "63bf7ac9f032394f5f002433",
      "63bf7ac9f032396a8e001690",
      "63bf7ac9f03239cf1b00169b",
    ],
  },
  {
    _id: "63bf7ccef03239aec900155d",
    amount: "$245.16",
    buyer: "Mikaela Murrish",
    productIds: [
      "63bf7ac9f03239e002001605",
      "63bf7ac9f032397b4f0015fd",
      "63bf7ac9f0323975d6001694",
    ],
  },
  {
    _id: "63bf7ccef03239aec900155e",
    amount: "$153.99",
    buyer: "Page Allom",
    productIds: ["63bf7ac9f03239eb9d0016c9", "63bf7ac9f03239343e00169e"],
  },
  {
    _id: "63bf7ccef03239aec900155f",
    amount: "$230.26",
    buyer: "Rafael Haughin",
    productIds: [
      "63bf7ac9f0323975d6001695",
      "63bf7ac9f0323901e6001552",
      "63bf7ac9f03239eb9d0016cc",
      "63bf7ac9f032396a8e001693",
    ],
  },
  {
    _id: "63bf7ccef03239aec9001560",
    amount: "$200.80",
    buyer: "Branden Ossenna",
    productIds: [
      "63bf7ac9f03239eb9d0016cb",
      "63bf7ac9f0323901e6001554",
      "63bf7ac9f032394f5f002437",
      "63bf7ac9f0323901e6001556",
      "63bf7ac9f032396a8e001691",
    ],
  },
  {
    _id: "63bf7ccef03239aec9001561",
    amount: "$170.16",
    buyer: "Mattie Eskrigge",
    productIds: ["63bf7ac9f032395ccf001690"],
  },
  {
    _id: "63bf7ccef03239aec9001562",
    amount: "$45.08",
    buyer: "Sebastian Bonavia",
    productIds: ["63bf7ac9f032394f5f002436", "63bf7ac9f03239aec9001555"],
  },
  {
    _id: "63bf7ccef03239554c002449",
    amount: "$221.06",
    buyer: "Moyna O'Growgane",
    productIds: [
      "63bf7ac9f0323975d6001694",
      "63bf7ac9f03239554c002446",
      "63bf7ac9f0323901e6001554",
      "63bf7ac9f032394cbd00169a",
    ],
  },
  {
    _id: "63bf7ccef03239554c00244a",
    amount: "$117.84",
    buyer: "Silvan Leisman",
    productIds: [
      "63bf7ac9f0323901e6001553",
      "63bf7ac9f032394cbd00169e",
      "63bf7ac9f03239cf1b001699",
      "63bf7ac9f032395ccf001691",
    ],
  },
  {
    _id: "63bf7ccef03239554c00244b",
    amount: "$18.26",
    buyer: "Efren Mabley",
    productIds: [
      "63bf7ac9f0323901e6001556",
      "63bf7ac9f032397b4f0015fd",
      "63bf7ac9f03239f920001689",
    ],
  },
  {
    _id: "63bf7ccef03239554c00244c",
    amount: "$108.19",
    buyer: "Si Aime",
    productIds: [
      "63bf7ac9f0323937a5001688",
      "63bf7ac9f03239eb9d0016c9",
      "63bf7ac9f03239966c001692",
    ],
  },
  {
    _id: "63bf7ccef03239554c00244d",
    amount: "$275.91",
    buyer: "Deedee Gebbe",
    productIds: [
      "63bf7ac9f03239f92000168b",
      "63bf7ac9f03239966c001693",
      "63bf7ac9f03239e002001603",
      "63bf7ac9f03239eb9d0016c6",
    ],
  },
  {
    _id: "63bf7ccef03239554c00244e",
    amount: "$274.98",
    buyer: "Arlinda Smaile",
    productIds: [
      "63bf7ac9f032396d8900000c",
      "63bf7ac9f032396d89000009",
      "63bf7ac9f0323937a5001689",
      "63bf7ac9f032394cbd00169c",
    ],
  },
  {
    _id: "63bf7ccef03239554c00244f",
    amount: "$215.93",
    buyer: "Jareb Bayle",
    productIds: [
      "63bf7ac9f0323922d2001692",
      "63bf7ac9f03239eb9d0016c5",
      "63bf7ac9f03239f92000168e",
      "63bf7ac9f03239eb9d0016c8",
    ],
  },
  {
    _id: "63bf7ccef03239554c002450",
    amount: "$270.00",
    buyer: "Kermie Goldhill",
    productIds: ["63bf7ac9f032396a8e00168f", "63bf7ac9f0323922d2001690"],
  },
  {
    _id: "63bf7ccef03239554c002451",
    amount: "$183.78",
    buyer: "Birch Morch",
    productIds: [
      "63bf7ac9f032395ccf00168e",
      "63bf7ac9f032397420001696",
      "63bf7ac9f03239f92000168a",
      "63bf7ac9f032397420001698",
    ],
  },
  {
    _id: "63bf7ccef03239554c002452",
    amount: "$96.80",
    buyer: "Alvis Rowthorne",
    productIds: [
      "63bf7ac9f0323975d6001696",
      "63bf7ac9f03239e002001605",
      "63bf7ac9f03239554c002447",
      "63bf7ac9f0323937a5001687",
    ],
  },
  {
    _id: "63bf7ccef03239554c002453",
    amount: "$91.98",
    buyer: "Roger Gouldthorpe",
    productIds: [
      "63bf7ac9f03239343e00169c",
      "63bf7ac9f0323922d2001691",
      "63bf7ac9f032395ccf00168f",
      "63bf7ac9f03239aec9001554",
      "63bf7ac9f03239f92000168d",
    ],
  },
  {
    _id: "63bf7ccef03239554c002454",
    amount: "$224.12",
    buyer: "Patric Pounds",
    productIds: [
      "63bf7ac9f032397b4f0015fe",
      "63bf7ac9f03239343e00169d",
      "63bf7ac9f032396a8e001692",
    ],
  },
  {
    _id: "63bf7ccef03239742000169c",
    amount: "$282.34",
    buyer: "Dillie Gaine",
    productIds: [
      "63bf7ac9f032397420001699",
      "63bf7ac9f032397b4f0015fe",
      "63bf7ac9f03239aec9001553",
      "63bf7ac9f032396d8900000a",
      "63bf7ac9f032394cbd00169a",
    ],
  },
  {
    _id: "63bf7ccef03239742000169d",
    amount: "$238.02",
    buyer: "Shelagh Gammidge",
    productIds: [
      "63bf7ac9f03239f92000168e",
      "63bf7ac9f03239aec9001554",
      "63bf7ac9f0323975d6001696",
      "63bf7ac9f03239aec9001556",
    ],
  },
  {
    _id: "63bf7ccef03239742000169e",
    amount: "$91.34",
    buyer: "Lammond Candy",
    productIds: ["63bf7ac9f03239554c002447", "63bf7ac9f03239966c001697"],
  },
  {
    _id: "63bf7ccef03239742000169f",
    amount: "$25.43",
    buyer: "Regan Warwick",
    productIds: [
      "63bf7ac9f032395ccf00168f",
      "63bf7ac9f03239554c002443",
      "63bf7ac9f03239cf1b001698",
    ],
  },
  {
    _id: "63bf7ccef0323974200016a0",
    amount: "$195.00",
    buyer: "Teodoor Conigsby",
    productIds: [
      "63bf7ac9f03239855d001553",
      "63bf7ac9f03239e002001600",
      "63bf7ac9f032395ccf001691",
    ],
  },
  {
    _id: "63bf7ccef0323974200016a1",
    amount: "$192.42",
    buyer: "Brooke Musgrave",
    productIds: [
      "63bf7ac9f03239eb9d0016c3",
      "63bf7ac9f03239966c001692",
      "63bf7ac9f0323937a500168b",
      "63bf7ac9f03239855d001556",
    ],
  },
  {
    _id: "63bf7ccef0323974200016a2",
    amount: "$41.76",
    buyer: "Ado Gerran",
    productIds: [
      "63bf7ac9f032394f5f002435",
      "63bf7ac9f0323975d6001697",
      "63bf7ac9f032397b4f0015fc",
    ],
  },
  {
    _id: "63bf7ccef0323974200016a3",
    amount: "$96.17",
    buyer: "Hadria Freake",
    productIds: [
      "63bf7ac9f032394cbd001699",
      "63bf7ac9f03239f920001689",
      "63bf7ac9f03239554c002448",
      "63bf7ac9f03239855d001551",
    ],
  },
  {
    _id: "63bf7ccef0323974200016a4",
    amount: "$159.67",
    buyer: "Waylon Valentinuzzi",
    productIds: [
      "63bf7ac9f0323922d2001692",
      "63bf7ac9f032394f5f002437",
      "63bf7ac9f03239343e00169f",
      "63bf7ac9f03239eb9d0016c4",
      "63bf7ac9f03239eb9d0016cc",
    ],
  },
  {
    _id: "63bf7ccef0323974200016a5",
    amount: "$102.35",
    buyer: "Sal Bysshe",
    productIds: [
      "63bf7ac9f032396a8e001690",
      "63bf7ac9f03239eb9d0016c6",
      "63bf7ac9f03239cf1b00169c",
      "63bf7ac9f03239742000169b",
      "63bf7ac9f032396d8900000b",
    ],
  },
  {
    _id: "63bf7ccef0323974200016a6",
    amount: "$125.85",
    buyer: "Florian Brookbank",
    productIds: ["63bf7ac9f0323975d6001693", "63bf7ac9f032396a8e001692"],
  },
  {
    _id: "63bf7ccef0323974200016a7",
    amount: "$264.64",
    buyer: "Aylmer Klimentyev",
    productIds: ["63bf7ac9f03239e002001604"],
  },
  {
    _id: "63bf7ccef03239966c001698",
    amount: "$274.87",
    buyer: "Sancho Sleaford",
    productIds: [
      "63bf7ac9f0323975d6001694",
      "63bf7ac9f032397b4f0015fe",
      "63bf7ac9f0323922d200168f",
      "63bf7ac9f03239eb9d0016c5",
    ],
  },
  {
    _id: "63bf7ccef03239966c001699",
    amount: "$220.00",
    buyer: "Evelin Oneile",
    productIds: [
      "63bf7ac9f032394f5f002433",
      "63bf7ac9f0323937a500168c",
      "63bf7ac9f032396a8e001692",
    ],
  },
  {
    _id: "63bf7ccef03239966c00169a",
    amount: "$277.71",
    buyer: "Larine Couvet",
    productIds: ["63bf7ac9f03239966c001692"],
  },
  {
    _id: "63bf7ccef03239966c00169b",
    amount: "$134.74",
    buyer: "Leonanie Juliff",
    productIds: ["63bf7ac9f03239aec9001553"],
  },
  {
    _id: "63bf7ccef03239966c00169c",
    amount: "$266.52",
    buyer: "Aubine Giacomucci",
    productIds: [
      "63bf7ac9f03239554c002443",
      "63bf7ac9f032397420001697",
      "63bf7ac9f0323937a5001687",
      "63bf7ac9f03239eb9d0016c9",
    ],
  },
  {
    _id: "63bf7ccef03239966c00169d",
    amount: "$142.48",
    buyer: "Obed Garm",
    productIds: [
      "63bf7ac9f0323901e6001556",
      "63bf7ac9f032396a8e001693",
      "63bf7ac9f03239eb9d0016c4",
      "63bf7ac9f032396d89000009",
    ],
  },
  {
    _id: "63bf7ccef03239966c00169e",
    amount: "$59.95",
    buyer: "Arie Yardy",
    productIds: [
      "63bf7ac9f03239aec9001556",
      "63bf7ac9f032395ccf001690",
      "63bf7ac9f032396d89000007",
      "63bf7ac9f03239855d001553",
      "63bf7ac9f03239554c002448",
    ],
  },
  {
    _id: "63bf7ccef03239966c00169f",
    amount: "$282.21",
    buyer: "Peter Gallager",
    productIds: [
      "63bf7ac9f03239f920001689",
      "63bf7ac9f032397b4f0015fb",
      "63bf7ac9f03239554c002446",
      "63bf7ac9f0323922d2001692",
    ],
  },
  {
    _id: "63bf7ccef03239966c0016a0",
    amount: "$59.31",
    buyer: "Freemon Hargey",
    productIds: [
      "63bf7ac9f03239cf1b00169b",
      "63bf7ac9f032396d8900000b",
      "63bf7ac9f03239554c002445",
      "63bf7ac9f03239554c002447",
      "63bf7ac9f03239855d001554",
    ],
  },
  {
    _id: "63bf7ccef03239966c0016a1",
    amount: "$202.58",
    buyer: "Libby Dannett",
    productIds: [
      "63bf7ac9f0323975d6001695",
      "63bf7ac9f03239aec9001554",
      "63bf7ac9f03239554c002444",
    ],
  },
  {
    _id: "63bf7ccef03239966c0016a2",
    amount: "$106.57",
    buyer: "Claudetta Kinnett",
    productIds: ["63bf7ac9f0323975d6001697"],
  },
  {
    _id: "63bf7ccef03239966c0016a3",
    amount: "$292.34",
    buyer: "Taryn Shankland",
    productIds: ["63bf7ac9f03239742000169b", "63bf7ac9f032397b4f0015fc"],
  },
  {
    _id: "63bf7ccef0323975d6001698",
    amount: "$178.53",
    buyer: "Roxanne Impson",
    productIds: ["63bf7ac9f03239cf1b00169b", "63bf7ac9f032394cbd001699"],
  },
  {
    _id: "63bf7ccef0323975d6001699",
    amount: "$262.23",
    buyer: "Vyky Rumbelow",
    productIds: [
      "63bf7ac9f03239aec9001555",
      "63bf7ac9f032394f5f002437",
      "63bf7ac9f0323975d6001694",
      "63bf7ac9f03239e002001605",
      "63bf7ac9f03239cf1b00169d",
    ],
  },
  {
    _id: "63bf7ccef0323975d600169a",
    amount: "$91.65",
    buyer: "Ethelred Layne",
    productIds: ["63bf7ac9f032394cbd00169d", "63bf7ac9f032394f5f002436"],
  },
  {
    _id: "63bf7ccef0323975d600169b",
    amount: "$127.72",
    buyer: "Sigismund Coombes",
    productIds: [
      "63bf7ac9f0323922d2001693",
      "63bf7ac9f03239eb9d0016c9",
      "63bf7ac9f032397b4f0015fb",
    ],
  },
  {
    _id: "63bf7ccef0323975d600169c",
    amount: "$32.05",
    buyer: "Lacey Clemanceau",
    productIds: ["63bf7ac9f03239855d001556", "63bf7ac9f032397b4f001600"],
  },
  {
    _id: "63bf7ccef0323975d600169d",
    amount: "$130.53",
    buyer: "Monroe Matthewson",
    productIds: ["63bf7ac9f0323922d2001690", "63bf7ac9f0323937a5001687"],
  },
  {
    _id: "63bf7ccef0323975d600169e",
    amount: "$82.41",
    buyer: "Martita Kinze",
    productIds: [
      "63bf7ac9f032397420001699",
      "63bf7ac9f032395ccf001693",
      "63bf7ac9f03239e002001600",
    ],
  },
  {
    _id: "63bf7ccef0323975d600169f",
    amount: "$242.59",
    buyer: "Suzette Tonkes",
    productIds: ["63bf7ac9f03239aec9001551"],
  },
  {
    _id: "63bf7ccef0323975d60016a0",
    amount: "$282.57",
    buyer: "Clare Torns",
    productIds: ["63bf7ac9f03239eb9d0016c6"],
  },
  {
    _id: "63bf7ccef0323975d60016a1",
    amount: "$193.44",
    buyer: "Silvia Harrap",
    productIds: ["63bf7ac9f03239cf1b00169a", "63bf7ac9f03239e002001602"],
  },
  {
    _id: "63bf7ccef0323975d60016a2",
    amount: "$41.09",
    buyer: "Megen Manicomb",
    productIds: [
      "63bf7ac9f03239742000169a",
      "63bf7ac9f0323975d6001695",
      "63bf7ac9f03239f92000168a",
      "63bf7ac9f03239855d001554",
      "63bf7ac9f03239554c002445",
    ],
  },
  {
    _id: "63bf7ccef0323975d60016a3",
    amount: "$167.97",
    buyer: "Lacee Dyche",
    productIds: [
      "63bf7ac9f03239aec9001554",
      "63bf7ac9f032395ccf001691",
      "63bf7ac9f03239742000169b",
    ],
  },
  {
    _id: "63bf7ccef032396a8e001694",
    amount: "$89.43",
    buyer: "Ravi Morrant",
    productIds: ["63bf7ac9f032397b4f0015fb"],
  },
  {
    _id: "63bf7ccef032396a8e001695",
    amount: "$185.00",
    buyer: "Ida Rolles",
    productIds: ["63bf7ac9f032397b4f001600", "63bf7ac9f032397420001698"],
  },
  {
    _id: "63bf7ccef032396a8e001696",
    amount: "$58.82",
    buyer: "Melicent Tembridge",
    productIds: ["63bf7ac9f0323937a500168a"],
  },
  {
    _id: "63bf7ccef032396a8e001697",
    amount: "$57.02",
    buyer: "Gerald Egell",
    productIds: [
      "63bf7ac9f03239cf1b001698",
      "63bf7ac9f03239343e00169c",
      "63bf7ac9f0323975d6001694",
      "63bf7ac9f032397b4f0015ff",
    ],
  },
  {
    _id: "63bf7ccef032396a8e001698",
    amount: "$78.59",
    buyer: "Lorelei Siebart",
    productIds: [
      "63bf7ac9f0323975d6001697",
      "63bf7ac9f0323901e6001555",
      "63bf7ac9f032394f5f002433",
      "63bf7ac9f03239aec9001555",
      "63bf7ac9f032394f5f002437",
    ],
  },
  {
    _id: "63bf7ccef032396a8e001699",
    amount: "$297.89",
    buyer: "Cullie Lampens",
    productIds: [
      "63bf7ac9f032396a8e001691",
      "63bf7ac9f032394cbd00169d",
      "63bf7ac9f03239855d001556",
      "63bf7ac9f0323901e6001554",
      "63bf7ac9f032395ccf001690",
    ],
  },
  {
    _id: "63bf7ccef032396a8e00169a",
    amount: "$242.06",
    buyer: "Cora Pasque",
    productIds: [
      "63bf7ac9f03239343e00169f",
      "63bf7ac9f032396d8900000b",
      "63bf7ac9f03239aec9001554",
      "63bf7ac9f032394f5f002436",
    ],
  },
  {
    _id: "63bf7ccef032396a8e00169b",
    amount: "$26.01",
    buyer: "Lou Singyard",
    productIds: [
      "63bf7ac9f0323901e6001552",
      "63bf7ac9f0323975d6001693",
      "63bf7ac9f032394f5f002434",
    ],
  },
  {
    _id: "63bf7ccef032396a8e00169c",
    amount: "$123.88",
    buyer: "Patsy Cargill",
    productIds: [
      "63bf7ac9f032394cbd001699",
      "63bf7ac9f03239eb9d0016ca",
      "63bf7ac9f032396d8900000a",
      "63bf7ac9f03239554c002445",
    ],
  },
  {
    _id: "63bf7ccef032396a8e00169d",
    amount: "$221.16",
    buyer: "Naoma Abrams",
    productIds: [
      "63bf7ac9f03239742000169b",
      "63bf7ac9f03239554c002446",
      "63bf7ac9f03239cf1b00169d",
    ],
  },
  {
    _id: "63bf7ccef032396a8e00169e",
    amount: "$55.67",
    buyer: "Susann Dri",
    productIds: [
      "63bf7ac9f032394cbd00169a",
      "63bf7ac9f03239eb9d0016c6",
      "63bf7ac9f032396d89000007",
    ],
  },
  {
    _id: "63bf7ccef032396a8e00169f",
    amount: "$79.87",
    buyer: "Billye Durward",
    productIds: [
      "63bf7ac9f032397b4f0015fc",
      "63bf7ac9f03239eb9d0016c9",
      "63bf7ac9f0323975d6001692",
      "63bf7ac9f03239e002001602",
      "63bf7ac9f03239f92000168e",
    ],
  },
  {
    _id: "63bf7ccef032395ccf001694",
    amount: "$216.01",
    buyer: "Thadeus Meredith",
    productIds: [
      "63bf7ac9f03239f92000168a",
      "63bf7ac9f032394f5f002433",
      "63bf7ac9f03239eb9d0016c8",
      "63bf7ac9f032396d8900000a",
    ],
  },
  {
    _id: "63bf7ccef032395ccf001695",
    amount: "$69.63",
    buyer: "Isiahi MacMorland",
    productIds: ["63bf7ac9f03239f92000168d", "63bf7ac9f032396a8e001693"],
  },
  {
    _id: "63bf7ccef032395ccf001696",
    amount: "$204.00",
    buyer: "Jaquelin Thomson",
    productIds: [
      "63bf7ac9f03239aec9001554",
      "63bf7ac9f03239aec9001555",
      "63bf7ac9f0323937a500168b",
      "63bf7ac9f032396a8e001690",
      "63bf7ac9f03239e002001602",
    ],
  },
  {
    _id: "63bf7ccef032395ccf001697",
    amount: "$283.84",
    buyer: "Page Quiney",
    productIds: [
      "63bf7ac9f03239eb9d0016c4",
      "63bf7ac9f03239aec9001552",
      "63bf7ac9f03239cf1b00169b",
    ],
  },
  {
    _id: "63bf7ccef032395ccf001698",
    amount: "$32.76",
    buyer: "Stephana Bulcroft",
    productIds: [
      "63bf7ac9f03239742000169a",
      "63bf7ac9f032397b4f0015fc",
      "63bf7ac9f03239554c002447",
      "63bf7ac9f0323922d200168e",
      "63bf7ac9f0323937a500168c",
    ],
  },
  {
    _id: "63bf7ccef032395ccf001699",
    amount: "$34.52",
    buyer: "Desdemona Zukierman",
    productIds: [
      "63bf7ac9f032396d8900000c",
      "63bf7ac9f032397b4f0015fd",
      "63bf7ac9f032394f5f002438",
      "63bf7ac9f0323975d6001696",
      "63bf7ac9f03239343e00169c",
    ],
  },
  {
    _id: "63bf7ccef032395ccf00169a",
    amount: "$248.19",
    buyer: "Fredericka Ayliffe",
    productIds: [
      "63bf7ac9f032397420001696",
      "63bf7ac9f03239eb9d0016ca",
      "63bf7ac9f032395ccf001690",
      "63bf7ac9f03239e002001600",
    ],
  },
  {
    _id: "63bf7ccef032395ccf00169b",
    amount: "$115.61",
    buyer: "Rouvin Breit",
    productIds: [
      "63bf7ac9f03239e002001601",
      "63bf7ac9f03239966c001695",
      "63bf7ac9f032394cbd00169e",
      "63bf7ac9f032394f5f002436",
    ],
  },
  {
    _id: "63bf7ccef032395ccf00169c",
    amount: "$158.54",
    buyer: "Marlyn Packington",
    productIds: [
      "63bf7ac9f03239cf1b00169d",
      "63bf7ac9f032394f5f002434",
      "63bf7ac9f03239f92000168b",
      "63bf7ac9f032396a8e00168f",
    ],
  },
  {
    _id: "63bf7ccef032395ccf00169d",
    amount: "$189.62",
    buyer: "Ric Heasly",
    productIds: ["63bf7ac9f032395ccf001692", "63bf7ac9f03239eb9d0016cb"],
  },
  {
    _id: "63bf7ccef032395ccf00169e",
    amount: "$215.68",
    buyer: "Rhoda Furst",
    productIds: ["63bf7ac9f03239aec9001551"],
  },
  {
    _id: "63bf7ccef032395ccf00169f",
    amount: "$264.99",
    buyer: "Khalil Climpson",
    productIds: ["63bf7ac9f0323922d2001690"],
  },
  {
    _id: "63bf7ccef032397b4f001601",
    amount: "$218.65",
    buyer: "Padgett Brimelow",
    productIds: [
      "63bf7ac9f0323901e6001552",
      "63bf7ac9f03239eb9d0016c6",
      "63bf7ac9f0323922d2001692",
      "63bf7ac9f03239f92000168b",
      "63bf7ac9f03239aec9001553",
    ],
  },
  {
    _id: "63bf7ccef032397b4f001602",
    amount: "$267.79",
    buyer: "Nariko Grelka",
    productIds: [
      "63bf7ac9f03239554c002445",
      "63bf7ac9f032397420001696",
      "63bf7ac9f0323975d6001694",
    ],
  },
  {
    _id: "63bf7ccef032397b4f001603",
    amount: "$228.64",
    buyer: "Prescott Bax",
    productIds: [
      "63bf7ac9f032396a8e001690",
      "63bf7ac9f032397b4f0015fc",
      "63bf7ac9f032394f5f002437",
    ],
  },
  {
    _id: "63bf7ccef032397b4f001604",
    amount: "$141.93",
    buyer: "Olva Curtois",
    productIds: [
      "63bf7ac9f03239855d001553",
      "63bf7ac9f03239f920001689",
      "63bf7ac9f03239cf1b00169d",
      "63bf7ac9f032397420001697",
      "63bf7ac9f0323975d6001693",
    ],
  },
  {
    _id: "63bf7ccef032397b4f001605",
    amount: "$87.23",
    buyer: "Diann Firby",
    productIds: ["63bf7ac9f03239554c002444"],
  },
  {
    _id: "63bf7ccef032397b4f001606",
    amount: "$157.51",
    buyer: "Poppy Rankcom",
    productIds: [
      "63bf7ac9f03239cf1b00169a",
      "63bf7ac9f0323937a500168c",
      "63bf7ac9f03239cf1b00169b",
      "63bf7ac9f032396a8e001693",
    ],
  },
  {
    _id: "63bf7ccef032397b4f001607",
    amount: "$234.45",
    buyer: "Ardath Bulgen",
    productIds: ["63bf7ac9f0323937a500168b"],
  },
  {
    _id: "63bf7ccef032397b4f001608",
    amount: "$92.16",
    buyer: "Jacinta Swede",
    productIds: ["63bf7ac9f0323975d6001695"],
  },
  {
    _id: "63bf7ccef032397b4f001609",
    amount: "$10.17",
    buyer: "Obadias Matyashev",
    productIds: [
      "63bf7ac9f03239eb9d0016c3",
      "63bf7ac9f032397420001698",
      "63bf7ac9f03239855d001555",
      "63bf7ac9f032397420001699",
    ],
  },
  {
    _id: "63bf7ccef032397b4f00160a",
    amount: "$65.15",
    buyer: "Hill McElwee",
    productIds: [
      "63bf7ac9f0323901e6001553",
      "63bf7ac9f0323975d6001696",
      "63bf7ac9f03239554c002443",
      "63bf7ac9f0323901e6001554",
    ],
  },
  {
    _id: "63bf7ccef032397b4f00160b",
    amount: "$79.19",
    buyer: "Malachi Extall",
    productIds: [
      "63bf7ac9f03239eb9d0016cc",
      "63bf7ac9f03239cf1b001698",
      "63bf7ac9f0323975d6001692",
    ],
  },
  {
    _id: "63bf7ccef032397b4f00160c",
    amount: "$127.08",
    buyer: "Neil O'Reilly",
    productIds: [
      "63bf7ac9f0323922d200168e",
      "63bf7ac9f032396a8e00168e",
      "63bf7ac9f032395ccf00168e",
      "63bf7ac9f0323975d6001697",
      "63bf7ac9f032396a8e001691",
    ],
  },
  {
    _id: "63bf7ccef0323922d2001694",
    amount: "$162.80",
    buyer: "Juan Mynett",
    productIds: [
      "63bf7ac9f032394cbd00169d",
      "63bf7ac9f03239eb9d0016ca",
      "63bf7ac9f03239e002001601",
      "63bf7ac9f032395ccf00168e",
      "63bf7ac9f03239f92000168b",
    ],
  },
  {
    _id: "63bf7ccef0323922d2001695",
    amount: "$198.54",
    buyer: "Bambi Pirdue",
    productIds: [
      "63bf7ac9f032397b4f0015fd",
      "63bf7ac9f03239aec9001554",
      "63bf7ac9f0323922d2001692",
      "63bf7ac9f032394cbd001699",
    ],
  },
  {
    _id: "63bf7ccef0323922d2001696",
    amount: "$198.34",
    buyer: "Jefferey Karus",
    productIds: ["63bf7ac9f032394cbd00169e", "63bf7ac9f0323922d2001691"],
  },
  {
    _id: "63bf7ccef0323922d2001697",
    amount: "$249.25",
    buyer: "Philbert Punshon",
    productIds: [
      "63bf7ac9f03239855d001553",
      "63bf7ac9f0323922d2001690",
      "63bf7ac9f0323937a500168b",
      "63bf7ac9f0323975d6001693",
      "63bf7ac9f03239343e00169f",
    ],
  },
  {
    _id: "63bf7ccef0323922d2001698",
    amount: "$93.07",
    buyer: "Dinnie Belz",
    productIds: [
      "63bf7ac9f03239742000169b",
      "63bf7ac9f03239aec9001552",
      "63bf7ac9f032395ccf001692",
    ],
  },
  {
    _id: "63bf7ccef0323922d2001699",
    amount: "$126.85",
    buyer: "Gilbertine Brunner",
    productIds: [
      "63bf7ac9f0323937a5001687",
      "63bf7ac9f03239cf1b001698",
      "63bf7ac9f03239966c001692",
      "63bf7ac9f03239cf1b00169c",
      "63bf7ac9f0323901e6001554",
    ],
  },
  {
    _id: "63bf7ccef0323922d200169a",
    amount: "$103.11",
    buyer: "Jaynell Filshin",
    productIds: ["63bf7ac9f032397420001697"],
  },
  {
    _id: "63bf7ccef0323922d200169b",
    amount: "$141.80",
    buyer: "Doro Sheerman",
    productIds: [
      "63bf7ac9f032396d89000009",
      "63bf7ac9f03239e002001600",
      "63bf7ac9f03239cf1b001699",
      "63bf7ac9f0323922d2001693",
    ],
  },
  {
    _id: "63bf7ccef0323922d200169c",
    amount: "$11.40",
    buyer: "Elisa Pechet",
    productIds: [
      "63bf7ac9f032394f5f002437",
      "63bf7ac9f03239eb9d0016cb",
      "63bf7ac9f03239855d001556",
    ],
  },
  {
    _id: "63bf7ccef0323922d200169d",
    amount: "$53.46",
    buyer: "Shirlene Keyzman",
    productIds: [
      "63bf7ac9f032396d8900000b",
      "63bf7ac9f03239f920001689",
      "63bf7ac9f032396a8e001690",
      "63bf7ac9f03239f92000168c",
    ],
  },
  {
    _id: "63bf7ccef0323922d200169e",
    amount: "$147.22",
    buyer: "Osgood Bentick",
    productIds: ["63bf7ac9f032394f5f002433"],
  },
  {
    _id: "63bf7ccef0323922d200169f",
    amount: "$87.29",
    buyer: "Cosette Topling",
    productIds: [
      "63bf7ac9f03239eb9d0016c5",
      "63bf7ac9f03239f92000168d",
      "63bf7ac9f032395ccf001691",
      "63bf7ac9f032396a8e00168f",
    ],
  },
  {
    _id: "63bf7ccef032396d8900000d",
    amount: "$37.57",
    buyer: "Clevie Claige",
    productIds: ["63bf7ac9f0323901e6001556"],
  },
  {
    _id: "63bf7ccef032396d8900000e",
    amount: "$21.95",
    buyer: "Lenard Sheach",
    productIds: [
      "63bf7ac9f0323937a5001688",
      "63bf7ac9f0323937a500168a",
      "63bf7ac9f032394f5f002437",
      "63bf7ac9f03239aec9001553",
      "63bf7ac9f0323975d6001695",
    ],
  },
  {
    _id: "63bf7ccef032396d8900000f",
    amount: "$268.02",
    buyer: "Noe Crowth",
    productIds: [
      "63bf7ac9f0323922d200168e",
      "63bf7ac9f032396a8e001692",
      "63bf7ac9f03239cf1b00169a",
      "63bf7ac9f032396d8900000b",
    ],
  },
  {
    _id: "63bf7ccef032396d89000010",
    amount: "$221.94",
    buyer: "Benoite Crocken",
    productIds: ["63bf7ac9f0323975d6001693", "63bf7ac9f032394f5f002435"],
  },
  {
    _id: "63bf7ccef032396d89000011",
    amount: "$173.77",
    buyer: "Blondy Gyer",
    productIds: [
      "63bf7ac9f032395ccf001693",
      "63bf7ac9f03239554c002443",
      "63bf7ac9f032397420001697",
    ],
  },
  {
    _id: "63bf7ccef032396d89000012",
    amount: "$183.77",
    buyer: "Yale Myott",
    productIds: [
      "63bf7ac9f03239554c002448",
      "63bf7ac9f03239aec9001555",
      "63bf7ac9f032394cbd00169d",
    ],
  },
  {
    _id: "63bf7ccef032396d89000013",
    amount: "$195.54",
    buyer: "Ebba Durdy",
    productIds: [
      "63bf7ac9f032395ccf001691",
      "63bf7ac9f03239f92000168e",
      "63bf7ac9f03239cf1b00169c",
      "63bf7ac9f03239eb9d0016c5",
    ],
  },
  {
    _id: "63bf7ccef032396d89000014",
    amount: "$208.47",
    buyer: "Rockwell Fiddler",
    productIds: [
      "63bf7ac9f03239855d001555",
      "63bf7ac9f0323901e6001555",
      "63bf7ac9f032397420001696",
      "63bf7ac9f032396d89000009",
      "63bf7ac9f032394cbd00169b",
    ],
  },
  {
    _id: "63bf7ccef032396d89000015",
    amount: "$133.89",
    buyer: "Ashlen Steely",
    productIds: ["63bf7ac9f03239aec9001554", "63bf7ac9f03239eb9d0016c9"],
  },
  {
    _id: "63bf7ccef032396d89000016",
    amount: "$129.79",
    buyer: "Pammy Rubie",
    productIds: ["63bf7ac9f03239855d001553"],
  },
  {
    _id: "63bf7ccef032396d89000017",
    amount: "$141.60",
    buyer: "Brittne Causnett",
    productIds: [
      "63bf7ac9f03239742000169a",
      "63bf7ac9f032397b4f0015fe",
      "63bf7ac9f03239966c001692",
    ],
  },
  {
    _id: "63bf7ccef032396d89000018",
    amount: "$111.86",
    buyer: "Allina Peele",
    productIds: [
      "63bf7ac9f03239f92000168c",
      "63bf7ac9f0323975d6001697",
      "63bf7ac9f03239343e0016a0",
      "63bf7ac9f03239eb9d0016c8",
      "63bf7ac9f032394cbd00169e",
    ],
  },
  {
    _id: "63bf7ccef03239f92000168f",
    amount: "$263.95",
    buyer: "Correy Addionisio",
    productIds: [
      "63bf7ac9f03239aec9001552",
      "63bf7ac9f032397b4f0015ff",
      "63bf7ac9f0323975d6001697",
    ],
  },
  {
    _id: "63bf7ccef03239f920001690",
    amount: "$184.66",
    buyer: "Babbette Fudge",
    productIds: ["63bf7ac9f032396a8e00168e", "63bf7ac9f03239eb9d0016cb"],
  },
  {
    _id: "63bf7ccef03239f920001691",
    amount: "$212.43",
    buyer: "Goldarina Wingrove",
    productIds: [
      "63bf7ac9f03239eb9d0016c3",
      "63bf7ac9f032396a8e001693",
      "63bf7ac9f032395ccf001692",
    ],
  },
  {
    _id: "63bf7ccef03239f920001692",
    amount: "$266.52",
    buyer: "Sibel Eakly",
    productIds: [
      "63bf7ac9f0323901e6001551",
      "63bf7ac9f032397420001699",
      "63bf7ac9f032396d89000009",
    ],
  },
  {
    _id: "63bf7ccef03239f920001693",
    amount: "$252.29",
    buyer: "Iain Stuffins",
    productIds: [
      "63bf7ac9f032397b4f001600",
      "63bf7ac9f03239554c002445",
      "63bf7ac9f0323975d6001692",
      "63bf7ac9f03239cf1b00169b",
      "63bf7ac9f032395ccf001691",
    ],
  },
  {
    _id: "63bf7ccef03239f920001694",
    amount: "$59.82",
    buyer: "Merrill Cubley",
    productIds: [
      "63bf7ac9f032396a8e001691",
      "63bf7ac9f03239e002001602",
      "63bf7ac9f03239eb9d0016c8",
      "63bf7ac9f0323975d6001693",
    ],
  },
  {
    _id: "63bf7ccef03239f920001695",
    amount: "$253.84",
    buyer: "Nikki Overlow",
    productIds: [
      "63bf7ac9f03239eb9d0016c5",
      "63bf7ac9f032396d89000007",
      "63bf7ac9f032394cbd001699",
    ],
  },
  {
    _id: "63bf7ccef03239f920001696",
    amount: "$150.73",
    buyer: "Giffard Slesser",
    productIds: [
      "63bf7ac9f032394f5f002435",
      "63bf7ac9f03239eb9d0016c9",
      "63bf7ac9f032394f5f002438",
    ],
  },
  {
    _id: "63bf7ccef03239f920001697",
    amount: "$65.72",
    buyer: "Gerik Wherton",
    productIds: [
      "63bf7ac9f03239966c001697",
      "63bf7ac9f032397b4f0015fb",
      "63bf7ac9f032394f5f002433",
    ],
  },
  {
    _id: "63bf7ccef03239f920001698",
    amount: "$194.44",
    buyer: "Hebert Dorrington",
    productIds: ["63bf7ac9f03239cf1b00169c"],
  },
  {
    _id: "63bf7ccef03239f920001699",
    amount: "$248.57",
    buyer: "Mable Stirrup",
    productIds: [
      "63bf7ac9f03239855d001552",
      "63bf7ac9f032395ccf00168e",
      "63bf7ac9f03239554c002444",
      "63bf7ac9f03239e002001601",
      "63bf7ac9f03239554c002443",
    ],
  },
  {
    _id: "63bf7ccef03239f92000169a",
    amount: "$276.83",
    buyer: "Dorothy Cristofolini",
    productIds: [
      "63bf7ac9f03239966c001694",
      "63bf7ac9f032396d8900000a",
      "63bf7ac9f03239cf1b001699",
    ],
  },
  {
    _id: "63bf7ccef032394f5f002439",
    amount: "$175.44",
    buyer: "Reuben Davidzon",
    productIds: ["63bf7ac9f03239e002001600"],
  },
  {
    _id: "63bf7ccef032394f5f00243a",
    amount: "$141.20",
    buyer: "Mitchell Scriven",
    productIds: [
      "63bf7ac9f0323937a500168a",
      "63bf7ac9f03239eb9d0016cb",
      "63bf7ac9f03239966c001692",
      "63bf7ac9f032397420001698",
      "63bf7ac9f03239eb9d0016c8",
    ],
  },
  {
    _id: "63bf7ccef032394f5f00243b",
    amount: "$137.00",
    buyer: "Jozef McFfaden",
    productIds: [
      "63bf7ac9f032394f5f002438",
      "63bf7ac9f03239855d001555",
      "63bf7ac9f03239855d001556",
      "63bf7ac9f032397b4f0015ff",
    ],
  },
  {
    _id: "63bf7ccef032394f5f00243c",
    amount: "$30.46",
    buyer: "Sarajane Giovannilli",
    productIds: ["63bf7ac9f03239343e00169c"],
  },
  {
    _id: "63bf7ccef032394f5f00243d",
    amount: "$163.77",
    buyer: "Malchy Hanbury-Brown",
    productIds: ["63bf7ac9f03239966c001696"],
  },
  {
    _id: "63bf7ccef032394f5f00243e",
    amount: "$272.04",
    buyer: "Nathanil Aidler",
    productIds: ["63bf7ac9f032397b4f0015fe", "63bf7ac9f032394cbd00169c"],
  },
  {
    _id: "63bf7ccef032394f5f00243f",
    amount: "$270.05",
    buyer: "Petronille Ganter",
    productIds: ["63bf7ac9f03239cf1b00169b"],
  },
  {
    _id: "63bf7ccef032394f5f002440",
    amount: "$183.86",
    buyer: "Nanni Bettles",
    productIds: ["63bf7ac9f0323975d6001695", "63bf7ac9f032395ccf001690"],
  },
  {
    _id: "63bf7ccef032394f5f002441",
    amount: "$13.39",
    buyer: "Marcello Connechy",
    productIds: [
      "63bf7ac9f0323922d200168e",
      "63bf7ac9f03239554c002443",
      "63bf7ac9f03239e002001605",
      "63bf7ac9f03239e002001601",
      "63bf7ac9f0323922d200168f",
    ],
  },
  {
    _id: "63bf7ccef032394f5f002442",
    amount: "$158.95",
    buyer: "Reggis Ketton",
    productIds: [
      "63bf7ac9f03239343e00169d",
      "63bf7ac9f03239343e00169b",
      "63bf7ac9f032395ccf001691",
      "63bf7ac9f03239855d001553",
    ],
  },
  {
    _id: "63bf7ccef032394f5f002443",
    amount: "$103.60",
    buyer: "Willyt Cato",
    productIds: ["63bf7ac9f0323922d2001693"],
  },
  {
    _id: "63bf7ccef032394f5f002444",
    amount: "$154.99",
    buyer: "Ira Martinello",
    productIds: ["63bf7ac9f032395ccf00168e", "63bf7ac9f0323901e6001551"],
  },
  {
    _id: "63bf7ccef0323937a500168d",
    amount: "$197.90",
    buyer: "Udall Alessandretti",
    productIds: [
      "63bf7ac9f032397b4f0015ff",
      "63bf7ac9f03239eb9d0016c3",
      "63bf7ac9f03239eb9d0016c8",
    ],
  },
  {
    _id: "63bf7ccef0323937a500168e",
    amount: "$28.26",
    buyer: "Erminia Giraudo",
    productIds: [
      "63bf7ac9f03239cf1b00169d",
      "63bf7ac9f032396a8e001692",
      "63bf7ac9f032394cbd00169a",
      "63bf7ac9f03239e002001605",
    ],
  },
  {
    _id: "63bf7ccef0323937a500168f",
    amount: "$282.44",
    buyer: "Glenda Stenners",
    productIds: [
      "63bf7ac9f032395ccf001693",
      "63bf7ac9f032397420001698",
      "63bf7ac9f03239966c001694",
    ],
  },
  {
    _id: "63bf7ccef0323937a5001690",
    amount: "$233.02",
    buyer: "Judon Tremathick",
    productIds: [
      "63bf7ac9f03239eb9d0016cb",
      "63bf7ac9f03239f92000168d",
      "63bf7ac9f032397b4f0015fd",
      "63bf7ac9f0323937a500168c",
    ],
  },
  {
    _id: "63bf7ccef0323937a5001691",
    amount: "$244.78",
    buyer: "Lynnet Faichney",
    productIds: ["63bf7ac9f032394cbd00169c", "63bf7ac9f032395ccf001692"],
  },
  {
    _id: "63bf7ccef0323937a5001692",
    amount: "$169.19",
    buyer: "Anet Le Monnier",
    productIds: ["63bf7ac9f032396a8e001693"],
  },
  {
    _id: "63bf7ccef0323937a5001693",
    amount: "$202.97",
    buyer: "Catriona Ashton",
    productIds: ["63bf7ac9f0323937a5001689", "63bf7ac9f0323937a500168a"],
  },
  {
    _id: "63bf7ccef0323937a5001694",
    amount: "$282.67",
    buyer: "Tristam Clemot",
    productIds: ["63bf7ac9f03239aec9001552"],
  },
  {
    _id: "63bf7ccef0323937a5001695",
    amount: "$282.30",
    buyer: "Millard Sallan",
    productIds: ["63bf7ac9f032396a8e001690", "63bf7ac9f03239aec9001555"],
  },
  {
    _id: "63bf7ccef0323937a5001696",
    amount: "$32.78",
    buyer: "Nikolos Baddoe",
    productIds: [
      "63bf7ac9f032396d89000008",
      "63bf7ac9f0323975d6001692",
      "63bf7ac9f03239eb9d0016c5",
    ],
  },
  {
    _id: "63bf7ccef0323937a5001697",
    amount: "$74.56",
    buyer: "Joannes Tinn",
    productIds: [
      "63bf7ac9f03239cf1b00169a",
      "63bf7ac9f03239966c001693",
      "63bf7ac9f032396a8e00168e",
      "63bf7ac9f03239e002001603",
      "63bf7ac9f03239554c002448",
    ],
  },
  {
    _id: "63bf7ccef0323937a5001698",
    amount: "$54.01",
    buyer: "Mira Beese",
    productIds: [
      "63bf7ac9f03239554c002443",
      "63bf7ac9f0323975d6001695",
      "63bf7ac9f03239eb9d0016c7",
      "63bf7ac9f032397b4f0015fe",
      "63bf7ac9f03239cf1b00169b",
    ],
  },
  {
    _id: "63bf7ccef03239855d001557",
    amount: "$216.98",
    buyer: "Tonye Mauditt",
    productIds: ["63bf7ac9f03239966c001693", "63bf7ac9f03239eb9d0016cb"],
  },
  {
    _id: "63bf7ccef03239855d001558",
    amount: "$233.79",
    buyer: "Margo Paull",
    productIds: ["63bf7ac9f032396a8e001693"],
  },
  {
    _id: "63bf7ccef03239855d001559",
    amount: "$265.54",
    buyer: "Ashly Rubee",
    productIds: [
      "63bf7ac9f03239eb9d0016cc",
      "63bf7ac9f0323922d200168e",
      "63bf7ac9f03239966c001694",
      "63bf7ac9f0323975d6001695",
    ],
  },
  {
    _id: "63bf7ccef03239855d00155a",
    amount: "$244.04",
    buyer: "Cymbre Cronk",
    productIds: [
      "63bf7ac9f032395ccf00168f",
      "63bf7ac9f0323901e6001551",
      "63bf7ac9f032394cbd00169c",
      "63bf7ac9f032395ccf001692",
    ],
  },
  {
    _id: "63bf7ccef03239855d00155b",
    amount: "$27.78",
    buyer: "Weidar Durand",
    productIds: [
      "63bf7ac9f032396d8900000a",
      "63bf7ac9f03239eb9d0016c9",
      "63bf7ac9f032395ccf001690",
      "63bf7ac9f0323937a500168a",
    ],
  },
  {
    _id: "63bf7ccef03239855d00155c",
    amount: "$216.52",
    buyer: "Alonzo Adds",
    productIds: [
      "63bf7ac9f032396a8e001690",
      "63bf7ac9f032394f5f002435",
      "63bf7ac9f032394cbd00169d",
      "63bf7ac9f032394f5f002434",
    ],
  },
  {
    _id: "63bf7ccef03239855d00155d",
    amount: "$226.10",
    buyer: "Regina Normanvill",
    productIds: [
      "63bf7ac9f032395ccf001693",
      "63bf7ac9f03239966c001692",
      "63bf7ac9f03239343e0016a0",
    ],
  },
  {
    _id: "63bf7ccef03239855d00155e",
    amount: "$293.98",
    buyer: "Danie Dybell",
    productIds: [
      "63bf7ac9f0323937a5001688",
      "63bf7ac9f0323922d2001691",
      "63bf7ac9f032397b4f0015fd",
      "63bf7ac9f03239cf1b00169d",
    ],
  },
  {
    _id: "63bf7ccef03239855d00155f",
    amount: "$191.88",
    buyer: "Reg Dron",
    productIds: [
      "63bf7ac9f03239343e00169b",
      "63bf7ac9f03239eb9d0016c6",
      "63bf7ac9f03239aec9001556",
      "63bf7ac9f032394f5f002433",
      "63bf7ac9f032394f5f002437",
    ],
  },
  {
    _id: "63bf7ccef03239855d001560",
    amount: "$242.26",
    buyer: "Carmine Catonnet",
    productIds: [
      "63bf7ac9f032397420001696",
      "63bf7ac9f0323922d200168f",
      "63bf7ac9f032397420001698",
      "63bf7ac9f03239f92000168e",
      "63bf7ac9f0323975d6001694",
    ],
  },
  {
    _id: "63bf7ccef03239855d001561",
    amount: "$207.93",
    buyer: "Valaree Inchan",
    productIds: [
      "63bf7ac9f0323975d6001692",
      "63bf7ac9f03239cf1b00169c",
      "63bf7ac9f03239966c001696",
      "63bf7ac9f0323922d2001692",
    ],
  },
  {
    _id: "63bf7ccef03239855d001562",
    amount: "$76.13",
    buyer: "Franklin Markie",
    productIds: ["63bf7ac9f03239855d001554", "63bf7ac9f032396a8e001691"],
  },
  {
    _id: "63bf7ccef03239eb9d0016cd",
    amount: "$194.94",
    buyer: "Sigmund Ramiro",
    productIds: ["63bf7ac9f0323901e6001553"],
  },
  {
    _id: "63bf7ccef03239eb9d0016ce",
    amount: "$32.76",
    buyer: "Glen Proven",
    productIds: ["63bf7ac9f03239855d001556", "63bf7ac9f03239eb9d0016c6"],
  },
  {
    _id: "63bf7ccef03239eb9d0016cf",
    amount: "$24.96",
    buyer: "Georgi Alfonso",
    productIds: [
      "63bf7ac9f032397420001698",
      "63bf7ac9f0323922d200168e",
      "63bf7ac9f03239cf1b001698",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016d0",
    amount: "$229.93",
    buyer: "Karoly Tilio",
    productIds: [
      "63bf7ac9f03239855d001554",
      "63bf7ac9f03239966c001694",
      "63bf7ac9f032396a8e001690",
      "63bf7ac9f03239f92000168d",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016d1",
    amount: "$83.15",
    buyer: "Adrianne Simmance",
    productIds: [
      "63bf7ac9f03239f920001689",
      "63bf7ac9f03239eb9d0016c4",
      "63bf7ac9f032394cbd00169d",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016d2",
    amount: "$161.43",
    buyer: "Frederich Hush",
    productIds: [
      "63bf7ac9f03239aec9001554",
      "63bf7ac9f032397b4f001600",
      "63bf7ac9f03239eb9d0016cc",
      "63bf7ac9f03239eb9d0016ca",
      "63bf7ac9f032394cbd00169a",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016d3",
    amount: "$266.01",
    buyer: "Mandie Keely",
    productIds: ["63bf7ac9f032396d8900000a"],
  },
  {
    _id: "63bf7ccef03239eb9d0016d4",
    amount: "$276.18",
    buyer: "Benedicta Khomich",
    productIds: ["63bf7ac9f032394f5f002433", "63bf7ac9f03239966c001692"],
  },
  {
    _id: "63bf7ccef03239eb9d0016d5",
    amount: "$261.62",
    buyer: "Lurette Searles",
    productIds: ["63bf7ac9f03239eb9d0016c3"],
  },
  {
    _id: "63bf7ccef03239eb9d0016d6",
    amount: "$32.46",
    buyer: "Rube Makin",
    productIds: [
      "63bf7ac9f032396a8e00168e",
      "63bf7ac9f03239966c001695",
      "63bf7ac9f03239eb9d0016c8",
      "63bf7ac9f03239aec9001553",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016d7",
    amount: "$83.42",
    buyer: "Shanan McKevin",
    productIds: [
      "63bf7ac9f03239966c001696",
      "63bf7ac9f0323922d200168f",
      "63bf7ac9f032396d8900000b",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016d8",
    amount: "$18.18",
    buyer: "Dara Benck",
    productIds: [
      "63bf7ac9f032394f5f002434",
      "63bf7ac9f0323937a5001687",
      "63bf7ac9f03239e002001604",
      "63bf7ac9f0323922d2001690",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016d9",
    amount: "$106.37",
    buyer: "Emeline Stranger",
    productIds: [
      "63bf7ac9f03239cf1b00169a",
      "63bf7ac9f032395ccf00168e",
      "63bf7ac9f03239742000169b",
      "63bf7ac9f032396a8e001693",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016da",
    amount: "$20.08",
    buyer: "Goddart Atton",
    productIds: [
      "63bf7ac9f032396a8e001692",
      "63bf7ac9f032394cbd001699",
      "63bf7ac9f03239855d001555",
      "63bf7ac9f03239343e00169c",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016db",
    amount: "$57.45",
    buyer: "Ellwood Cicculini",
    productIds: ["63bf7ac9f032395ccf00168f", "63bf7ac9f03239966c001697"],
  },
  {
    _id: "63bf7ccef03239eb9d0016dc",
    amount: "$192.17",
    buyer: "Moe Tebboth",
    productIds: [
      "63bf7ac9f03239cf1b00169b",
      "63bf7ac9f03239aec9001556",
      "63bf7ac9f032395ccf001691",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016dd",
    amount: "$174.53",
    buyer: "Adena Sepey",
    productIds: [
      "63bf7ac9f03239554c002447",
      "63bf7ac9f032397420001696",
      "63bf7ac9f03239343e00169f",
      "63bf7ac9f03239554c002448",
      "63bf7ac9f0323975d6001695",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016de",
    amount: "$107.89",
    buyer: "Jess Smails",
    productIds: [
      "63bf7ac9f032394cbd00169e",
      "63bf7ac9f03239343e00169b",
      "63bf7ac9f03239e002001602",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016df",
    amount: "$110.12",
    buyer: "Ezri Husset",
    productIds: [
      "63bf7ac9f032396d89000007",
      "63bf7ac9f032397b4f0015fb",
      "63bf7ac9f03239f92000168c",
      "63bf7ac9f0323975d6001692",
      "63bf7ac9f03239cf1b001699",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016e0",
    amount: "$233.24",
    buyer: "Dru Spittles",
    productIds: ["63bf7ac9f03239855d001552"],
  },
  {
    _id: "63bf7ccef03239eb9d0016e1",
    amount: "$232.25",
    buyer: "Leah Tuttiett",
    productIds: [
      "63bf7ac9f0323975d6001697",
      "63bf7ac9f032395ccf001692",
      "63bf7ac9f032394f5f002438",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016e2",
    amount: "$254.42",
    buyer: "Roldan Jacquemy",
    productIds: [
      "63bf7ac9f032397b4f0015fc",
      "63bf7ac9f03239855d001551",
      "63bf7ac9f032394cbd00169c",
      "63bf7ac9f03239eb9d0016cb",
      "63bf7ac9f032394f5f002436",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016e3",
    amount: "$232.11",
    buyer: "Ned Muddimer",
    productIds: [
      "63bf7ac9f03239e002001603",
      "63bf7ac9f03239554c002444",
      "63bf7ac9f032396d89000008",
      "63bf7ac9f032394f5f002435",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016e4",
    amount: "$73.14",
    buyer: "Christos Sirey",
    productIds: [
      "63bf7ac9f03239eb9d0016c9",
      "63bf7ac9f03239855d001553",
      "63bf7ac9f03239343e00169d",
      "63bf7ac9f0323937a500168c",
      "63bf7ac9f03239e002001605",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016e5",
    amount: "$175.29",
    buyer: "Sharai Jeffcoat",
    productIds: [
      "63bf7ac9f03239e002001600",
      "63bf7ac9f0323937a500168a",
      "63bf7ac9f03239554c002445",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016e6",
    amount: "$35.89",
    buyer: "Jeramie Mc Gaughey",
    productIds: ["63bf7ac9f0323922d2001691"],
  },
  {
    _id: "63bf7ccef03239eb9d0016e7",
    amount: "$88.26",
    buyer: "Der Yuille",
    productIds: [
      "63bf7ac9f03239343e00169e",
      "63bf7ac9f0323901e6001551",
      "63bf7ac9f032397420001697",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016e8",
    amount: "$50.84",
    buyer: "Zelig Punton",
    productIds: ["63bf7ac9f03239f92000168a", "63bf7ac9f032397b4f0015fe"],
  },
  {
    _id: "63bf7ccef03239eb9d0016e9",
    amount: "$88.76",
    buyer: "Randolf Cheavin",
    productIds: [
      "63bf7ac9f0323937a5001689",
      "63bf7ac9f0323901e6001552",
      "63bf7ac9f03239eb9d0016c5",
    ],
  },
  {
    _id: "63bf7ccef03239eb9d0016ea",
    amount: "$254.58",
    buyer: "Loy Randerson",
    productIds: ["63bf7ac9f032394f5f002437"],
  },
];