import { useState } from 'react';
import { Box, Button, Typography, TextareaAutosize } from '@mui/material';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/system';
import { useSaveRateMutation } from '../../api/support.api';
import { selectCurrentUser } from '../../slices/auth.slice';
import { useAppSelector } from '../../app/hooks';
import { SaveRate } from '../../dto/save-rate-request.dto';

const StyledTextarea = styled(TextareaAutosize)({
  width: '100%',
  minHeight: '100px',
  padding: '10px',
  fontSize: '16px',
  borderRadius: '5px',
  marginTop: '20px',
  resize: 'none',
});


const Feedback = () => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [saveRate] = useSaveRateMutation();
  const user = useAppSelector((state) => selectCurrentUser(state));

  const handleRatingChange = (event: any, newValue: any) => {
    setRating(newValue);
  };

  const handleFeedbackChange = (event: any) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    let newRaRequest: SaveRate = {
      rate: rating,
      feedback: feedback,
      user: user as any
    };
    saveRate(newRaRequest);
    setRating(0);
    setFeedback('');
  };

  return (
    <Box className='content-container'>
      <main className='content-content-full-width'>
        <header className='content-header section header'>
          <div className='exchanges-header'>
            <span className='text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
              Értékelés
            </span>
          </div>
        </header>
        <section className='primary-content'>
          <Box className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
            <form onSubmit={handleSubmit}>
              <Box>
                <span className='mb-ds-3 flex text-ds-secondary text-ds-celias-30-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Értékelés</span>
                <Rating
                  name='feedback-rating'
                  value={rating}
                  onChange={handleRatingChange}
                  precision={1}
                />
              </Box>
              <Box sx={{ marginTop: '30px' }}>
                <span className='mb-ds-3 mt-ds-5 text-ds-secondary text-ds-celias-30-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Visszajelzés</span>
                <StyledTextarea
                  placeholder='Írd ide a véleményed...'
                  value={feedback}
                  onChange={handleFeedbackChange}
                />
              </Box>
              <Box mt={3}>
                <Button className='shadow-ds-button-primary-high shadow-ds-button-primary-high-active text-ds-button-primary-high rounded-ds-round margin-start-0 margind-end-4 text-ds-button-sm bg-ds-button-primary-high py-ds-3 px-ds-5 text-transform-none' type='submit'>
                  Küldés
                </Button>
              </Box>
            </form>
          </Box>
        </section>
      </main>
    </Box>
  );
};

export default Feedback;
