import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { CreateKrakenWalletRequest } from "../dto/create-kraken-wallet-request.dto";
import { KrakenWallet } from "../models/KrakenWallet";
const { REACT_APP_API_ENDPOINT } = process.env;

export const krakenApi = createApi({
    reducerPath: "krakenApi",
    baseQuery: fetchBaseQuery({ 
        baseUrl: `${REACT_APP_API_ENDPOINT}/api/kraken`,
        credentials: "include"
    }),
    endpoints: (build) => ({
        createKrakenWallet: build.mutation<KrakenWallet, CreateKrakenWalletRequest>({
            query: (createKrakenWalletRequest) => ({
                url: "/wallet/create",
                method: "POST",
                body: createKrakenWalletRequest,
            }),
        }),
    }),
});

export const { useCreateKrakenWalletMutation } = krakenApi;