import { useNavigate } from "react-router";
import React, { useState } from 'react';
import { useValidateAccountMutation } from '../../../api/user.api';

const ValidateAccountForm: React.FC<{ userId: string, token: string }> = ({ userId, token }) => {

    const [validateAccount] = useValidateAccountMutation(); // Pass the required arguments here
    const navigate = useNavigate();

    useState(() => {
        const handleData= async () => {
            try {
                const password = "password";
                const response = await validateAccount({ userId, token, password });
                console.log(response);
                // navigate("/p");
            } catch (err) {
                console.error("error", err);
            }
        }
        handleData();
    
    });
        
    return null;
}

export { ValidateAccountForm };
