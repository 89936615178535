import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { User } from "../models/User";
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

interface AuthState {
  user?: User;
}

const initialState: AuthState = {
  user: undefined,
};

const loadAuthStateFromLocalStorage = (): AuthState => {
  const storedAuthState = localStorage.getItem('authState');
  return storedAuthState ? JSON.parse(storedAuthState) : initialState;
};

const isTokenExpired = (token: string): boolean => {
  const decodedToken: any = jwtDecode(token);
  return decodedToken.exp < (Date.now() / 1000);
};

const slice = createSlice({
  name: "auth",
  initialState: loadAuthStateFromLocalStorage(),
  reducers: {
    setAuthState: (state, { payload: { user } }: PayloadAction<AuthState>) => {
      state.user = user;
      localStorage.setItem('authState', JSON.stringify(state));
    },
    logout: state => {
      state.user = undefined;
      Cookies.remove('Authentication')
      localStorage.removeItem('authState');
    }
  },
});

export const { setAuthState, logout } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;

export const checkTokenExpiration = () => (dispatch: any) => {
  const token = Cookies.get('Authentication');
  if (token) {
    if (isTokenExpired(token)) {
      dispatch(logout());
    }
  } else {
     dispatch(logout());
  } 
};