import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { User } from "../models/User";
import { CreateUserRequest } from "../dto/create-user-request.dto";
import { UpdateUserInfo } from "../dto/updateUserInfor.dto";
import { ResetPasswordRequest } from "../dto/resetPassword.dto";
import { SetPasswordRequest } from "../dto/setNewPassword.dto";
const { REACT_APP_API_ENDPOINT } = process.env;

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${REACT_APP_API_ENDPOINT}/api/user`, credentials: "include" }),
    endpoints: (build) => ({
        createUser: build.mutation<User, CreateUserRequest>({
            query: (createUserRequest) => ({
                url: "/",
                method: "POST",
                body: createUserRequest,
            }),
        }),
        updateUser: build.mutation<User, UpdateUserInfo>({
            query: (updateUserInfo) => ({
                url: `/update/${updateUserInfo.userId}` ,
                method: "POST",
                body: updateUserInfo,
            }),
        }),
        getUser: build.query<User, undefined>({
            query: () => ({ url: "/" }),
        }),

        getUserSubscription: build.query<any, string | undefined>({
            query: (userId) => ({ url: `${userId}/subscription` }),
        }),
        resetPassword: build.mutation<void, ResetPasswordRequest>({
            query: (resetPasswordRequest) => ({
                url: "/resetPassword",
                method: "POST",
                body: resetPasswordRequest,
            }),
        }),
        setPassword: build.mutation<void, SetPasswordRequest>({
            query: (setPasswordRequest) => ({
                url: "/newPassword",
                method: "POST",
                body: setPasswordRequest,
            }),
        }),
        validateAccount: build.mutation<void, SetPasswordRequest>({
            query: (setPasswordRequest) => ({
                url: `/validateAccount/${setPasswordRequest.userId}/${setPasswordRequest.token}`,
                method: "GET"
            }),
        }),
    }),
});

export const { 
    useCreateUserMutation, 
    useGetUserQuery,
    useGetUserSubscriptionQuery, 
    useResetPasswordMutation, 
    useSetPasswordMutation, 
    useValidateAccountMutation,
    useUpdateUserMutation
} = userApi;