import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BarionResponse } from "../dto/barion-response.dto";
const { REACT_APP_API_ENDPOINT } = process.env;

export const paymentApi = createApi({
    reducerPath: "paymentApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${REACT_APP_API_ENDPOINT}/api/payment`, credentials: "include" }),
    endpoints: (build) => ({
        startPayment: build.mutation<BarionResponse, any>({
            query: ({ userId, product }) => ({
                url: `/start`,
                method: "POST",
                body: {
                    userId: userId,
                    product: product,
                },
            }),
        }),
        paymentSuccess: build.query<boolean, string>({
            query: (paymentId) => ({ 
                url: '/payment-success' ,
                method: 'POST',
                body: {
                    paymentId: paymentId
                }
            }),
        }),
    }),
});

export const { useStartPaymentMutation, usePaymentSuccessQuery } = paymentApi;
