import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { SaveTransactionRequest } from "../dto/save-transaction-request.dto";
const { REACT_APP_API_ENDPOINT } = process.env;

export const transactionApi = createApi({
    reducerPath: "transactionApi",
    baseQuery: fetchBaseQuery({ 
        baseUrl: `${REACT_APP_API_ENDPOINT}/api/transaction`, 
        credentials: "include" 
    }),
    endpoints: (build) => ({
        getUserTransactions: build.query<any, string | undefined>({
            query: (userId) => ({ url: `/user/${userId}` }),
        }),
        getUserTransactionsBalanceHistory: build.query<any, string | undefined>({
            query: (userId) => ({ url: `/history/${userId}` }),
        }),
        saveTransaction: build.mutation<any, SaveTransactionRequest>({
            query: (saveTransactionRequest) => ({
                url: "/save",
                method: "POST",
                body: saveTransactionRequest,
            }),
        }),
    }),
});

export const { useGetUserTransactionsQuery, useGetUserTransactionsBalanceHistoryQuery, useSaveTransactionMutation } = transactionApi;