import { Box, useMediaQuery } from '@mui/material';
import Row1 from './Row1';
import Row2 from './Row2';
import Row3 from './Row3';
import PageHeader from '../../components/dashboard/PageHeader';

const gridTemplateLargeSreens = `
    "a b c"
    "a b c"
    "a b c"
    "a b f"
    "d e f"
    "d e f"
    "d h i"
    "g h i"
    "g h j"
    "g h j"
`;

const gridTemplateSmallScreens = `
    "a"
    "a"
    "a"
    "a"
    "b"
    "b"
    "b"
    "b"
    "c"
    "c"
    "c"
    "d"
    "d"
    "d"
    "e"
    "e"
    "f"
    "f"
    "f"
    "g"
    "g"
    "g"
    "h"
    "h"
    "h"
    "h"
    "i"
    "i"
    "j"
    "j"
`;

const Portfolio = () => {
    const isAboveMediumScreens = useMediaQuery("(min-width: 1200px)");
    return (
        <>
        <div className='content-container'>
            <Box margin="auto" width="95%" mb="2rem" padding="1rem 2rem 0rem 2rem">
                <PageHeader 
                    name="My Portfolio"
                    description="All your assets on one place"
                    isDateRange={true}
                    buttonText="Connect Wallet"
                />
            </Box>
            <Box margin="auto" width="95%" height="100%" display="grid" gap="1.5rem"
                padding="0rem 2rem 2rem 2rem"
                sx={
                isAboveMediumScreens ? {
                    gridTemplateColumns: "repeat(3, minmax(370px, 1fr))",
                    gridTemplateRows: "repeat(10, minmax(60px, 1fr))",
                    gridTemplateAreas: gridTemplateLargeSreens,
                } : {
                    gridAutoColumns: "1fr",
                    gridAutoRows: "80px",
                    gridTemplateAreas: gridTemplateSmallScreens,
                }}
            >
                <Row1 />
                <Row2 />
                <Row3 />
            </Box>
        </div>
        </>
    );
};

export default Portfolio;