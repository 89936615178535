import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { UserPortfolioDetails } from "../models/UserPortfolioDetails";
const { REACT_APP_API_ENDPOINT } = process.env;

export const balanceApi = createApi({
    reducerPath: "balanceApi",
    baseQuery: fetchBaseQuery({ 
        baseUrl: `${REACT_APP_API_ENDPOINT}/api/balance`, 
        credentials: "include" 
    }),
    endpoints: (build) => ({
        getUserPortfolioDetails: build.query<UserPortfolioDetails, string | undefined>({
            query: (userId) => ({ url: `/user/${userId}/portfolio/HUF` }),
        }),
    }),
});

export const { useGetUserPortfolioDetailsQuery } = balanceApi;