import { selectCurrentUser } from '../../slices/auth.slice';
import { useAppSelector } from '../../app/hooks';
import { useGetUserPortfolioDetailsQuery } from '../../api/balance.api';
import NumberFormatComponent from '../../components/general/NumberFormatComponent';
import { currencyDictionary } from '../../general/constants/currencies';
import { cryptoCurrencyDictionary } from '../../general/constants/cryptocurrencies';
import { LineChart } from '@mui/x-charts/LineChart';
import { useEffect, useState } from 'react';
import { useGetUserTransactionsBalanceHistoryQuery } from '../../api/transaction.api';
import { Alert } from '@mui/material';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

enum ExchangeImages {
  COINBASE = "/assets/images/exchanges/coinbase.jpeg",
  KRAKEN = "/assets/images/exchanges/kraken.jpeg",
  CRYPTOCOM = "/assets/images/exchanges/cryptocom.jpeg",
  REVOLUT = "/assets/images/exchanges/revolut.jpeg",
  BYBIT = "/assets/images/exchanges/bybit.jpeg",
  OKX = "/assets/images/exchanges/okx.png",
  GATEIO = "/assets/images/exchanges/gateio.jpeg",
  KUKOIN = "/assets/images/exchanges/kucoin.png",
  BINANCE = "/assets/images/exchanges/binance.jpeg",
  BITFINEX = "/assets/images/exchanges/bitfinex.png"
}

const Dashboard = () => {
    const user = useAppSelector((state) => selectCurrentUser(state));
    const userId = user?.id;
    const { data: userPortfolioDetails } = useGetUserPortfolioDetailsQuery(userId);
    const { data: portfolioData, isLoading } = useGetUserTransactionsBalanceHistoryQuery(userId);
    const [datesArray, setDatesArray] = useState<Date[]>([]);
    const [hufBalanceSumsArray, setHufBalanceSumsArray] = useState<number[]>([]);
    const [result, setResult] = useState<{ currency: string; hufBalanceSum: number }[]>([]);
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (!isLoading && portfolioData) {
            if (!portfolioData) {
                console.error('Metadata is undefined');
                return;
            }

            const datesSet = new Set<string>();
            for (const date in portfolioData) {
                datesSet.add(date);
            }
            const uniqueDatesArray = Array.from(datesSet).sort();

            const hufBalanceSumByDate: Record<string, number> = {};
            for (const date in portfolioData) {
                for (const currency in portfolioData[date]) {
                    if (hufBalanceSumByDate[currency]) {
                        hufBalanceSumByDate[currency] += portfolioData[date][currency].hufBalance;
                    } else {
                        hufBalanceSumByDate[date] = portfolioData[date][currency].hufBalance;
                    }
                }
            }

            const result = uniqueDatesArray.map(currency => ({
                currency,
                hufBalanceSum: hufBalanceSumByDate[currency] || 0
            }));

            setResult(result);
            setDatesArray(result.map(item => new Date(item.currency)));
            setHufBalanceSumsArray(result.map(item => item.hufBalanceSum));
        }
    }, [isLoading, portfolioData]);

    var totalLengthCrypto = 0;
    var totalLengthFiat = 0;
    if (userPortfolioDetails && userPortfolioDetails.cryptoBalances) {
        totalLengthCrypto = userPortfolioDetails.cryptoBalances.length;
    }
    if (userPortfolioDetails && userPortfolioDetails.fiatBalances) {
        totalLengthFiat = userPortfolioDetails.fiatBalances.length;
    }

    const tbodyHeightCrypto = 40 + (totalLengthCrypto * 40);
    const tbodyHeightFiat = 70 + (totalLengthFiat * 40);

    const isWalletDetailsAvailable = userPortfolioDetails?.walletDetails !== null ? true : false;

    const updateDivStyle = (selectedTimeFrame: string, newStyle: any) => {
        let filteredResult;

        const div = document.querySelector('.portfolio-time-selector-div') as HTMLElement;
        if (div) {
            div.style.cssText = newStyle;
        }

        switch (!isLoading && selectedTimeFrame) {
            case '1W':
                // Filter data for the last week
                filteredResult = result.filter(item => {
                    const currentDate = new Date();
                    const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
                    return new Date(item.currency) >= oneWeekAgo;
                });
                break;
            case '1M':
                // Filter data for the last month
                filteredResult = result.filter(item => {
                    const currentDate = new Date();
                    const oneMonthAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
                    return new Date(item.currency) >= oneMonthAgo;
                });
                break;
            case '3M':
                // Filter data for the last 3 months
                filteredResult = result.filter(item => {
                    const currentDate = new Date();
                    const threeMonthsAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, currentDate.getDate());
                    return new Date(item.currency) >= threeMonthsAgo;
                });
                break;
            case '6M':
                // Filter data for the last 6 months
                filteredResult = result.filter(item => {
                    const currentDate = new Date();
                    const sixMonthsAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());
                    return new Date(item.currency) >= sixMonthsAgo;
                });
                break;
            case '1Y':
                // Filter data for the last year
                filteredResult = result.filter(item => {
                    const currentDate = new Date();
                    const oneYearAgo = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());
                    return new Date(item.currency) >= oneYearAgo;
                });
                break;
            case 'ALL':
                // No filtering required, show all data
                filteredResult = result;
                break;
            default:
                // Default case, no filtering
                filteredResult = result;
                break;
        }

        setDatesArray(filteredResult.map(item => new Date(item.currency)));
        setHufBalanceSumsArray(filteredResult.map(item => item.hufBalanceSum));
        console.log(datesArray)

        // Use filteredResult to update the chart or display the data
    };
    return (
    <div className='content-container'>
        <main className='content-content content-content-main'>
            <header className='content-header section header'>
                {open && (
                <Alert severity="info" onClose={() => handleClose()}
                    sx={{
                        alignItems: 'center',
                        overflow: 'scroll',
                        '& .MuiAlert-message': {
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            paddingRight: '2rem',
                            paddingLeft: '2rem',
                            '@media (max-width: 768px)': {
                                // Adjust styles for smaller screens
                                overflow: 'scroll',
                                minWidth: 'max-content'
                            }
                        }
                    }}
                >
                    <div className='instruction-wrapper'>
                        <div className='instruction-content-wrapper'>
                            <div className='instruction-step-wrapper'>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <span className='instruction-number-span'>1.</span>
                                </div>
                                <div style={{ marginTop: '.2rem' }}>
                                    <span style={{ fontSize: '1rem' }}>
                                        <a style={{ color: '#014361' }} href='/p/wallets/select'>Hozz létre egy tárcát</a>
                                    </span>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <EastRoundedIcon />
                            </div>
                            <div className='instruction-step-wrapper'>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <span className='instruction-number-span'>2.</span>
                                </div>
                                <div style={{ marginTop: '.2rem' }}>
                                    <span style={{ fontSize: '1rem' }}>
                                        <a style={{ color: '#014361' }} href='/p/transactions'>Szinkronizálj a tranzakciókat</a>
                                    </span>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <EastRoundedIcon />
                            </div>
                            <div className='instruction-step-wrapper'>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <span className='instruction-number-span'>3.</span>
                                </div>
                                <div style={{ marginTop: '.2rem' }}>
                                    <span style={{ fontSize: '1rem' }}>
                                        <a style={{ color: '#014361' }} href='/p/taxation'>Kalkulálj jövedelmet</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Alert>
                )}
                <h1 className='text-ds-brand text-ds-celias-36-bold'>Szia, {user?.firstName}</h1>
            </header>
            <section className='primary-content'>
                <div className='overflow-hidden shadow-ds-container py-ds-7 px-ds-9 bg-ds-container rounded-ds-7 mt-ds-0 ms-ds-0 me-ds-0 mb-ds-0' style={{ paddingBottom: '0px' }}>
                    <div className='display-grid'>
                        <div className='wrapper-portfolio'>
                            <div className='balance-header'>
                                {!isLoading ? (
                                <div className='balance-header-container'>
                                    <div>
                                        <div className='flex items-center'>
                                            <span>Portfólió érték</span>
                                        </div>
                                        <div className='flex items-baseline'>
                                            <div>
                                                <span>
                                                    <div className='font-size-16'>
                                                        <div className='portfolio-details'>
                                                            <span className='text-ds-celias-36-medium text-ds-primary'><NumberFormatComponent number={userPortfolioDetails?.totalPortfolioValue ?? 0} /></span>
                                                            <span className='text-ds-celias-36-medium text-ds-neutral'> HUF</span>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex items-baseline'>
                                                <LineChart
                                                margin={{
                                                    left: 15,
                                                    right: 30,
                                                    bottom: 20,
                                                    top: 10
                                                }}
                                                sx={{
                                                        '& .MuiAreaElement-series-Balances': {
                                                            fill: "url('#myGradient')",
                                                        },
                                                    }}
                                                    xAxis={[
                                                        { 
                                                            data: datesArray,
                                                            scaleType: 'time'
                                                        }
                                                    ]}
                                                    series={[
                                                        {
                                                            id: 'Balances', 
                                                            data: hufBalanceSumsArray,
                                                            area: true,
                                                            color: '#5841d8',
                                                            showMark: false
                                                        },
                                                    ]}
                                                    leftAxis={null}
                                                    width={600}
                                                    height={300}
                                                    >
                                                        <defs>
                                                        <linearGradient id="myGradient" gradientTransform="rotate(90)">
                                                            <stop offset="0%" stopColor="rgba(88, 65, 216, 0)" />
                                                            <stop offset="100%" stopColor="rgba(88, 65, 216, 1)" />
                                                        </linearGradient>
                                                    </defs>
                                                </LineChart>
                                            </div>
                                            <div className='flex justify-center items-center mb-ds-5 mt-ds-10'>
                                                <div className='portfolio-time-selector'>
                                                    <div className='portfolio-time-selector-div' style={{height: '40px', width: '48px', left: '304px', top: '4px', transition: 'all 0.2s ease 0s'}}></div>
                                                    <button className='portfolio-time-selector-button' onClick={() => updateDivStyle('1W', 'height: 40px; width: 48px; left: 4px; top: 4px; transition: all 0.2s ease 0s;')}>1W</button>
                                                    <button className='portfolio-time-selector-button' onClick={() => updateDivStyle('1M', 'height: 40px; width: 48px; left: 64px; top: 4px; transition: all 0.2s ease 0s;')}>1M</button>
                                                    <button className='portfolio-time-selector-button' onClick={() => updateDivStyle('3M', 'height: 40px; width: 48px; left: 124px; top: 4px; transition: all 0.2s ease 0s;')}>3M</button>
                                                    <button className='portfolio-time-selector-button' onClick={() => updateDivStyle('6M', 'height: 40px; width: 48px; left: 184px; top: 4px; transition: all 0.2s ease 0s;')}>6M</button>
                                                    <button className='portfolio-time-selector-button' onClick={() => updateDivStyle('1Y', 'height: 40px; width: 48px; left: 244px; top: 4px; transition: all 0.2s ease 0s;')}>1Y</button>
                                                    <button className='portfolio-time-selector-button' onClick={() => updateDivStyle('ALL', 'height: 40px; width: 48px; left: 304px; top: 4px; transition: all 0.2s ease 0s;')}>ALL</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ) : (
                                <div style={{ display: 'flex', minWidth: '100%', justifyContent: 'center', marginTop: '80px', marginBottom: '80px' }}>
                                    <div className="loader"></div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='overflow-hidden shadow-ds-container py-ds-7 px-ds-9 bg-ds-container rounded-ds-7 mt-ds-0 ms-ds-0 me-ds-0 mb-ds-0'>
                    <div className='mb-ds-3 flex w-full items-baseline'>
                        <span className='text-ds-primary text-ds-celias-20-medium'>Fiat</span>
                        <span className='text-ds-primary text-ds-celias-20-medium ml-auto'><NumberFormatComponent number={userPortfolioDetails?.totalFiatValue ?? 0} /> HUF</span>
                    </div>
                    <div className='responsive-assets-container'>
                        <table className='assets-table'>
                            <thead className='general-table'>
                                <tr className='assets-table-row'>
                                    <th className='assets-table-th min-width-0' colSpan={1} role='columnheader'>
                                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                            <div className='table-cell' aria-colspan={1} role='columnheader'>Valuta</div>
                                        </span>
                                    </th>
                                    <th className='assets-table-th min-width-0 assets-table-right' colSpan={1} role='columnheader'>
                                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                            <div className='assets-table-right table-cell' aria-colspan={1} role='columnheader'>Egyenleg</div>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='exchange-table-body table-table-row' style={{minHeight: `${tbodyHeightFiat}px`, maxHeight: '250px', overflowY: 'scroll', display: "block"}}>
                                {userPortfolioDetails?.fiatBalances && userPortfolioDetails.fiatBalances.map((fiatBalance, index) => {
                                    const translateValue = index * 30;

                                    return (
                                    <tr className='assets-table-row' style={{ transform: `translateY(${translateValue}px)` }}  key={index}>
                                        <td>
                                            <div className='flex min-w-min group hover:bg-ds-table-row-hover cursor-pointer px-ds-5 text-ds-primary text-ds-plex-14-medium'>
                                                <div className='wallet-info-cell'>
                                                    <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 w-ds-icon-xl h-ds-icon-xl'>
                                                        <img className='br-1r' src={currencyDictionary[fiatBalance.currencyCode]?.image} alt={currencyDictionary[fiatBalance.currencyCode].currency} height={35} />
                                                    </div>
                                                    <div className='wallet-info-cell-name-right'>
                                                        <span className='text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{currencyDictionary[fiatBalance.currencyCode]?.currency}</span>
                                                        <span className='text-ds-neutral text-ds-plex-14-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{currencyDictionary[fiatBalance.currencyCode]?.currencyCode}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='table-cell-div justify-content-right'>
                                                <div className='position-end'>
                                                    <span className='text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'><NumberFormatComponent number={fiatBalance.balance} /> HUF</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='overflow-hidden shadow-ds-container py-ds-7 px-ds-9 bg-ds-container rounded-ds-7 mt-ds-0 ms-ds-0 me-ds-0 mb-ds-0'>
                    <div className='mb-ds-3 flex w-full items-baseline'>
                        <span className='text-ds-primary text-ds-celias-20-medium'>Kriptovaluták</span>
                        <span className='text-ds-primary text-ds-celias-20-medium ml-auto'><NumberFormatComponent number={userPortfolioDetails?.totalCryptoValue} /> HUF</span>
                    </div>
                    <div className='responsive-assets-container'>
                        <table className='assets-table'>
                            <thead className='general-table'>
                                <tr className='assets-table-row'>
                                    <th className='assets-table-th min-width-0' colSpan={1} role='columnheader'>
                                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                            <div className='table-cell' aria-colspan={1} role='columnheader'>Eszköz</div>
                                        </span>
                                    </th>
                                    <th className='assets-table-th min-width-0 assets-table-right' colSpan={1} role='columnheader'>
                                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                            <div className='assets-table-right table-cell' aria-colspan={1} role='columnheader'>Egyenleg</div>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='exchange-table-body table-table-row' style={{minHeight: `${tbodyHeightCrypto}px`, maxHeight: '250px', overflowY: 'scroll', display: "block"}}>
                                {userPortfolioDetails?.cryptoBalances && userPortfolioDetails.cryptoBalances.map((cryptoBalance, index) => {
                                    const translateValue = index * 30;

                                    return (
                                    <tr className='assets-table-row' style={{ transform: `translateY(${translateValue}px)` }}  key={index}>
                                        <td>
                                            <div className='flex min-w-min group hover:bg-ds-table-row-hover cursor-pointer px-ds-5 text-ds-primary text-ds-plex-14-medium'>
                                                <div className='wallet-info-cell'>
                                                    <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 w-ds-icon-xl h-ds-icon-xl'>
                                                        <img className='br-1r' src={cryptoCurrencyDictionary[cryptoBalance.currencyCode]?.image} alt={cryptoCurrencyDictionary[cryptoBalance.currencyCode].cryptoCurrencyCode} height={35} width={35} />
                                                    </div>
                                                    <div className='wallet-info-cell-name-right'>
                                                        <span className='text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{cryptoCurrencyDictionary[cryptoBalance.currencyCode]?.cryptoCurrency}</span>
                                                        <span className='text-ds-neutral text-ds-plex-14-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{cryptoCurrencyDictionary[cryptoBalance.currencyCode]?.cryptoCurrencyCode}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='table-cell-div justify-content-right'>
                                                <div className='position-end'>
                                                    <span className='text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'><NumberFormatComponent number={cryptoBalance.balance} /> HUF</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='overflow-hidden shadow-ds-container py-ds-7 px-ds-9 bg-ds-container rounded-ds-7 mt-ds-0 ms-ds-0 me-ds-0 mb-ds-0'>
                    <div className='mb-ds-3 flex w-full items-baseline'>
                        <span className='text-ds-primary text-ds-celias-20-medium'>Tőzsdék</span>
                    </div>
                    <div className='responsive-assets-container'>
                        {isWalletDetailsAvailable ? (
                        <table className='assets-table'>
                            <thead className='general-table'>
                                <tr className='assets-table-row'>
                                    <th className='assets-table-th min-width-0' colSpan={1} role='columnheader'>
                                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                            <div className='table-cell' aria-colspan={1} role='columnheader'>Tőzsde</div>
                                        </span>
                                    </th>
                                    <th className='assets-table-th min-width-0 assets-table-right' colSpan={1} role='columnheader'>
                                        <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                            <div className='assets-table-right table-cell' aria-colspan={1} role='columnheader'>Egyenleg</div>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='exchange-table-body table-table-row' style={{minHeight: `${tbodyHeightCrypto}px`, maxHeight: '250px', overflowY: 'scroll', display: "block"}}>
                                {userPortfolioDetails?.walletDetails && userPortfolioDetails.walletDetails.map((walletDetail, index) => {
                                    const translateValue = index * 30;

                                    return (
                                    <tr className='assets-table-row' style={{ transform: `translateY(${translateValue}px)` }}  key={index}>
                                        <td>
                                            <div className='flex min-w-min group hover:bg-ds-table-row-hover cursor-pointer px-ds-5 text-ds-primary text-ds-plex-14-medium'>
                                                <div className='wallet-info-cell'>
                                                    <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 w-ds-icon-xl h-ds-icon-xl'>
                                                        <img src={ExchangeImages[walletDetail.walletType as keyof typeof ExchangeImages]} alt={walletDetail.walletType} height={35} width={35} style={{borderRadius: "0.5rem"}} />
                                                    </div>
                                                    <div className='wallet-info-cell-name-right'>
                                                        <span className='text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{walletDetail.walletName}</span>
                                                        <span className='text-ds-neutral text-ds-plex-14-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{walletDetail.walletType}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='table-cell-div justify-content-right'>
                                                <div className='position-end'>
                                                    <span className='text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'><NumberFormatComponent number={walletDetail.walletHufValue} /> HUF</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                        ) : (
                        <div className='connected-exchanges-wrapper'>
                            <div className='connected-exchanges-content'>
                                <span className='dashboard-image-exchange-span-outter'>
                                    <span className='dashboard-image-exchange-span-inner'>
                                        <img width={120} height={120} className='dashboard-image-exchange' src='/assets/images/site_images/explore.jpg' alt='Explore exchanges' />
                                    </span>
                                </span>
                                <h2 className='text-ds-primary text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Építsd a portfóliódat</h2>
                                <p className='text-ds-neutral text-ds-plex-14-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Adj hozzá tőzsdéket a portfóliódhoz a személyre szabott nézetért.</p>
                            </div>
                            <a className='text-ds-button-sm mb-ds-0 me-ds-0 ms-ds-0 mt-ds-5 box-border inline-block cursor-pointer rounded-ds-round bg-ds-button-primary-medium px-ds-5 py-ds-3 text-ds-button-primary-medium hover:bg-ds-button-primary-medium-hover mt-ds-5' href='/p/wallets/select'>
                                <span className='flex items-center justify-center'>
                                    <span className='my-px'>Fedezd fel az összes tőzsdét</span>
                                </span>
                            </a>
                        </div>
                        )}
                    </div>
                </div>
            </section>
            <section className='secondary-content'>
                <div>
                    <div className='card-card-dashboard carousel-card-dashboard'>
                        <div className='carousel-container'>
                            <div className='craousel-viewport craousel-viewport-extra'>
                                <div className='card-template-container info-container'>
                                    <div className='card-template-container-content'>
                                        <div className='card-template-hero'>
                                        </div>
                                        <div className='card-template-content'></div>
                                    </div>
                                </div>
                                <div className='card-template-container'>
                                    <div className='card-template-container-content'>
                                        <div className='card-template-hero'>
                                            <img src='/assets/images/site_images/calculation_1.jpg' alt='Tax calculation' width={243} height={170} style={{ marginTop: '30px' }} />
                                        </div>
                                        <div className='card-template-content'>
                                            <div className='card-template-title'>
                                                <span className='text-ds-primary text-ds-celias-20-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                                    <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-3 bg-ds-badge-primary-low text-ds-badge-primary flex-row inline-flex items-center text-ds-plex-12-medium h-ds-7 flex items-center rounded-ds-2 px-ds-3 box-border border border-solid border-ds-badge-brand'>
                                                        <span className='inline-flex  my-ds-2'>Új</span>
                                                    </div>
                                                    <div>Jövedelemkalkuláció</div>
                                                </span>
                                            </div>
                                            <div className='card-template-description'>
                                                <span className='text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Légy az előnyben és biztosítsd a szabályozások legfrissebb követését és betartását.</span>
                                            </div>
                                            <div className='card-template-button'>
                                                <a href='/p/taxation' className='inline-block no-underline whitespace-nowrap text-ds-button-sm px-ds-5 py-ds-3 ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 text-ds-button-primary-medium hover:text-ds-button-primary-medium-hover bg-ds-button-primary-medium hover:bg-ds-button-primary-medium-hover active:bg-ds-button-primary-medium-active w-full rounded-ds-round box-border relative focus-visible:outline focus-visible:outline-2 disabled:pointer-events-none border-0 outline-offset-2 cursor-pointer [-webkit-appearance:button] focus:outline-ds-button'>
                                                    <span className='flex items-center justify-center'>
                                                        <span className='my-px'>Kezdj neki!</span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </section>
        </main>
    </div>
    );
};

export default Dashboard;