import { UserWalletList } from '../../components/wallet/UserWalletList';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useGetUserWalletsQuery } from '../../api/wallet.api';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentUser } from '../../slices/auth.slice';

type Props = {};

const Wallets = (props: Props) => {
    const user = useAppSelector((state) => selectCurrentUser(state));
    const userId = user?.id;
    const { data: walletDetails, isLoading } = useGetUserWalletsQuery(userId);
    
    return (
      <div className='content-container'>
        <main className='content-content-full-width'>
          <header className='content-header section header'>
              <div className='exchanges-header'>
                  <h1 className='text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Tárcák</h1>
                  <div className='search-bordered search-container'>
                      <SearchRoundedIcon sx={{height: 24, width: 24}} />
                      <div>
                          <input id='exchange-search-input' type='text' placeholder='Tárca keresése' className='search-input' />
                          <div className='search-hidden-label' aria-hidden="true">Tárca keresése</div>
                      </div>
                  </div>
              </div>
          </header>
          <section className='primary-content'>
            <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
              <div className='flex items-center justify-between mb-ds-7'>
                <div className='flex flex-col'>
                  <span className='text-ds-primary text-ds-celias-20-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Saját tárcák</span>
                  <span className='text-ds-neutral text-ds-plex-14-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>{walletDetails?.totalWalletNumber} hozzáadva</span>
                </div>
                <div className='flex justify-center items-center undefined'>
                  <a className='text-ds-button-sm mb-ds-0 me-ds-0 ms-ds-0 box-border inline-block cursor-pointer rounded-ds-round bg-ds-button-primary-medium px-ds-5 py-ds-3 text-ds-button-primary-medium hover:bg-ds-button-primary-medium-hover' href='/p/wallets/select'>
                    <span className='flex items-center justify-center'>
                      <span className='my-px'>Tárca hozzáadása</span>
                    </span>
                  </a>
                </div>
              </div>
              <div className='responsive-assets-container'>
                {walletDetails && <UserWalletList walletDetails={walletDetails} />}
              </div>
            </div>
          </section>
        </main>
      </div>
    )
};

export default Wallets;