import { useAppSelector } from '../../app/hooks';
import { Box, Typography } from '@mui/material';
import { selectCurrentUser } from '../../slices/auth.slice';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { SetPasswordForm } from '../../components/auth/resetPassword-form/setPassword.component'; // Import the SetPasswordForm component

const ResetPasswordPage = () => {
    const user = useAppSelector((state) => selectCurrentUser(state));
    const {userId, token}= useParams();
    const navigate = useNavigate();

    if (user) {
        navigate("/p");
    }
    return (
        <Box display="flex" height="100vh" alignItems="flex-start"
            sx={{
                background: "white"
            }}
        >
            <SetPasswordForm userId={userId ?? ''} token={token ?? ''} /> 
            <Box
                width="50%"
                display="flex"
                padding="0rem 4rem"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
                gap="0.25rem"
                flex="1 0 0"
                alignSelf="stretch"
                borderRadius="3.75rem 0rem 0rem 3.75rem"
                sx={{
                    backgroundColor: "#1E293B"
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="2rem"
                    alignSelf="stretch"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        gap="0.75rem"
                        alignSelf="stretch"
                    >
                        <Typography
                            color="white"
                            fontSize="2rem"
                            fontStyle="normal"
                            fontWeight="700"
                            lineHeight="125%"
                            letterSpacing="-0.036rem"
                        >
                            Mi mindent kiszámítunk neked, hogy ne kelljen aggódnod
                        </Typography>
                        <Typography
                            color="#D4D4D8"
                            fontFamily="Inter"
                            fontSize="0.85rem"
                            fontStyle="normal"
                            fontWeight="600"
                            lineHeight="1.75rem"
                            letterSpacing="-0.00625rem"
                        >
                            Kövesd, elemezd és kezeld kriptovaluta eszközeidet a pénzügyi siker érdekében.
                        </Typography>
                    </Box>
                    <Box
                        component="img"
                        borderRadius="0.75rem"
                        sx={{
                            height: "19.9375rem",
                            alignSelf: "stretch"
                        }}
                        alt="The house from the offer."
                        src={window.location.origin + '/test_image.png'}
                    />
                </Box>
            </Box>
        </Box>
    )
};

export default ResetPasswordPage;