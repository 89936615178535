import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
const { REACT_APP_API_ENDPOINT } = process.env;

export const portfolioApi = createApi({
    reducerPath: "portfolioApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${REACT_APP_API_ENDPOINT}/api/portfolio-details` }),
    endpoints: (build) => ({
        getPortfolioDetailsForUser: build.query<any, string | undefined>({
            query: (userId) => ({ url: `/${userId}` }),
        }),
    }),
});

export const { useGetPortfolioDetailsForUserQuery } = portfolioApi;