import { TextField, Link as MuiLink, Button, Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useResetPasswordMutation } from '../../../api/user.api';
import Alert from '@mui/material/Alert';

const ResetPasswordForm: React.FC = () => {
    const [email, setEmail] = useState("");
    const [emailErrored, setEmailErrored] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [resetPassword] = useResetPasswordMutation();
    const [sentSuccess, setSentSuccess] = useState(false);
    const navigate = useNavigate();

    const isEmailValid = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSendEmail = async () => {
        if (!email) {
            setEmailErrored(true);
            setEmailErrorText("Kérlek add meg az e-mail címed");
        } else if (!isEmailValid(email)) {
            setEmailErrored(true);
            setEmailErrorText("Kérlek adj meg egy érvényes e-mail címet");
        } else {
            setEmailErrorText("");
        }
        
        try {
            if (!email  || !isEmailValid(email)) {
                return;
            }

            const response = await resetPassword({ email });

            console.log(response);

            if ('error' in response) {
                const error: any = response.error;
                const data = error?.data;
                const message = data?.message;
                const status = error?.status;

                if (status === 404 && message === "Email not found") {
                    setErrorMessage("A megadott e-mail címhez nem tartozik regisztrált fiók.");
                    return;
                }
            }

            setSentSuccess(true);
        } catch (err) {
            console.log(err);
        }
    }
 
    return (
        <div className='login-content-box'>
            <Typography
                textAlign="center"
                fontSize="3rem"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="125%"
                letterSpacing="-0.036rem"
                className="main-name"
            >
                Kontri.io
            </Typography>
            <div className='login-content-box-content'>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="0.5rem"
                >
                    <Typography
                        fontFamily="Inter"
                        fontSize="1.7rem"
                        fontStyle="normal"
                        fontWeight="600"
                        lineHeight="2.25rem"
                        letterSpacing="-0.01406rem"
                    >
                        Kezdj hozzá most ✨
                    </Typography>
                </Box>
                {errorMessage && (
                    <Alert severity="error" style={{ width: '100%', marginBottom: '0rem' }}>
                        {errorMessage}
                    </Alert>
                )}
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="1rem"
                    alignSelf="stretch"
                >
                    {sentSuccess ? (
                    <Alert severity="success" style={{ width: '100%', marginBottom: '0rem' }}>
                        A jelszó helyreállító linket megküldük a megadott e-mail címre.
                    </Alert>
                    ) : (
                    <>
                        <TextField
                            label="Email"
                            fullWidth
                            type="email"
                            required
                            helperText={emailErrorText}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            error={emailErrored}
                        />
                        <Button className="login-button" variant="contained" fullWidth onClick={handleSendEmail}>
                            <span style={{ color: '#FFF' }}>Email küldése</span>
                        </Button>
                    </>
                    )}
                    <Typography>Már van fiókod?
                        <Link to="/login" className="custom-color">    Bejelentkezés</Link>
                    </Typography>
                </Box>
            </div>
            <Typography>2024 Kontri, Minden jog fenntartva</Typography>
        </div>
    )
};

export { ResetPasswordForm };