import { useParams } from 'react-router-dom';
import { WalletDetailsTransactionsGrid } from '../../components/transactions/wallet-details-transaction-grid.component';
import { useGetWalletDetailsQuery } from '../../api/wallet.api';
import { cryptoCurrencyDictionary } from '../../general/constants/cryptocurrencies';
import { isCryptoCurrency, isTraditionalCurrency } from '../../features/general-service';
import { currencyDictionary } from '../../general/constants/currencies';
import NumberFormatComponent from '../../components/general/NumberFormatComponent';
import { ExchangeImages } from '../../components/wallet/UserWalletList';

type Props = {};

const WalletDetails = (props: Props) => {
    const { walletId } = useParams();

    const { data: walletDetails, isLoading } = useGetWalletDetailsQuery(walletId);
    console.log(walletDetails)
    return (
      <div className='content-container'>
        <main className='content-content content-content-main'>
          <header className='content-header section header'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img width={50} height={50} src={ExchangeImages[walletDetails?.wallet.type as keyof typeof ExchangeImages]} alt='img' style={{
                    borderRadius: "0.5rem"
                  }} />
                  <h1 className='text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginLeft: '10px' }}>{walletDetails?.wallet.name}</h1>
              </div>
          </header>
          <section className='primary-content'>
              <div className='overflow-hidden shadow-ds-container py-ds-7 px-ds-9 bg-ds-container rounded-ds-7 mt-ds-0 ms-ds-0 me-ds-0 mb-ds-0'>
                  <div className='mb-ds-3 flex w-full items-baseline'>
                      <span className='text-ds-primary text-ds-celias-20-medium'>Tranzakciók</span>
                  </div>
                  <div className='responsive-assets-container'>
                      <div className='connected-exchanges-wrapper'>
                          {walletDetails && <WalletDetailsTransactionsGrid walletDetails={walletDetails} />}
                      </div>
                  </div>
              </div>
          </section>
          <section className='secondary-content'>
                <div>
                    <div className='card-card-dashboard carousel-card-dashboard'>
                        <div className='carousel-container'>
                            <div className='craousel-viewport craousel-viewport-extra'>
                                <div className='card-template-container info-container'>
                                    <div className='card-template-container-content'>
                                        <div className='card-template-hero'>
                                            <img src='https://www.kraken.com/spot-app/_next/static/chunks/images/c3a8492bb80f1583fdfb97f53dd1bd09.png' srcSet='https://www.kraken.com/spot-app/_next/static/chunks/images/c3a8492bb80f1583fdfb97f53dd1bd09.png 1x, https://www.kraken.com/spot-app/_next/static/chunks/images/c3a8492bb80f1583fdfb97f53dd1bd09.png 2x' width={256} height={193} />
                                        </div>
                                        <div className='card-template-content'></div>
                                    </div>
                                </div>
                                <div className='card-template-container'>
                                    <div className='card-template-container-content'>
                                        <div className='card-template-content'>
                                            <div className='card-template-title'>
                                                <span className='text-ds-primary text-ds-celias-20-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                                    <div>Vagyonrészek</div>
                                                </span>
                                            </div>
                                            <div>
                                              <table className='assets-table'>
                                                <thead className='general-table'>
                                                    <tr className='assets-table-row'>
                                                        <th className='assets-table-th min-width-0' colSpan={1} role='columnheader'>
                                                            <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                                                <div className='table-cell' aria-colspan={1} role='columnheader'>Eszköz</div>
                                                            </span>
                                                        </th>
                                                        <th className='assets-table-th min-width-0 assets-table-right' colSpan={1} role='columnheader'>
                                                            <span className='text-ds-neutral text-ds-plex-14-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                                                <div className='assets-table-right table-cell' aria-colspan={1} role='columnheader'>Egyenleg</div>
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className='exchange-table-body table-table-row' style={{minHeight: `200px`, maxHeight: '250px', overflowY: 'scroll', display: "block"}}>
                                                    {walletDetails?.walletBalances && walletDetails.walletBalances.map((balanceItem: any, index: number) => {
                                                      const translateValue = index * 30;

                                                      const isTradCurrencyCoin = isTraditionalCurrency(balanceItem.coin);
                                                      const isCryptoCurrencyCoin = isCryptoCurrency(balanceItem.coin);

                                                      console.log(isCryptoCurrencyCoin)

                                                      let coinImageSrc = "";

                                                      if (isTradCurrencyCoin) {
                                                          coinImageSrc = currencyDictionary[balanceItem.coin]?.image;
                                                      } else if (isCryptoCurrencyCoin) {
                                                          coinImageSrc = cryptoCurrencyDictionary[balanceItem.coin]?.image;
                                                      }

                                                      return (
                                                        <tr className='assets-table-row' style={{ transform: `translateY(${translateValue}px)` }}  key={index}>
                                                            <td>
                                                                <div className='flex min-w-min group hover:bg-ds-table-row-hover cursor-pointer px-ds-5 text-ds-primary text-ds-plex-14-medium'>
                                                                    <div className='wallet-info-cell'>
                                                                        <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 w-ds-icon-xl h-ds-icon-xl'>
                                                                          <img src={coinImageSrc} alt={balanceItem.coin} height={30} />
                                                                        </div>
                                                                        <div className='wallet-info-cell-name-right'>
                                                                          {isTradCurrencyCoin || isCryptoCurrencyCoin ? (
                                                                              <>
                                                                                  <span className='text-ds-primary text-ds-plex-12-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                                                                      {isTradCurrencyCoin ? currencyDictionary[balanceItem.coin]?.currency : cryptoCurrencyDictionary[balanceItem.coin]?.cryptoCurrency}
                                                                                  </span>
                                                                                  <span className='text-ds-neutral text-ds-plex-12-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>
                                                                                      {isTradCurrencyCoin ? currencyDictionary[balanceItem.coin]?.currencyCode : cryptoCurrencyDictionary[balanceItem.coin]?.cryptoCurrencyCode}
                                                                                  </span>
                                                                              </>
                                                                          ) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='table-cell-div justify-content-right'>
                                                                    <div className='position-end'>
                                                                        <span className='text-ds-neutral text-ds-plex-12-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>≈ <NumberFormatComponent number={balanceItem.balanceInHUF} /> HUF</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>)
                                                    })}
                                                </tbody>
                                            </table>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </section>
        </main>
      </div>
    )
};

export default WalletDetails;