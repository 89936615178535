import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import { useState } from 'react';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';

type Props = {};

const FirstStepsGuide = (props: Props) => {
    return (
    <div className='content-container'>
        <main className='content-content-full-width'>
            <header className='content-header section header'>
                <div className='exchanges-header'>
                    <h1 className='text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Útmutatók / Első lépések</h1>
                </div>
            </header>
            <section className='primary-content'>
                <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
                    <div className='flex items-center justify-between mb-ds-7'>
                        <div className='flex flex-col'>
                            <span className='text-ds-primary text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Első lépések - Kontri.io</span>
                            <span className='text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>A következő leírás abban segít, hogy a legtöbbet hozhasd ki a <strong>Kontri.io</strong>-ból</span>
                        </div>
                    </div>
                    <div className='flex items-center justify-between mb-ds-7'>
                        <div className='flex flex-col'>
                            <span className='text-ds-primary text-ds-celias-30-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Mi az a Kontri.io?</span>
                            <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '15px' }}>Kontri.io egy olyan alkalmazás, amely segít neked nyomon követni, elemezni és kezelni a kriptovaluta eszközeidet a lehető legkönnyebb módon és nem utolsósorban adót számít, így nem kell aggódnod emiatt.</span>
                        </div>
                    </div>
                    <div className='flex items-center justify-between mb-ds-7'>
                        <div className='flex flex-col'>
                            <span className='text-ds-primary text-ds-celias-30-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Adószámítás 4 lépésben</span>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '10px', marginBottom: '10px' }}>1. Lépés: Hozd létre a tárcáidat</span>
                            <span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '10px', marginBottom: '15px' }}>A Kontri alkalmazásban minden kriptovaluta tőzsdét, walletet, szolgáltatást stb. "Tárcának" nevezünk.</span>
                            <ul style={{ marginLeft: '35px', marginBottom: '25px' }}>
                                <li><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>A tárcák létrahozásához kattints a "<strong>Tárcák</strong>" oldalon a "<strong>Tárca hozzáadása</strong>" gombra.</span></li>
                                <li><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Ott kiválaszthatod a listából a kívánt tőzsdét.</span></li>
                            </ul>
                            <div style={{ maxWidth: '100%', height: 'auto' }}>
                                <img src='/assets/images/site_images/exchanges.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '500', fontSize: '1.1rem', marginTop: '20px' }}>Nem találod a tőzsdét ahol kereskedsz?</span>
                            <span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '10px', marginBottom: '30px' }}>Vedd fel velünk a kapcsolatot és a lehető meghamarabb megpróbáljuk bővíteni a támogatott tőzsdék listáját.</span>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '20px', marginBottom: '10px' }}>2. Lépés: Adatok importálása</span>
                            <span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '10px', marginBottom: '10px' }}>Miután rákattintottál egy tárcára lehetőséged nyílik arra, hogy importáld a tranzakcióidat.</span>
                            <span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '10px', marginBottom: '10px', fontStyle: 'italic' }}>Például így:</span>
                            <div style={{ maxWidth: '100%', height: 'auto' }}>
                                <img src='/assets/images/site_images/data_upload.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '20px', marginBottom: '30px' }}>A legtöbb tőzsdénél találni fogsz egy Automatikus szinkronizálás beállítást, amely API kulcsok segítségével automatikusan letölti a tranzakciós előzményeidet. Ez a javasolt módszer, mivel így nem kell foglalkoznod CSV fájlokkal.</span>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '20px' }}>3. Lépés: Várd meg az adatok betöltését</span>
                            <span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '20px', marginBottom: '10px' }}>Néhány tárca esetében időbe fog telni, mire minden tranzakciós előzményt összegyűjtünk, így azt javasoljuk, hogy légy türelmes. Amikor a folyamatok befejeződtek egy e-mailt fogunk küldeni az adatok szinkronizációjának megerősítéséről.</span>
                            <span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '10px', marginBottom: '30px' }}>Az adatok szinkronizálása során több folyamat fut a háttérben, például az árfolyamok összegyűjtése, bevételek és ráfordítások kalkulálása valamint összefüggő tranzakciók elemzése.</span>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '20px' }}>4. Lépés: Töltsd le az adószámítási dokumentumot</span>
                            <span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '20px', marginBottom: '10px' }}>Menj az "<strong>Adószámítás</strong>" oldalra, hogy megtekintsd a jövedelemkimutatásokat és a jövedelmeid rövid összefoglalóját a 2023-as adóévre vonatkozóan.</span>
                            <div style={{ maxWidth: '100%', height: 'auto' }}>
                                <img src='/assets/images/site_images/tax.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '30px', marginBottom: '30px' }}>Görgess a lap aljára az adójelentés letöltéséhez. Több lehetőség közül tudsz választani amit PDF formátumban van lehetőséged letölteni.</span>
                            <div style={{ maxWidth: '100%', height: 'auto' }}>
                                <img src='/assets/images/site_images/report.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '30px', marginBottom: '0px' }}>Ha egyéb kérdésed merül fel, lépj velünk kapcsolatba!</span>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
    )
};

export default FirstStepsGuide;