import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import { useState } from 'react';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';

type Props = {};

const Guides = (props: Props) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const totalArticles = 6; // Total number of articles
    const visibleArticles = 3; // Number of articles visible at a time
    const maxScrollPosition = totalArticles - visibleArticles;

    const handlePrevClick = () => {
        const newPosition = Math.max(scrollPosition - 1, 0);
        setScrollPosition(newPosition);
    };

    const handleNextClick = () => {
        const newPosition = Math.min(scrollPosition + 1, maxScrollPosition);
        setScrollPosition(newPosition);
    };

    const scrollStyle = {
        transform: `translateX(-${scrollPosition * 288}px)`, // Adjust the width of each article card accordingly
        transition: 'transform 0.5s ease-in-out', // Adjust the transition duration as needed
    };
    
    return (
    <div className='content-container'>
        <main className='content-content-full-width'>
            <header className='content-header section header'>
                <div className='exchanges-header'>
                    <h1 className='text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Útmutatók</h1>
                </div>
            </header>
            <section className='primary-content'>
                <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
                    <div className='flex items-center justify-between mb-ds-7'>
                        <div className='flex flex-col'>
                            <span className='text-ds-primary text-ds-celias-20-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Első lépések</span>
                            <span className='text-ds-neutral text-ds-plex-14-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>A következő leírások abban segítenek, hogy a legtöbbet kihozd a <strong>Kontri.io</strong>-ból</span>
                        </div>
                    </div>
                    <div>
                        <div className='guide-card-shadow' style={{ borderRadius: '1rem', maxWidth: '264px' }}>
                            <a href='/p/guides/first-steps' style={{ textDecoration: 'none' }}>
                                <div className='guide-card'>
                                    <h4 className='guide-card-h4'>Első lépések</h4>
                                    <div style={{ marginTop: '1rem', marginBottom: '1rem', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                        <div style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.15rem', color: 'black' }}>Első lépések a Kontrin</div>
                                        <div style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>Tudj meg többet arról, hogy hogyan tudod adataidat...</div>
                                    </div>
                                    <div className='flex' style={{ display: 'flex', alignItems: 'center', color: '#656a91' }}>
                                        <MenuBookRoundedIcon />
                                        <div style={{ marginLeft: '7px' }}>10 perc</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='flex items-center justify-between mb-ds-7' style={{ marginTop: '2rem' }}>
                        <div className='flex flex-col'>
                            <span className='text-ds-primary text-ds-celias-20-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Tőzsdék</span>
                            <span className='text-ds-neutral text-ds-plex-14-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>A következő leírások a tőzsdei adatok szinkronizálásában nyújtanak segítséget.</span>
                        </div>
                    </div>
                    <div>
                        <div className='flex' style={scrollStyle}>
                            <div className='guide-card-shadow' style={{ borderRadius: '1rem', maxWidth: '264px', marginRight: '24px' }}>
                                <a href='/p/guides/coinbase' style={{ textDecoration: 'none' }}>
                                    <div className='guide-card'>
                                        <h4 className='guide-card-h4'>Tőzsdék</h4>
                                        <div style={{ marginTop: '1rem', marginBottom: '1rem', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                            <div style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.15rem', color: 'black' }}>Coinbase</div>
                                            <div style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>Tudj meg többet arról, hogy hogyan tudsz adatokat szinkronizálni...</div>
                                        </div>
                                        <div className='flex' style={{ display: 'flex', alignItems: 'center', color: '#656a91' }}>
                                            <MenuBookRoundedIcon />
                                            <div style={{ marginLeft: '7px' }}>5 perc</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='guide-card-shadow' style={{ borderRadius: '1rem', maxWidth: '264px', marginRight: '24px' }}>
                                <a href='/p/guides/kraken' style={{ textDecoration: 'none' }}>
                                    <div className='guide-card'>
                                        <h4 className='guide-card-h4'>Tőzsdék</h4>
                                        <div style={{ marginTop: '1rem', marginBottom: '1rem', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                            <div style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.15rem', color: 'black' }}>Kraken</div>
                                            <div style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>Tudj meg többet arról, hogy hogyan tudsz adatokat szinkronizálni...</div>
                                        </div>
                                        <div className='flex' style={{ display: 'flex', alignItems: 'center', color: '#656a91' }}>
                                            <MenuBookRoundedIcon />
                                            <div style={{ marginLeft: '7px' }}>5 perc</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='guide-card-shadow' style={{ borderRadius: '1rem', maxWidth: '264px', marginRight: '24px' }}>
                                <a href='/p/guides/crypto-com' style={{ textDecoration: 'none' }}>
                                    <div className='guide-card'>
                                        <h4 className='guide-card-h4'>Tőzsdék</h4>
                                        <div style={{ marginTop: '1rem', marginBottom: '1rem', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                            <div style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.15rem', color: 'black' }}>Crypto.com</div>
                                            <div style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>Tudj meg többet arról, hogy hogyan tudsz adatokat szinkronizálni...</div>
                                        </div>
                                        <div className='flex' style={{ display: 'flex', alignItems: 'center', color: '#656a91' }}>
                                            <MenuBookRoundedIcon />
                                            <div style={{ marginLeft: '7px' }}>5 perc</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='guide-card-shadow' style={{ borderRadius: '1rem', maxWidth: '264px', marginRight: '24px' }}>
                                <a href='/p/guides/revolut' style={{ textDecoration: 'none' }}>
                                    <div className='guide-card'>
                                        <h4 className='guide-card-h4'>Tőzsdék</h4>
                                        <div style={{ marginTop: '1rem', marginBottom: '1rem', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                            <div style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.15rem', color: 'black' }}>Revolut</div>
                                            <div style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>Tudj meg többet arról, hogy hogyan tudsz adatokat szinkronizálni...</div>
                                        </div>
                                        <div className='flex' style={{ display: 'flex', alignItems: 'center', color: '#656a91' }}>
                                            <MenuBookRoundedIcon />
                                            <div style={{ marginLeft: '7px' }}>5 perc</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='guide-card-shadow' style={{ borderRadius: '1rem', maxWidth: '264px', marginRight: '24px' }}>
                                <a href='/p/guides/binance' style={{ textDecoration: 'none' }}>
                                    <div className='guide-card'>
                                        <h4 className='guide-card-h4'>Tőzsdék</h4>
                                        <div style={{ marginTop: '1rem', marginBottom: '1rem', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                            <div style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.15rem', color: 'black' }}>Binance</div>
                                            <div style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>Tudj meg többet arról, hogy hogyan tudod adataidat...</div>
                                        </div>
                                        <div className='flex' style={{ display: 'flex', alignItems: 'center', color: '#656a91' }}>
                                            <MenuBookRoundedIcon />
                                            <div style={{ marginLeft: '7px' }}>5 perc</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='guide-card-shadow' style={{ borderRadius: '1rem', maxWidth: '264px', marginRight: '24px' }}>
                                <a href='/p/guides/bitfinex' style={{ textDecoration: 'none' }}>
                                    <div className='guide-card'>
                                        <h4 className='guide-card-h4'>Tőzsdék</h4>
                                        <div style={{ marginTop: '1rem', marginBottom: '1rem', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                            <div style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.15rem', color: 'black' }}>Bitfinex</div>
                                            <div style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>Tudj meg többet arról, hogy hogyan tudod adataidat...</div>
                                        </div>
                                        <div className='flex' style={{ display: 'flex', alignItems: 'center', color: '#656a91' }}>
                                            <MenuBookRoundedIcon />
                                            <div style={{ marginLeft: '7px' }}>5 perc</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center', marginTop: '2rem' }}>
                            <button className='guide-site-button' style={{ padding: '.5rem', borderWidth: '1px', borderRadius: '9999px', width: '52px', height: '52px' }} onClick={handlePrevClick}><NavigateBeforeRoundedIcon style={{ fontSize: '30px' }} /></button>
                            <button className='guide-site-button' style={{ padding: '.5rem', borderWidth: '1px', borderRadius: '9999px', width: '52px', height: '52px' }} onClick={handleNextClick}><NavigateNextRoundedIcon style={{ fontSize: '30px' }} /></button>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
    )
};

export default Guides;