import { Divider } from "@mui/material";
import NumberFormatComponent from "../../components/general/NumberFormatComponent";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentUser } from "../../slices/auth.slice";
import { useGetUserDetailsQuery, useSaveUserDetailsMutation } from "../../api/user-details.api";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useStartPaymentMutation } from "../../api/payment.api";
import { Alert } from '@mui/material';

type Props = {};

const ProductPurchase = (props: Props) => {
    const user = useAppSelector((state) => selectCurrentUser(state));
    const userId = user?.id;
    const { data: userDetails } = useGetUserDetailsQuery(userId);
    const [saveUserDetails] = useSaveUserDetailsMutation();
    const [startPayment] = useStartPaymentMutation();
    const [billingInfoSaved, setBillingInfoSaved] = useState(false);
    const [paymentError, setPaymentError] = useState(false);
    const [notSelectedError, setNotSelectedError] = useState(true);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedError, setIsCheckedError] = useState(false);
    const [orderSuccess, setOrderSuccess] = useState(false);

    useEffect(() => {
        if (userDetails) {
            setBillingInfoSaved(true);
        }
    }, [userDetails]);

    const handleChangeAllow = () => {
        setIsChecked(!isChecked);
    };


    const [formData, setFormData] = useState<{
        [key: string]: string;
    }>({
        invoice_name: '',
        invoice_country: 'HU',
        invoice_postal_code: '',
        invoice_city: '',
        invoice_address: '',
        invoice_tax_number: ''
    });

    const [errors, setErrors] = useState<{
        [key: string]: boolean;
    }>({
        invoice_name: false,
        invoice_postal_code: false,
        invoice_city: false,
        invoice_address: false
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        // Clear the error for the current input when user types
        setErrors({ ...errors, [name]: false });
    };

    const handlePayment = async () => {
        if (!billingInfoSaved) {
            setPaymentError(true);
            return;
        }

        if (notSelectedError) {
            return;
        }

        if (!isChecked) {
            setIsCheckedError(true);
            return;
        }

        try {
            const response = await startPayment({userId, product: selectedProduct });

            setOrderSuccess(true);

            if ('data' in response && response.data.GatewayUrl) {
                // Redirect the user to the payment provider's platform
                window.location.href = response.data.GatewayUrl;
            } else {
                console.error('Error: No GatewayUrl property in response');
            }
        } catch (error) {
            console.error('Error starting payment:', error);
        }
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        let hasErrors = false;
        const newErrors = { ...errors };

        // Trim form data values
        const trimmedFormData = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, value.trim()])
        );

        // Check if required fields are filled
        Object.keys(trimmedFormData).forEach((key) => {
            if (trimmedFormData[key] === '' && key !== 'invoice_tax_number') {
                newErrors[key] = true;
                hasErrors = true;
            }
        });

        if (hasErrors) {
            // Set errors if any
            setErrors(newErrors);
            return;
        }

        try {
            if (userId) {
                await saveUserDetails({
                    userId: userId, 
                    name: trimmedFormData.invoice_name, 
                    countryCode: trimmedFormData.invoice_country,
                    postCode: trimmedFormData.invoice_postal_code,
                    city: trimmedFormData.invoice_city,
                    address: trimmedFormData.invoice_address,
                    taxNumber: trimmedFormData.invoice_tax_number
                });
            } else {
                console.error('userId is undefined');
                // You might want to set an error state here for user feedback
                return;
            }

            setFormData({
                invoice_name: '',
                invoice_country: 'HU',
                invoice_postal_code: '',
                invoice_city: '',
                invoice_address: '',
                invoice_tax_number: ''
            });
            console.log('User details saved successfully');

            setBillingInfoSaved(true);

            window.location.reload();
        } catch (error) {
            console.error('Error saving user details:', error);
            // Set error state for user feedback
        }
    };

    const handleProductSelect = (productName: string) => {
        setSelectedProduct(productName);
        setNotSelectedError(false);
    };
    
    return (
    <div className='content-container'>
        <main className='content-content-full-width'>
            <header className='content-header section header'>
                <div className='exchanges-header'>
                    <h1 className='text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Szolgáltatás megvásárlása</h1>
                </div>
            </header>
            <section className='primary-content'>
                {!userDetails ? (
                <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
                    <div className='mb-ds-3 flex w-full items-baseline'>
                        <span className='text-ds-primary text-ds-celias-20-medium'>Számlázási információ <strong style={{ color: 'red' }}>(kötelező a fizetés megkezdése előtt)</strong></span>
                    </div>
                    <div className='personal-info-container' style={{ marginTop: '30px' }}>
                        <div className='personal-info-label-container' style={{  gridTemplateColumns: '20rem 1fr auto' }}>
                            <div className='personal-info-label' style={{ display: 'flex', alignItems: 'center' }}>
                                <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Számlázási név</span>
                            </div>
                            <div className='personal-info-content'>
                                <div className='personal-info-label-value'>
                                    <input type='text' placeholder="Számlázási név" style={{ paddingTop: '0px', paddingBottom: '0px', width: '400px', height: '40px' }} className='exchange-modal-wallet-name-input input-style' name='invoice_name' onChange={handleChange} required />
                                    {errors.invoice_name && <div style={{ color: 'red' }}>A mező kitöltése kötelező</div>}
                                </div>
                            </div>
                        </div>
                        <div className='personal-info-label-container' style={{  gridTemplateColumns: '20rem 1fr auto' }}>
                            <div className='personal-info-label' style={{ display: 'flex', alignItems: 'center' }}>
                                <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Ország</span>
                            </div>
                            <div className='personal-info-content'>
                                <div className='personal-info-label-value' style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Magyarország</span>
                                </div>
                            </div>
                        </div>
                        <div className='personal-info-label-container' style={{  gridTemplateColumns: '20rem 1fr auto' }}>
                            <div className='personal-info-label' style={{ display: 'flex', alignItems: 'center' }}>
                                <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Irányítószám</span>
                            </div>
                            <div className='personal-info-content'>
                                <div className='personal-info-label-value'>
                                    <input type='text' placeholder="Irányítószám" style={{ paddingTop: '0px', paddingBottom: '0px', width: '400px', height: '40px' }} className='exchange-modal-wallet-name-input input-style' name='invoice_postal_code' onChange={handleChange} required />
                                    {errors.invoice_postal_code && <div style={{ color: 'red' }}>A mező kitöltése kötelező</div>}
                                </div>
                            </div>
                        </div>
                        <div className='personal-info-label-container' style={{  gridTemplateColumns: '20rem 1fr auto' }}>
                            <div className='personal-info-label' style={{ display: 'flex', alignItems: 'center' }}>
                                <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Város</span>
                            </div>
                            <div className='personal-info-content'>
                                <div className='personal-info-label-value'>
                                    <input type='text' placeholder="Város" style={{ paddingTop: '0px', paddingBottom: '0px', width: '400px', height: '40px' }} className='exchange-modal-wallet-name-input input-style' name='invoice_city' onChange={handleChange} required />
                                    {errors.invoice_city && <div style={{ color: 'red' }}>A mező kitöltése kötelező</div>}
                                </div>
                            </div>
                        </div>
                        <div className='personal-info-label-container' style={{  gridTemplateColumns: '20rem 1fr auto' }}>
                            <div className='personal-info-label' style={{ display: 'flex', alignItems: 'center' }}>
                                <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Számlázási cím</span>
                            </div>
                            <div className='personal-info-content'>
                                <div className='personal-info-label-value'>
                                    <input type='text' placeholder="Számlázási cím" style={{ paddingTop: '0px', paddingBottom: '0px', width: '400px', height: '40px' }} className='exchange-modal-wallet-name-input input-style' name='invoice_address' onChange={handleChange} required />
                                    {errors.invoice_address && <div style={{ color: 'red' }}>A mező kitöltése kötelező</div>}
                                </div>
                            </div>
                        </div>
                        <div className='personal-info-label-container' style={{  gridTemplateColumns: '20rem 1fr auto' }}>
                            <div className='personal-info-label' style={{ display: 'flex', alignItems: 'center' }}>
                                <span className='text-ds-neutral text-ds-plex-16-medium ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Adószám</span>
                            </div>
                            <div className='personal-info-content'>
                                <div className='personal-info-label-value'>
                                    <input type='text' placeholder="Adószám (Nem kötelező)" style={{ paddingTop: '0px', paddingBottom: '0px', width: '400px', height: '40px' }} className='exchange-modal-wallet-name-input input-style' name='invoice_tax_number' onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '0px' }}>
                            <button style={{ width: '250px' }} onClick={handleSubmit} className='text-ds-button-sm mb-ds-0 me-ds-0 ms-ds-0 mt-ds-5 box-border inline-block cursor-pointer rounded-ds-round bg-ds-button-primary-medium px-ds-5 py-ds-3 text-ds-button-primary-medium hover:bg-ds-button-primary-medium-hover mt-7'>
                                <span className='flex items-center justify-center'>
                                    <span className='my-px'>Számlázási adatok mentése</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                ):null}
                {!orderSuccess ? (
                <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
                    <div className="flex justify-between">
                        <div className="justify-between" style={{ marginBottom: '0', width: '50%' }}>
                            <div className={`guide-card-shadow ${selectedProduct === 'BASIC' ? 'selected-product' : ''}`} style={{ borderRadius: '1rem', maxWidth: '310px', marginBottom: '1rem' }} onClick={() => handleProductSelect('BASIC')}>
                                <div style={{ textDecoration: 'none' }}>
                                    <div className='flex guide-card' style={{ height: 'auto', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ marginRight: '20px' }}>
                                            <div className={`selection-circle ${selectedProduct === 'BASIC' ? 'selected' : ''}`}></div>
                                        </div>
                                        <div>
                                            <div style={{ marginTop: '.5rem', marginBottom: '10px', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                                <div style={{ fontWeight: '900', lineHeight: '1.5', fontSize: '1.3rem', color: 'black', textAlign: 'left' }}><span style={{ fontSize: '1.4rem' }}>BASIC</span></div>
                                            </div>
                                            <div style={{ marginBottom: '1rem', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                                <div style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}><span>2 Tárca csatlakoztatás</span></div>
                                            </div>
                                            <div style={{ marginBottom: '.5rem', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                                <div style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.3rem', color: 'black', textAlign: 'left' }}><span style={{ fontSize: '1.3rem' }}><NumberFormatComponent number={39900} /> HUF</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`guide-card-shadow ${selectedProduct === 'MID' ? 'selected-product' : ''}`} style={{ borderRadius: '1rem', maxWidth: '310px', marginBottom: '1rem' }} onClick={() => handleProductSelect('MID')}>
                                <div style={{ textDecoration: 'none' }}>
                                    <div className='flex guide-card' style={{ height: 'auto', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ marginRight: '20px' }}>
                                            <div className={`selection-circle ${selectedProduct === 'MID' ? 'selected' : ''}`}></div>
                                        </div>
                                        <div>
                                            <div style={{ marginTop: '.5rem', marginBottom: '10px', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                                <div style={{ fontWeight: '900', lineHeight: '1.5', fontSize: '1.3rem', color: 'black', textAlign: 'left' }}><span style={{ fontSize: '1.4rem' }}>MID</span></div>
                                            </div>
                                            <div style={{ marginBottom: '1rem', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                                <div style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}><span>3 Tárca csatlakoztatás</span></div>
                                            </div>
                                            <div style={{ marginBottom: '.5rem', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                                <div style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.3rem', color: 'black', textAlign: 'left' }}><span style={{ fontSize: '1.3rem' }}><NumberFormatComponent number={69900} /> HUF</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`guide-card-shadow ${selectedProduct === 'PRO' ? 'selected-product' : ''}`} style={{ borderRadius: '1rem', maxWidth: '310px' }} onClick={() => handleProductSelect('PRO')}>
                                <div style={{ textDecoration: 'none' }}>
                                    <div className='flex guide-card' style={{ height: 'auto', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ marginRight: '20px' }}>
                                            <div className={`selection-circle ${selectedProduct === 'PRO' ? 'selected' : ''}`}></div>
                                        </div>
                                        <div>
                                            <div style={{ marginTop: '.5rem', marginBottom: '10px', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                                <div style={{ fontWeight: '900', lineHeight: '1.5', fontSize: '1.3rem', color: 'black', textAlign: 'left' }}><span style={{ fontSize: '1.4rem' }}>PRO</span></div>
                                            </div>
                                            <div style={{ marginBottom: '1rem', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                                <div style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}><span>Korlátlan tárca</span></div>
                                            </div>
                                            <div style={{ marginBottom: '.5rem', flexShrink: '0', flexGrow: '1', color: '#656a91' }}>
                                                <div style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.3rem', color: 'black', textAlign: 'left' }}><span style={{ fontSize: '1.3rem' }}><NumberFormatComponent number={94900} /> HUF</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div style={{ borderRadius: '1rem', width: '60%', paddingTop: '2rem', marginLeft: '2rem' }}>
                            <div className="flex justify-between">
                                <span style={{ fontWeight: '600', lineHeight: '1.5', fontSize: '1.5rem', color: 'black' }}>Termék információ</span>
                            </div>
                            <div className="flex justify-between" style={{ marginTop: '30px' }}>
                                <span style={{ fontWeight: '400', lineHeight: '1.5', fontSize: '1.3rem', color: 'black' }}>Termék:</span>
                                <span style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.3rem', color: 'black', textAlign: 'center' }}>
                                    {selectedProduct === 'BASIC' && 'Jövedelemkalkuláció - 2023 (BASIC)'}
                                    {selectedProduct === 'MID' && 'Jövedelemkalkuláció - 2023 (MID)'}
                                    {selectedProduct === 'PRO' && 'Jövedelemkalkuláció - 2023 (PRO)'}
                                </span>
                            </div>
                            <div className="flex justify-between" style={{ marginTop: '10px' }}>
                                <span style={{ fontWeight: '400', lineHeight: '1.5', fontSize: '1.3rem', color: 'black' }}>Ár:</span>
                                {selectedProduct === 'BASIC' && <span style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.3rem', color: 'black', textAlign: 'center' }}><NumberFormatComponent number={39000} /> HUF</span>}
                                {selectedProduct === 'MID' && <span style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.3rem', color: 'black', textAlign: 'center' }}><NumberFormatComponent number={69000} /> HUF</span>}
                                {selectedProduct === 'PRO' && <span style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.3rem', color: 'black', textAlign: 'center' }}><NumberFormatComponent number={95000} /> HUF</span>}
                            </div>
                            <div className="flex justify-between" style={{ marginTop: '10px' }}>
                                <div>
                                    <span style={{ fontWeight: '400', lineHeight: '1.5', fontSize: '1.2rem', color: 'black' }}>Leírás:</span>
                                </div>
                                <div style={{ textAlign: 'justify', maxWidth: '380px' }}>
                                    {selectedProduct === 'BASIC' && <span style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.1rem', color: 'black' }}>A <strong>BASIC</strong> csomagban 2 tárcára vonatkozó jövedelemkalkulációt biztosítunk. Amennyiben 2-nél több tárcával rendelkezel, a rendszer automatikusan a két legtöbb tranzakcióval rendelkező tárcát választja ki.</span>}
                                    {selectedProduct === 'MID' && <span style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.1rem', color: 'black' }}>A <strong>MID</strong> csomagban 3 tárcára vonatkozó jövedelemkalkulációt biztosítunk. Amennyiben 3-nél több tárcával rendelkezel, a rendszer automatikusan a három legtöbb tranzakcióval rendelkező tárcát választja ki.</span>}
                                    {selectedProduct === 'PRO' && <span style={{ fontWeight: '500', lineHeight: '1.5', fontSize: '1.1rem', color: 'black' }}>A <strong>PRO</strong> csomagban korlátlan tárcára vonatkozó jövedelemkalkulációt biztosítunk ahol a manuálisan felvitt tranzakciókat is figyelembe veszi a rendszer.</span>}
                                </div>
                            </div>
                            <div style={{ marginTop: '40px', textAlign: 'center' }}>
                                <span style={{ fontWeight: '600', lineHeight: '1.5', fontSize: '.95rem', color: 'red' }}>Figyelem! Csak előreutalással van lehetőség fizetni, a bankkártyás fizetésen jelenleg dolgozunk.</span>
                            </div>
                            <div style={{ marginTop: '40px', textAlign: 'center' }}>
                                <span style={{ fontWeight: '600', lineHeight: '1.5', fontSize: '.95rem', color: 'red' }}>Fizetés után kollégáink hozzáférést adnak a jövedelemkalkulációhoz.</span>
                            </div>
                            <div style={{ marginTop: '40px' }}>
                                <label style={{ fontSize: '12px' }}>
                                    <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleChangeAllow}
                                    />
                                    Elolvastam és megértettem, hogy a vásárlás fizetési kötelezettséggel jár, fizetésemmel elfogadom a szolgáltatás <a href="/assets/docs/kontri_aszf.pdf" target="_blank">Általános szerződési feltételeit</a> és az <a href="/assets/docs/kontri_adatkezelesi_szabalyzat.pdf" target="_blank">Adatkezelési tájékoztatót</a>.
                                </label>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '15px' }}>
                                <button onClick={handlePayment} style={{ width: '250px' }} className='text-ds-button-sm mb-ds-0 me-ds-0 ms-ds-0 mt-ds-5 box-border inline-block cursor-pointer rounded-ds-round bg-ds-button-primary-medium px-ds-5 py-ds-3 text-ds-button-primary-medium hover:bg-ds-button-primary-medium-hover mt-7'>
                                    <span className='flex items-center justify-center'>
                                        <span className='my-px'>Szolgáltatás megrendelése</span>
                                    </span>
                                </button>
                            </div>
                            {paymentError && <Alert severity="error">Előbb el kell mentened a számlázási adataidat.</Alert>}
                            {notSelectedError && <Alert severity="info">A fizetés megkezdéséhez válassz ki egy csomagot.</Alert>}
                            {isCheckedError && <Alert severity="warning">A fizetés megkezdéséhez el kell fogadnod az általános szerződési feltételeket.</Alert>}
                            {/*<div style={{ display: 'flex', justifyContent: 'center', marginTop: '60px' }}>
                                <div style={{ maxWidth: '100%', height: 'auto' }}>
                                    <img src='/assets/images/site_images/barion-card-strip-intl__medium.png' alt='Exchanges' style={{ width: '100%', height: '100%' }} />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                ) : 
                <Alert variant='filled' severity="success">A fizetési kérelmedet sikeresen megkaptuk, hamarosan jelentkezünk!.</Alert>}
            </section>
        </main>
    </div>
    )
};

export default ProductPurchase;