import { Typography } from "@mui/material";
import FlexBetween from "../../components/general/FlexBetween";
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {

  return (
    <div className="footer">
        <div className="footer-container">
            <div className="footer-kontri flex">
                <FlexBetween gap={0.5}>
                    <img src={window.location.origin + '/assets/icons/logo.png'} alt="logo" height={20} />
                    <Typography variant="h4" fontSize="20px">
                        Kontri
                    </Typography>
                </FlexBetween>
            </div>
            <ul className="footer-socials">
                <li className="footer-social-item">
                    <a href="https://twitter.com/Kontri_io" target="_blank" rel="noreferrer"><TwitterIcon/></a>
                </li>
                <li className="footer-social-item">
                    <a href="https://www.linkedin.com/company/kontri-io/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
                </li>
            </ul>
            <div className="footer-copyright">© 2024 Nostrum Services Kft.</div>
            <div className="footer-content">
                <div>
                    <h2 className="footer-section-header">Rólunk</h2>
                    <ul className="footer-section-menu footer-section-menu-small">
                        <li className="footer-section-menu-item"><a>Rólunk</a></li>
                        <li className="footer-section-menu-item"><a>Kapcsolat</a></li>
                    </ul>
                </div>
                <div>
                    <h2 className="footer-section-header">Információ</h2>
                    <ul className="footer-section-menu">
                        <li className="footer-section-menu-item"><a style={{ color: '#595b60' }} href="/assets/docs/kontri_aszf.pdf" target="_blank">Általános szerződési feltételek</a></li>
                        <li className="footer-section-menu-item"><a style={{ color: '#595b60' }} href="/assets/docs/kontri_adatkezelesi_szabalyzat.pdf" target="_blank">Adatkezelési tájékoztató</a></li>
                    </ul>
                </div>
                <div>
                    <h2 className="footer-section-header">Online fizetés a Barionnal</h2>
                    <div style={{ maxWidth: '100%', height: 'auto', marginTop: '0px' }}>
                        <img src='/assets/images/site_images/barion-card-strip-intl__small.png' alt='Barion' style={{ width: '100%', height: '100%' }} />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Footer;