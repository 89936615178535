import { useMemo } from 'react';
import { kpis, products, transactions } from '../dashboard/data';
import { Box, Typography, useTheme } from '@mui/material';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { Cell, Pie, PieChart } from 'recharts';
import DashboardBox from '../../components/dashboard/DashboardBox';
import BoxHeader from '../../components/dashboard/BoxHeader';
import { GetKpisResponse, GetProductsResponse, GetTransactionsResponse } from '../../state/types';
import FlexBetween from '../../components/general/FlexBetween';
import NewsFeed from '../../components/dashboard/NewsFeed';

function convertToGetKpisResponse(data: any[]): Array<GetKpisResponse> {
  return data.map((item) => {
    // Extracting totalProfit, totalRevenue, and totalExpenses from strings to numbers
    const totalProfit = parseFloat(item.totalProfit.replace('$', '').replace(/,/g, ''));
    const totalRevenue = parseFloat(item.totalRevenue.replace('$', '').replace(/,/g, ''));
    const totalExpenses = parseFloat(item.totalExpenses.replace('$', '').replace(/,/g, ''));

    // Extracting monthly data
    const monthlyData = item.monthlyData.map((month: any) => ({
      id: month.month.toLowerCase(),
      month: month.month,
      revenue: parseFloat(month.revenue.replace('$', '').replace(/,/g, '')),
      expenses: parseFloat(month.expenses.replace('$', '').replace(/,/g, '')),
      operationalExpenses: parseFloat(month.operationalExpenses.replace('$', '').replace(/,/g, '')),
      nonOperationalExpenses: parseFloat(month.nonOperationalExpenses.replace('$', '').replace(/,/g, '')),
    }));

    // Extracting daily data
    const dailyData = item.dailyData.map((day: any) => ({
      id: day.date,
      date: day.date,
      revenue: parseFloat(day.revenue.replace('$', '').replace(/,/g, '')),
      expenses: parseFloat(day.expenses.replace('$', '').replace(/,/g, '')),
    }));

    // Creating the GetKpisResponse object
    return {
      id: item._id,
      _id: item._id,
      __v: 0, // Assuming __v is always 0
      totalProfit,
      totalRevenue,
      totalExpenses,
      expensesByCategory: {
        salaries: 38000.00, // You can set appropriate values if available
        supplies: 13000.00,
        services: 10000.00,
      },
      monthlyData,
      dailyData,
      createdAt: '', // You can set the appropriate createdAt value if available
      updatedAt: '', // You can set the appropriate updatedAt value if available
    };
  });
}

const getKpisResponseArray = convertToGetKpisResponse(kpis);

function convertToGetProductsResponse(data: any[]): Array<GetProductsResponse> {
  return data.map((item) => {
    // Parse price and expense strings to numbers
    const price = parseFloat(item.price.replace('$', ''));
    const expense = parseFloat(item.expense.replace('$', ''));

    return {
      id: item._id,
      _id: item._id,
      __v: item.__v,
      price: price,
      expense: expense,
      transactions: item.transactions,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    };
  });
}

const getProductsResponseArray = convertToGetProductsResponse(products);

function convertToGetTransactionsResponse(data: any[]): Array<GetTransactionsResponse> {
  return data.map((item) => {
    // Extract properties from the input data and convert them to the desired format.
    const responseItem: GetTransactionsResponse = {
      id: item._id,
      _id: item._id,
      __v: 0, // You can set any default value for __v, as it's not provided in the input data.
      buyer: item.buyer,
      amount: parseFloat(item.amount.replace('$', '')), // Remove '$' and parse as a float.
      productIds: item.productIds,
      createdAt: new Date().toISOString(), // You can set the createdAt and updatedAt values as needed.
      updatedAt: new Date().toISOString(),
    };

    return responseItem;
  });
};

const transactionsResponse = convertToGetTransactionsResponse(transactions);

const Row3 = () => {
    const pieColors = ["#fff" , "#fff" ];
    const operationalData = getKpisResponseArray;
    const productData = getProductsResponseArray;
    const transactionsData = transactionsResponse;

    const pieChartData = useMemo(() => {
        if(operationalData) {
            const totalExpenses = operationalData[0].totalExpenses;
            return Object.entries(operationalData[0].expensesByCategory).map(
                ([key, value]) => {
                    return [
                        {
                            name: key,
                            value: value,
                        },
                        {
                            name: `${key} of Total`,
                            value: totalExpenses - value
                        },
                    ];
                }
            );
        }
    }, [operationalData]);

    const productColumns = [
        {
            field: "_id",
            headerName: "id",
            flex: 1,
        },
        {
            field: "expense",
            headerName: "Expense",
            flex: 0.5,
            renderCell: (params: GridCellParams) => `$${params.value}`
        }
        ,
        {
            field: "price",
            headerName: "Price",
            flex: 0.5,
            renderCell: (params: GridCellParams) => `$${params.value}`
        }
    ];

    const transactionColumns = [
        {
            field: "_id",
            headerName: "id",
            flex: 1,
        },
        {
            field: "buyer",
            headerName: "Buyer",
            flex: 0.67,
        },
        {
            field: "amount",
            headerName: "Amount",
            flex: 0.35,
            renderCell: (params: GridCellParams) => `$${params.value}`
        },
        {
            field: "productIds",
            headerName: "Count",
            flex: 0.1,
            renderCell: (params: GridCellParams) => (params.value as Array<string>).length,
        },
    ];

    return (
        <>
            <DashboardBox gridArea="g">
                <BoxHeader
                    title="Latest news"
                    sideText={`Read more`}
                    link={true}
                    linkUrl='/news'
                />
                <Box
                    mt="1rem"
                    p="0 0.5rem 0rem"
                    height="78%"
                    sx={{
                        "& .MuiDataGrid-root": {
                            color: "#fff" ,
                            border: "none"
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: `1px solid ${"#fff" } !important`
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            borderBottom: `1px solid ${"#fff" } !important`
                        },
                        "& .MuiDataGrid-columnSeparator": {
                            visibility: "hidden",
                        }
                    }}
                >
                    <NewsFeed />
                </Box>
            </DashboardBox>
            <DashboardBox gridArea="h">
                <BoxHeader
                    title="Recent of Transactions"
                    sideText={`${productData?.length} latest transactions`}
                />
                <Box
                    mt="1rem"
                    p="0 0.5rem"
                    height="80%"
                    sx={{
                        "& .MuiDataGrid-root": {
                            color: "#fff" ,
                            border: "none"
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: `1px solid ${"#fff" } !important`
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            borderBottom: `1px solid ${"#fff" } !important`
                        },
                        "& .MuiDataGrid-columnSeparator": {
                            visibility: "hidden",
                        }
                    }}
                >
                    <DataGrid
                        columnHeaderHeight={25}
                        rowHeight={35}
                        hideFooter={true}
                        rows={transactionsData || []}
                        columns={transactionColumns}
                    />
                </Box>
            </DashboardBox>
            <DashboardBox gridArea="i">
                <BoxHeader title="Expense Breakdown By Category" sideText="+4%" />
                <FlexBetween mt="0.5rem" gap="0.5rem" p="0 1rem" textAlign="center">
                    {pieChartData?.map((data, i) => (
                        <Box key={`${data[0].name}-${i}`}>
                            <PieChart width={110} height={100}>
                                <Pie
                                    stroke="none"
                                    data={data}
                                    innerRadius={18}
                                    outerRadius={35}
                                    paddingAngle={2}
                                    dataKey="value"
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={pieColors[index]} />
                                    ))}
                                </Pie>
                            </PieChart>
                            <Typography variant="h5">{data[0].name}</Typography>
                        </Box>
                    ))}
                </FlexBetween>
            </DashboardBox>
            <DashboardBox gridArea="j">
                <BoxHeader title="Overall Summary and  Explanation Data" sideText="+15%" />
                <Box
                    height="15px"
                    margin="1.25rem 1rem 0.4rem 1rem"
                    bgcolor={"#fff" }
                    borderRadius="1rem"
                >
                    <Box
                        height="15px"
                        bgcolor={"#fff" }
                        borderRadius="1rem"
                        width="40%"
                    ></Box>
                </Box>
                <Typography margin="0 1rem" variant="h6">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Typography>
            </DashboardBox>
        </>
    )
};

export default Row3;