import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoginRequest } from "../dto/login-request.dto";
import { User } from "../models/User";
const { REACT_APP_API_ENDPOINT } = process.env;

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({ 
        baseUrl: `${REACT_APP_API_ENDPOINT}/api/auth`, 
        credentials: "include" 
    }),
    endpoints: (build) => ({
        login: build.mutation<User, LoginRequest>({
            query: (loginRequest) => ({
                url: "/login",
                method: "POST",
                body: loginRequest,
            })
        }),
        logout: build.mutation<void, void>({
            query: () => ({
                url: "/logout",
                method: "POST",
            })
        }),
      
    }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;


