import { Box, Button, TextField, Typography, useTheme, Divider } from '@mui/material';
import { useNavigate } from "react-router";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import Alert from '@mui/material/Alert';
import { useSetPasswordMutation } from '../../../api/user.api';

const SetPasswordForm: React.FC<{ userId: string, token: string }> = ({ userId, token }) => {
    const [password, setPassword] = useState("");
    const [passwordErrored, setPasswordErrored] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const[setNewPassword] = useSetPasswordMutation();
    const [operationSuccess, setOperationSuccess] = useState(false);
    const navigate = useNavigate();

    const handlePassword = async () => {
        setErrorMessage("");
        setPasswordErrored(!password);

        try {
            if ( !password ) {
                return;
            }
            await setNewPassword({ userId, token, password });
            setOperationSuccess(true);
        } catch (err) {
            setErrorMessage("Incorrect email or password. Please check your credentials and try again.");
            console.error("error", err);
        }
    }

    return (
        <Box display="flex"
            padding="8rem 10rem" 
            flexDirection="column" 
            justifyContent="space-between"
            alignItems="center"
            flex="1 0 0"
            alignSelf="stretch"
            width="50%"
        >
            {errorMessage && (
                <Alert severity="error" style={{ width: '100%', marginBottom: '1rem' }}>
                    {errorMessage}
                </Alert>
            )}
            <Typography
                textAlign="center"
                fontSize="3rem"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="125%"
                letterSpacing="-0.036rem"
                className="main-name"
            >
                Kontri.io
            </Typography>
            <Box
                display="flex"
                paddingBottom="0px"
                flexDirection="column"
                alignItems="flex-start"
                gap="2rem"
                width="30.625rem"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="0.5rem"
                >
                    <Typography
                        fontFamily="Inter"
                        fontSize="1.7rem"
                        fontStyle="normal"
                        fontWeight="600"
                        lineHeight="2.25rem"
                        letterSpacing="-0.01406rem"
                    >
                        Kezdj hozzá most ✨
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="1rem"
                    alignSelf="stretch"
                >
                    {operationSuccess ? (
                    <Alert severity="success" style={{ width: '100%', marginBottom: '0rem' }}>
                        A jelszó megváltoztatása sikeresen megtörtént.
                    </Alert>
                    ) : (
                    <>
                        <TextField
                            label="Jelszó"
                            fullWidth
                            type="password"
                            required
                            helperText={passwordErrored && "Nem adtál meg jelszót."}
                            value={password}
                            onChange={(event) => {
                                setPassword(event.target.value);
                            }}
                            error={passwordErrored}
                        />
                        <PasswordStrengthBar password={password} style={{ width: '100%' }} minLength={8} />                    
                        <Button className="login-button" variant="contained" fullWidth onClick={handlePassword}>
                            <span style={{ color: '#FFF' }}>Set Password</span>
                        </Button>
                    </>
                    )}
                    <Typography>Már van fiókod?
                        <Link to="/login" className="custom-color">    Bejelentkezés</Link>
                    </Typography>
                </Box>
            </Box>
            <Typography>2024 Kontri, Minden jog fenntartva</Typography>
        </Box>
    )
};

export { SetPasswordForm };
