import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const { REACT_APP_API_ENDPOINT } = process.env;

export const documentApi = createApi({
    reducerPath: "documentApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${REACT_APP_API_ENDPOINT}/api/document-export`, credentials: "include" }),
    endpoints: (build) => ({
        generateDocument: build.mutation<Blob, string>({
            query: (userId) => ({
                url: '/generate-document',
                method: "POST",
                body: {
                    userId: userId
                },
                responseHandler: async (response) => {
                    if (!response.ok) {
                        const errorData = await response.blob();
                        console.error("Error response:", errorData);
                        throw new Error("Failed to generate document: " + response.statusText);
                    }
                    console.log("Response received:", response);
                    return response.blob();
                },
            }),
        }),
    }),
});

export const { useGenerateDocumentMutation } = documentApi;
