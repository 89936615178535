import { Box, Button, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import FlexBetween from '../general/FlexBetween';
import { useNavigate } from "react-router";

type Props = {
    name: string;
    description: string;
    isDateRange: boolean;
    buttonText?: string;
    buttonUrl?: string;
};

const PageHeader = ({ name, description, isDateRange, buttonText, buttonUrl }: Props) => {
    const { palette } = useTheme();
    const [selected, setSelected] = useState("12months");
    const navigate = useNavigate();

    const handleButtonClick = async () => {
        try {
            if (buttonUrl) {
                navigate(buttonUrl);
            }
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <FlexBetween
            color={palette.grey[400]}
        >
            <FlexBetween>
                <Box width="100%">
                    <Typography variant="h1" mb="0.2rem">
                        {name}
                    </Typography>
                    <Typography variant="h4" fontWeight="200" mb="0.8rem">
                        {description}
                    </Typography>
                    {isDateRange && (
                    <FlexBetween>
                        <Button
                            variant="outlined"
                            onClick={() => setSelected("12months")}
                            sx={{
                                color: palette.primary.main,
                                backgroundColor: selected === "12months" ? "inherit" : "inherit",
                                borderTopRightRadius: "0",
                                borderBottomRightRadius: "0",
                                boxShadow: "0.1rem 0.1rem 0.1rem 0.1rem rgba(0,0,0,.4)"
                            }}
                        >
                            12 months
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setSelected("30days")}
                            sx={{
                                color: palette.primary.main,
                                backgroundColor: selected === "30days" ? "inherit" : "inherit",
                                borderRadius: "0",
                                boxShadow: "0.1rem 0.1rem 0.1rem 0.1rem rgba(0,0,0,.4)"
                            }}
                        >
                            30 days
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setSelected("7days")}
                            sx={{
                                color: palette.primary.main,
                                backgroundColor: selected === "7days" ? "inherit" : "inherit",
                                borderRadius: "0",
                                boxShadow: "0.1rem 0.1rem 0.1rem 0.1rem rgba(0,0,0,.4)"
                            }}
                        >
                            7 days
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setSelected("24hours")}
                            sx={{
                                color: palette.primary.main,
                                backgroundColor: selected === "24hours" ? "inherit" : "inherit",
                                borderTopLeftRadius: "0",
                                borderBottomLeftRadius: "0",
                                boxShadow: "0.1rem 0.1rem 0.1rem 0.1rem rgba(0,0,0,.4)"
                            }}
                        >
                            24 hours
                        </Button>
                    </FlexBetween>
                    )}
                </Box>
            </FlexBetween>
            <Button
                variant="outlined"
                onClick={handleButtonClick}
                sx={{
                    color: palette.primary.main,
                    boxShadow: "0.1rem 0.1rem 0.1rem 0.1rem rgba(0,0,0,.4)"
                }}
            >
                {buttonText && (
                    buttonText
                )}
            </Button>
        </FlexBetween>
    );
};

export default PageHeader;