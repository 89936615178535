import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import { useState } from 'react';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';

type Props = {};

const RevolutGuide = (props: Props) => {
    return (
    <div className='content-container'>
        <main className='content-content-full-width'>
            <header className='content-header section header'>
                <div className='exchanges-header'>
                    <h1 className='text-ds-brand text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Útmutatók / Revolut</h1>
                </div>
            </header>
            <section className='primary-content'>
                <div className='ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0 rounded-ds-7 bg-ds-container shadow-ds-container overflow-hidden px-ds-9 py-ds-7'>
                    <div className='flex items-center justify-between mb-ds-7'>
                        <div className='flex flex-col'>
                            <span className='text-ds-primary text-ds-celias-36-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-2'>Adatok szinkronizálása a Revolut-ról</span>
                            <span className='text-ds-neutral text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>A következő leírás abban segít, hogy a legtöbbet hozhasd ki a <strong>Kontri.io</strong>-ból</span>
                        </div>
                    </div>
                    <div className='flex items-center justify-between mb-ds-7'>
                        <div className='flex flex-col'>
                            <span className='text-ds-primary text-ds-plex-16-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '15px' }}>A <strong>Revolut</strong> tárcák hozzáadásakor javasoljuk a CSV fájlfeltöltés használatát.</span>
                        </div>
                    </div>
                    <div className='flex items-center justify-between mb-ds-7'>
                        <div className='flex flex-col'>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '10px', marginBottom: '30px' }}>CSV fájlok generálása és feltöltése</span>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.1rem', marginBottom: '20px' }}>Webes felületes:</span>
                            <ol style={{ marginLeft: '35px', marginBottom: '25px' }}>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Jelentkezz be a <strong>Revolut</strong> fiókodba.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Kattints a <strong>Kriptovaluta</strong> vagy <strong>Crypto</strong> fülre.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Válaszd ki a <strong>token/coin</strong>-t, pédául a Bitcoint.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Válaszd ki a <strong>statement/kimutatás</strong> opciót.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Exportáld adataidat <strong>2023 januártól</strong> a jelenlegi hónapig <strong>Excel</strong> formátumban.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Menj a Kontri <strong>Tárcák</strong> oldalára, majd kattints a <strong>Tárca hozzáadása</strong> gombra.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Válaszd ki a <strong>Revolut</strong> opciót.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Válaszd ki a feltöltendő fájlt, majd kattints az <strong>Import</strong> gombra.</span></li>
                            </ol>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '10px', marginBottom: '40px' }}>Adatok exportálása a webes felületen</span>
                            <div style={{ maxWidth: '100%', height: 'auto' }}>
                                <img src='/assets/images/site_images/revolut_data.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.1rem', marginBottom: '20px', marginTop: '40px' }}>Mobil alkalmazásban:</span>
                            <ol style={{ marginLeft: '35px', marginBottom: '25px' }}>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Nyisd meg a <strong>Revolut</strong> alkalmazást.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Az alsó sorban válaszd ki a <strong>Crypto</strong> opciót.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Válassz ki egy tetszőleges <strong>token/coin</strong>-t, amivel kereskedtél.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Válaszd ki a <strong>statement/kimutatás</strong> opciót.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Válassz ki minden kriptovalutát amivel kereskedtél, ahogy az az alábbi képen is látható.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Exportáld adataidat <strong>2023 januártól</strong> a jelenlegi hónapig <strong>Excel</strong> formátumban.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Menj a Kontri <strong>Tárcák</strong> oldalára, majd kattints a <strong>Tárca hozzáadása</strong> gombra.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Válaszd ki a <strong>Revolut</strong> opciót.</span></li>
                                <li style={{ marginBottom: '15px' }}><span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0'>Válaszd ki a feltöltendő fájlt, majd kattints az <strong>Import</strong> gombra.</span></li>
                            </ol>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '10px', marginBottom: '40px' }}>Kriptovaluták kiválasztása a mobil alkalmazásban</span>
                            <div style={{ maxWidth: '35%', height: 'auto' }}>
                                <img src='/assets/images/site_images/revolut_account_mobil.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '30px', marginBottom: '40px' }}>Adatok exportálása a mobil alkalmazásban</span>
                            <div style={{ maxWidth: '35%', height: 'auto' }}>
                                <img src='/assets/images/site_images/revolut_export_mobil.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-plex-16-bold ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ fontWeight: '600', fontSize: '1.2rem', marginTop: '30px', marginBottom: '40px' }}>Kraken Tárca létrehozása</span>
                            <div style={{ maxWidth: '100%', height: 'auto' }}>
                                <img src='/assets/images/site_images/revolut_wallet.png' alt='Exchanges' style={{ width: '100%', height: '100%', borderRadius: '1rem' }} />
                            </div>
                            <span className='text-ds-primary text-ds-celias-18-regular ms-ds-0 me-ds-0 mt-ds-0 mb-ds-0' style={{ marginTop: '30px', marginBottom: '0px' }}>Ha egyéb kérdésed merül fel, lépj velünk kapcsolatba!</span>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
    )
};

export default RevolutGuide;